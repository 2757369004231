import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, InputNumber } from 'antd';
import useRequest from 'hooks/useRequest';
import { updateOcValuePerLabel } from 'services/oclabels';

function OverCommitmentModal({ isNew, oclabel, refetchOcLabels, close }) {
  let { label, size, overCommitmentValue } = oclabel ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async ocLabelInfo => {
        await updateOcValuePerLabel(ocLabelInfo);
        await refetchOcLabels();
      },
      [refetchOcLabels],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(ocLabelInfo) {
    await makeRequest(ocLabelInfo);
    close();
  }

  return (
    <Modal
      visible
      title={'Edit Over Commitment per Node Label'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          label,
          size,
          overCommitmentValue,
        }}
        layout="vertical"
        name="OcLabel"
        onFinish={handleFinish}
      >
        <Form.Item name="label" label="Label" rules={[{ required: true }]}>
          <Input disabled={true} defaultValue={label} />
        </Form.Item>

        <Form.Item name="size" label="Size" rules={[{ required: true }]}>
          <Input disabled={true} defaultValue={size} />
        </Form.Item>

        <Form.Item name="overCommitmentValue" label="Over Commitment Value">
          <InputNumber defaultValue={null} min="1" />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default OverCommitmentModal;
