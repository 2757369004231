/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import './index.less';

import { useState, useEffect, useContext } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { IntlProvider } from 'react-intl';
import { SessionContext, SessionProvider } from './auth/SessionProvider';
import { AppStateProvider } from './contexts/AppState';
import Auth0ProviderWithRedirectCallback from './auth/auth0Provider';
import AppRoutes from './components/AppRoutes/AppRoutes';
import * as serviceWorker from './serviceWorker';
import { flattenMessages } from 'utils/locale';
import locale from 'locale';
import './styles/overrides.less';

type MyWindow = typeof window & {
  drift: any;
  driftt: any;
  qualified: any;
};

export const queryClient = new QueryClient();

function App() {
  const { user } = useContext(SessionContext);
  const [chatIsShown, setChatIsShown] = useState(false);

  useEffect(() => {
    user ? setChatIsShown(true) : setChatIsShown(false);
  }, [user]);

  const interval = setInterval(() => {
    const el = document.querySelector('iframe#q-messenger-frame');
    if (el !== null) {
      chatIsLoaded(el);
      clearInterval(interval);
    }
  }, 2000);

  const chatIsLoaded = (el: any) => {
    const chatIframe = document.getElementsByClassName('show-qualified-chat');

    if (chatIsShown && window.location.pathname !== '/signup') {
      if (chatIframe.length === 0) el.classList.add('show-qualified-chat');
    } else {
      el.classList.remove('show-qualified-chat');
    }
  };

  useEffect(() => {
    const el = document.querySelector('iframe#q-messenger-frame');
    if (el !== null) {
      chatIsLoaded(el);
    }
    //eslint-disable-next-line
  }, [chatIsShown]);

  const showChat = (value: boolean) => {
    setChatIsShown(value);

    if (
      window.location.pathname === '/signup' ||
      window.location.pathname === '/signin'
    ) {
      (window as MyWindow).qualified(
        'showExperience',
        'experience-1645200217227',
      );
    } else if (window.location.pathname === 'account-created') {
      (window as MyWindow).qualified(
        'showExperience',
        'experience-1645200217227',
      );
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <IntlProvider locale="en-US" messages={flattenMessages(locale['en-US'])}>
        <BrowserRouter>
          <Auth0ProviderWithRedirectCallback>
            <SessionProvider>
              <AppStateProvider setChatIsShown={showChat}>
                <AppRoutes />
              </AppStateProvider>
            </SessionProvider>
          </Auth0ProviderWithRedirectCallback>
        </BrowserRouter>
      </IntlProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

render(<App />, document.getElementById('root'));

serviceWorker.unregister();
