import React, { useCallback, useState, useEffect } from 'react';
import { Button, Space, Form, Typography, Checkbox } from 'antd';
const { Title } = Typography;

import useRequest from 'hooks/useRequest';
import { updateInstanceBlockedActions } from 'services/cluster';
import { useGetConfigurations } from 'hooks/queries';
import { multiclusterAction } from 'utils/helpers';

function LockActionsModal({
  cluster,
  clusterIds,
  refetchClusters,
  handleShowErrorModal,
  close,
}) {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('Instance Locked Actions');
  let selfActions = [];
  let adminActions = [];
  let { id } = cluster ?? {};

  const {
    data,
    isLoading,
    refetch: refetchConfigurations,
  } = useGetConfigurations({ page, search });

  let { makeRequest } = useRequest(
    useCallback(
      async newInstanceLockedActions => {
        await multiclusterAction(
          updateInstanceBlockedActions,
          [newInstanceLockedActions, cluster ? [id] : clusterIds],
          refetchClusters,
          handleShowErrorModal,
        );
        close();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [refetchClusters, id, cluster, clusterIds],
    ),
    {
      handleError: true,
    },
  );

  let configurations = data?.data?.configurations;

  if (configurations) {
    configurations = configurations[0]?.configValue?.split(',');
    configurations = configurations?.map(item => item.trim());
    selfActions = configurations.filter(configuration =>
      configuration.startsWith('Self'),
    );
    adminActions = configurations.filter(
      configuration => !configuration.startsWith('Self'),
    );
  }

  const [adminCheckedValues, setAdminCheckedValues] = useState([]);
  let lockedActionsList = cluster?.lockedActions?.split(',');
  lockedActionsList = lockedActionsList?.map(item => item.trim());
  const lockedActionsListSelf = lockedActionsList?.filter(str =>
    str.startsWith('Self'),
  );
  const lockedActionsListAdmin = lockedActionsList?.filter(
    str => !str.startsWith('Self'),
  );
  const [customerCheckedValues, setCustomerCheckedValues] = useState([]);

  useEffect(() => {
    setCustomerCheckedValues(lockedActionsListSelf);
    setAdminCheckedValues(lockedActionsListAdmin);
  }, [id, clusterIds]);

  const handleFinish = async () => {
    let newInstanceLockedActions = [
      ...customerCheckedValues || [],
      ...adminCheckedValues || [],
    ];
    await makeRequest(newInstanceLockedActions);
  };

  return (
    <div title="Blocked Actions">
      {!isLoading && (
        <Form
          className="ActionLocks"
          layout="vertical"
          name="actions"
          onFinish={handleFinish}
          initialValues={{
            lockedCustomerActionsList: lockedActionsListSelf,
            lockedAdminActionsList: lockedActionsListAdmin,
          }}
        >
          <Title level={5}>Blocked Actions From Portal</Title>
          <Form.Item name="lockedCustomerActionsList">
            <Checkbox.Group
              options={selfActions}
              onChange={checkedValues =>
                setCustomerCheckedValues(checkedValues)
              }
            />
          </Form.Item>
          <Title level={5}>Blocked Actions From Admin</Title>
          <Form.Item name="lockedAdminActionsList">
            <Checkbox.Group
              options={adminActions}
              onChange={checkedValues => setAdminCheckedValues(checkedValues)}
            />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                {'Submit'}
              </Button>
              <Button onClick={close}>{'Cancel'}</Button>
            </Space>
          </Form.Item>
        </Form>
      )}
    </div>
  );
}

export default LockActionsModal;
