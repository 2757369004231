import React, { useCallback, useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { jsonParser } from 'utils/jsonParser';

function AuditModal({ audit, refetchAudit, close }) {
  let { id, extraInfo } = audit ?? {};
  let [keys, setKeys] = useState<any[]>([]);
  let extraInfoJson: any[] = [];

  if (extraInfo) {
    extraInfo = JSON.stringify(extraInfo).replace(/(?:\\[rn])+/g, '');
    extraInfoJson = jsonParser(extraInfo);
  }

  useEffect(() => {
    const options = [...Object.keys(extraInfoJson)];
    setKeys(options);
    // eslint-disable-next-line
  }, [extraInfo]);

  let { makeRequest } = useRequest(
    useCallback(
      async _configuration => {
        await refetchAudit();
      },
      // eslint-disable-next-line
      [id, refetchAudit],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(audit) {
    await makeRequest(audit);
    close();
  }

  return (
    <Modal visible title={'Extra Information'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          extraInfoJson,
        }}
        layout="vertical"
        name="User"
        onFinish={handleFinish}
      >
        {keys.map(key => {
          return (
            <Form.Item
              label={<p style={{ fontWeight: 'bold' }}>{key}</p>}
              rules={[{ required: true }]}
            >
              {extraInfoJson[key].split(/,/).map(line => (
                <div key={line}>{line}</div>
              ))}
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
}

export default AuditModal;
