import './UserToggleFeatureModal.less';
import { Button, Form, Modal, Select, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import {
  useGetAzurePlans,
  useAddUserToAzureSupportFeature,
} from 'hooks/queries';

const userToggleFeatureModalFormFieldNames = {
  tacklePlan: 'tackle_plan',
} as const;

function UserToggleFeatureModal({
  selectedUser,
  feature,
  setIsUserToggleFeatureModalOpen,
}) {
  const [form] = Form.useForm();
  let { validateFields, getFieldsValue, setFieldsValue } = form;
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const {
    data: azurePlans,
    isLoading: isAzurePlansLoading,
    isError: haszurePlansAzurePlansError,
  } = useGetAzurePlans({
    userId: selectedUser?.uuid,
    userRole: selectedUser?.role?.name,
  });

  const {
    mutateAsync: mutateAddUserToFeature,
    isLoading: isMutateAddUserToFeatureLoading,
  } = useAddUserToAzureSupportFeature();

  const onSelectValuesChange = (changedValues: any, allValues: any) => {
    let isBtnDisabled = false;
    for (const [, value] of Object.entries(allValues)) {
      if (!value) {
        isBtnDisabled = true;
        break;
      }
    }
    if (!isBtnDisabled) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  };

  async function handleFinish(values: { tackle_plan: string }) {
    try {
      const result = await mutateAddUserToFeature({
        uid: selectedUser.uuid,
        tackle_plan: values?.tackle_plan,
        userRole: selectedUser?.role?.name,
      });
      if (result?.status?.toString().startsWith('2')) {
        setIsUserToggleFeatureModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function close() {
    setIsUserToggleFeatureModalOpen(false);
  }

  return (
    <Modal
      open
      title={
        <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.title" />
      }
      footer={null}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={{
          tackle_plan: '',
        }}
        layout="vertical"
        name="User Toggle Feature"
        onFinish={handleFinish}
        onValuesChange={onSelectValuesChange}
      >
        <Form.Item
          name={userToggleFeatureModalFormFieldNames.tacklePlan}
          className="user-toggle-feature-modal__label"
          label={
            <>
              <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.azurePlansDdlLabel" />
              <div className="user-toggle-feature-modal__free-user-hint">
                {selectedUser?.role?.name === 'free' &&
                  !azurePlans &&
                  !isAzurePlansLoading && (
                    <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.freeUserHint" />
                  )}
              </div>
            </>
          }
        >
          <Select
            placeholder={
              <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.azurePlansDdlLabel" />
            }
            disabled={!azurePlans || haszurePlansAzurePlansError}
            loading={isAzurePlansLoading}
          >
            {azurePlans?.map((azurePlan: any) => (
              <Select.Option key={azurePlan.plan} value={azurePlan.plan}>
                {azurePlan.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isMutateAddUserToFeatureLoading}
            disabled={
              submitBtnDisabled ||
              isAzurePlansLoading ||
              haszurePlansAzurePlansError
            }
          >
            <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.updateBtn" />
          </Button>
          <Button onClick={close}>
            <FormattedMessage id="systemManagement.toggleFeatures.azureSupport.userModal.cancelBtn" />
          </Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default UserToggleFeatureModal;
