import './EmailSentPage.less';
import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'antd';
import { ReactComponent as IncortaLogo } from '../../images/incorta-blue-logo.svg';
import { ReactComponent as EmailSentBackgroundImage } from './icons/email-sent-background-image.svg';

function EmailSentPage() {
  const intl = useIntl();
  const { loginWithRedirect, logout } = useAuth0();
  function resendEmail(e: React.MouseEvent<HTMLParagraphElement>) {
    e.preventDefault();

    loginWithRedirect({
      redirectUri: `${process.env.REACT_APP_URL}`,
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      screen_hint: 'login',
      forceSendEmailVerification: true,
    });
  }

  function handleReturnToHomePageButton() {
    logout({ returnTo: window.location.origin });
  }

  return (
    <div className="email__wrapper">
      <div className="email-sent-page__wrapper">
        <IncortaLogo className="email-sent__incorta-logo" />
        <section className="email-sent-page__info">
          <article className="email-sent-page__article">
            <h2>
              {intl.formatMessage({
                id: 'auth0.emailSent.pageTitle',
              })}
            </h2>
            <p>
              {intl.formatMessage({
                id: 'auth0.emailSent.matchingEmail',
              })}
            </p>
            <p>
              {intl.formatMessage({
                id: 'auth0.emailSent.cantFindEmail',
              })}
              <p
                className="email-sent-page__resend-email"
                onClick={resendEmail}
              >
                {intl.formatMessage({
                  id: 'auth0.emailSent.resendEmail',
                })}
              </p>
              {intl.formatMessage({
                id: 'auth0.emailSent.or',
              })}
              <a
                className="email-sent-page__contact-support"
                href="https://support.incorta.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage({
                  id: 'auth0.emailSent.contactSupport',
                })}
              </a>
            </p>
          </article>
          <div className="submit-form-button__wrapper">
            <Button
              className="submit-form-button"
              data-operation="logout"
              onClick={handleReturnToHomePageButton}
            >
              {intl.formatMessage({
                id: 'auth0.emailSent.returnToHomePage',
              })}
            </Button>
          </div>
        </section>
        <EmailSentBackgroundImage className="email-sent__background-image" />
      </div>
    </div>
  );
}

export default EmailSentPage;
