import axios from 'axios';
import { NotebookUsersConfigResult } from 'types/notebook';
import { API_URL } from 'utils/http';

export function getNotebookUsersConfigs() {
  return axios.get<NotebookUsersConfigResult>(
    `${API_URL}/spark/notebook-users-configs`,
  );
}

export function updateNotebookUsersConfigs({ configs }) {
  return axios.post(`${API_URL}/spark/notebook-users-configs`, { configs });
}

export async function updateNotebook({
  instanceID,
  enableBusinessUserNotebook,
  businessUserNotebookCount,
}: {
  instanceID: string;
  enableBusinessUserNotebook: boolean;
  businessUserNotebookCount?: number;
}) {
  try {
    const res = await axios.post<{ status: number; message: string }>(
      `${API_URL}/instances/update-notebook`,
      {
        instanceID,
        enableBusinessUserNotebook,
        businessUserNotebookCount: enableBusinessUserNotebook
          ? businessUserNotebookCount
          : 0,
      },
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
