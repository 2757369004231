import React from 'react';

export enum ClusterSizeEnum {
  XSmall = 'XSmall',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
  Size2XLarge = '2XLarge',
  Size3XLarge = '3XLarge',
  Size4XLarge = '4XLarge',
  Size6XLarge = '6XLarge',
  Size10XLarge = '10XLarge',
}

export type ClusterSizeValue =
  | 'small'
  | 'medium'
  | 'larges1'
  | 'larges2'
  | 'larges3'
  | 'larges4'
  | 'large3x'
  | 'larges5'
  | 'larges6'
  | 'larges7';

const clusterSizeMap = new Map<ClusterSizeEnum, ClusterSizeValue>([
  [ClusterSizeEnum.XSmall, 'small'],
  [ClusterSizeEnum.Small, 'medium'],
  [ClusterSizeEnum.Medium, 'larges1'],
  [ClusterSizeEnum.Large, 'larges2'],
  [ClusterSizeEnum.XLarge, 'larges3'],
  [ClusterSizeEnum.Size2XLarge, 'larges4'],
  [ClusterSizeEnum.Size3XLarge, 'large3x'],
  [ClusterSizeEnum.Size4XLarge, 'larges5'],
  [ClusterSizeEnum.Size6XLarge, 'larges6'],
  [ClusterSizeEnum.Size10XLarge, 'larges7'],
]);

function useClusterSize() {
  function getClusterSizeMapped(initialSize: ClusterSizeEnum) {
    return clusterSizeMap.get(initialSize);
  }

  function getClusterSizeKeyByValue(clusterSizeValue: ClusterSizeValue) {
    for (let [key, value] of clusterSizeMap) {
      if (value === clusterSizeValue) {
        return key;
      }
    }
  }

  return {
    getClusterSizeMapped,
    getClusterSizeKeyByValue,
    clusterSizeMap,
    ClusterSizeEnum,
  };
}

export default useClusterSize;
