import './PermissionField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getCliConfig } from 'services/clicommands';

let { Option } = Select;

function PermissionField({ name, label }) {
  let { result, isPending } = useRequest(getCliConfig, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let permissions = result?.data?.permissions;

  return (
    <Form.Item
      className="PermissionField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !permissions}
        permissions={permissions}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, permissions }: any) {
  return (
    <Select
      value={permissions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="PermissionField__select-dropdown"
    >
      {permissions?.map(permission => (
        <Option key={permission} value={permission}>
          {permission.name}
        </Option>
      ))}
    </Select>
  );
}

export default PermissionField;
