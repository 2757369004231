import { useCallback, useMemo, useState } from 'react';
import { Form, Select } from 'antd';
import semver from 'semver';
import useRequest from 'hooks/useRequest';
import { getImages } from 'services/images';

const { Option } = Select;
function VersionsFormFields({ form }) {
  const [minVersion, setMinVersion] = useState('');
  const { result } = useRequest(
    useCallback(() => getImages(), []),
    { resolve: true },
  );

  const versions = useMemo(() => {
    return result?.data?.images
      ?.map(image => ({
        label: image,
        value: image,
      }))
      .sort(({ value: a }, { value: b }) => {
        a = semver.valid(a) ? a : semver.coerce(a);
        b = semver.valid(b) ? b : semver.coerce(b);
        return semver.gt(a, b) ? 1 : -1;
      });
  }, [result]);

  const maxVersions = useMemo(() => {
    const versionIndex = versions?.findIndex(e => e.value === minVersion);
    return versions?.slice(versionIndex + 1, versions.length);
  }, [minVersion, versions]);

  return (
    <div className="log-analyzer-modal__versions">
      <Form.Item
        rules={[{ required: true, message: 'Version is Required' }]}
        label={'Min Version'}
        name={'minVersion'}
      >
        <Select
          showArrow
          placeholder={'select min version'}
          onSelect={val => {
            setMinVersion(val);
            form.setFieldValue('maxVersion', null);
          }}
        >
          {versions?.map(version => (
            <Option key={version.value} value={version.label}>
              {version.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={'Max Version'} name={'maxVersion'}>
        <Select placeholder={'select max version'}>
          {maxVersions?.map(version => (
            <Option key={version.value} value={version.label}>
              {version.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
}

export default VersionsFormFields;
