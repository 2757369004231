export function mapRoleToName(rule) {
  switch (rule) {
    case 'owner':
      return 'Owner';
    case 'developer':
      return 'User';
    case 'accountManager':
      return 'Billing User';
    case 'accountAdmin':
      return 'Manager';
  }
}
