import React, { useState, useCallback, useMemo } from 'react';
import { Tooltip, Input, Select, Spin, Form, Button } from 'antd';
import SparkPodDetails from './SparkPodsModal';
import { getSparkPodsStatus } from 'services/clustersstatus';
import useRequest from 'hooks/useRequest';
import '../clustersstatus.less';

let { Search } = Input;
let { Option } = Select;

function SparkPods() {
  let [searchQuery, setSearchQuery] = useState<any>('');
  let [searchDummy, setSearchDummy] = useState<any>('');
  let [operatorFilter, setOperatorFilter] = useState<any>('All');
  let [slaFilter, setSlaFilter] = useState<any>('All');
  let [sparkPodDetails, setSparkPodDetails] = useState<any>(null);

  const searchParams = useMemo(
    () => ({
      clusterName: searchQuery,
      clusterCode: operatorFilter,
      SlaTypeFilter: slaFilter,
    }),
    [operatorFilter, searchQuery, slaFilter],
  );

  let { result: sparkPodsResult, isPending: sparkPodsLoading } = useRequest(
    useCallback(() => getSparkPodsStatus(searchParams), [searchParams]),
    { resolve: true, handleError: true },
  );
  let sparkPods = sparkPodsResult?.data;

  const onFilter = value => {
    setSearchQuery(value);
  };

  function getClusterStatus(podStatus, name, namespace) {
    let tooltipText = `${namespace}/${name}`;
    let mappedResult = {
      Pending: 'loading',
      Running: 'running',
      Succeeded: 'running',
      Failed: 'service-down',
      Unknown: 'service-down',
    };
    let status = podStatus.phase;

    if (status === 'Pending') {
      status = !podStatus.containerStatuses
        ? 'Pending'
        : podStatus.containerStatuses[0].state.waiting.reason
            .toLowerCase()
            .includes('init') ||
          podStatus.containerStatuses[0].state.waiting.reason
            .toLowerCase()
            .includes('creat')
        ? 'starting'
        : 'service-down';
    }

    if (status === 'starting' || status === 'service-down') {
      return (
        <Tooltip placement="top" title={tooltipText}>
          <Button
            className={status}
            type="primary"
            size="large"
            onClick={() => {
              setSparkPodDetails({
                isNew: false,
                name: name,
                podStatus: podStatus,
                podState: status,
              });
            }}
          >
            <div></div>
          </Button>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip placement="top" title={tooltipText}>
          <Button
            className={mappedResult[status]}
            type="primary"
            size="large"
            onClick={() => {
              setSparkPodDetails({
                isNew: false,
                name: name,
                podStatus: podStatus,
                podState: mappedResult[status],
              });
            }}
          >
            <div></div>
          </Button>
        </Tooltip>
      );
    }
  }
  return (
    <div>
      {sparkPods ? (
        <div>
          <Form layout="inline" name="filterStuff">
            <Form.Item name="op">
              <FieldSelect
                operators={sparkPods.clusterCodes}
                onChange={e => {
                  setOperatorFilter(e);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Search
                placeholder="Search by cluster name"
                onSearch={searchDummy => onFilter(searchDummy)}
                onChange={e => {
                  setSearchDummy(e.target.value);
                }}
                value={searchDummy}
                style={{
                  width: 304,
                  paddingBottom: 20,
                }}
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="reset"
                onClick={() => {
                  setSearchQuery('');
                  setSearchDummy('');
                  setOperatorFilter(null);
                  setSlaFilter(null);
                }}
              >
                Clear search
              </Button>
            </Form.Item>
          </Form>
          <div className="count-info-running">
            <div className="running-clusters-square"></div>
            <span className="running-cluster-text">Running</span>
          </div>

          <div className="count-info-starting">
            <div className="starting-clusters-square"></div>
            <span className="starting-cluster-text">Starting</span>
          </div>

          <div className="count-info-upgrading">
            <div className="upgrading-clusters-square"></div>
            <span className="upgrading-clusters-text">Pending</span>
          </div>

          <div className="count-info-service-down">
            <div className="service-down-clusters-square"></div>
            <span className="service-down-clusters-text">Error</span>
          </div>
          {sparkPodsLoading ? (
            <Spin />
          ) : (
            <div>
              <div className="card">
                {sparkPods.allSparkPods.map(pod => (
                  <div>
                    <div>
                      {getClusterStatus(pod.status, pod.name, pod.namespace)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spin />
      )}
      {sparkPodDetails && (
        <SparkPodDetails
          {...sparkPodDetails}
          close={() => setSparkPodDetails(null)}
        />
      )}
    </div>
  );
}
function FieldSelect({ onChange, loading, operators }: any) {
  return (
    <Select
      allowClear
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
      style={{
        width: 304,
        height: 20,
      }}
      placeholder="Filter by operator"
    >
      {operators?.map(operator => (
        <Option key={operator.cluster_code} value={`${operator.cluster_code}`}>
          {`${operator.cluster_code}`}
        </Option>
      ))}
      <Option key="All" value="All">
        All Operators
      </Option>
    </Select>
  );
}

export default SparkPods;
