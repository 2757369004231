import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const QUERY_KEY = 'search-query-key';
const QUERY_VALUE = 'search-query-value';

export default function usePersistSearchValue(
  performSearch: Function,
  shouldPersistSearch: boolean = true,
): [string, Function] {
  const { pathname } = useLocation();
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!shouldPersistSearch) {
      return;
    }
    if (sessionStorage.getItem(QUERY_KEY) !== pathname) {
      sessionStorage.removeItem(QUERY_VALUE);
      sessionStorage.removeItem(QUERY_KEY);
    } else {
      const cachedSearchValue = sessionStorage.getItem(QUERY_VALUE);
      setSearchValue(cachedSearchValue || '');
      if (cachedSearchValue) {
        performSearch(cachedSearchValue);
      }
    }

    // eslint-disable-next-line
  }, []);

  const updateCache = useCallback(
    (value: string) => {
      setSearchValue(value);
      sessionStorage.setItem(QUERY_KEY, pathname);
      sessionStorage.setItem(QUERY_VALUE, value);
    },
    [pathname],
  );

  return [searchValue, updateCache];
}
