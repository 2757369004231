import { Heap, TrackingEventsEnum } from '../types/tracking';

declare global {
  interface Window {
    heap?: Heap;
  }
}

/**
 * set user identity
 * @param identity
 */
export const identifyUser = (identity: string) => {
  window.heap?.identify(identity);
};

/**
 * adding track methods for user interactions events
 * @param event
 * @param props
 */
export const trackEvent = (event: TrackingEventsEnum, props?: Object) => {
  window.heap?.track(event, props);
};

/**
 * adding add user properties
 * @param props
 */

export const addProperties = (props: Object) => {
  window.heap?.addUserProperties(props);
};

/**
 * adding add user properties
 */
export const resetIdentity = () => {
  window.heap?.resetIdentity();
};

/**
 * set user identity for FS
 * @param identity
 */
export const identifyUserForFullStory = (identity: string) => {
  // @ts-ignore
  FS.identify(identity);
};
