import { useCallback, useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { jsonParser } from 'utils/jsonParser';

function ExportSummaryModal({
  exportedBlueprint,
  refetchExportedBlueprint,
  close,
}) {
  let { id, summary } = exportedBlueprint ?? {};
  let [keys, setKeys] = useState([]);
  let summaryJson: any = [];
  let summaryJsonExtended: any = {};

  if (summary) {
    summary = JSON.stringify(summary).replace(/(?:\\[rn])+/g, '');
    summaryJson = jsonParser(summary);
    summaryJsonExtended = summaryJson.exported;
  }

  useEffect(() => {
    const options: any = [...Object.keys(summaryJsonExtended)];
    setKeys(options);
    // eslint-disable-next-line
  }, [summary]);

  let { makeRequest } = useRequest(
    useCallback(
      async _configuration => {
        await refetchExportedBlueprint();
      },
      // eslint-disable-next-line
      [id, refetchExportedBlueprint],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(exportedBlueprint) {
    await makeRequest(exportedBlueprint);
    close();
  }

  return (
    <Modal
      visible
      title={'Exported Data App Details'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          summaryJsonExtended,
        }}
        layout="vertical"
        name="User"
        onFinish={handleFinish}
      >
        {keys.map(key => {
          return (
            <Form.Item
              label={<p style={{ fontWeight: 'bold' }}>{'Exported ' + key}</p>}
              rules={[{ required: true }]}
            >
              {summaryJsonExtended[key].map(item => {
                return <div>{item}</div>;
              })}
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
}

export default ExportSummaryModal;
