import { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, AutoComplete, Select } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { mapRoleToName } from './helpers';
import { getUsersEmails } from 'services/user';
const { Option } = AutoComplete;
import { addUserRole } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function InvitedUsersModal({ instance, close, refetchUserInstances }) {
  const [result, setResult] = useState([]);
  const [role, setRole] = useState<undefined | string>(undefined);
  let authorizedRoles = ['accountManager', 'accountAdmin', 'developer'];

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async invitation => {
        await addUserRole(
          instance.name,
          invitation.role,
          invitation.email,
          false,
        );
        await refetchUserInstances();
      },
      [instance.name, refetchUserInstances],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(invitation) {
    await makeRequest(invitation);
    close();
  }
  const debounced = useDebouncedCallback(async email => {
    let res = [];
    if (email) {
      let emailResult = await getUsersEmails(email);

      let users = emailResult?.data?.users;
      res = users.map(user => `${user.email}`);

      setResult(res);
    } else {
      res = [];
      setResult(res);
    }
  }, 500);

  async function handleSearch(email) {
    debounced.callback(email);
  }

  return (
    <Modal
      visible
      title={'Cluster Access & Security'}
      footer={null}
      onCancel={close}
    >
      <Form layout="vertical" name="schedulerRecord" onFinish={handleFinish}>
        <Form.Item name="email" label="User Email" rules={[{ required: true }]}>
          <AutoComplete
            onSearch={value => {
              handleSearch(value);
            }}
            placeholder="User Email"
          >
            {result.map(email => (
              <Option key={email} value={email}>
                {email}
              </Option>
            ))}
          </AutoComplete>
        </Form.Item>

        <Form.Item name="role">
          <Select
            placeholder="Select Role"
            value={role}
            onChange={role => {
              setRole(role);
            }}
          >
            {authorizedRoles.map(rule => {
              return (
                <Select.Option key={rule} value={rule}>
                  {mapRoleToName(rule)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit" loading={isPending}>
              {'Add'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default InvitedUsersModal;
