import React, { useState, useCallback, useContext } from 'react';
import { Typography, Button, Tooltip, Space, Checkbox, message } from 'antd';
import { formatISO } from 'date-fns';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import SparkImageModal from './SparkImageModal/SparkImageModal';
import SparkEditImageModal from './SparkImageModal/SparkEditImageModal';
import useRequest from 'hooks/useRequest';
import { getSparkImages, updateSparkImage } from 'services/sparkimages';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

const { Title } = Typography;

function SparkImages() {
  const [page, setPage] = useState(1);
  const [search] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const [modalEditProps, setModalEditProps] = useState<any>(null);
  const { user } = useContext(SessionContext);

  const userHasEditImagesPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.IMAGES] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const {
    result,
    isPending,
    makeRequest: refetchSparkImages,
  } = useRequest(
    useCallback(() => getSparkImages({ page, search }), [page, search]),
    { resolve: true },
  );

  const images = result?.data?.images;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
    },
    {
      title: 'Tag',
      dataIndex: 'tag',
      width: '15%',
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      width: '15%',
    },
    {
      title: 'Spark Version',
      dataIndex: 'version',
    },
    {
      title: 'Language',
      dataIndex: 'language',
    },
    {
      title: 'Language Version',
      dataIndex: 'languageVersion',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '10%',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Default',
      dataIndex: 'isDefault',
      width: '5%',
      render(isDefault, image) {
        return (
          <Space>
            <Tooltip title="Set Default">
              <Checkbox
                checked={isDefault}
                onChange={async () => {
                  try {
                    if (image.language.toLowerCase() === 'python')
                      await updateSparkImage(image.id, { isDefault: true });
                    else
                      message.error('Cannot set default image. Choose Python');
                  } catch (e: any) {
                    message.error(e.message);
                  } finally {
                    refetchSparkImages();
                  }
                }}
                disabled={
                  +user.userRolesPagesPermissions?.[userRolesPages.IMAGES] <
                  +userRolesPagesPermissionsValues.CAN_EDIT
                }
              />
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: 'Min Version',
      dataIndex: 'minVersion',
    },
    {
      title: 'Max Version',
      dataIndex: 'maxVersion',
    },
    {
      title: 'Edit',
      render(_, image) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalEditProps({ isNew: false, image });
                }}
                disabled={
                  +user.userRolesPagesPermissions?.[userRolesPages.IMAGES] <
                  +userRolesPagesPermissionsValues.CAN_EDIT
                }
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="DataAgents">
      <div className="tableTitle">
        <Title>Spark Images</Title>
        <Tooltip
          title={
            !userHasEditImagesPagePermission
              ? 'You don❜t have sufficient permission'
              : 'Create'
          }
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalProps({ isNew: true });
            }}
            disabled={!userHasEditImagesPagePermission}
          >
            Add Spark Image
          </Button>
        </Tooltip>
      </div>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={images}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <SparkImageModal
          refetchSparkImages={refetchSparkImages}
          close={() => setModalProps(null)}
        />
      )}
      {modalEditProps && (
        <SparkEditImageModal
          {...modalEditProps}
          refetchSparkImages={refetchSparkImages}
          close={() => setModalEditProps(null)}
        />
      )}
    </div>
  );
}

export default SparkImages;
