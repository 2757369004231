import React, { useContext, useEffect, useRef, useState } from 'react';
import './PythonPackagesEditableTable.less';
import type { InputRef } from 'antd';
import {
  Button,
  Form,
  Input,
  Space,
  Table,
  Tooltip,
  Row,
  Col,
  Typography,
} from 'antd';
const { Title } = Typography;

import type { FormInstance } from 'antd/es/form';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { format, parseISO, isValid } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import PythonPackageInstallationStatusTag from '../../PythonPackageInstallationStatusTag/PythonPackageInstallationStatusTag';
import { pythonVersionRegex, pythonPackageNameRegex } from 'utils/constants';
import {
  InstalledPythonPackage,
  InstalledPythonPackageVersionSeparated,
  pythonInstallationStateEnum,
} from 'types/cluster';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

type EditableRowProps = {
  index: number;
};
const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

type EditableCellProps = {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof InstalledPythonPackageVersionSeparated;
  record: InstalledPythonPackageVersionSeparated;
  handleSave: (record: InstalledPythonPackageVersionSeparated) => void;
};
const EditableCell: React.FC<
  EditableCellProps & { isClusterRunning: boolean }
> = ({
  isClusterRunning,
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      const currentKey = Object.keys(values)[0];
      const otherPartKey =
        currentKey === 'packageName' ? 'packageVersion' : 'packageName';

      let newValue = null;
      if (currentKey === 'packageName') {
        newValue =
          values[currentKey] +
          (record[otherPartKey] ? '==' + record[otherPartKey] : '');
      } else {
        newValue =
          (record[otherPartKey] ? record[otherPartKey] + '==' : '') +
          values[currentKey];
      }
      toggleEdit();
      handleSave({ ...record, name: newValue, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            validator: (_, value) => {
              if (
                dataIndex === 'packageVersion' &&
                value &&
                !pythonVersionRegex.test(value)
              ) {
                return Promise.reject(
                  'Please enter a valid package version e.g. 1, 1.0 or 1.0.0 or 1.0.0.0',
                );
              } else if (dataIndex === 'packageName' && !value) {
                return Promise.reject('Please Enter package name');
              } else if (
                dataIndex === 'packageName' &&
                value &&
                !pythonPackageNameRegex.test(value)
              ) {
                return Promise.reject(
                  `Package name must match this pattern ${pythonPackageNameRegex}`,
                );
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
        {isClusterRunning ? <EditOutlined /> : null}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

function PythonPackagesEditableTable({
  pythonPackages,
  instanceID,
  isClusterRunning,
  isChidori,
  dataSource,
  setDataSource,
}: {
  pythonPackages: InstalledPythonPackage[];
  instanceID: string;
  isClusterRunning: boolean;
  isChidori: boolean;
  dataSource: InstalledPythonPackageVersionSeparated[];
  setDataSource: React.Dispatch<
    React.SetStateAction<InstalledPythonPackageVersionSeparated[]>
  >;
}) {
  const currentNewId = useRef(-1);
  const [count, setCount] = useState(pythonPackages?.length || 0);

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 5;

  const pythonPackagesTablePagination = {
    current: currentPage,
    pageSize,
    total: dataSource?.length,
    onChange: page => setCurrentPage(page),
  };

  type EditableColumn<T> = {
    editable?: boolean;
    dataIndex?: string;
  } & ColumnsType<T>[number];

  const pythonPackagesColumns: EditableColumn<InstalledPythonPackageVersionSeparated>[] =
    [
      {
        title: 'Package',
        dataIndex: 'packageName',
        key: 'packageName',
        editable: isClusterRunning,
        render(value, { packageName }, index) {
          if (!packageName?.length) {
            return <Input value={packageName} />;
          }
          return packageName;
        },
      },
      {
        title: 'Version',
        dataIndex: 'packageVersion',
        key: 'packageVersion',
        width: 150,
        editable: isClusterRunning,
        render(value, { packageVersion }, index) {
          if (!packageVersion?.length) {
            return (
              <Input value={packageVersion} disabled={!isClusterRunning} />
            );
          }
          return packageVersion;
        },
      },
      {
        title: 'States',
        dataIndex: 'states',
        key: 'states',
        width: 200,
        render(
          value,
          { analyticsState, loaderState, chidoriState, id },
          index,
        ) {
          if (id < 0) {
            return <></>;
          }
          return (
            <section className="python-packages-table__state-wrapper">
              <article className="python-packages-table__state-item">
                Analytics{' '}
                <PythonPackageInstallationStatusTag
                  pythonPackageStatus={analyticsState}
                />
              </article>
              <article className="python-packages-table__state-item">
                Loader{' '}
                <PythonPackageInstallationStatusTag
                  pythonPackageStatus={loaderState}
                />
              </article>
              {isChidori && (
                <article className="python-packages-table__state-item">
                  Chidori{' '}
                  <PythonPackageInstallationStatusTag
                    pythonPackageStatus={chidoriState}
                  />
                </article>
              )}
            </section>
          );
        },
      },
      {
        title: 'Updated At',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        width: 150,
        render(date) {
          if (isValid(new Date(date))) {
            return (
              <section>
                <article>{format(parseISO(date), 'MMMM d')}</article>
                <article>{format(parseISO(date), 'yyyy h:mm:ss a')}</article>
              </section>
            );
          }
          return <></>;
        },
      },
      {
        title: '',
        width: 50,
        render(_, { id }, index) {
          return (
            <Space>
              <Tooltip
                title={
                  !isClusterRunning ? (
                    <FormattedMessage id="general.clusterMustBeConnectedToChange" />
                  ) : (
                    ''
                  )
                }
              >
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setDataSource(dataSource.filter(item => item.id !== id));
                    setCount(count - 1);
                  }}
                  disabled={!isClusterRunning}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Space>
          );
        },
      },
    ];

  const handleAdd = () => {
    const newData: InstalledPythonPackageVersionSeparated = {
      id: currentNewId.current - 1,
      analyticsState: pythonInstallationStateEnum.installing,
      loaderState: pythonInstallationStateEnum.installing,
      chidoriState: pythonInstallationStateEnum.installing,
      createdAt: '',
      instanceID,
      name: '',
      updatedAt: '',
      packageName: '',
      packageVersion: '',
    };
    currentNewId.current--;
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
    const lastPage = Math.ceil(count + 1 / pageSize);
    setCurrentPage(lastPage);
  };

  const handleSave = (row: InstalledPythonPackageVersionSeparated) => {
    const newData = [...dataSource];
    const index = newData.findIndex(item => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    row.name =
      row.packageName + (row.packageVersion ? `==${row.packageVersion}` : '');
    setDataSource(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: props => EditableCell({ ...props, isClusterRunning }),
    },
  };

  const columns = pythonPackagesColumns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: InstalledPythonPackageVersionSeparated) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={4}>Python Packages</Title>
        </Col>
        <Col>
          <Tooltip
            title={
              isClusterRunning ? (
                ''
              ) : (
                <FormattedMessage id="general.clusterMustBeConnectedToChange" />
              )
            }
          >
            <Button
              onClick={handleAdd}
              type="primary"
              style={{ marginBottom: 16 }}
              disabled={!isClusterRunning}
            >
              Add New Python package
            </Button>
          </Tooltip>
        </Col>
      </Row>

      {isClusterRunning
        ? ''
        : 'Your cluster must be connected to make any changes'}
      <Table
        className="python-packages-table"
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns as ColumnTypes}
        pagination={pythonPackagesTablePagination}
      />
    </>
  );
}

export default PythonPackagesEditableTable;
