export const QueryServerKeys = {
  ROLES: {
    GET_ROLES: 'roles',
  },
  ACCOUNT_TYPES: {
    GET_USER_ACCOUNT_TYPES: 'user-account-types',
  },
  USER_ROLES: {
    GET_USER_ROLES: 'user-roles',
    GET_USER_ROLE_BY_ID: 'user-role-by-id',
    CREATE_USER_ROLE: 'create-user-role',
    USER_ROLES_OPERATIONS: 'user-roles-operations',
  },
  USER: {
    GET_USER_DATA: 'me',
    GET_USERS: 'get-users',
    UPDATE_USER: 'update-user',
    PERMISSIONS: 'get-user-permissions',
  },
  HOME: {
    GET_INFO: 'home-info',
  },
  CLUSTER_MANAGEMENT: {
    CLUSTERS: 'get-clusters',
    UPDATE_CLUSTER: 'update-cluster',
    UPDATE_AUTO_SCALING: 'update-auto-scaling',
    SYNC_PYTHON_PACKAGES: 'sync-python-packages',
  },
  SYSTEM_MANAGEMENT: {
    CONFIGURATIONS: 'configurations',
    CREATE_CONFIGURATIONS: 'create-configurations',
    UPDATE_CONFIGURATIONS: 'update-configurations',
    PLATFORM_ACTIONS: 'platform-actions',
    UPDATE_PLATFORM_ACTION: 'update-platform-action',
    AZURE_PLANS: 'azure-plans',
    ADD_USER_TO_FEATURE: 'add-user-to-feature',
    TOGGLE_FEATURES: 'toggle-features',
  },
  CHIDORI: {
    CHIDORI: 'get-chidori',
    GET_SIZES: 'get-chidori-sizes',
    UPDATE_SIZES: 'update-chidori-sizes',
    EXPOSE_CHIDORI_CREDENTIALS: 'expose-chidori-credentials',
    DISABLE_CHIDORI_PUBLIC_API: 'disable-chidori-public-api',
    CHIDORIS: 'get-all-chidoris',
    UPGRADE: 'upgrade',
  },
  NOTIFICATION: {
    INSTANCE_USERS: 'get-notification-instance-users',
    ADD_GLOBAL_NOTIFICATION: 'add-global-notification',
  },
  OPERATORS: {
    GET_OPERATORS: 'get-operators',
  },
  SLA_TYPE: {
    GET_SLA_TYPE: 'get-sla-type',
  },
  INCORTA_VERSIONS: {
    GET_INCORTA_VERSIONS: 'get-incorta-versions',
  },
  FEATURE_BITS: {
    GET_FEATURE_BITS: 'get-feature-bits',
  },
  COST_TYPE: {
    GET_COST_TYPE: 'get-cost-type',
  },
  NOTEBOOK: {
    GET_NOTEBOOK_USERS_CONFIGS: 'get-notebook-users-configs',
    UPDATE_NOTEBOOK_USERS_CONFIGS: 'update-notebook-users-configs',
    UPDATE_NOTEBOOK: 'update-notebook',
  },
  PARTNERS: {
    GET_PARTNERS: 'get-partners',
  },
  EXTERNAL_BUCKET: {
    GET_INSTANCE_EXTERNAL_BUCKET_CONFIG: 'get-external-bucket',
    TOGGLE_EXTERNAL_BUCKET: 'toggle-external-bucket',
    SIMPLE_CLUSTER_SEARCH_FOR_EXTERNAL_BUCKET:
      'simple-cluster-search-for-external-bucket',
    ADD_CLUSTER_TO_EXTERNAL_BUCKET: 'add-cluster-to-external-bucket',
    REMOVE_CLUSTER_TO_EXTERNAL_BUCKET: 'remove-cluster-to-external-bucket',
    TOGGLE_EXTERNAL_BUCKET_DESTINATION_CLUSTER:
      'toggle-external-bucket-destination-cluster',
  },
};
