import { useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import useRequest from 'hooks/useRequest';
import { getUsers } from 'services/user';

const { Option } = Select;

function CustomerFormField({ label, name }) {
  const { result, makeRequest: fetchCustomers } = useRequest(
    useCallback(({ page, search }) => getUsers({ page, search }), []),
  );
  const costCenters = useMemo(() => {
    const users = result?.data?.users;

    const costCenters: { id: number; name: string }[] | undefined =
      users?.reduce((r, user, idx) => {
        if (r[user['cost_center']]) return r;
        r[user['cost_center']] = { id: idx, name: user['cost_center'] };
        return r;
      }, {});
    return costCenters
      ? Object.values(costCenters).filter(e => e.name)
      : undefined;
  }, [result]);

  const handleSearch = useDebouncedCallback(search => {
    fetchCustomers({ search });
  }, 200).callback;

  return (
    <Form.Item
      label={label || 'Customers'}
      rules={[{ required: true, message: 'Customer is Required' }]}
      name={name || 'customer'}
    >
      <Select
        mode="multiple"
        showSearch
        placeholder="search by customer name"
        optionFilterProp="children"
        onSearch={handleSearch}
        showArrow={false}
        notFoundContent={null}
      >
        {costCenters?.map(costCenter => (
          <Option key={costCenter.id} value={costCenter.name}>
            {costCenter.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default CustomerFormField;
