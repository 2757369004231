import { InstancePlatform } from 'types/cluster';
import AzureLogo from 'images/azure-a-logo.svg';
import AzureSaaSLogo from 'images/azure-saas-logo.svg';
import GoogleCloudLogo from 'images/google-cloud-logo.svg';

export function getPlatformIconPath({ platform }: { platform: string }) {
  let Icon = '';
  switch (platform) {
    case InstancePlatform.GC:
      Icon = GoogleCloudLogo;
      break;
    case InstancePlatform.AZURE:
      Icon = AzureLogo;
      break;
    case InstancePlatform.AZURE_SAAS:
      Icon = AzureSaaSLogo;
      break;
    default:
      break;
  }
  return Icon;
}
