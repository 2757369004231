import { useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import { format } from 'date-fns';

function BackupInfoModal({ instance, close }) {
  let { backup } = instance ?? {};
  let [keys, setKeys] = useState<any[]>([]);
  let extraInfoJson: {};

  const formatOldInstanceState = oldState => {
    let jsonOldState = JSON.parse(oldState);
    let formatted = '';
    for (let key of Object.keys(jsonOldState)) {
      if (jsonOldState[key]) formatted += `${key}: ${jsonOldState[key]}, `;
    }
    return formatted.slice(0, -1);
  };

  if (backup) {
    let info = {
      Date: format(new Date(backup.backup), 'dd-MM-yyyy hh:mm a'),
      'Backup Type':
        backup.backupType === 'full'
          ? 'Full Backup'
          : backup.backupType === 'metadata'
          ? 'Metadata Backup'
          : backup.backupType === 'metadisks'
          ? 'Metadata and Disks Backup'
          : backup.backupType === 'disks'
          ? 'Disks Backup'
          : '',
      Retention: backup.retention,
      Frequency: backup.frequency,
      'Backup was taken from instance': backup.instance?.name,
      [`${backup.instance?.name} state when backup was taken`]:
        formatOldInstanceState(backup.instanceSnapshot),
    };
    extraInfoJson = info;
  }

  useEffect(() => {
    const options = [...Object.keys(extraInfoJson)];
    setKeys(options);
    // eslint-disable-next-line
  }, [backup]);

  return (
    <Modal visible title={'Backup Information'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          extraInfoJson,
        }}
        layout="vertical"
        name="User"
      >
        {keys.map(key => {
          const values =
            typeof extraInfoJson[key] === 'string'
              ? extraInfoJson[key].split(',')
              : [extraInfoJson[key]];
          return (
            <Form.Item
              label={<p style={{ fontWeight: 'bold' }}>{key + ':'}</p>}
              rules={[{ required: true }]}
            >
              {/* {values.map((value, index) => (
                                <p key={index}>{value}</p>
                            ))} */}
              {extraInfoJson[key]}
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
}

export default BackupInfoModal;
