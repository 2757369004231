import { message } from 'antd';
import {
  getErrorsOfInput,
  hideErrorBelowField,
  resetErrors,
} from './fieldsValidation';

export function getErrorMessage(error) {
  let errorMessage: string = 'Something went wrong!';
  try {
    if (typeof error?.response?.data === 'string') {
      errorMessage = error?.response?.data;
    } else if (error?.response?.data?.errors) {
      const { errors } = error.response.data;
      if (Array.isArray(errors)) {
        errorMessage = '';
        getFieldErrorMessage(errors);
      } else {
        [errorMessage] = Object.values(errors);
      }
    } else if (error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    }
  } catch {}
  return errorMessage.toString();
}

export function showErrorMessage(errorMessage = 'Something went wrong!') {
  message.error(errorMessage);
}
export function showSuccessMessage(successMessage = 'Successful!') {
  message.success(successMessage);
}

export function showError(error) {
  showErrorMessage(getErrorMessage(error));
}

export function showSucess(success) {
  showSuccessMessage(success.data.message);
}

export function getFormErrors(errors) {
  return Object.values(errors).filter(error => {
    return Boolean(error);
  });
}

export function fakeErrorResponse(data) {
  return {
    response: {
      data,
    },
  };
}

function getFieldErrorMessage(errors) {
  resetErrors();
  try {
    errors.forEach((error: any) => {
      hideErrorBelowField(error.fieldName);
      getErrorsOfInput(
        error.fieldName,
        () => true,
        error.message,
        error.notActivated,
      );
    });
  } catch {}
}
