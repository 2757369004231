import { useQuery } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { QueryOptions } from 'types/home';
import { getInfo } from 'services/home';

export function useGetBlueprints(options?: QueryOptions) {
  return useQuery(QueryServerKeys.HOME.GET_INFO, () => getInfo(), {
    staleTime: 10 * (60 * 1000), // 10 mins
    cacheTime: 15 * (60 * 1000), // 15 mins
    refetchOnWindowFocus: false,
    ...options,
  });
}
