import { useState, useCallback, useEffect, useContext } from 'react';
import { Typography, Button, Tooltip, Space, notification } from 'antd';
import { formatISO } from 'date-fns';
import { ExportOutlined, InfoCircleOutlined } from '@ant-design/icons';

import LogsAnalyzerModal from './LogsAnalyzerModal/LogsAnalyzerModal';
import AuditModal from './AuditModal/AuditModal';
import { getAuditHistory } from 'services/auditHistory';

import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesActions } from 'types/user';

const { Title } = Typography;

function AuditHistory({ userID, instance }) {
  const { user } = useContext(SessionContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [userid] = useState(userID);
  const [instanceid] = useState(instance?.id);
  const [modalProps, setModalProps] = useState<any>(null);
  const [logsAnalayzerProps, setLogsAnalayzerProps] = useState<any>(null);

  const {
    result,
    isPending,
    makeRequest: refetchAudit,
  } = useRequest(
    useCallback(
      () => getAuditHistory({ page, search, userid, instanceid }),
      [page, search, userid, instanceid],
    ),
    { resolve: true },
  );

  useEffect(() => () => notification.destroy(), []);

  const audit = result?.data?.audit;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const searchColumns = [
    'Owner Email',
    'Instance',
    'Action',
    'Platform',
    'Created At',
  ];

  const columns = [
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      width: '20%',
    },
    {
      title: 'Instance',
      dataIndex: ['instance', 'name'],
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      width: '15%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'complete' });
      },
      width: '15%',
    },
    {
      title: 'Extra Information',
      render(_, audit) {
        return (
          <Space>
            <Tooltip title="Info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, audit });
                }}
              >
                <InfoCircleOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const searchPlaceholder =
    "Search By Email, Instance, Action or 'Field Name'=Some Value.";

  return (
    <div className="AuditHistory">
      <div className="tableTitle">
        {!instanceid && <Title level={userid ? 3 : 1}>Audit History</Title>}

        <Tooltip title="Export Logs">
          <Button
            type="primary"
            icon={<ExportOutlined />}
            onClick={() => {
              setLogsAnalayzerProps({ isNew: true });
            }}
          >
            Export Logs
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        type="Audit History"
        searchPlaceholder={searchPlaceholder}
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={audit}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        shouldPersistSearch={false}
        // rowSelection={rowSelection}
      />
      {modalProps && (
        <AuditModal
          {...modalProps}
          refetchAudit={refetchAudit}
          close={() => setModalProps(null)}
        />
      )}

      {logsAnalayzerProps &&
        userRolesActions.LOGS_ANALYSIS in user.userRolesActions && (
          <LogsAnalyzerModal
            {...logsAnalayzerProps}
            close={() => setLogsAnalayzerProps(null)}
          />
        )}
    </div>
  );
}

export default AuditHistory;
