import { Col, Modal, Row, Spin, Timeline } from 'antd';
import React, { useCallback, useState } from 'react';
import StatHistoryDetails from './StatHistoryDetails/StatHistoryDetails';
import { getStats } from 'services/user';
import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

function UserConsumption({ userID }) {
  let { result, isPending, isRejected } = useRequest(
    useCallback(() => getStats({ userId: userID }), [userID]),
    { resolve: true },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting billing history" />
      </div>
    );
  }

  if (isRejected) {
    return <div className="flex-center"></div>;
  }

  let stats = result?.data.stats;
  let userObj = result?.data.user;

  return (
    <div className="UserConsumption">
      <Row justify="center">
        <Col xs={24} md={12}>
          <Timeline mode="left">
            {stats?.map(stat => {
              return (
                <StatHistory key={stat.date} stat={stat} userObj={userObj} />
              );
            })}
          </Timeline>
        </Col>
      </Row>
    </div>
  );
}

function StatHistory({ stat, userObj }) {
  let [showModal, setShowModal] = useState(false);

  return (
    <Timeline.Item>
      <h3>
        {stat.date}{' '}
        <button
          className="link"
          style={{ marginLeft: 8 }}
          onClick={() => setShowModal(true)}
        >
          Details
        </button>
      </h3>

      <SearchableTable
        dataSource={[
          {
            key: 1,
            name: 'Units',
            label: `${stat.units} units`,
            amount: `${stat.unitsCost} USD`,
          },
          {
            key: 2,
            name: 'User count',
            label: `${stat.users} users`,
            amount: `${stat.usersCost} USD`,
          },
          {
            key: 3,
            name: 'Disk Usage',
            label: `${stat.usage} GB`,
            amount: `${stat.usageCost} USD`,
          },
        ]}
        showHeader={false}
        columns={[
          {
            dataIndex: 'name',
            key: 'name',
            render: name => <strong>{name}</strong>,
          },
          {
            dataIndex: 'label',
            key: 'label',
          },
          {
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
          },
        ]}
        pagination={false}
        size="small"
        footer={() => {
          return (
            <div style={{ marginLeft: 8 }}>
              <strong>Total:</strong> ${stat.totalCost} USD
            </div>
          );
        }}
      />

      <Modal
        visible={showModal}
        title={stat.date}
        onCancel={() => setShowModal(false)}
        width={900}
        footer={null}
        centered
      >
        <StatHistoryDetails stat={stat} userObj={userObj} />
      </Modal>
    </Timeline.Item>
  );
}

export default UserConsumption;
