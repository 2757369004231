import { useCallback, useContext, useState } from 'react';
import { Modal, Form, Button, Space, Table, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import CriteriaModal from '../CriteriaField/CriteriaModal';
import { getOperators } from 'services/contextManager';
import useRequest from 'hooks/useRequest';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

function ContextManagerCriteriaModal({ operator, close, refetchOperators }) {
  const { user } = useContext(SessionContext);

  const url = operator?.url;
  const [showAdvancedSearchModal, setShowAdvancedSearchModal] =
    useState<boolean>(false);
  const {
    result,
    isPending,
    makeRequest: refetchOperator,
  } = useRequest(
    useCallback(() => getOperators(url), [url]),
    { resolve: true },
  );

  const userHasEditOperatorsPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.OPERATORS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  operator = result?.data?.operators[0];
  const formatDataSource = filteringCriteriaList => {
    const dataSource = [];
    if (filteringCriteriaList) {
      for (const criteria of filteringCriteriaList) {
        let filter;
        if (criteria.includes('like')) filter = 'like';
        else filter = 'exact';
        const criteriaSplitted = criteria.split(filter);
        dataSource.push({
          criteria: criteriaSplitted[0].replace(/'/g, ''),
          filter: filter,
          value: criteriaSplitted[1],
        });
      }
      return dataSource;
    }
  };
  const filteringCriteriaList = operator?.filteringCriteria?.split('&&');
  const dataSource =
    operator?.filteringCriteria == ''
      ? []
      : formatDataSource(filteringCriteriaList);

  const columns = [
    {
      title: 'Filter By',
      dataIndex: 'criteria',
      key: 'criteria',
      width: '30%',
    },
    {
      title: 'Filter',
      dataIndex: 'filter',
      key: 'filter',
      width: '20%',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      width: '60%',
    },
  ];
  const filterColumns = [
    'Full Name',
    'Email',
    'Company',
    'Cost Center',
    'Organization',
  ];

  return (
    <Modal
      open
      title={'Filter Operator Based On User Criteria'}
      footer={null}
      onCancel={close}
    >
      <Form layout="vertical" name="Operator">
        <Table dataSource={dataSource} loading={isPending} columns={columns} />
        <Tooltip
          title={
            !userHasEditOperatorsPagePermission
              ? 'You don❜t have sufficient permission'
              : ''
          }
        >
          <Form.Item>
            <Button
              type="primary"
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowAdvancedSearchModal(true);
              }}
              disabled={!userHasEditOperatorsPagePermission}
            >
              Edit Criteria
            </Button>
          </Form.Item>
        </Tooltip>

        <Space>
          <Button onClick={close}>Close</Button>
        </Space>
      </Form>
      {showAdvancedSearchModal && (
        <CriteriaModal
          close={() => setShowAdvancedSearchModal(false)}
          columnsList={filterColumns}
          dataSource={dataSource}
          refetchOperator={refetchOperator}
          refetchOperators={refetchOperators}
          operator={operator}
        />
      )}
    </Modal>
  );
}

export default ContextManagerCriteriaModal;
