import { useState, useCallback, useContext } from 'react';
import { Spin, Table, Button, Form, Tooltip, Typography, Space } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import InvitedUsersModal from '../InvitedUsersModal/InvitedUsersModal';
import EditInvitedUsersModal from '../InvitedUsersModal/EditInvitedUsersModal';
import { getInstanceUsers } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { userRolesActions } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
const { Title } = Typography;

function InstanceUsers({ cluster }) {
  const { user } = useContext(SessionContext);

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState<any>(null);
  const [instanceName] = useState(cluster?.name);
  const {
    result,
    isPending,
    makeRequest: refetchUserInstances,
  } = useRequest(
    useCallback(() => getInstanceUsers(instanceName), [instanceName]),

    {
      handleError: true,
      resolve: true,
    },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting UserRoles" />
      </div>
    );
  }
  const userRoles = result?.data?.users;
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 100,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render(_, record) {
        return (
          record.role != 'Owner' &&
          userRolesActions.CLUSTER_ROLES in user.userRolesActions && (
            <Space>
              <Tooltip title="Edit">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setShowEditModal({ record });
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            </Space>
          )
        );
      },
    },
  ];

  return (
    <Form>
      {userRolesActions.CLUSTER_ROLES in user.userRolesActions && (
        <Form.Item>
          {showModal && (
            <InvitedUsersModal
              instance={cluster}
              close={() => setShowModal(null)}
              refetchUserInstances={refetchUserInstances}
            />
          )}
          {showEditModal && (
            <EditInvitedUsersModal
              {...showEditModal}
              instance={cluster}
              close={() => setShowEditModal(null)}
              refetchUserInstances={refetchUserInstances}
            />
          )}
          <div className="tableTitle">
            <Title></Title>

            <Tooltip title="New">
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModal(true);
                }}
              >
                Invite User
              </Button>
            </Tooltip>
          </div>
        </Form.Item>
      )}

      <Table dataSource={userRoles} loading={isPending} columns={columns} />
    </Form>
  );
}

export default InstanceUsers;
