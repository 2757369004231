import React, { PropsWithChildren, createContext, useState } from 'react';
import {
  ClusterModalCountType,
  ErrorModalType,
  modalKeys,
} from 'components/Clusters/helper';

export const ModalCountContext = createContext<ClusterModalCountType>('single');
export const SetModalCountContext =
  createContext<React.Dispatch<React.SetStateAction<ClusterModalCountType>>>(
    null,
  );
export const ActiveModalContext = createContext<modalKeys | null>(null);
export const SetActiveModalContext =
  createContext<React.Dispatch<React.SetStateAction<modalKeys>>>(null);
export const ModalErrorContext = createContext<ErrorModalType>(null);
export const SetModalErrorContext =
  createContext<React.Dispatch<React.SetStateAction<ErrorModalType>>>(null);

export default function ClusterProvider({ children }: PropsWithChildren<{}>) {
  const [modalCount, setModalCount] = useState<ClusterModalCountType>('single');
  const [activeModal, setActiveModal] = useState<modalKeys | null>(null);
  const [error, setError] = useState<ErrorModalType>(null);

  return (
    <ModalCountContext.Provider value={modalCount}>
      <SetModalCountContext.Provider value={setModalCount}>
        <ActiveModalContext.Provider value={activeModal}>
          <SetActiveModalContext.Provider value={setActiveModal}>
            <ModalErrorContext.Provider value={error}>
              <SetModalErrorContext.Provider value={setError}>
                {children}
              </SetModalErrorContext.Provider>
            </ModalErrorContext.Provider>
          </SetActiveModalContext.Provider>
        </ActiveModalContext.Provider>
      </SetModalCountContext.Provider>
    </ModalCountContext.Provider>
  );
}
