import axios from 'axios';
import { API_URL } from 'utils/http';

export function getClusterAction({
  page = 1,
  action = 'UPGRADE',
  filterBy = '',
  filterName = '',
}) {
  return axios.get(`${API_URL}/cloudactioncenter/getentities`, {
    params: { page, action, filterBy, filterName },
  });
}
