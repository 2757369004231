import React, { useContext, useRef, useState } from 'react';
import './Partners.less';
import {
  Typography,
  Button,
  Tooltip,
  Input,
  InputNumber,
  Select,
  Tag,
  TableColumnsType,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';
import Table, { ColumnsType } from 'antd/lib/table';
import Icon from '@ant-design/icons/lib/components/Icon';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { usePartners } from 'hooks/queries';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import NewPartnerModal from 'components/Partners/NewPartnerModal/NewPartnerModal';
import { seachableTablePageSizeOptions } from 'utils/constants';
import { SessionContext } from 'auth/SessionProvider';
import { PartnerSupportedRegion, PartnersResponseItem } from 'types/partner';
import useClusterSize from 'hooks/useClusterSize';
import {
  partnersSearchColumns,
  platformsMap,
} from 'components/Clusters/helper';
import { ReactComponent as SparkLogo } from 'images/spark.svg';
import { ReactComponent as CPUIcon } from 'images/cpu-icon.svg';
import { ReactComponent as MemoryIcon } from 'images/memory-icon.svg';
import MLFlowLogo from 'images/ml-flow-logo.svg';
import { getPlatformIconPath } from 'utils/platform';

const { Title } = Typography;

const fieldNames = {
  name: 'name',
  partnerEmail: 'partnerEmail',
  clustersCount: 'clustersCount',
  platforms: 'platforms',
  clustersDefaultSize: 'clustersDefaultSize',
} as const;

function Partners() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { user } = useContext(SessionContext);
  const { ClusterSizeEnum } = useClusterSize();
  const canUserRegisterNewPartner =
    userRolesActions.REGISTER_PARTNER_ACTION in user.userRolesActions;

  const [showNewPartnerModal, setShowNewPartnerModal] = useState(false);
  const isNew = useRef<boolean>();
  const roleId = useRef<number>();

  const { data: partnersResult, isLoading: isPartnersLoading } = usePartners({
    page,
    search,
  });

  const partners = partnersResult?.partners;
  const current = partnersResult?.page;
  const total = partnersResult?.total;

  const columns: ColumnsType<PartnersResponseItem> = [
    {
      title: <FormattedMessage id="partnersPage.partnersTable.fields.name" />,
      dataIndex: fieldNames.name,
    },
    {
      title: <FormattedMessage id="partnersPage.partnersTable.fields.email" />,
      dataIndex: fieldNames.partnerEmail,
    },
    {
      title: 'Clusters Default Size',
      dataIndex: fieldNames.clustersDefaultSize,
      align: 'center',
    },
    {
      title: (
        <FormattedMessage id="partnersPage.partnersTable.fields.clustersCount" />
      ),
      dataIndex: fieldNames.clustersCount,
      align: 'center',
    },
    {
      title: (
        <FormattedMessage id="partnersPage.partnersTable.fields.platform" />
      ),
      dataIndex: fieldNames.platforms,
      render(_, { platforms }) {
        const ValuesWithIcons = platforms.split(',').map(platform => {
          const Icon = getPlatformIconPath({ platform });
          return (
            <section className="partners__platform-cell">
              <img
                className="partners__platform-img"
                src={Icon}
                alt={platform}
              />
              <label>{platform}</label>
            </section>
          );
        });
        return ValuesWithIcons;
      },
    },
    {
      key: 'spark-quotas',
      title: (
        <article className="partners__spark-quotas-cell">
          <Icon key="spark" component={SparkLogo} alt="Spark" />
          <span>Spark Quotas</span>
        </article>
      ),
      children: [
        {
          key: 'maxCPU',
          title: (
            <article className="partners__max-cpu-cell">
              <CPUIcon />
              <span>Max CPU</span>
            </article>
          ),
          dataIndex: 'maxCPU',
          align: 'center',
        },
        {
          key: 'maxMemory',
          title: (
            <article className="partners__max-memory-cell">
              <MemoryIcon />
              <span>Max Memory</span>
            </article>
          ),
          dataIndex: 'maxMemory',
          align: 'center',
        },
      ],
    },
    {
      key: 'allows',
      title: 'More Info',
      children: [
        {
          title: <i>SQLX</i>,
          dataIndex: 'allowSqlX',
          key: 'allowSqlX',
          render(value, { allowSqlX }) {
            if (allowSqlX) {
              return <Tag color="green">Allowed</Tag>;
            } else {
              return <Tag color="red">Disallowed</Tag>;
            }
          },
        },
        {
          key: 'allowMLflow',
          title: (
            <article className="partners__mlflow-cell">
              <img src={MLFlowLogo} alt="MLflow" />
            </article>
          ),
          dataIndex: 'allowMLflow',
          render(value, { allowMLflow }) {
            if (allowMLflow) {
              return <Tag color="green">Allowed</Tag>;
            } else {
              return <Tag color="red">Disallowed</Tag>;
            }
          },
        },
      ],
    },
  ];

  const searchColumns = [
    'Name',
    'Email',
    'Clusters Count',
    'Platform',
    'Clusters Size',
  ];

  const optionsClusterSizes = Object.keys(ClusterSizeEnum).map(
    (key: string) => {
      const label = ClusterSizeEnum[key as keyof typeof ClusterSizeEnum];
      return { label, value: label };
    },
  );

  const fieldsNamesMap = new Map([
    ['Full Name', 'Full Name'],
    ['Email', 'Email'],
    ['Company', 'Company'],
    ['Cost Center', 'Cost Center'],
    ['Organization', 'Organization'],
    ['Created At', 'Created At'],
    ['User Role', 'User Role'],
    ['Partner', 'Partner'],
    ['account type', 'Account Type'],
  ]);

  const filterMapping = new Map(
    partnersSearchColumns?.map(field => [
      field,
      (onChange: (value: any) => void, id: string, defaultValue?: any) => {
        switch (field) {
          case 'Name':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Email':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Clusters Count':
            return (
              <InputNumber
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Platform':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={Object.entries(platformsMap).map(([key, value]) => {
                  const Icon = getPlatformIconPath({ platform: key });
                  return {
                    label: (
                      <section className="partners__advanced-search-platform-select-label">
                        <img
                          className="partners__platform-select-img"
                          src={Icon}
                          alt={value}
                        />
                        <label>{value}</label>
                      </section>
                    ),
                    value,
                  };
                })}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Clusters Size':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsClusterSizes}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );

          default:
            return null;
        }
      },
    ]),
  );

  const expandedRowRender = ({ supportedRegions }: PartnersResponseItem) => {
    const columns: TableColumnsType<PartnerSupportedRegion> = [
      {
        title: 'Regions',
        dataIndex: 'region',
        key: 'region',
        render(value, { platform, name: regionName }) {
          const Icon = getPlatformIconPath({ platform });
          return (
            <section className="partners__region-cell">
              <img
                className="partners__platform-img"
                src={Icon}
                alt={platform}
              />
              <label>{regionName}</label>
            </section>
          );
        },
      },
    ];

    return (
      <Table
        className="partners__expanded-row-table"
        columns={columns}
        dataSource={supportedRegions}
        pagination={false}
      />
    );
  };

  return (
    <>
      <div className="partners">
        <div className="tableTitle">
          <Title>
            <FormattedMessage id="partnersPage.pageTitle" />
          </Title>
          <Tooltip
            title={
              !canUserRegisterNewPartner
                ? 'You don❜t have sufficient permission'
                : 'Create'
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowNewPartnerModal(true);
                isNew.current = true;
                roleId.current = null;
              }}
              disabled={!canUserRegisterNewPartner}
            >
              <FormattedMessage id="partnersPage.createNewPartnerButton" />
            </Button>
          </Tooltip>
        </div>

        <SearchableTable
          type="IncortaOne Partners"
          modalWidth={720}
          pagination={{
            pageSize: seachableTablePageSizeOptions[0],
            current,
            total,
            pageSizeOptions: seachableTablePageSizeOptions,
            onChange(newPage) {
              setPage(newPage);
            },
          }}
          onSearch={value => {
            setSearch(value);
            setPage(1);
          }}
          dataSource={partners}
          loading={isPartnersLoading}
          columns={columns}
          expandable={{ expandedRowRender }}
          // advancedSearchColumns={searchColumns}
          // filterMapping={filterMapping}
          // fieldsNamesMap={fieldsNamesMap}
        />
      </div>
      {showNewPartnerModal && (
        <NewPartnerModal
          key={isNew ? 'isNew' : roleId.current}
          isNew={isNew.current}
          roleId={roleId.current}
          close={() => setShowNewPartnerModal(false)}
        />
      )}
    </>
  );
}

export default Partners;
