import axios from 'axios';
import { API_URL } from 'utils/http';

export function getBackups({
  page = 1,
  search,
}: {
  page?: number;
  search?: string;
}) {
  return axios.get(`${API_URL}/backups`, {
    params: { page, search },
  });
}

export function getInstancesNames(search) {
  return axios.get(`${API_URL}/backups/instancesnames`, { params: { search } });
}

export function getInstancesNamesRunning(search) {
  return axios.get(`${API_URL}/backups/instancesnames/running`, { params: { search } });
}

export function backupInstance(backupRecord) {
  return axios.post(`${API_URL}/backups/backup`, {
    backupRecord: backupRecord,
  });
}

export function restoreBackup(instanceID, backupID) {
  return axios.post(`${API_URL}/backups/restore`, { instanceID, backupID });
}

export function deleteScheduledBackup(backupID) {
  return axios.delete(`${API_URL}/backups/${backupID}/deletescheduledbackup`);
}

export function stopScheduledBackup(backupID, instanceName) {
  return axios.get(`${API_URL}/backups/${backupID}/stopscheduledbackup`, {
    params: { instanceName },
  });
}

export function editScheduledBackup(backupID, backupRecord) {
  return axios.put(`${API_URL}/backups/${backupID}/editscheduledbackup`, {
    backupRecord: backupRecord,
  });
}

export function getBackupParent({ page = 1, search }) {
  return axios.get(`${API_URL}/backups/parentBackup`, {
    params: { page, search },
  });
}

export function getValidBackups(instanceName) {
  return axios.get(`${API_URL}/backups/${instanceName}/validbackups`);
}
