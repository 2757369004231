import { useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import useRequest from 'hooks/useRequest';
import { getClusterTenants } from 'services/cluster';

function TenantFormField({ label, name, cluster }) {
  const { result } = useRequest(
    useCallback(
      () =>
        getClusterTenants({
          userId: cluster.user.id,
          clusterName: cluster.name,
        }),
      [cluster],
    ),
    {
      resolve: cluster?.user?.id && cluster?.name,
    },
  );

  const tenants = useMemo(() => {
    return result?.data?.tenants?.map(e => ({
      label: e?.name,
      value: e?.name,
    }));
  }, [result]);

  return (
    <Form.Item
      rules={[{ required: true, message: 'Tenant Name is Required' }]}
      label={label || 'Tenant'}
      name={name || 'tenant'}
    >
      <Select
        disabled={!cluster.name}
        showSearch
        placeholder={'select tenant'}
        defaultActiveFirstOption={true}
      >
        {tenants &&
          tenants.map(tenant => (
            <Select.Option key={tenant.value} value={tenant.label}>
              {tenant.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export default TenantFormField;
