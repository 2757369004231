import React, { PropsWithChildren } from 'react';
import './MenuItemBody.less';
import { Tooltip, Button } from 'antd';
import { ButtonType } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';

function MenuItemBody({
  tooltipPlacement = 'left',
  tooltipTitle,
  btnType,
  btnOnClick,
  isBtnDisabled = false,
  children,
}: PropsWithChildren<{
  tooltipPlacement?: TooltipPlacement;
  btnType?: ButtonType;
  tooltipTitle: string;
  isBtnDisabled?: boolean;
  btnOnClick: React.MouseEventHandler<HTMLElement>;
}>) {
  return (
    <Tooltip
      className="menu-item-body__tooltip"
      placement={tooltipPlacement}
      title={tooltipTitle}
    >
      <Button
        className="menu-item-body__button"
        size="small"
        type={btnType}
        onClick={btnOnClick}
        disabled={isBtnDisabled}
      >
        {children}
      </Button>
    </Tooltip>
  );
}

export default MenuItemBody;
