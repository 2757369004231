import './MaintenanceStatusField.less';

import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getMaintenanceStatus } from 'services/configuration';

let { Option } = Select;

function MaintenanceStatusField({ name, label }) {
  let { result, isPending } = useRequest(getMaintenanceStatus, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let maintenanceStatus = result?.data?.maintenanceStatus;

  return (
    <Form.Item className="MaintenanceStatusField" name={name} label={label}>
      <FieldSelect
        loading={isPending && !maintenanceStatus}
        maintenanceStatus={maintenanceStatus}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, maintenanceStatus }: any) {
  return (
    <Select
      value={maintenanceStatus ? value : undefined}
      onChange={onChange}
      loading={loading}
      popupClassName="MaintenanceStatusField__select-dropdown"
      allowClear
    >
      {maintenanceStatus?.map(maintenanceState => (
        <Option key={maintenanceState.name} value={maintenanceState.value}>
          {maintenanceState.name}
        </Option>
      ))}
    </Select>
  );
}

export default MaintenanceStatusField;
