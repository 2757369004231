import { useContext, useState } from 'react';
import { Button, Typography, Tooltip, Row } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  RiseOutlined,
  CheckSquareOutlined,
} from '@ant-design/icons';
import ImagesModal from './ImagesModal';
import ImagesTable from './ImagesTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

const { Title } = Typography;

function Images() {
  const [modalProps, setModalProps] = useState<any>(null);
  const { user } = useContext(SessionContext);

  return (
    <div className="BlueprintsCategories">
      <div className="tableTitle">
        <Title>Images</Title>
        <Row justify="center">
          {+user.userRolesPagesPermissions?.[userRolesPages.IMAGES] >=
            +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Tooltip title="Sanity Check">
              <Button
                style={{ backgroundColor: 'slategray' }}
                type="primary"
                icon={<CheckSquareOutlined />}
                onClick={() => {
                  setModalProps({ image: 'Check' });
                }}
              >
                {'Sanity Checks'}{' '}
              </Button>{' '}
            </Tooltip>
          )}

          {+user.userRolesPagesPermissions?.[userRolesPages.IMAGES] >=
            +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Tooltip title="Promote Image">
              <Button
                style={{ backgroundColor: 'green' }}
                type="primary"
                icon={<RiseOutlined />}
                onClick={() => {
                  setModalProps({ image: 'Promote' });
                }}
              >
                {'promote Image'}{' '}
              </Button>{' '}
            </Tooltip>
          )}

          {+user.userRolesPagesPermissions?.[userRolesPages.IMAGES] >=
            +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Tooltip title="Add Image">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalProps({ image: 'Add' });
                }}
              >
                {'Add Image'}{' '}
              </Button>{' '}
            </Tooltip>
          )}
          {user.userRolesPagesPermissions?.[userRolesPages.IMAGES] ===
            userRolesPagesPermissionsValues.CAN_DELETE && (
            <Tooltip title="Delete Image By BuildID">
              <Button
                type="default"
                icon={<DeleteOutlined />}
                onClick={() => {
                  setModalProps({ image: 'Delete' });
                }}
              >
                {'Delete Image By BuildID'}{' '}
              </Button>
            </Tooltip>
          )}
        </Row>
        {modalProps && (
          <ImagesModal {...modalProps} close={() => setModalProps(null)} />
        )}
      </div>
      <ImagesTable />
    </div>
  );
}

export default Images;
