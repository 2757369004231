import React, { useContext, useState } from 'react';
import { Typography, Button, Tooltip, Space } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined } from '@ant-design/icons';
import ConfigModal from './ConfigModal/ConfigModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetConfigurations } from 'hooks/queries';
import { SessionContext } from 'auth/SessionProvider';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';

const { Title } = Typography;

function Configurations() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);

  const { user } = useContext(SessionContext);
  const userHasEditConfigurationsPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.CONFIGURATIONS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const userHasConfigureAzurePlansAction = !!(
    userRolesActions.CONFIGURE_AZURE_PLAN in user.userRolesActions
  );
  const userHasReleaseManagerConfiguration = !!(
    userRolesActions.RELEASE_MANAGER_CONFIGURATIONS in user.userRolesActions
  );

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchConfigurations,
  } = useGetConfigurations({ page, search });

  const configurations = result?.data?.configurations;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const getPermission = (configuration) =>{
    if (['azureRegions', 'azurePlans'].includes(configuration.configKey)) return !userHasConfigureAzurePlansAction;
    else if(['latestReleaseInfo', 'supportedVersions', 'defaultIncortaImage', 'parkedClusters'].includes(configuration.configKey)) return !userHasReleaseManagerConfiguration;
    return !userHasEditConfigurationsPagePermission
  }

  const columns = [
    {
      title: 'Configuration Key',
      dataIndex: 'displayName',
    },
    {
      title: 'Configuration Value',
      dataIndex: 'configValue',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, configuration) {
        const isEditBtnDisabled = getPermission(configuration)
        return (
          <Space>
            <Tooltip
              title={
                isEditBtnDisabled
                  ? 'You don❜t have sufficient permission'
                  : 'Edit'
              }
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, configuration });
                }}
                disabled={isEditBtnDisabled}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="Configurations">
      <div className="tableTitle">
        <Title>System Configurations</Title>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={configurations}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        searchPlaceholder={
          "Search by Display Name, Configuration Key or 'Field Name'=Some Value."
        }
      />

      {modalProps && (
        <ConfigModal
          {...modalProps}
          refetchConfigurations={refetchConfigurations}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Configurations;
