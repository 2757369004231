import React, { useContext } from 'react';
import { Typography, Button, Tooltip, message } from 'antd';
import { routeToCloudFunction } from 'services/costAwareness';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

const { Title } = Typography;

function CostAwareness() {
  const { user } = useContext(SessionContext);
  const userHasEditCostAwarnessPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.COST_AWARENESS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  async function mapToCloudFunction(cloudFunction) {
    routeToCloudFunction(cloudFunction).then(result => {
      message.info(result.data.message);
    });
  }
  return (
    <div className="UserInfo">
      <div className="tableTitle">
        <Title>Cost Awareness</Title>
      </div>

      <div className="UserDetails">
        <div>
          <Tooltip
            title={
              !userHasEditCostAwarnessPagePermission
                ? 'You don❜t have sufficient permission'
                : 'Update Users table'
            }
          >
            <Button
              type="primary"
              onClick={() => {
                mapToCloudFunction(true);
              }}
              disabled={!userHasEditCostAwarnessPagePermission}
            >
              {'Update Users table'}{' '}
            </Button>{' '}
          </Tooltip>

          <Tooltip
            title={
              !userHasEditCostAwarnessPagePermission
                ? 'You don❜t have sufficient permission'
                : 'Notify Directors'
            }
          >
            <Button
              type="default"
              onClick={() => {
                mapToCloudFunction(false);
              }}
              disabled={!userHasEditCostAwarnessPagePermission}
            >
              {'Notify Directors'}{' '}
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

export default CostAwareness;
