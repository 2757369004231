import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';

function CreateUserProvisionPanel({
  form,
  provisionOptionsIsPending,
  provisionOptions,
}) {
  return (
    <div
      className="NewProvisionCustomer__panel-wrapper"
      style={{ maxWidth: '550px' }}
    >
      <Row align="stretch" gutter={24} justify="space-around">
        <Col span={12}>
          <Form.Item
            name={['user', 'firstName']}
            label="First Name"
            rules={
              [
                // { required: true, message: 'First Name is required' },
              ]
            }
          >
            <Input placeholder="First name" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', 'lastName']}
            label="Last Name"
            rules={
              [
                // { required: true, message: 'Last Name is required' },
              ]
            }
          >
            <Input placeholder="Last name" />
          </Form.Item>
        </Col>
      </Row>

      <Row align="stretch" gutter={24} justify="space-around">
        <Col span={12}>
          <Form.Item
            name={['user', 'email']}
            label="Email"
            rules={[{ required: true, message: 'Email is required' }]}
          >
            <Input
              placeholder="Email"
              onChange={event => {
                form.setFieldsValue({
                  email: {
                    to: event.target.value,
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', 'permission']}
            label="Permission"
            rules={[{ required: true, message: 'Permission is required' }]}
          >
            <Select disabled loading={provisionOptionsIsPending}>
              {provisionOptions?.permissions?.map(permission => {
                return (
                  <Select.Option key={permission} value={permission}>
                    {permission}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row align="stretch" gutter={24} justify="space-around">
        <Col span={12}>
          <Form.Item
            name={['user', 'accountType']}
            label="Account Type"
            rules={[{ required: true, message: 'Account Type is required' }]}
          >
            <Select disabled loading={provisionOptionsIsPending}>
              {provisionOptions?.accountType?.map(type => {
                return (
                  <Select.Option key={type.id} value={type.id}>
                    {type.displayName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', 'slaType']}
            label="SLA Type"
            rules={[{ required: true, message: 'SLA Type is required' }]}
          >
            <Select disabled loading={provisionOptionsIsPending}>
              {provisionOptions?.availableSlaTypes?.map(type => {
                return (
                  <Select.Option key={type.id} value={type.id}>
                    {type.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row align="stretch" gutter={24} justify="space-around">
        <Col span={12}>
          <Form.Item
            name={['user', 'cost_center']}
            label="Cost Center"
            rules={[{ required: true, message: 'Cost Center is required' }]}
          >
            <Input
              placeholder="Cost Center"
              onChange={event => {
                form.setFieldsValue({
                  user: {
                    organization: event.target.value,
                  },
                });
                form.setFieldsValue({
                  email: {
                    subject: event.target.value + ' - Welcome to Incorta!',
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', 'organization']}
            label="Organization"
            rules={[{ required: true, message: 'Organization is required' }]}
          >
            <Input
              placeholder="Organization"
              onChange={event => {
                form.setFieldsValue({
                  email: {
                    subject: event.target.value + ' - Welcome to Incorta!',
                  },
                });
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default CreateUserProvisionPanel;
