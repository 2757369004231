import React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { notification } from 'antd';

export function displaySuccessMessage(message: string) {
  notification.success({
    message,
    icon: <CheckCircleOutlined style={{ color: '#37517F' }} />,
    style: { fontSize: 16, color: '#21314D' },
  });
}

export function displayErrorMessage(message: string) {
  notification.error({
    message,
    icon: <CloseCircleOutlined style={{ color: '#FF0000' }} />,
    style: { fontSize: 16, color: '#21314D' },
  });
}

export function wait(time: number) {
  return new Promise(resolve => setTimeout(resolve, time));
}

export function searchFilterOption(input: any, option: any) {
  if (typeof option.props?.children === 'string') {
    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }
  return false;
}

export function sum<T>(array: T[], cb: (a: T) => number): number {
  let sum = array.reduce((acc, a) => acc + cb(a), 0);
  return round(sum);
}

function round(number: number) {
  return Math.round((number + Number.EPSILON) * 100000) / 100000;
}

export function search(text: string | null, searchKeyword: string) {
  if (!text) {
    return false;
  }
  return (
    text
      .trim()
      .replace(/\s/g, '')
      .toLowerCase()
      .indexOf(searchKeyword.trim().replace(/\s/g, '').toLowerCase()) > -1
  );
}
