import React, { useState } from 'react';
import './AutoScalingModal.less';
import { Button, Card, Divider, Form, Input, Modal, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import ClusterAutoScalingIcon from 'images/cluster-autoscaling-icon.svg';
import { AutoscaleStatus, Instance } from 'types/cluster';
import { useUpdateAutoScalingConfig } from 'hooks/queries';

const fieldName = {
  REQUEST_DELAY: 'requestDelay',
  COOL_DELAY: 'coolDelay',
  REJECTED_QUERIES_UP: 'rejectedQueriesUp',
  REJECTED_QUERIES_DOWN: 'rejectedQueriesDown',
  CONSECUTIVE_QUERIES: 'consecutiveQueries',
} as const;

const fieldLimit = {
  [fieldName.REQUEST_DELAY]: {
    min: 5,
    max: 100,
  },
  [fieldName.COOL_DELAY]: {
    min: 5,
    max: 30,
  },
  [fieldName.REJECTED_QUERIES_UP]: {
    min: 1,
    max: 100,
  },
  [fieldName.REJECTED_QUERIES_DOWN]: {
    min: 1,
    max: 100,
  },
  [fieldName.CONSECUTIVE_QUERIES]: {
    min: 1,
    max: 100,
  },
} as const;

function formatTime(autoScales: AutoscaleStatus) {
  return {
    ...autoScales,
    coolDelay: autoScales.coolDelay / 60,
  };
}

type AutoScalingModalProps = {
  instance: Instance;
  onCancel: () => void;
};
function AutoScalingModal({ instance, onCancel }: AutoScalingModalProps) {
  const { id, name } = instance;
  const [form] = Form.useForm();
  const intl = useIntl();
  const [initialValues] = useState(
    instance.autoScales ? formatTime(instance.autoScales) : null,
  );

  const {
    mutateAsync: mutateUpdateAutoScalingConfig,
    isLoading: isUpdateAutoScalingLoading,
  } = useUpdateAutoScalingConfig();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const FormItem = ({ name, labelId, tooltipLabelId, min, max }) => {
    return (
      <Form.Item
        name={name}
        label={
          <div className="auto-scaling-modal__label">
            <FormattedMessage id={labelId} />
            <Tooltip
              title={
                <FormattedMessage id={tooltipLabelId} values={{ min, max }} />
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
        className="form-item__wrapper"
        required
        rules={validatorRules}
      >
        <Input type="number" min={min} max={max} />
      </Form.Item>
    );
  };

  function validateWholeNumber(rule, value, callback) {
    if (!Number.isInteger(Number(value))) {
      return Promise.reject('Please enter a whole number');
    } else {
      return Promise.resolve();
    }
  }
  function validateWithinRange(rule, value, callback) {
    if (
      value < fieldLimit[rule.field].min ||
      value > fieldLimit[rule.field].max
    ) {
      return Promise.reject(
        `Please enter a value between ${fieldLimit[rule.field].min} and ${
          fieldLimit[rule.field].max
        }`,
      );
    } else {
      return Promise.resolve();
    }
  }

  const validatorRules = [
    {
      validator: validateWholeNumber,
    },
    {
      validator: validateWithinRange,
    },
  ];

  async function handleSubmit(values: {
    requestDelay: string;
    coolDelay: string;
    rejectedQueriesUp: string;
    rejectedQueriesDown: string;
    consecutiveQueries: string;
  }) {
    try {
      await mutateUpdateAutoScalingConfig({
        instanceID: id,
        instanceName: name,
        consecutiveQueries: +values.consecutiveQueries,
        coolDelay: +values.coolDelay * 60,
        rejectedQueriesDown: +values.rejectedQueriesUp,
        rejectedQueriesUp: +values.rejectedQueriesDown,
        requestDelay: +values.requestDelay,
      });
      onCancel();
    } catch (error) {
      //
    }
  }

  async function onValuesChange(changedValues: any, allValues: any) {
    if (Object.keys(allValues).length === 1 && allValues.install) {
      form.setFieldValue('master', 'incortaspark');
    }
    setTimeout(() => {
      const fieldsValues = form.getFieldsValue();
      const shouldDisable = Object.values(fieldsValues).some(
        value => !value && value !== false,
      );

      setIsSubmitButtonDisabled(shouldDisable);
    }, 500);
  }

  return (
    <Modal
      open
      className="auto-scaling-modal__wrapper"
      title={
        <div className="auto-scaling-modal__title">
          <img
            src={ClusterAutoScalingIcon}
            alt={intl.formatMessage({
              id: 'clusterConfigurations.autoscaling.title',
            })}
          />
          <FormattedMessage id="clusterConfigurations.autoscaling.title" />
        </div>
      }
      onCancel={onCancel}
      footer={null}
      width={'50%'}
    >
      {!instance.autoScales ? (
        <p>You have to enable auto scaling from Cloud Portal</p>
      ) : (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{ ...initialValues }}
          onValuesChange={onValuesChange}
        >
          <p className="auto-scaling-modal__status">
            <FormattedMessage id={`clusterConfigurations.autoscaling.status`} />
            <FormattedMessage
              id={`clusterConfigurations.autoscaling.${
                initialValues.enabled ? 'enabled' : 'disabled'
              }`}
            />
          </p>
          {!initialValues.enabled && (
            <p className="auto-scaling-modal__change-autoscaling-hint">
              <InfoCircleOutlined />
              <FormattedMessage id="clusterConfigurations.autoscaling.changeAutoscalingHint" />
            </p>
          )}
          <Card className="auto-scaling-modal__configurations-wrapper">
            {Object.values(fieldName).map(name => (
              <FormItem
                key={name}
                name={name}
                labelId={`clusterConfigurations.autoscaling.${name}`}
                tooltipLabelId={`clusterConfigurations.autoscaling.hints.${name}`}
                min={fieldLimit[name].min}
                max={fieldLimit[name].max}
              />
            ))}
          </Card>
          <br />
          <Button
            type="primary"
            htmlType="submit"
            disabled={isSubmitButtonDisabled || isUpdateAutoScalingLoading}
          >
            Update
          </Button>
        </Form>
      )}
    </Modal>
  );
}

export default AutoScalingModal;
