import axios from 'axios';
import { API_URL } from 'utils/http';

export function getInfo(searchParams) {
  return axios.get(`${API_URL}/clustersstatus`, {
    params: searchParams,
  });
}
export function getInstancesNames(search) {
  return axios.get(`${API_URL}/clustersstatus/instancesearch`, {
    params: { search },
  });
}

export function getSlis(filterParams) {
  return axios.get(`${API_URL}/clustersstatus/sli`, {
    params: filterParams,
  });
}
export function insightsInfo(name) {
  return axios.get(`${API_URL}/clustersstatus/fetch`, {
    params: { name: name },
  });
}
export function getSparkPodsStatus(searchParams) {
  return axios.get(`${API_URL}/clustersstatus/spark/pods`, {
    params: searchParams,
  });
}

export function getSparkMvsStatus(searchParams) {
  return axios.get(`${API_URL}/clustersstatus/spark/mvs`, {
    params: searchParams,
  });
}
