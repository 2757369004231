import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { TrackingEventsEnum } from '../types/tracking';
import { trackEvent } from '../services/tracking';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    trackEvent(TrackingEventsEnum.SUBMIT_SIGN_IN_WITH_PASSWORD);
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      useRefreshTokens={true}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      scope={process.env.REACT_APP_AUTH_SCOPE}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;
