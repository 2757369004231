import './RoleField.less';

import React from 'react';
import { Select, Form, Tooltip } from 'antd';
import useRequest from 'hooks/useRequest';
import { getAccountTypes } from 'services/user';

let { Option } = Select;

function RoleField({ name, label }) {
  let { result, isPending } = useRequest(getAccountTypes, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let roles = result?.data?.roles;

  return (
    <Form.Item
      className="RoleField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect loading={isPending && !roles} roles={roles} />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, roles }: any) {
  return (
    <Select
      value={roles ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="RoleField__select-dropdown"
    >
      {roles?.map(role => (
        <Option key={role.name} value={role.name}>
          <Tooltip placement="right" title={role.description}>
            {role.displayName}
          </Tooltip>
        </Option>
      ))}
    </Select>
  );
}

export default RoleField;
