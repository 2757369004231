import React, { useCallback, useContext } from 'react';
import { Modal, Form, Input, Button, Space, Switch, Row, Col } from 'antd';
import moment from 'moment';
import RoleField from '../RoleField/RoleField';
import PermissionField from '../PermissionField/PermissionField';
import UserRolesField from '../UserRolesField/UserRolesField';
import useRequest from 'hooks/useRequest';
import { deleteUser } from 'services/user';
import { SessionContext } from 'auth/SessionProvider';
import { useUpdateUser } from 'hooks/queries';
import {
  userRolesPages,
  userRolesPagesPermissionsValues,
  userRolesActions,
} from 'types/user';

function UserModal({ isNew, user, refetchUsers, close }) {
  const { user: me } = useContext(SessionContext);
  const [form] = Form.useForm();

  let {
    email,
    firstName,
    lastName,
    company,
    phone,
    jobTitle,
    role,
    uuid,
    permission,
    trialExpiration,
    organization,
    cost_center,
    isPartner,
    enableMarketplace,
  } = user ?? {};

  if (user) {
    const differenceInDays = moment
      .duration(
        moment(trialExpiration).startOf('day').diff(moment().startOf('day')),
      )
      .asDays();
    trialExpiration = differenceInDays < 0 ? 0 : differenceInDays;
  }

  const { mutateAsync: mutateUpdateUser, isLoading } = useUpdateUser(uuid);

  const { makeRequest: deleteUserRequest } = useRequest(
    useCallback(async () => {
      await deleteUser(uuid);
      await refetchUsers();
    }, [uuid, refetchUsers]),
    {
      handleError: true,
    },
  );

  const userHasEditUserRoles = !!(
    userRolesActions.EDIT_USER_ROLES in me?.userRolesActions
  );

  async function handleFinish(user) {
    user.userRoles = user?.userRoles?.join(',');
    await mutateUpdateUser({ uid: uuid, user });
    await refetchUsers();
    close();
  }

  async function handleDeleteUser() {
    await deleteUserRequest();
    close();
  }

  function setUserRolesField(values: string[]) {
    form.setFieldValue('userRoles', values);
  }

  return (
    <Modal
      visible
      title={isNew ? 'New User' : 'Edit User'}
      footer={null}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={{
          email,
          firstName,
          lastName,
          company,
          phone,
          jobTitle,
          role: role.name,
          permission,
          trialExpiration,
          organization,
          cost_center,
          isPartner,
          enableMarketplace,
        }}
        layout="vertical"
        name="User"
        onFinish={handleFinish}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: 'email' }]}
        >
          <Input disabled={!isNew} />
        </Form.Item>
        {me?.userRolesPagesPermissions?.[userRolesPages.USERS] ===
          userRolesPagesPermissionsValues.CAN_DELETE && (
          <RoleField label="Account Type" name="role" />
        )}
        {me?.userRolesPagesPermissions?.[userRolesPages.USERS] ===
          userRolesPagesPermissionsValues.CAN_DELETE && (
          <PermissionField label="Cli Permission" name="permission" />
        )}
        <Row
          align="stretch"
          gutter={24}
          justify="space-around"
          key="permissions"
        >
          {userHasEditUserRoles && (
            <Col span={13} key="permission">
              <UserRolesField
                label="User Roles"
                name="userRoles"
                currentUserRoles={user.roles}
                setUserRolesField={values => setUserRolesField(values)}
              />
            </Col>
          )}

          <Col span={4} key="partner">
            <Form.Item name="isPartner" label="Partner" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>

          <Col span={7} key="marketplace">
            <Form.Item
              name="enableMarketplace"
              label="App Marketplace"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row align="stretch" gutter={24} justify="space-around" key="guides">
          <Col span={12} key="firstName">
            <Form.Item name="firstName" label="First name">
              <Input />
            </Form.Item>
          </Col>

          <Col span={12} key="lastName">
            <Form.Item name="lastName" label="Last name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        {user.role.name === 'free' && (
          <Form.Item
            name="trialExpiration"
            label="Trial Expiration"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item name="company" label="Company">
          <Input />
        </Form.Item>
        <Form.Item name="jobTitle" label="Job title">
          <Input />
        </Form.Item>
        <Form.Item name="phone" label="Phone">
          <Input />
        </Form.Item>
        <Form.Item name="organization" label="Organization">
          <Input />
        </Form.Item>
        <Form.Item name="cost_center" label="cost_center">
          <Input />
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            disabled={
              +user?.userRolesPagesPermissions?.[userRolesPages.USERS] <
              +userRolesPagesPermissionsValues.CAN_EDIT
            }
          >
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
          {me?.userRolesPagesPermissions?.[userRolesPages.USERS] ===
            userRolesPagesPermissionsValues.CAN_DELETE && (
            <Button
              type="primary"
              danger
              onClick={() => {
                Modal.confirm({
                  title: (
                    <>
                      Are you sure you want to delete{' '}
                      <strong>{user.email}</strong>?
                    </>
                  ),
                  async onOk() {
                    await handleDeleteUser();
                  },
                });
              }}
            >
              Delete
            </Button>
          )}
        </Space>
      </Form>
    </Modal>
  );
}

export default UserModal;
