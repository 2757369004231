import React, { useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Button, Tooltip, Modal } from 'antd';
import {
  StopOutlined,
  CheckOutlined,
  UnlockOutlined,
  EditOutlined,
} from '@ant-design/icons';
import UserModal from '../UserModal/UserModal';
import useRequest from 'hooks/useRequest';
import { getUser, resetUserPassword, blockUser } from 'services/user';
import { showError } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import {
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';

const { Title } = Typography;

function UserDetails(userObj) {
  const { userid }: any = useParams();
  const [modalProps, setModalProps] = useState<any>(null);

  const { user: me } = useContext(SessionContext);
  const userHasEditUserProvisionPagePermission =
    +me.userRolesPagesPermissions?.[userRolesPages.PROVISION_USERS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const [param] = useState(userid);

  const {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getUser({ param }), [param]),
    { resolve: true },
  );

  const user = result?.data?.user || userObj;

  const usercolumns = [
    { title: 'Key', dataIndex: 'key' },
    { title: 'Value', dataIndex: 'value' },
  ];

  const userdata = [
    { key: 'First Name', value: `${user.firstName}` },
    { key: 'Last Name', value: `${user.lastName}` },
    { key: 'Email', value: `${user.email}` },
    { key: 'Company', value: `${user.company}` },
    { key: 'Organization', value: `${user.organization}` },
    { key: 'Cost Center', value: `${user.cost_center}` },
    {
      key: 'Job title',
      value: `${user.jobTitle === null ? '' : user.jobTitle}`,
    },
    {
      key: 'Phone',
      value: `${user.phone === null ? '' : user.phone}`,
    },
    {
      key: 'Roles',
      value: `${user?.roles?.replaceAll(',', ', ')}`,
    },
    {
      key: 'Account Type',
      value: `${user.role?.displayName}`,
    },
    {
      key: 'Total Consumed Credit',
      value: `${user.consumption === null ? '' : user.consumption}`,
    },
  ];

  return (
    <div className="UserDetails">
      <div className="tableTitle">
        <Title level={3}>Personal Information</Title>
        <div>
          <Tooltip
            title={
              !userHasEditUserProvisionPagePermission
                ? 'You don❜t have sufficient permission'
                : 'Edit'
            }
          >
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setModalProps({ isNew: false, user });
              }}
              disabled={!userHasEditUserProvisionPagePermission}
            >
              Edit
            </Button>
          </Tooltip>
          {user.uuid !== me.uuid &&
            userRolesActions.BLOCK_USER in me.userRolesActions && (
              <Tooltip title={user.blocked ? 'Unblock' : 'Block'}>
                <Button
                  icon={user.blocked ? <CheckOutlined /> : <StopOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to{' '}
                          {user.blocked ? 'Unblock' : 'block'}{' '}
                          <strong>{user.email}</strong>?
                        </>
                      ),
                      async onOk() {
                        await blockUser(user.uuid, !user.blocked).catch(
                          error => {
                            showError(error);
                          },
                        );
                        await refetchUsers();
                      },
                    });
                  }}
                >
                  {user.blocked ? 'Unblock' : 'Block'}{' '}
                </Button>
              </Tooltip>
            )}
          {userRolesActions.RESET_USER_PASSWORD in me.userRolesActions && (
            <Tooltip title="Reset Password">
              <Button
                icon={<UnlockOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to reset this user's password{' '}
                        <strong>{user.email}</strong>?
                      </>
                    ),
                    async onOk() {
                      await resetUserPassword(user.uuid);
                      await refetchUsers();
                    },
                  });
                }}
              >
                {'Reset Password'}{' '}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>

      <SearchableTable
        pagination={false}
        loading={isPending}
        columns={usercolumns}
        dataSource={userdata}
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default UserDetails;
