import { useCallback, useState } from 'react';
import { Modal, Form, Input, Button, Space, Radio, message } from 'antd';
import JavaVersionsField from './JavaVersionsField/JavaVersionsField';
import SparkVersionsField from './SparkVersionsField /SparkVersionsField';
import useRequest from 'hooks/useRequest';
import {
  createImage,
  deleteImage,
  promoteImage,
  sanityCheck,
} from 'services/images';

function ImagesModal({ image, close }) {
  let initValues = {
    Type: true,
  };
  const [disabled, setDisabled] = useState(false);

  const toggle = () => {
    setDisabled(!disabled);
  };

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async imageDetails => {
        if (image === 'Add') {
          let response = await createImage(imageDetails);
          message.info(response.data.message);
        } else if (image === 'Delete') {
          let response = await deleteImage(imageDetails);
          message.info(response.data.message);
        } else if (image === 'Promote') {
          let response = await promoteImage(imageDetails);
          message.info(response.data.message);
        } else if (image === 'Check') {
          let response = await sanityCheck(imageDetails);
          message.info(response.data.message);
        }
      },
      [image],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(imageDetails) {
    await makeRequest(imageDetails);
    close();
  }

  return (
    <Modal
      open
      title={image + ' Image'}
      footer={null}
      onCancel={close}
      wrapClassName="ImageModal"
    >
      <Form
        layout="vertical"
        name="Image"
        initialValues={initValues}
        onFinish={handleFinish}
      >
        {image === 'Delete' && (
          <div>
            <Form.Item
              name="build"
              label="Build ID"
              rules={[{ required: true }]}
            >
              <Input placeholder="Incorta-20200919-0030-470" />
            </Form.Item>

            <Form.Item
              name="upgradeMode"
              label="Release"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="custom">Custom Build</Radio>
                <Radio value="official">Official Release</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}

        {image === 'Promote' && (
          <div>
            <Form.Item
              name="incortaRelease"
              label="Incorta Release"
              rules={[{ required: true }]}
            >
              <Input placeholder="YYYY.MM.DD" />
            </Form.Item>
            <Form.Item
              extra="GCP: Promote Incorta Rlease from Staging Env to Prod Env
              Azure: Trigger Azure DevOps Pipeline for Image generation in Azure"
              name="Promotion"
              label="Promotion"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="GCP">GCP</Radio>
                <Radio value="Azure">Azure</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        )}

        {image === 'Check' && (
          <div>
            <Form.Item name="Type" label="Type">
              <Radio.Group onChange={toggle}>
                <Radio value={true}>newCluster</Radio>
                <Radio value={false}>upgradeCluster</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="cloudVersion"
              label="cloudVersion"
              rules={[{ required: true }]}
            >
              <Input placeholder="YYYY.MM.DD" />
            </Form.Item>

            {disabled && (
              <Form.Item
                extra="Build to upgrade cluster to "
                name="incortaCloudLatestOfficialBuildID"
                label="incortaCloudLatestOfficialBuildID"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            )}
          </div>
        )}

        {image === 'Add' && (
          <div>
            <Form.Item
              name="build"
              label="Build ID"
              rules={[{ required: true }]}
            >
              <Input placeholder="Incorta-20200919-0030-470" />
            </Form.Item>

            <Form.Item
              name="platform"
              label="Platform"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="gc">GCP</Radio>
                <Radio value="az">Azure</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="upgradeMode"
              label="Release"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="custom">Custom Build</Radio>
                <Radio value="official">Official Release</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="image" label="Image" rules={[{ required: true }]}>
              <Radio.Group>
                <Radio value="centos">Centos</Radio>
                <Radio value="ubuntu">Ubuntu</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="overwrite"
              label="Overwrite Existing build (if any)"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <JavaVersionsField label="Java Version" name="javaversions" />

            <SparkVersionsField label="Spark Version" name="spark" />
          </div>
        )}
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {image}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ImagesModal;
