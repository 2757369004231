export interface PasswordStrengthCondition {
  title: string;
  regex: RegExp;
  valid?: boolean;
}

export const PasswordStrengthConditions: PasswordStrengthCondition[] = [
  {
    title: 'Have 8 characters minimum ',
    regex: /^.{8,}$/g,
  },
  {
    title: 'Have 32 characters maximum ',
    regex: /^.{8,32}$/g,
  },
  {
    title: 'Have one lowercase character',
    regex: /(?=.*[a-z])/g,
  },
  {
    title: 'Have one uppercase character',
    regex: /(?=.*[A-Z])/g,
  },
  {
    title: 'Have one digit',
    regex: /(?=.*\d)/g,
  },
  {
    title: 'Have one special character',
    regex: /(?=.*[\W_])/g,
  },
  {
    title: 'Do not include spaces',
    regex: /(?=^\S*$)/g,
  },
];

export const pythonVersionRegex = /^\d+(\.\d+)?(\.\d+)?(\.\d+)?$/;
export const pythonPackageNameRegex =
  /^[a-zA-Z0-9]([a-zA-Z0-9._-]*[a-zA-Z0-9])?$/;

export const seachableTablePageSizeOptions = [10, 20, 30];

export const antDDateFormat = 'YYYY-MM-DD hh:mm A';
export const savedDateFormat = 'DD-MM-YYYY hh:mm A';
