import { useState, useContext } from 'react';
import './Restore.less';
import _ from 'lodash';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Typography, Button, Tooltip, Space } from 'antd';
import BackupInfoModal from './components/BackupInfoModal';
import RestoreClusterModal from './components/RestoreClusterModal';
import { useGetRestoredClusters } from 'hooks/queries';
import { seachableTablePageSizeOptions } from 'utils/constants';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

const { Title } = Typography;

function Restore() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const [modalProps, setModalProps] = useState<any>(null);
  const [restoreProps, setRestoreProps] = useState<any>(null);
  const { user } = useContext(SessionContext);

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchClusters,
  } = useGetRestoredClusters({ page, search, limit });

  const instancesAndBackups = result?.data?.instancesAndBackups;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const platformsMap = {
    gc: 'Google Cloud',
    az: 'Microsoft Azure',
    'az-saas': 'Microsoft Azure SaaS',
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
    },
    {
      title: 'Owner',
      render(_, instance) {
        return instance.user.email;
      },
    },
    {
      title: 'Backup ID',
      dataIndex: 'clonedFromBackup',
      render(_, instance) {
        return (
          <Space>
            <span>{instance?.backup?.id}</span>
            <Tooltip title="Info">

              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, instance });
                }}
              >
                <FormattedMessage id="backupInfo" defaultMessage="Backup Information" />

              </Button>
            </Tooltip>
          </Space>
        );
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      width: '10%',
    },
    {
      title: 'Region (Zone)',
      width: '20%',
      render(_, instance) {
        return `${instance.region
          .replace('-', ' ')
          .replace(/(^\w|\s\w)/g, m => m.toUpperCase())} (${instance.zone
            .replace('-', ' ')
            .replace(/(^\w|\s\w)/g, m => m.toUpperCase())})`;
      },
    },
    {
      title: 'Platform',
      width: '10%',
      render(_, instance) {
        return platformsMap[instance.platform];
      },
    },
    {
      title: 'Version',
      render(_, instance) {
        return (
          <span>
            {instance.customBuildName || instance.customBuild || instance.image}
          </span>
        );
      },
    },
    {
      title: 'Type',
      render(_, instance) {
        switch (instance.csize) {
          case 'small':
            return 'Xsmall';
          case 'medium':
            return 'Small';
          case 'larges1':
            return 'Medium';
          case 'larges2':
            return 'Large';
          case 'larges3':
            return 'XLarge';
          case 'larges4':
            return '2XLarge';
          case 'larges5':
            return '4XLarge';
          case 'larges6':
            return '6XLarge';
          case 'larges7':
            return '10XLarge';
          default: {
            return undefined;
          }
        }
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      width: '10%',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Last Startup',
      dataIndex: 'runningAt',
      width: '10%',
      render(date) {
        return date === null
          ? ''
          : format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },



  ];

  const searchPlaceholder =
    "Search By Cluster Name, Owner Email, Owner Name ";
  return (
    <div className="Clusters">
      <div className="cluster-header">
        <Title>Restored Clusters</Title>
        {+user.userRolesPagesPermissions?.[userRolesPages.RESTORED_CLUSTERS] >=
          +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Tooltip title="New">
              <Button type="primary" onClick={() => setRestoreProps(true)}>
                Restore Cluster
              </Button>
            </Tooltip>
          )}
      </div>
      <SearchableTable
        type="Clusters"
        searchPlaceholder={searchPlaceholder}
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={instancesAndBackups}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />
      {modalProps && (
        <BackupInfoModal
          {...modalProps}
          close={() => setModalProps(null)}
        />
      )}
      {restoreProps && (
        <RestoreClusterModal
          {...modalProps}
          refetchClusters={refetchClusters}
          close={() => setRestoreProps(null)}
        />)}

    </div>
  );
}

export default Restore;
