import { useQuery, useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { MessageApi } from 'antd/lib/message';
import { notification } from 'antd';
import { useUpdatePlatformAction } from './platformActionsQueries';
import { usePartners } from './partner';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { getAzurePlans, getToggleFeatures } from 'services/toggleFeature';
import {
  addUserToFeature,
  createUserRole,
  deleteUserRole,
  getAccountTypes,
  getUserRoleById,
  getUserRoles,
  getUsers,
  me,
  updateUser,
  updateUserRole,
  getCliPermissions,
  getUserRolesOperations,
} from 'services/user';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import { toggleFeatures } from 'types/toggleFeatures';
import {
  getClusters,
  updateCluster,
  updateAutoScalingConfig,
  syncPythonPackages,
  getRestoredClusters,
} from 'services/cluster';
import {
  createConfiguration,
  getConfigurations,
  getCostType,
  getSlaType,
  updateConfiguration,
} from 'services/configuration';
import { queryClient } from 'index';
import { Instance } from 'types/cluster';
import { getUserRolesResponse, userRoleCreateOrEditEndpoint } from 'types/user';
import {
  disableChidoriPublicApi,
  exposeChidoriCredentials,
  fetchAllChidoris,
  fetchInstanceChidoriStatus,
  getChidoriSizes,
  modifiedChidoriSize,
  updateChidoriSizes,
  upgradeChidori,
} from 'services/sparkmaster';
import { unifyPagesToHigherPermission } from 'utils/helpers';
import { ChidoriItem } from 'types/chidori';
import {
  addGlobalNotification,
  getNotificationsInstanceUsers,
} from 'services/notifications';
import { NotificationInstanceUsersData } from 'types/notification';
import { getOperators } from 'services/contextManager';
import { getImages } from 'services/images';
import { getFeatureBits } from 'services/featureBit';
import {
  getNotebookUsersConfigs,
  updateNotebookUsersConfigs,
  updateNotebook,
} from 'services/notebook';
import { NotebookUsersConfig } from 'types/notebook';

export { useUpdatePlatformAction };

export function useGetUserData({
  isAuthenticated,
}: {
  isAuthenticated: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.USER.GET_USER_DATA],
    () => me(),
    {
      select(res) {
        const unifiedPagesToHigherPermission = unifyPagesToHigherPermission(
          res.data.user.pages,
        );
        res = {
          ...res,
          data: {
            ...res.data,
            user: {
              ...res.data.user,
              pages: unifiedPagesToHigherPermission,
            },
          },
        };
        return res;
      },
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
  return queryOptions;
}

export function useUpdateUser(uid) {
  const queryOptions = useMutation(
    async ({ uid, user }: { uid: string; user: any }) => {
      return await updateUser(uid, user);
    },
    {
      mutationKey: [QueryServerKeys.USER.UPDATE_USER, uid],
      onSuccess(result) {
        // queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        // queryClient.invalidateQueries(
        //   QueryServerKeys.USER_ROLES.GET_USER_ROLES,
        // );
        displaySuccessMessage(result.data?.message);
      },
      onError(error: AxiosError) {
        // queryClient.invalidateQueries(
        //   QueryServerKeys.USER_ROLES.GET_USER_ROLE_BY_ID,
        // );
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetAzurePlans({
  userId,
  userRole,
}: {
  userId?: string;
  userRole?: string;
}) {
  return useQuery(
    [QueryServerKeys.SYSTEM_MANAGEMENT.AZURE_PLANS, userId],
    async () => {
      const result = await getAzurePlans({ userId, userRole });
      return result?.data?.body;
    },
    {
      enabled: !!userId,
    },
  );
}

export function useAddUserToAzureSupportFeature() {
  const queryOptions = useMutation(
    async ({
      uid,
      tackle_plan,
      userRole,
    }: {
      uid: string;
      tackle_plan: string;
      userRole: string;
    }) => {
      const result = await addUserToFeature({
        feature: toggleFeatures.azureSupport,
        uid,
        tackle_plan,
        userRole,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.ADD_USER_TO_FEATURE],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function getPaginatedData({
  key,
  page,
  search,
  limit,
  service,
  options,
}: {
  key: string;
  page: number;
  search: string;
  service: Function;
  limit?: number;
  options?: Object;
}) {
  const queryKey = [key, page, search, ...[limit]];
  const queryFn = async () => {
    return await service({ page, search, limit });
  };
  const queryOptions = {
    staleTime: 1 * 1000, // 1 sec
    ...options,
  };
  return { queryKey, queryFn, queryOptions };
}

export function useGetUserRoles({
  page = 1,
  search = '',
  limit,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.USER_ROLES.GET_USER_ROLES,
    page,
    search,
    limit,
    service: getUserRoles,
  });
  return useQuery<getUserRolesResponse>(queryKey, queryFn, queryOptions);
}

export function useGetUserAccountTypes() {
  return useQuery(
    [QueryServerKeys.ACCOUNT_TYPES.GET_USER_ACCOUNT_TYPES],
    async () => {
      const result = await getAccountTypes();
      return result?.data?.roles;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
}

export function useGetUserRoleById({ roleId }: { roleId: number }) {
  return useQuery(
    [QueryServerKeys.USER_ROLES.GET_USER_ROLE_BY_ID, roleId],
    async () => {
      const result = await getUserRoleById({ roleId: roleId?.toString() });
      return result;
    },
    {
      enabled: !!roleId,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
}

export function useCreateOrUpdateUserRole() {
  const queryOptions = useMutation(
    async ({
      userRole,
      roleId,
    }: {
      userRole: userRoleCreateOrEditEndpoint;
      roleId?: number;
    }) => {
      let result: AxiosResponse;
      if (roleId) {
        result = await updateUserRole({ userRole, roleId: roleId?.toString() });
      } else {
        result = await createUserRole({ userRole });
      }
      return result;
    },
    {
      mutationKey: [QueryServerKeys.USER_ROLES.CREATE_USER_ROLE],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        queryClient.invalidateQueries(
          QueryServerKeys.USER_ROLES.GET_USER_ROLES,
        );
        displaySuccessMessage(result.data?.message);
      },
      onError(error: AxiosError) {
        queryClient.invalidateQueries(
          QueryServerKeys.USER_ROLES.GET_USER_ROLE_BY_ID,
        );
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useDeleteUserRole() {
  const queryOptions = useMutation(
    async ({ roleId }: { roleId?: number }) => {
      const result = await deleteUserRole({
        roleId: roleId.toString(),
      });

      return result;
    },
    {
      mutationKey: [QueryServerKeys.USER_ROLES.CREATE_USER_ROLE],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        queryClient.invalidateQueries(
          QueryServerKeys.USER_ROLES.GET_USER_ROLES,
        );
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetUsers({
  page = 1,
  search = '',
  limit,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.USER.GET_USERS,
    page,
    search,
    limit,
    service: getUsers,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetChidori({ instanceid = '' }: { instanceid: string }) {
  return useQuery(
    [QueryServerKeys.CHIDORI.CHIDORI, instanceid],
    () => fetchInstanceChidoriStatus(instanceid),
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );
}

export function useExposeChidoriCredentials({
  instanceName,
}: {
  instanceName: string;
}) {
  const queryOptions = useMutation(
    async () => {
      const res = await exposeChidoriCredentials(instanceName);
      return res;
    },
    {
      mutationKey: [
        QueryServerKeys.CHIDORI.EXPOSE_CHIDORI_CREDENTIALS,
        instanceName,
      ],
      async onSuccess() {
        displaySuccessMessage('Chidori API token generated.');
        await queryClient.invalidateQueries(QueryServerKeys.CHIDORI.CHIDORI);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useDisableChidoriPublicApi({
  instanceName,
}: {
  instanceName: string;
}) {
  const queryOptions = useMutation(
    async () => {
      const res = await disableChidoriPublicApi(instanceName);
      return res;
    },
    {
      mutationKey: [
        QueryServerKeys.CHIDORI.DISABLE_CHIDORI_PUBLIC_API,
        instanceName,
      ],
      async onSuccess() {
        displaySuccessMessage('Chidori API disabled.');
        await queryClient.invalidateQueries(QueryServerKeys.CHIDORI.CHIDORI);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetChidoriSizes({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.CHIDORI.GET_SIZES,
    page,
    search,
    service: getChidoriSizes,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useUpdateChidoriSizes() {
  const queryOptions = useMutation(
    async ({ chidoriSizes }: { chidoriSizes: modifiedChidoriSize[] }) => {
      const result = await updateChidoriSizes({
        chidoriSizes,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.CHIDORI.UPDATE_SIZES],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.CHIDORI.GET_SIZES);
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetClusters({
  page = 1,
  search = '',
  limit = 10,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
    page,
    search,
    limit,
    service: getClusters,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useUpdateCluster() {
  const queryOptions = useMutation({
    mutationKey: QueryServerKeys.CLUSTER_MANAGEMENT.UPDATE_CLUSTER,
    mutationFn: async ({
      name,
      cluster,
    }: {
      name: string;
      cluster: Instance;
    }) => {
      const res = await updateCluster(name, cluster);
      return res;
    },
    onSuccess(result) {
      queryClient.invalidateQueries(
        QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
      );
      displaySuccessMessage(result.data.message);
    },
    onError(error: any) {
      let errorMessage = error && getErrorMessage(error);
      if (errorMessage) {
        displayErrorMessage(errorMessage);
      }
    },
  });
  return queryOptions;
}

export function useGetConfigurations({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
    page,
    search,
    service: getConfigurations,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetToggleFeatures({
  page = 1,
  search = '',
}: {
  page?: number;
  search?: string;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.SYSTEM_MANAGEMENT.TOGGLE_FEATURES,
    page,
    search,
    service: getToggleFeatures,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export function useGetUserPermissions({
  handleSuccess,
  handleError,
}: {
  handleSuccess?: Function;
  handleError?: Function;
}) {
  return useQuery([QueryServerKeys.USER.PERMISSIONS], getCliPermissions, {
    onSuccess(res) {
      handleSuccess?.(res);
    },
    onError(err) {
      handleError?.(err);
    },
    refetchOnWindowFocus: false,
  });
}

export function useCreateConfiguration() {
  const queryOptions = useMutation(
    async ({ configuration }: { configuration: any }) => {
      const result = await createConfiguration(configuration);
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.CREATE_CONFIGURATIONS],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}

export function useUpdateConfiguration() {
  const queryOptions = useMutation(
    async ({ id, configuration }: { id: any; configuration: any }) => {
      const result = await updateConfiguration(id, configuration);
      return result;
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.UPDATE_CONFIGURATIONS],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.SYSTEM_MANAGEMENT.CONFIGURATIONS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}

export function useUpdateAutoScalingConfig() {
  const queryOptions = useMutation(
    async ({
      instanceID,
      instanceName,
      requestDelay,
      coolDelay,
      rejectedQueriesUp,
      rejectedQueriesDown,
      consecutiveQueries,
    }: {
      instanceID: string;
      instanceName: string;
      requestDelay: number;
      coolDelay: number;
      rejectedQueriesUp: number;
      rejectedQueriesDown: number;
      consecutiveQueries: number;
    }) => {
      const result = await updateAutoScalingConfig({
        instanceID,
        instanceName,
        requestDelay,
        coolDelay,
        rejectedQueriesUp,
        rejectedQueriesDown,
        consecutiveQueries,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.CLUSTER_MANAGEMENT.UPDATE_AUTO_SCALING],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
        );
        displaySuccessMessage(result.data?.body?.message);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}

export function useGetUserRolesOperations() {
  const queryOptions = useQuery(
    [QueryServerKeys.USER_ROLES.USER_ROLES_OPERATIONS],
    async () => {
      return await getUserRolesOperations();
    },
    {
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );
  return queryOptions;
}

export function useSyncPythonPackages({ instanceID }: { instanceID: string }) {
  const queryOptions = useMutation(
    async () => {
      const res = await syncPythonPackages({
        instanceID,
      });
      return res;
    },
    {
      mutationKey: [
        QueryServerKeys.CLUSTER_MANAGEMENT.SYNC_PYTHON_PACKAGES,
        instanceID,
      ],
      async onSuccess() {
        await queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useGetAllChidoris({
  page = 1,
  limit,
  search,
}: {
  page: number;
  limit: number;
  search: string;
}) {
  return useQuery(
    [QueryServerKeys.CHIDORI.CHIDORI, search, page, limit],
    () => fetchAllChidoris({ page, limit, search }),
    {
      staleTime: 0,
      cacheTime: 0,
    },
  );
}

export function useUpgradeChidori(userID) {
  const queryOptions = useMutation(
    async (chidori: ChidoriItem) => {
      const result = await upgradeChidori(chidori?.instanceName, userID);

      return result;
    },
    {
      mutationKey: [QueryServerKeys.CHIDORI.UPGRADE, userID],
      onSuccess(result) {
        queryClient.invalidateQueries(QueryServerKeys.CHIDORI.CHIDORIS);
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useNotificationsInstanceUsers({
  page = 1,
  search = '',
  limit,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.NOTIFICATION.INSTANCE_USERS,
    page,
    search,
    limit,
    service: getNotificationsInstanceUsers,
  });

  return useQuery<AxiosResponse<NotificationInstanceUsersData>>(
    queryKey,
    queryFn,
    {
      ...queryOptions,
      staleTime: 2 * 1000, // 1 sec
      refetchInterval: 5 * (60 * 1000), // 5 mins
      onError(err) {
        if (err) {
          notification.error({
            message: 'Error',
            description: err,
          });
        }
      },
    },
  );
}

export function useAddGlobalNotification({
  selectedUsersIDs,
}: {
  selectedUsersIDs: string[];
}) {
  const queryOptions = useMutation(
    async ({
      event,
      message,
      method,
    }: {
      event: string;
      message: string;
      method: { email: boolean; alert: boolean };
    }) => {
      const res = await addGlobalNotification(
        event,
        message,
        selectedUsersIDs,
        method,
      );
      return res;
    },
    {
      mutationKey: [
        QueryServerKeys.NOTIFICATION.ADD_GLOBAL_NOTIFICATION,
        selectedUsersIDs,
      ],
      async onSuccess() {
        await queryClient.invalidateQueries(
          QueryServerKeys.NOTIFICATION.INSTANCE_USERS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useOperators() {
  const queryOptions = useQuery(
    [QueryServerKeys.OPERATORS.GET_OPERATORS],
    async () => {
      const res = await getOperators();
      const data = res.data.operators;
      return data;
    },
  );
  return queryOptions;
}

export function useUserRoles() {
  const queryOptions = useQuery(
    [QueryServerKeys.USER_ROLES.GET_USER_ROLES],
    async () => {
      const res = await getUserRoles({ search: '' });
      const data = res.userRoles;
      return data;
    },
  );
  return queryOptions;
}

export function useIncortaVersions() {
  const queryOptions = useQuery(
    [QueryServerKeys.INCORTA_VERSIONS.GET_INCORTA_VERSIONS],
    async () => {
      const res = await getImages();
      const data = res.data.images;
      return data;
    },
  );
  return queryOptions;
}

export function useFeatureBits() {
  const queryOptions = useQuery(
    [QueryServerKeys.FEATURE_BITS.GET_FEATURE_BITS],
    async () => {
      const res = await getFeatureBits({ search: '' });
      const data = res.data.featureBits;
      return data;
    },
  );
  return queryOptions;
}

export function useCostTypes() {
  const queryOptions = useQuery(
    [QueryServerKeys.COST_TYPE.GET_COST_TYPE],
    async () => {
      const res = await getCostType();
      const data = res.data.costTypes;
      return data;
    },
  );
  return queryOptions;
}

export function useSLAType() {
  const queryOptions = useQuery(
    [QueryServerKeys.SLA_TYPE.GET_SLA_TYPE],
    async () => {
      const res = await getSlaType();
      const data = res.data.slaTypes;
      return data;
    },
  );
  return queryOptions;
}

export function useGetNotebookUsersConfigs({
  handleSuccess,
  handleError,
  enabled = true,
}: {
  handleSuccess?: Function;
  handleError?: Function;
  enabled?: boolean;
}) {
  return useQuery(
    [QueryServerKeys.NOTEBOOK.GET_NOTEBOOK_USERS_CONFIGS],
    async () => {
      const res = await getNotebookUsersConfigs();
      return res?.data?.allChidoriXSizesInTable?.rows;
    },
    {
      enabled,
      onSuccess(res) {
        handleSuccess?.(res);
      },
      onError(err) {
        handleError?.(err);
      },
      refetchOnWindowFocus: false,
    },
  );
}

export function useUpdateNotebookUsersConfigs() {
  const queryOptions = useMutation(
    async ({
      notebookUsersSizes,
    }: {
      notebookUsersSizes: NotebookUsersConfig[];
    }) => {
      const result = await updateNotebookUsersConfigs({
        configs: notebookUsersSizes,
      });
      return result;
    },
    {
      mutationKey: [QueryServerKeys.NOTEBOOK.UPDATE_NOTEBOOK_USERS_CONFIGS],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.NOTEBOOK.GET_NOTEBOOK_USERS_CONFIGS,
        );
        displaySuccessMessage(result.data?.message);
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}

export function useUpdateNotebook({ instanceID }: { instanceID: string }) {
  return useMutation(
    async ({
      enableBusinessUserNotebook,
      businessUserNotebookCount,
    }: {
      enableBusinessUserNotebook: boolean;
      businessUserNotebookCount?: number;
    }) => {
      return await updateNotebook({
        instanceID,
        enableBusinessUserNotebook,
        businessUserNotebookCount,
      });
    },
    {
      mutationKey: [QueryServerKeys.NOTEBOOK.UPDATE_NOTEBOOK, instanceID],
      async onSuccess() {
        await queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
        );
      },
      onError(error: any) {
        let errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          showErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useGetRestoredClusters({
  page = 1,
  search = '',
  limit = 10,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
    page,
    search,
    limit,
    service: getRestoredClusters,
  });
  return useQuery(queryKey, queryFn, queryOptions);
}

export { usePartners };
