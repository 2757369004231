import './SlaField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getSlaType } from 'services/configuration';

let { Option } = Select;

function SlaField({ name, label, form }) {
  let { result, isPending } = useRequest(getSlaType, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let slaTypes = result?.data?.slaTypes;

  const onSlaTypeChange = slaType => {
    if (slaType === 1) {
      form.setFieldsValue({ isExcludedOc: true });
    }
  };

  return (
    <Form.Item className="SlaField" name={name} label={label}>
      <FieldSelect
        loading={isPending && !slaTypes}
        slaTypes={slaTypes}
        onChange={onSlaTypeChange}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, slaTypes }: any) {
  return (
    <Select
      value={slaTypes ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="SlaField__select-dropdown"
      allowClear
    >
      {slaTypes?.map(slaType => (
        <Option key={slaType.id} value={slaType.id}>
          {slaType.name}
        </Option>
      ))}
    </Select>
  );
}

export default SlaField;
