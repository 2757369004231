/**
 * from: https://ant.design/components/upload/#header
 */
import React, { useState, useEffect } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { message, Upload as AntUpload } from 'antd';

const { Dragger } = AntUpload;

interface IUpload {
  id?: string;
  onChange?: (e: any) => {};
  value?: any;
  className?: string;
  draggable?: boolean;
  text?: string;
  icon?: any;
  beforeUpload?: (e) => boolean;
  notImage?: boolean;
}

function Upload({
  id,
  onChange,
  value,
  className,
  draggable = false,
  text = 'Upload',
  icon = <PlusOutlined />,
  beforeUpload = beforeUploadImage,
  notImage = false,
  ...rest
}: IUpload) {
  let [loading, setLoading] = useState(false);
  let [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (value instanceof File) {
      if (notImage) {
        setLocalValue(value.name);
      } else {
        getBase64(value, imageUrl => {
          setLocalValue(imageUrl);
        });
      }
    } else {
      setLocalValue(value);
    }
  }, [notImage, value]);

  function handleChange(info) {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false);
      onChange?.(info.file.originFileObj);
    }
  }

  let uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : icon}
      <div className="ant-upload-text">{text}</div>
    </div>
  );

  let UploadComp = draggable ? Dragger : AntUpload;

  return (
    <UploadComp
      name={id}
      listType="picture-card"
      className={className}
      showUploadList={false}
      customRequest={dummyRequest as any}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      {...rest}
    >
      {!loading && localValue ? (
        notImage ? (
          localValue
        ) : (
          <img src={localValue} alt="img" style={{ width: '100%' }} />
        )
      ) : (
        uploadButton
      )}
    </UploadComp>
  );
}

function dummyRequest({ file, onSuccess }) {
  setTimeout(() => {
    onSuccess('ok');
  }, 0);
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUploadImage(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
    return false;
  }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error('Image must smaller than 10MB!');
    return false;
  }
  return isJpgOrPng && isLt10M;
}

export default Upload;
