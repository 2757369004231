import { Spin } from 'antd';

export function Spinner() {
  return (
    <Spin
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    />
  );
}
