import React, { useCallback, useState } from 'react';
import { Button, Space, Input, Modal, Form } from 'antd';
import FeatureBitsTable from './FeatureBitsTable';
import { getFeatureBits } from 'services/featureBit';
import { toggleFeatureBits } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { multiclusterAction } from 'utils/helpers';
const { Search } = Input;

function FeatureBitsActionsModal({
  clusterIds,
  instanceFeatureBits,
  refetchClusters,
  close,
  handleShowErrorModal,
}) {
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState('');
  let [searchVal, setSearchValue] = useState('');
  let [status, setStatus] = useState([]);

  const handleChange = value => {
    setSearchValue(value);
  };

  let { result } = useRequest(
    useCallback(() => getFeatureBits({ page, search }), [page, search]),
    { resolve: true },
  );

  let featureBits = result?.data?.featureBits;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async featureStatus => {
        if (featureStatus.length > 0) {
          const { featureBitsEnabled, featureBitsDisabled } =
            categorizeFeatureBits(featureStatus);

          await multiclusterAction(
            toggleFeatureBits,
            [clusterIds, featureBitsEnabled, featureBitsDisabled],
            refetchClusters,
            handleShowErrorModal,
          );
        }
        await refetchClusters();
      },
      // eslint-disable-next-line
      [clusterIds],
    ),
    {
      handleError: true,
    },
  );

  const categorizeFeatureBits = (status: any[]) => {
    const featureBitsCovered: any[] = [];
    const featureBitsEnabled: any[] = [];
    const featureBitsDisabled: any[] = [];

    for (let i = status.length - 1; i >= 0; i--) {
      if (!featureBitsCovered.includes(status[i].id)) {
        status[i].enabled === true
          ? featureBitsEnabled.push(status[i].id)
          : featureBitsDisabled.push(status[i].id);
        featureBitsCovered.push(status[i].id);
      }
    }

    return {
      featureBitsEnabled: featureBitsEnabled,
      featureBitsDisabled: featureBitsDisabled,
    };
  };

  async function handleFinish() {
    try {
      await makeRequest(status);
    } catch (e) {
      await refetchClusters();
    }
    close();
  }

  return (
    <Modal
      title="Feature Bits Status"
      visible={true}
      footer={null}
      width={'50%'}
      onCancel={close}
    >
      <Form className="FeatureBits" layout="vertical" name="Toggle Feature">
        <Form.Item>
          <Search
            placeholder="Search By Name or Key."
            onSearch={value => {
              setSearch(value);
              setPage(1);
            }}
            onChange={e => handleChange(e.target.value)}
            value={searchVal}
          />
        </Form.Item>
        <Form.Item>
          <FeatureBitsTable
            loading={isPending}
            featureBits={featureBits}
            clusterFeatureBits={instanceFeatureBits ? instanceFeatureBits : []}
            pageSize={pageSize}
            current={current}
            total={total}
            setStatus={setStatus}
            setPage={setPage}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              onClick={handleFinish}
            >
              Update
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default FeatureBitsActionsModal;
