import axios from 'axios';
import { API_URL } from 'utils/http';

export function getDataAgents({ page = 1, search }) {
  return axios.get(`${API_URL}/dataagents`, { params: { page, search } });
}

export function updateDataAgent(id, dataagent) {
  let formData = new FormData();
  Object.entries(dataagent).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.put(`${API_URL}/dataagents/${id}/update`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function createDataAgent(dataagent) {
  let formData = new FormData();
  Object.entries(dataagent).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.post(`${API_URL}/dataagents/create`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function deleteDataAgent(id) {
  return axios.delete(`${API_URL}/dataagents/${id}/delete`);
}

export function getAvailableVersions(agent) {
  return axios.get(`${API_URL}/dataagents/${agent?.agent?.id}/getversions`);
}
