import React, { useContext, useState } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { formatISO } from 'date-fns';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  BackwardOutlined,
} from '@ant-design/icons';

import { useParams, useHistory } from 'react-router-dom';
import UserToggleFeatureModal from './UserToggleFeatureModal/UserToggleFeatureModal';
import { toggleFeatures } from 'types/toggleFeatures';
import { addUserToFeature, removeUserFromFeature } from 'services/user';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetUsers } from 'hooks/queries';
import { userRolesActions } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import RolesTag from 'components/RolesTag/RolesTag';

const { Title } = Typography;

function ToggleFeature() {
  const { user } = useContext(SessionContext);
  const { feature }: any = useParams();
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const featureName: any = queryParams.get('value');
  const [isUserToggleFeatureModalOpen, setIsUserToggleFeatureModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchUsers,
  } = useGetUsers({ page, search });

  async function handleAddingUser(uid: string, feature: string) {
    if (feature === toggleFeatures.azureSupport) {
      setIsUserToggleFeatureModalOpen(true);
    } else {
      await addUserToFeature({ feature, uid });
    }
  }

  const users = result?.data?.users;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, userItem) {
        return (
          <RolesTag key={userItem.id} user={userItem} roles={userItem.roles} />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, userItem) {
        const userHasFeatureEnabled = userItem.toggleFeatures.find(
          ({ key }) => key === feature,
        );

        return (
          <Space>
            {
              <Tooltip title={userHasFeatureEnabled ? 'Remove' : 'Add'}>
                <Button
                  type={userHasFeatureEnabled ? 'default' : 'primary'}
                  size="small"
                  disabled={
                    !(
                      userRolesActions.ASSIGN_UNASSIGN_USER_TOGGLE_FEATURE in
                      user.userRolesActions
                    )
                  }
                  onClick={async () => {
                    setSelectedUser(userItem);
                    userHasFeatureEnabled
                      ? await removeUserFromFeature(userItem.uuid, feature)
                      : await handleAddingUser(userItem.uuid, feature);
                    await refetchUsers();
                  }}
                >
                  {userHasFeatureEnabled ? (
                    <MinusSquareOutlined />
                  ) : (
                    <PlusSquareOutlined />
                  )}
                </Button>
              </Tooltip>
            }
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ToggleFeature">
      <div className="tableTitle">
        <Title>
          {featureName.charAt(0).toUpperCase() + featureName.slice(1)} Toggle
          Feature: Eligible Users
        </Title>
        <Tooltip title="Toggle Features">
          <Button
            type="primary"
            icon={<BackwardOutlined />}
            onClick={() => {
              history.push(`/togglefeatures`);
            }}
          >
            Toggle Features
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        searchPlaceholder={
          "Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        }
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />

      {isUserToggleFeatureModalOpen && selectedUser && (
        <UserToggleFeatureModal
          selectedUser={selectedUser}
          feature={feature}
          setIsUserToggleFeatureModalOpen={setIsUserToggleFeatureModalOpen}
        />
      )}
    </div>
  );
}

export default ToggleFeature;
