import axios from 'axios';
import { API_URL } from 'utils/http';

export function getOcLabelsInfo(searchParams) {
  return axios.get(`${API_URL}/oclabels`, {
    params: searchParams,
  });
}

export function updateOcValuePerLabel(ocLabel) {
  return axios.post(`${API_URL}/oclabels/updateOcValuePerLabel`, { ocLabel });
}
