import { useContext, useState } from 'react';
import {
  Typography,
  Button,
  Tooltip,
  Space,
  Select,
  Input,
  DatePicker,
  Switch,
} from 'antd';
import { format } from 'date-fns';
import { EditOutlined, UserOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import UserModal from './UserModal/UserModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import {
  useGetUserAccountTypes,
  useGetUsers,
  useUserRoles,
} from 'hooks/queries';
import { seachableTablePageSizeOptions } from 'utils/constants';
import { SessionContext } from 'auth/SessionProvider';
import {
  getUserResponseUserItem,
  userRolesActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import RolesTag from 'components/RolesTag/RolesTag';
import { usersSearchColumns } from 'components/Clusters/helper';

const { Title } = Typography;

function Users() {
  const { user } = useContext(SessionContext);
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);

  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const {
    data: result,
    isLoading: isPending,
    refetch: refetchUsers,
  } = useGetUsers({ page, search, limit });

  const users = result?.data?.users;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const { data: userRolesResult, isLoading: isUserRolesLoading } =
    useUserRoles();
  const optionsUserRoles = userRolesResult;

  const fieldNames = {
    fullName: 'fullName',
    email: 'email',
    company: 'company',
    costCenter: 'cost_center',
    organization: 'organization',
    createdAt: 'createdAt',
    lastLoginAt: 'lastLoginAt',
    loginProvider: 'loginProvider',
    userRoles: 'User Role',
  } as const;

  const fieldsNamesMap = new Map([
    ['Full Name', 'Full Name'],
    ['Email', 'Email'],
    ['Company', 'Company'],
    ['Cost Center', 'Cost Center'],
    ['Organization', 'Organization'],
    ['Created At', 'Created At'],
    ['User Role', 'User Role'],
    ['Partner', 'Partner'],
    ['account type', 'Account Type'],
  ]);

  const {
    data: userAccountTypes,
    isLoading: isUserAccountTypesLoading,
    isError: hasUserAccountTypesErrors,
  } = useGetUserAccountTypes();

  const columns = [
    {
      title: 'Full name',
      dataIndex: fieldNames.fullName,
      render(_, userItem: getUserResponseUserItem) {
        return (
          <RolesTag key={userItem.id} user={userItem} roles={userItem.roles} />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: fieldNames.email,
      render(_, userItem) {
        return userItem.email || userItem.fullName;
      },
    },
    {
      title: 'Company',
      dataIndex: fieldNames.company,
      width: '10%',
    },
    {
      title: 'Cost Center',
      dataIndex: fieldNames.costCenter,
      width: '10%',
    },
    {
      title: 'Org',
      dataIndex: fieldNames.organization,
    },
    {
      title: 'Created At',
      dataIndex: fieldNames.createdAt,
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'last Login At',
      dataIndex: fieldNames.lastLoginAt,
      render(date) {
        return date ? format(new Date(date), 'dd-MM-yyyy hh:mm a') : 'Never';
      },
    },
    {
      title: 'Login Provider',
      dataIndex: fieldNames.loginProvider,
      render(_, userItem) {
        return userItem.loginProvider;
      },
    },
    {
      title: 'Action',
      render(_, userItem) {
        return (
          <Space>
            {+user?.userRolesPagesPermissions?.[userRolesPages.USERS] >=
              +userRolesPagesPermissionsValues.CAN_EDIT && (
              <Tooltip title="Edit">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setModalProps({ isNew: false, user: userItem });
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
            {userRolesActions.GET_USER in user.userRolesActions && (
              <Tooltip title="User Info">
                <Button
                  type="primary"
                  size="small"
                  icon={<UserOutlined />}
                  onClick={() => {
                    history.push(`/accounting/userdetails/${userItem.uuid}`);
                  }}
                ></Button>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  const filterMapping = new Map(
    usersSearchColumns?.map(field => [
      field,
      (onChange: (value: any) => void, id: string, defaultValue?: any) => {
        switch (field) {
          case 'Full Name':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Email':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Company':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Cost Center':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
                defaultValue={defaultValue}
              />
            );
          case 'Organization':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({
                    field,
                    id,
                    value: e.target.value,
                  })
                }
              />
            );
          case 'Created At':
            return defaultValue?.length ? (
              <DatePicker
                key={field}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: moment(val).format('DD-MM-YYYY hh:mm A'),
                  })
                }
                defaultValue={moment(defaultValue[0], 'YYYY-MM-DD hh:mm A')}
              />
            ) : (
              <DatePicker
                key={field}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: moment(val).format('DD-MM-YYYY hh:mm A'),
                  })
                }
              />
            );
          case 'User Role':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsUserRoles?.map(userRole => {
                  return {
                    value: userRole.name,
                    label: userRole.name,
                  };
                })}
                onChange={value =>
                  onChange({
                    field,
                    id,
                    value,
                  })
                }
                loading={isUserRolesLoading}
                filterOption={(input, option) => {
                  if (typeof option.label === 'string') {
                    return (
                      +option.label
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }
                  return false;
                }}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'Partner':
            return (
              <Switch
                key={field}
                checkedChildren="Is Partner"
                unCheckedChildren=""
                onChange={val =>
                  onChange({
                    field,
                    id,
                    value: val,
                  })
                }
                defaultChecked={defaultValue}
              />
            );
          case 'account type':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={userAccountTypes?.map(userAccountTypeItem => ({
                  label: userAccountTypeItem.displayName,
                  value: userAccountTypeItem.name,
                }))}
                loading={isUserAccountTypesLoading}
                disabled={hasUserAccountTypesErrors}
                onChange={value =>
                  onChange({ field: 'account type', id, value })
                }
                defaultValue={defaultValue ?? []}
              />
            );
          default:
            return null;
        }
      },
    ]),
  );

  return (
    <div className="Users">
      <Title>Users</Title>

      <SearchableTable
        type="Users"
        modalWidth={720}
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={usersSearchColumns}
        filterMapping={filterMapping}
        fieldsNamesMap={fieldsNamesMap}
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Users;
