import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Input, Button, Select, Modal } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './AdvancedSearch.less';

const { Option } = Select;

const AdvancedSearch = ({ close, columnsList, type, setSearchValue }) => {
  const [form] = Form.useForm();
  const [searchKeys, setsearchKeys] = useState<any>(null);

  useEffect(() => {
    const options = [...columnsList];
    setsearchKeys(options);
  }, [columnsList]);

  const onFinish = values => {
    let searchParam = '';
    if (values.defaultSearchKey && values.defaultValueOfSearchKey) {
      searchParam += `'${values.defaultSearchKey}'=${
        values.defaultValueOfSearchKey
      }${values.search ? (values.search.length > 0 ? '&&' : '') : ''}`;
    }
    if (values.search) {
      values.search.forEach((element, index) => {
        if (element.searchValue && element.searchKey) {
          searchParam += `'${element.searchKey}'=${element.searchValue}${
            values.search.length - 1 === index ? '' : '&&'
          }`;
        }
      });
    }
    setSearchValue(searchParam);
    close();
  };

  const handleRemoveItem = field => {
    const formValues = form.getFieldsValue(true);
    let currentOptions = [...columnsList];
    if (formValues.defaultSearchKey) {
      _.remove(currentOptions, function (c) {
        return c === formValues.defaultSearchKey;
      });
    }
    formValues.search.forEach((value, index) => {
      if (value && field.name !== index) {
        _.remove(currentOptions, function (c) {
          return c === value.searchKey;
        });
      }
    });
    setsearchKeys(currentOptions);
  };

  const handleChange = () => {
    const formValues = form.getFieldsValue(true);
    let currentOptions = [...columnsList];
    if (formValues.defaultSearchKey) {
      _.remove(currentOptions, function (c) {
        return c === formValues.defaultSearchKey;
      });
    }
    if (formValues.search) {
      formValues.search.forEach(value => {
        if (value) {
          _.remove(currentOptions, function (c) {
            return c === value.searchKey;
          });
        }
      });
    }
    setsearchKeys(currentOptions);
  };

  return (
    <Modal
      visible
      bodyStyle={{ maxHeight: '450px', overflow: 'auto' }}
      title={`Search For ${type}`}
      footer={null}
      onCancel={close}
    >
      {searchKeys && (
        <Form
          form={form}
          name="advanced-search-form"
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="form-item-row">
            <Form.Item
              className="form-item-select-default"
              name="defaultSearchKey"
            >
              <Select onChange={handleChange}>
                {searchKeys.map(item => (
                  <Option value={item}>{item}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              className="form-item-input-default"
              name="defaultValueOfSearchKey"
            >
              <Input />
            </Form.Item>
          </div>
          <Form.List name="search">
            {(fields, { add, remove }) => (
              <>
                {fields.map(field => (
                  <div key={field.key}>
                    <div className="form-item-row">
                      <Form.Item
                        {...field}
                        className="form-item-select"
                        name={[field.name, 'searchKey']}
                      >
                        <Select onChange={handleChange}>
                          {searchKeys.map(item => (
                            <Option value={item}>{item}</Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        {...field}
                        className="form-item-input"
                        name={[field.name, 'searchValue']}
                      >
                        <Input />
                      </Form.Item>
                      <MinusCircleOutlined
                        onClick={() => {
                          handleRemoveItem(field);
                          remove(field.name);
                        }}
                      />
                    </div>
                  </div>
                ))}
                {fields.length < columnsList.length - 1 && (
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Search Key
                    </Button>
                  </Form.Item>
                )}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default AdvancedSearch;
