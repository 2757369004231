import { useContext } from 'react';
import { SessionContext } from './SessionProvider';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import BlockedUserErrorPage from 'components/ErrorPages/BlockedUser/BlockedUserErrorPage';
import GuestUserErrorPage from 'components/ErrorPages/GuestUser/GuestUserErrorPage';
import MFARequired from 'components/ErrorPages/MFARequired/MFARequired';

type ErrorHandlerProps = {
  message: string;
  userEmail?: string;
};
export function ErrorHandler({ message }: ErrorHandlerProps) {
  const { logout } = useContext(SessionContext);
  if (message.toLocaleLowerCase().includes('blocked'))
    return <BlockedUserErrorPage />;
  else if (message.toLocaleLowerCase().includes('verify')) {
    return <EmailSentPage />;
  } else if (message.toLocaleLowerCase().includes('permission')) {
    return <GuestUserErrorPage />;
  } else if (message.toLocaleLowerCase().includes('state'))
    return <>Invalid State</>;
  else if (
    message.toLocaleLowerCase().includes('multifactor authentication required')
  ) {
    return <MFARequired />;
  } else if (
    message.toLocaleLowerCase().includes('please change your password')
  ) {
    logout({ returnTo: `${process.env.REACT_APP_URL}` });
    return null;
  } else return <>message</>;
}
