import { useCallback, useReducer, useRef } from 'react';
import useIsMounted from './useIsMounted';

interface State<TResult> {
  status: 'pending' | 'rest' | 'rejected';
  result: TResult | null;
  error: any;
}

type Action<TResult> =
  | { type: 'PENDING' }
  | { type: 'RESOLVED'; result: TResult | null }
  | { type: 'SET_RESULT'; result: TResult | null }
  | { type: 'REJECTED'; error: any };

function usePromiseRunner<TResult = any>(initialResult: TResult | null = null) {
  const mounted = useIsMounted();

  const [{ status, result, error }, dispatch] = useReducer(
    (state: State<TResult>, action: Action<TResult>): State<TResult> => {
      switch (action.type) {
        case 'PENDING': {
          return {
            ...state,
            status: 'pending',
          };
        }
        case 'RESOLVED': {
          return {
            ...state,
            status: 'rest',
            result: action.result,
          };
        }
        case 'REJECTED': {
          return {
            ...state,
            status: 'rejected',
            error: action.error,
          };
        }
        case 'SET_RESULT': {
          return {
            ...state,
            result: action.result,
          };
        }
        default: {
          throw Error();
        }
      }
    },
    {
      status: 'rest',
      result: initialResult,
      error: null,
    },
  );

  const isRest = status === 'rest';
  const isPending = status === 'pending';
  const isRejected = status === 'rejected';

  function setResult(result: TResult | null) {
    dispatch({
      type: 'SET_RESULT',
      result,
    });
  }

  // this ref to make sure that this hook state
  // is sync with last call data to run method
  const callCountRef = useRef(0);

  const run = useCallback(
    (promise: Promise<TResult>) => {
      const callCount = ++callCountRef.current;

      dispatch({
        type: 'PENDING',
      });
      return promise.then(
        result => {
          if (mounted.current && callCountRef.current === callCount) {
            dispatch({
              type: 'RESOLVED',
              result,
            });
          }
          return result;
        },
        error => {
          if (mounted.current && callCountRef.current === callCount) {
            dispatch({
              type: 'REJECTED',
              error,
            });
          }
          throw error;
        },
      );
    },
    [mounted],
  );

  return {
    isRest,
    isPending,
    isRejected,
    status,
    setResult,
    result,
    error,
    run,
  };
}

export default usePromiseRunner;
