import axios from 'axios';
import { API_URL } from 'utils/http';

export function getPackages({ page = 1, search }) {
  return axios.get(`${API_URL}/packages`, { params: { page, search } });
}

export function updatePackage(id, userPackage) {
  return axios.put(`${API_URL}/packages/${id}/update`, userPackage);
}

export function createPackage(userPackage) {
  return axios.post(`${API_URL}/packages/create`, userPackage);
}

export function deletePackage(id) {
  return axios.delete(`${API_URL}/packages/${id}/delete`);
}
