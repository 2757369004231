import { message } from 'antd';
import { userPage } from 'types/user';

export async function multiclusterAction(
  action,
  params,
  refetchClusters,
  handleShowErrorModal,
) {
  try {
    const response = await action(...params);
    message.success(response.data.message);
    await refetchClusters();
  } catch (e: any) {
    const data = e.response.data;
    message.error({
      content: data.message,
      onClick: () => {
        handleShowErrorModal({
          message: data.message,
          responseMessages: data.responseMessages,
        });
      },
    });
  }
}

export function unifyPagesToHigherPermission(pages: userPage[]) {
  const uniqueIdsArrayIndex = new Map<number, number>([]); // { page id -> index in uniqueHigherPermissionPages }
  const uniqueHigherPermissionPages: userPage[] = [];

  pages?.filter(currentPage => {
    const isPageAddedBefore = uniqueIdsArrayIndex.has(currentPage.page.id);
    if (!isPageAddedBefore) {
      const arrayNewLength = uniqueHigherPermissionPages.push(currentPage);
      uniqueIdsArrayIndex.set(currentPage.page.id, arrayNewLength - 1);
    } else {
      const currentIndex = uniqueIdsArrayIndex.get(currentPage.page.id);
      if (
        (+uniqueHigherPermissionPages[currentIndex]?.permission || 0) <
        +currentPage.permission
      ) {
        uniqueHigherPermissionPages[currentIndex].permission =
          currentPage.permission;
      }
    }
  });
  return uniqueHigherPermissionPages;
}

export function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getRelVersion(verStr: string) {
  const envVer = verStr || process.env.REACT_APP_BUILD_VERSION;
  const verSplit = envVer ? envVer.split('Rel') : [];
  const ver = verSplit.length > 1 ? verSplit[1] : envVer;

  if (ver) {
    const split = ver.split('.');
    let majorMinor = split.length === 1 ? split[0] : ver;
    if (split.length > 1) majorMinor = split[0] + '.' + split[1];
    let count = 0;
    if (split[2])
      split[2] = split[2]
        .replace(/\d+/g, match => (++count > 1 ? '' : match))
        .replace(/[^0-9]/g, '');
    return {
      majorMinor, //4.x
      fullVersionTruncated: split.splice(0, 3).join('.'), //x.y.z-abc_12 -> x.y.z
      fullVersion: split.splice(0, 3), //4.x.y
    };
  }
  return {
    majorMinor: '',
    fullVersion: '',
    fullVersionTruncated: '',
  };
}
