import { Typography, Tabs } from 'antd';
import './clustersstatus.less';
import IncortaClusters from './Incorta/IncortaClusters';
import SparkPods from './Spark/SparkPods';
import SparkMVs from './Spark/SparkMVs';
import SLIs from './SLIs/slis';

let { Title } = Typography;

function ClustersStatus() {
  return (
    <div className="Home">
      <Title>Cloud Insights</Title>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Incorta" key="1">
          <IncortaClusters />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Spark" key="2">
          <Tabs defaultActiveKey="1" tabPosition="left">
            <Tabs.TabPane tab="Pods" key="1">
              <SparkPods />
            </Tabs.TabPane>
            <Tabs.TabPane tab="MVs" key="2">
              <SparkMVs />
            </Tabs.TabPane>
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SLIs" key="3">
          <SLIs />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
export default ClustersStatus;
