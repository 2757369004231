import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, Select } from 'antd';
import { cloneCluster, cloneClusterFromBackup } from 'services/cluster';
import { getValidBackups } from 'services/backup';
import useRequest from 'hooks/useRequest';

function CloneModal({ isNew, cluster, refetchClusters, close }) {
  let { name } = cluster ?? {};
  let { result, isPending: isLoading } = useRequest(
    useCallback(() => getValidBackups(cluster?.name), [cluster?.name]),
    { resolve: true },
  );
  let validBackups = result?.data.validBackups;

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        if (cluster.cloneFrom !== 'Latest' && cluster.cloneFrom != -1) {
          await cloneClusterFromBackup(cluster.cloneFrom, cluster.name);
        } else {
          await cloneCluster(name, cluster);
        }
        await refetchClusters();
      },
      [name, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal visible title={'Clone Cluster'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          name,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Clone Name" rules={[{ required: true }]}>
          <Input placeholder="New Cluster Name" />
        </Form.Item>
        <Form.Item
          name="cloneFrom"
          label={'Clone version'}
          initialValue={validBackups ? validBackups[0]?.backup : 'Latest'}
          rules={[
            {
              required: true,
              message: 'Clone Version is required.',
            },
          ]}
        >
          <Select loading={isLoading}>
            {validBackups &&
              validBackups.map(({ id, backup, backupType }) => {
                return (
                  <Select.Option
                    key={id}
                    value={id}
                    className="image-options_ddl"
                  >
                    {' '}
                    {id !== -1 ? new Date(backup).toLocaleString() : backup}
                    {backupType === 'full'
                      ? ' (Full Backup)'
                      : backupType === 'metadata'
                      ? '(Metadata Backup)'
                      : backupType === 'disks'
                      ? '(Disks Backup)'
                      : backupType === 'metadisks'
                      ? '(Metadata and Disks Backup)'
                      : ''}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Clone'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default CloneModal;
