import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import MaintenanceStatusField from '../MaintenanceStatusField/MaintenanceStatusField';
import MaintenanceTimeoutsField from '../MaintenanceTimeoutsField/MaintenanceTimeoutsField';
import { updateMaintenanceMode } from 'services/cluster';
import useRequest from 'hooks/useRequest';
function ClusterMaintenanceModal({ isNew, cluster, refetchClusters, close }) {
  let {
    id,
    name,
    cost_center,
    organization,
    slaTypeID,
    costTypeID,
    isExcludedOc,
    maintenanceStatus,
    maintenanceTimeout,
  } = cluster ?? {};

  const [form] = Form.useForm();

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        await updateMaintenanceMode(id, cluster);
        await refetchClusters();
      },
      [id, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal open title="Cluster Maintenance" footer={null} onCancel={close}>
      <Form
        form={form}
        initialValues={{
          name,
          cost_center,
          organization,
          slaTypeID,
          costTypeID,
          isExcludedOc,
          maintenanceStatus,
          maintenanceTimeout,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>
        <MaintenanceStatusField
          label="Maintenance Mode"
          name="maintenanceStatus"
        />
        <MaintenanceTimeoutsField
          label="Maintenance Timeout"
          name="maintenanceTimeout"
        />
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ClusterMaintenanceModal;
