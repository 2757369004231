import React, { useCallback } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Switch,
  InputNumber,
  Col,
} from 'antd';

import { ldapSync } from 'services/cluster';

import useRequest from 'hooks/useRequest';

function LdapModal({ isNew, cluster, refetchClusters, close }) {
  let instanceName = cluster.name;
  let ldapRecord = {
    instanceName: instanceName,
    runOnce: false,
    frequency: 30,
    selfSync: false,
    analytics_url: '',
    tenant: '',
    username: '',
    password: '',
    ldap_base_provider_url: '',
    ldap_base_dn: '',
    ldap_user_dn: '',
    ldap_user_dn_password: '',
    ldap_user_mapping_login: '',
    ldap_user_mapping_name: '',
    ldap_user_mapping_mail: '',
    ldap_group_mapping_name: '',
    ldap_group_mapping_member: '',
    ldap_user_search_filter: '',
    ldap_group_search_filter: '',
    user_type: '',
    ldap_follow_referral: '',
    userLogin: '',
    userEmail: '',
    userName: '',
    userLang: '',
    userCountry: '',
    userTimezone: '',
    userCalendar: '',
    userType: '',
    groupName: '',
    groupDescription: '',
    groupType: '',
    groupId: '',
    userId: '',
    operation: '',
    deleteOperation: '',
    functionId: '',
    roleCode: '',
    autoSync: '',
    autoGenerateGroup: '',
    fullSync: '',
    assignmentMode: '',
    loggingEnabled: '',
  };

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async ldapRecord => {
        await ldapSync(ldapRecord);
        await refetchClusters();
      },
      [refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  const [disabled, setDisabled] = React.useState(true);

  const toggle = () => {
    setDisabled(!disabled);
  };

  const [isSelfSync, setLdapDetails] = React.useState(false);

  const toggleLdapDetails = () => {
    setLdapDetails(!isSelfSync);
  };
  async function handleFinish(ldapRecord) {
    await makeRequest(ldapRecord);
    close();
  }

  return (
    <Modal
      visible
      title={'Schedule Ldap/Self Sync'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={ldapRecord}
        layout="vertical"
        name="ldapRecord"
        onFinish={handleFinish}
      >
        <Form.Item name="instanceName" label="Cluster Name">
          <Input disabled />
        </Form.Item>
        <Form.Item
          extra="If the cluster already has a scheduled job, this will overwrite the parameters."
          name="runOnce"
          label="Run Once"
          valuePropName="checked"
        >
          <Switch onClick={toggle} />
        </Form.Item>

        <Form.Item
          name="selfSync"
          label={isSelfSync ? 'Self Sync' : 'Ldap Sync'}
          valuePropName="checked"
        >
          <Switch defaultChecked onChange={toggleLdapDetails} />
        </Form.Item>

        {disabled && (
          <Form.Item
            name="frequency"
            label="Frequency in Minutes"
            rules={[{ required: true }]}
          >
            <InputNumber
              style={{ width: '100%' }}
              min={5}
              max={1440}
              defaultValue={30}
            />
          </Form.Item>
        )}

        <Form.Item
          extra="Tenant name"
          name="tenant"
          label="tenant"
          rules={[{ required: true }]}
        >
          <Input placeholder="default" />
        </Form.Item>

        <Form.Item
          extra="User name"
          name="username"
          label="username"
          rules={[{ required: true }]}
        >
          <Input placeholder="admin" />
        </Form.Item>

        <Form.Item
          extra="Password"
          name="password"
          label="password"
          rules={[{ required: true }]}
        >
          <Input type="password" placeholder="Password" />
        </Form.Item>

        {!isSelfSync && (
          <Col>
            <Form.Item
              extra="Provide ldap url"
              name="ldap_base_provider_url"
              label="ldap.base.provider.url"
              rules={[{ required: true }]}
            >
              <Input placeholder="ldap://HOST_NAME:PORT_NUM" />
            </Form.Item>

            <Form.Item
              extra="Distinguished Name to connect with"
              name="ldap_base_dn"
              label="ldap.base.dn"
              rules={[{ required: true }]}
            >
              <Input placeholder="dc=ldap,dc=test,dc=com" />
            </Form.Item>

            <Form.Item
              extra="Distinguished Name of a user in the ldap to authenticate with"
              name="ldap_user_dn"
              label="ldap.user.dn"
              rules={[{ required: true }]}
            >
              <Input placeholder="cn=admin,dc=ldap,dc=test,dc=com" />
            </Form.Item>

            <Form.Item
              extra="Distinguished Password of a user in the ldap to authenticate with"
              name="ldap_user_dn_password"
              label="ldap.user.dn.password"
              rules={[{ required: true }]}
            >
              <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item
              extra="The attribute that will map login of Incorta user"
              name="ldap_user_mapping_login"
              label="ldap.user.mapping.login"
              rules={[{ required: true }]}
            >
              <Input placeholder="mail" />
            </Form.Item>

            <Form.Item
              extra="The attributes that will map name of Incorta user"
              name="ldap_user_mapping_name"
              label="ldap.user.mapping.name"
              rules={[{ required: true }]}
            >
              <Input placeholder="cn" />
            </Form.Item>

            <Form.Item
              extra="The attributes that will map mail of Incorta user"
              name="ldap_user_mapping_mail"
              label="ldap.user.mapping.mail"
              rules={[{ required: true }]}
            >
              <Input placeholder="mail" />
            </Form.Item>

            <Form.Item
              extra="The attributes that will map name of Incorta group"
              name="ldap_group_mapping_name"
              label="ldap.group.mapping.name"
              rules={[{ required: true }]}
            >
              <Input placeholder="cn" />
            </Form.Item>

            <Form.Item
              extra="The attributes that will map attached users of Incorta group"
              name="ldap_group_mapping_member"
              label="ldap.group.mapping.member"
              rules={[{ required: true }]}
            >
              <Input placeholder="member" />
            </Form.Item>

            <Form.Item
              extra="A filter to search users with given example"
              name="ldap_user_search_filter"
              label="ldap.user.search.filter"
              rules={[{ required: true }]}
            >
              <Input placeholder="(sn=*)" />
            </Form.Item>

            <Form.Item
              extra="A filter to search groups with given example"
              name="ldap_group_search_filter"
              label="ldap.group.search.filter"
              rules={[{ required: true }]}
            >
              <Input placeholder="(cn=*)" />
            </Form.Item>

            <Form.Item
              extra="User type, possible values are: internal, sso, and ldap"
              name="user_type"
              label="user.type"
              rules={[{ required: true }]}
            >
              <Input placeholder="ldap" />
            </Form.Item>

            <Form.Item
              extra="Indicate that it does not have a copy of a requested object, If set to true ,the search will be slower"
              name="ldap_follow_referral"
              label="ldap.follow.referral"
              rules={[{ required: true }]}
            >
              <Input placeholder="false" />
            </Form.Item>
          </Col>
        )}

        {isSelfSync && (
          <Col>
            <Form.Item
              extra="User fields"
              name="userLogin"
              label="userLogin"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_users.Login" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userEmail"
              label="userEmail"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_users.Email" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userName"
              label="userName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_users.Name" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userLang"
              label="userLang"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;English&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userCountry"
              label="userCountry"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;United States&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userTimezone"
              label="userTimezone"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;GMT-08:00&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userCalendar"
              label="userCalendar"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;Gregorian&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="User fields"
              name="userType"
              label="userType"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;INTERNAL&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="Group fields"
              name="groupName"
              label="groupName"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_functions.Function_ID" />
            </Form.Item>

            <Form.Item
              extra="Group fields"
              name="groupDescription"
              label="groupDescription"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_functions.Description" />
            </Form.Item>

            <Form.Item
              extra="Group fields"
              name="groupType"
              label="groupType"
              rules={[{ required: true }]}
            >
              <Input placeholder="&#38;quot;SSO&#38;quot;" />
            </Form.Item>

            <Form.Item
              extra="User group fields"
              name="groupId"
              label="groupId"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_user_groups.Group_ID" />
            </Form.Item>

            <Form.Item
              extra="User group and User role fields"
              name="userId"
              label="userId"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_user_groups.User_ID" />
            </Form.Item>

            <Form.Item
              extra="User group and User role fields"
              name="operation"
              label="operation"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_user_groups.Operation" />
            </Form.Item>

            <Form.Item
              extra="User group and User role fields"
              name="deleteOperation"
              label="deleteOperation"
              rules={[{ required: true }]}
            >
              <Input placeholder="DELETE" />
            </Form.Item>

            <Form.Item
              extra="Function role fields"
              name="functionId"
              label="functionId"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_function_roles.Function_ID" />
            </Form.Item>

            <Form.Item
              extra="Function role and User role fields"
              name="roleCode"
              label="roleCode"
              rules={[{ required: true }]}
            >
              <Input placeholder="Self_sync.selfsync_function_roles.Role_Code" />
            </Form.Item>

            <Form.Item
              extra="When set to true, the script will sync users/groups with Incorta"
              name="autoSync"
              label="autoSync"
              rules={[{ required: true }]}
            >
              <Input placeholder="true" />
            </Form.Item>

            <Form.Item
              extra="When set to true, the script will generate groups as well"
              name="autoGenerateGroup"
              label="autoGenerateGroup"
              rules={[{ required: true }]}
            >
              <Input placeholder="true" />
            </Form.Item>

            <Form.Item
              extra="When set to true, the script will flush group assignments before starting"
              name="fullSync"
              label="fullSync"
              rules={[{ required: true }]}
            >
              <Input placeholder="true" />
            </Form.Item>

            <Form.Item
              extra="Either 'roles' (default) to user roles and function roles, or 'groups' to use user groups"
              name="assignmentMode"
              label="assignmentMode"
              rules={[{ required: true }]}
            >
              <Input placeholder="groups" />
            </Form.Item>

            <Form.Item
              extra="Global configuration"
              name="loggingEnabled"
              label="loggingEnabled"
              rules={[{ required: true }]}
            >
              <Input placeholder="true" />
            </Form.Item>
          </Col>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {disabled ? 'Schedule Run' : 'Run Once'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default LdapModal;
