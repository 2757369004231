import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { queryClient } from 'index';
import {
  addClusterToExternalBucket,
  instanceExternalBucketConfig,
  removeClusterFromExternalBucket,
  toggleDataExtraction,
  toggleExternalBucketDestinationCluster,
} from 'services/externalBucket';
import { simpleClusterSearchWithAttribute } from 'services/cluster';
import {
  AddClusterToExternalBucketResponse,
  ExternalBucketClusterSimpleSearchItem,
  ToggleDataExtractionResponse,
} from 'types/externalBucket.types';

type SuccessResponseType = {
  status: number;
  message: string;
};
function handleErrorInSuccessResponse<T extends SuccessResponseType>({
  data,
}: {
  data: T;
}) {
  if (!data?.status?.toString()?.startsWith('2')) {
    throw Error(data?.message);
  }
}

export function useGetInstanceExternalBucketConfig({
  instanceID,
}: {
  instanceID: string;
}) {
  return useQuery(
    [
      QueryServerKeys.EXTERNAL_BUCKET.GET_INSTANCE_EXTERNAL_BUCKET_CONFIG,
      instanceID,
    ],
    async () => {
      const result = await instanceExternalBucketConfig({ instanceID });
      return result?.data;
    },
  );
}

export function useToggleDataExtraction({
  instanceID,
  clusterName,
}: {
  instanceID: string;
  clusterName: string;
}) {
  return useMutation(
    async ({ enableExternalBucket }: { enableExternalBucket: boolean }) => {
      const result = await toggleDataExtraction({
        instanceID,
        enableExternalBucket,
      });
      handleErrorInSuccessResponse<ToggleDataExtractionResponse>({
        data: result.data,
      });
      return result;
    },
    {
      mutationKey: [
        QueryServerKeys.EXTERNAL_BUCKET.TOGGLE_EXTERNAL_BUCKET,
        instanceID,
      ],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.CLUSTER_MANAGEMENT.CLUSTERS,
        );
        queryClient.invalidateQueries(
          QueryServerKeys.EXTERNAL_BUCKET.GET_INSTANCE_EXTERNAL_BUCKET_CONFIG,
        );

        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `Data Extraction of cluster ${clusterName} is successfully updated`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const currentError = {
          response: {
            data: error,
          },
        };
        const errorMessage = error && getErrorMessage(currentError);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useSimpleClusterSearchForExternalBucket() {
  return useMutation(
    async ({ search }: { search: string }) => {
      const result =
        await simpleClusterSearchWithAttribute<ExternalBucketClusterSimpleSearchItem>(
          {
            attribute: 'externalbucketattributes',
            search,
          },
        );
      const availableData = result?.data?.instances?.filter(instance => {
        const isClusterASource = instance.externalBucketName;
        const isClusterConfiguredAsDestination = instance.configuredAsDist;
        return !isClusterASource && !isClusterConfiguredAsDestination;
      });
      return availableData;
    },
    {
      mutationKey: [
        QueryServerKeys.EXTERNAL_BUCKET
          .SIMPLE_CLUSTER_SEARCH_FOR_EXTERNAL_BUCKET,
      ],
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useAddClusterToExternalBucket({
  instanceID,
}: {
  instanceID: string;
}) {
  return useMutation(
    async ({ clusters }: { clusters: string[] }) => {
      const result = await addClusterToExternalBucket({ instanceID, clusters });
      handleErrorInSuccessResponse<AddClusterToExternalBucketResponse>({
        data: result.data,
      });
      return result;
    },
    {
      mutationKey: [
        QueryServerKeys.EXTERNAL_BUCKET.ADD_CLUSTER_TO_EXTERNAL_BUCKET,
        instanceID,
      ],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.EXTERNAL_BUCKET.GET_INSTANCE_EXTERNAL_BUCKET_CONFIG,
        );
        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `Cluster(s) successfully added to the external bucket`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const currentError = {
          response: {
            data: error,
          },
        };
        const errorMessage = error && getErrorMessage(currentError);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useRemoveClusterFromExternalBucket({
  instanceID,
}: {
  instanceID: string;
}) {
  return useMutation(
    async ({ clusters }: { clusters: string[] }) => {
      return await removeClusterFromExternalBucket({
        instanceID,
        clusters,
      });
    },
    {
      mutationKey: [
        QueryServerKeys.EXTERNAL_BUCKET.REMOVE_CLUSTER_TO_EXTERNAL_BUCKET,
        instanceID,
      ],
      onSuccess(result) {
        queryClient.invalidateQueries(
          QueryServerKeys.EXTERNAL_BUCKET.GET_INSTANCE_EXTERNAL_BUCKET_CONFIG,
        );
        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `Cluster(s) successfully removed from the external bucket`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const currentError = {
          response: {
            data: error,
          },
        };
        const errorMessage = error && getErrorMessage(currentError);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}

export function useToggleExternalBucketDestinationCluster({
  instanceID,
}: {
  instanceID: string;
}) {
  return useMutation(
    async ({
      destinationClusterID,
      enableExternalBucketCluster,
    }: {
      destinationClusterID: string;
      enableExternalBucketCluster: boolean;
    }) => {
      return await toggleExternalBucketDestinationCluster({
        instanceID,
        destinationClusterID,
        enableExternalBucketCluster,
      });
    },
    {
      mutationKey: [
        QueryServerKeys.EXTERNAL_BUCKET
          .TOGGLE_EXTERNAL_BUCKET_DESTINATION_CLUSTER,
        instanceID,
      ],
      onSuccess(result, { enableExternalBucketCluster }) {
        queryClient.invalidateQueries(
          QueryServerKeys.EXTERNAL_BUCKET.GET_INSTANCE_EXTERNAL_BUCKET_CONFIG,
        );
        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `Cluster successfully ${
                enableExternalBucketCluster ? 'enabled' : 'disabled'
              } in the external bucket`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const currentError = {
          response: {
            data: error,
          },
        };
        const errorMessage = error && getErrorMessage(currentError);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );
}
