import React, { useContext, useRef, useState } from 'react';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { FormattedMessage } from 'react-intl';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useDeleteUserRole, useGetUserRoles } from 'hooks/queries';
import {
  userRoleType,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import UserRoleModal from 'components/UserRoles/UserRoleModal/UserRoleModal';
import { seachableTablePageSizeOptions } from 'utils/constants';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

const fieldNames = {
  name: 'name',
} as const;

function UserRoles() {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');

  const { user } = useContext(SessionContext);

  const userHasEditRolesPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.ROLES] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const [showUserRoleModal, setShowUserRoleModal] = useState(false);
  const isNew = useRef<boolean>();
  const roleId = useRef<number>();
  const canBeDeleted = useRef<boolean>();

  const {
    data: userRolesResult,
    isLoading: isPending,
    refetch: refetchUserRoles,
  } = useGetUserRoles({ page, search });

  const {
    mutateAsync: mutateDeleteUserRole,
    isLoading: isDeleteUserRoleLoading,
    isError: hasDeleteUserRoleError,
  } = useDeleteUserRole();

  const userRoles = userRolesResult?.userRoles;
  const current = userRolesResult?.page;
  const total = userRolesResult?.total;

  const columns = [
    {
      title: <FormattedMessage id="userRolesPage.userRolesTable.fields.role" />,
      dataIndex: fieldNames.name,
      render(_, role: userRoleType) {
        return role.name;
      },
    },
    {
      title: (
        <FormattedMessage id="userRolesPage.userRolesTable.fields.action" />
      ),
      render(_, role: userRoleType) {
        return (
          <Space>
            <Tooltip
              title={
                <FormattedMessage
                  id={'userRolesPage.userRolesTable.buttons.edit'}
                />
              }
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setShowUserRoleModal(true);
                  isNew.current = false;
                  roleId.current = role.id;
                  canBeDeleted.current = role.canBeDeleted;
                }}
                //  disabled={!userHasEditRolesPagePermission}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              title={
                <FormattedMessage
                  id={
                    !userHasEditRolesPagePermission
                      ? 'You don❜t have sufficient permission'
                      : role.canBeDeleted
                      ? 'userRolesPage.userRolesTable.buttons.delete'
                      : 'userRolesPage.userRolesTable.buttons.deleteDisabled'
                  }
                />
              }
            >
              <Button
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to delete
                        <strong> {role.name} </strong>
                        role?
                      </>
                    ),
                    async onOk() {
                      await mutateDeleteUserRole({ roleId: role.id });
                      await refetchUserRoles();
                    },
                  });
                }}
                disabled={!role.canBeDeleted || !userHasEditRolesPagePermission}
              ></Button>
            </Tooltip>
          </Space>
        );
      },
      width: 150,
    },
  ];

  const searchColumns = [fieldNames.name];

  return (
    <>
      <div className="roles">
        <div className="tableTitle">
          <Title>
            <FormattedMessage id="userRolesPage.pageTitle" />
          </Title>
          <Tooltip
            title={
              !userHasEditRolesPagePermission
                ? 'You don❜t have sufficient permission'
                : 'Create'
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setShowUserRoleModal(true);
                isNew.current = true;
                roleId.current = null;
                canBeDeleted.current = null;
              }}
              disabled={!userHasEditRolesPagePermission}
            >
              <FormattedMessage id="userRolesPage.createNewRoleButton" />
            </Button>
          </Tooltip>
        </div>

        <SearchableTable
          type="User Roles"
          pagination={{
            pageSize: seachableTablePageSizeOptions[0],
            current,
            total,
            pageSizeOptions: seachableTablePageSizeOptions,
            onChange(newPage) {
              setPage(newPage);
            },
          }}
          onSearch={value => {
            setSearch(value);
            setPage(1);
          }}
          dataSource={userRoles?.sort(
            (a: userRoleType, b: userRoleType) => a.id - b.id,
          )}
          loading={isPending}
          columns={columns}
          advancedSearchColumns={searchColumns}
          searchPlaceholder="Search by User Role"
        />
      </div>
      {showUserRoleModal && (
        <UserRoleModal
          key={isNew ? 'isNew' : roleId.current}
          isNew={isNew.current}
          roleId={roleId.current}
          canBeDeleted={canBeDeleted.current}
          close={() => setShowUserRoleModal(false)}
        />
      )}
    </>
  );
}

export default UserRoles;
