import axios from 'axios';
import { API_URL } from 'utils/http';

export function getBlueprintCategories({ page, search }: any = {}) {
  return axios.get(`${API_URL}/blueprintcategories`, {
    params: { page, search },
  });
}

export function getBlueprintTags() {
  return axios.get(`${API_URL}/configurations/blueprinttags`);
}

export function getBlueprintFunctions() {
  return axios.get(`${API_URL}/configurations/blueprintfunctions`);
}

export function getAvailableIdleTime() {
  return axios.get(`${API_URL}/configurations/availableidletime`);
}

export function getBlueprintTypes() {
  return axios.get(`${API_URL}/configurations/blueprinttypes`);
}

export function updateBlueprintCategory(id, blueprintCategory) {
  let formData = new FormData();
  Object.entries(blueprintCategory).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.put(`${API_URL}/blueprintcategories/${id}/update`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function createBlueprintCategory(blueprintCategory) {
  let formData = new FormData();
  Object.entries(blueprintCategory).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.post(`${API_URL}/blueprintcategories/create`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function deleteBlueprintCategory(id) {
  return axios.delete(`${API_URL}/blueprintcategories/${id}/delete`);
}
