export const toggleFeatures = {
  onDemandLoader: 'onDemandLoader',
  microsoftSynapse: 'microsoftSynapse',
  azureSupport: 'azureSupport',
  dataagent: 'dataagent',
  payment: 'payment',
  subcluster: 'subcluster',
  whitelist: 'whitelist',
  chatbot: 'chatbot',
} as const;
