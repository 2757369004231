import axios from 'axios';
import { OperatorsData } from 'types/operators';
import { API_URL } from 'utils/http';

export function getOperators(search = '') {
  return axios.get<OperatorsData>(`${API_URL}/contextmanager/operators`, {
    params: { search: search },
  });
}

export function updateOperator(
  platform,
  region,
  cluster_code,
  priority,
  filteringCriteria,
  incortaOne,
) {
  return axios.put(`${API_URL}/contextmanager/update-operator`, {
    platform,
    region,
    cluster_code,
    priority,
    filteringCriteria,
    incortaOne,
  });
}
