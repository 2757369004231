import React, { useState, useCallback, useContext } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import CliModal from './CliModal/CliModal';
import useRequest from 'hooks/useRequest';
import { getCliCommands } from 'services/clicommands';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import RolesTag from 'components/RolesTag/RolesTag';

const { Title } = Typography;

function Cli() {
  const { user } = useContext(SessionContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);

  const {
    result,
    isPending,
    makeRequest: refetchCliCommands,
  } = useRequest(
    useCallback(() => getCliCommands({ page, search }), [page, search]),
    { resolve: true },
  );

  const cliCommands = result?.data?.cliCommands;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Command Category',
      dataIndex: 'category',
    },
    {
      title: 'Command',
      dataIndex: 'command',
    },
    {
      title: 'Least Allowed Permission',
      dataIndex: 'permission',
      render(_, cliCommand) {
        return (
          
              <Tag color="cyan">{cliCommand.permission}</Tag>
          
        );
      },
    },
    {
      title: 'Action',
      render(_, clicommand) {
        return (
          <Space>
            <Tooltip title="Edit">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, clicommand });
                }}
                disabled={
                  !(
                    +user.userRolesPagesPermissions?.[
                      userRolesPages.CLI_COMMAND
                    ] >= +userRolesPagesPermissionsValues.CAN_EDIT
                  )
                }
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="clicommands">
      <Title>CLI Commands</Title>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={cliCommands}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
      />

      {modalProps && (
        <CliModal
          {...modalProps}
          refetchCliCommands={refetchCliCommands}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Cli;
