import React, { useState, useContext } from 'react';
import { Typography, Button, Tooltip, Select, Input } from 'antd';
import { formatISO } from 'date-fns';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import NotificationsModal from './NotificationsModal';
import './Notification.less';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import { notificationSearchColumns } from 'components/Clusters/helper';
import {
  useNotificationsInstanceUsers,
  useOperators,
  useSLAType,
} from 'hooks/queries';
import { NotificationInstanceUsersData } from 'types/notification';
import { seachableTablePageSizeOptions } from 'utils/constants';

const { Title } = Typography;

function Notifications() {
  const { user } = useContext(SessionContext);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const onSelectChange = selectedRowIds => {
    setSelectedUsers(selectedRowIds);
  };

  const { data: notificationInstanceUsersResult, isLoading: isPending } =
    useNotificationsInstanceUsers({ page, search, limit });

  const users = notificationInstanceUsersResult?.data?.users;
  const current = +notificationInstanceUsersResult?.data?.currentPage;
  const total = notificationInstanceUsersResult?.data?.count;

  const columns: ColumnsType<NotificationInstanceUsersData> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '10%',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(dateValue) {
        return formatISO(new Date(dateValue), { representation: 'date' });
      },
    },
  ];

  const optionsPlatform = [
    { label: 'gc', value: 'gc' },
    {
      label: 'az-saas',
      value: 'az-saas',
    },
  ];
  const {
    data: operatorsData,
    isLoading: isOperatorsLoading,
    isError: hasOperatorsErrors,
  } = useOperators();
  const uniqueRegions = Array.from(
    new Set(operatorsData?.map(item => item.region)),
  );
  const uniqueOperators = Array.from(
    new Set(operatorsData?.map(item => item.cluster_code)),
  );
  const {
    data: sLAType,
    isLoading: isSLATypeLoading,
    isError: hasSLATypeErrors,
  } = useSLAType();
  const optionsAccessRoles = [
    { label: 'Owner', value: 'owner' },
    { label: 'Manager', value: 'accountAdmin' },
    { label: 'User', value: 'developer' },
    { label: 'Billing User', value: 'accountManager' },
    { label: 'All', value: 'all' },
  ];

  const fieldsNamesMap = new Map([
    ['platform', 'Platform'],
    ['region', 'Region'],
    ['operator', 'Operator'],
    ['sla type', 'SLA Type'],
    ['organization', 'Organization'],
    ['name', 'Cluster Name'],
    ['email', 'Email'],
    ['access role', 'Access Role'],
  ]);

  const filterMapping = new Map(
    notificationSearchColumns?.map(field => [
      field,
      (onChange: (value: any) => void, id: string, defaultValue?: any) => {
        switch (field) {
          case 'platform':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsPlatform}
                onChange={value => onChange({ field: 'platform', id, value })}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'region':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={uniqueRegions.map(region => {
                  return {
                    value: region,
                    label: region,
                  };
                })}
                disabled={hasOperatorsErrors}
                onChange={value => onChange({ field: 'region', id, value })}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'operator':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={uniqueOperators.map(operator => ({
                  label: operator,
                  value: operator,
                }))}
                loading={isOperatorsLoading}
                disabled={hasOperatorsErrors}
                onChange={value => onChange({ field: 'operator', id, value })}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'sla type':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={sLAType.map(sLATypeItem => ({
                  label: sLATypeItem.name,
                  value: sLATypeItem.name,
                }))}
                loading={isSLATypeLoading}
                disabled={hasSLATypeErrors}
                onChange={value => onChange({ field: 'sla type', id, value })}
                defaultValue={defaultValue ?? []}
              />
            );
          case 'organization':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({ field: 'organization', id, value: e.target.value })
                }
                defaultValue={defaultValue}
              />
            );

          case 'name':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({ field: 'name', id, value: e.target.value })
                }
                defaultValue={defaultValue}
              />
            );
          case 'email':
            return (
              <Input
                key={field}
                onChange={e =>
                  onChange({ field: 'email', id, value: e.target.value })
                }
                defaultValue={defaultValue}
              />
            );
          case 'access role':
            return (
              <Select
                key={field}
                mode="multiple"
                maxTagCount="responsive"
                options={optionsAccessRoles}
                onChange={value =>
                  onChange({ field: 'access role', id, value })
                }
                defaultValue={defaultValue ?? []}
              />
            );

          default:
            return null;
        }
      },
    ]),
  );

  return (
    <div className="Notifcations">
      <div className="tableTitle">
        <Title>Notifications</Title>
        <span>
          {selectedUsers.length > 0 && (
            <span className="num-of-selected">
              {selectedUsers.length} user(s) selected
            </span>
          )}
          <Tooltip
            title={
              +user.userRolesPagesPermissions?.[userRolesPages.NOTIFICATIONS] <
              +userRolesPagesPermissionsValues.CAN_EDIT
                ? 'You don❜t have sufficient permission to send global notification'
                : 'Send Global Notification'
            }
          >
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setModalProps({ isNew: true });
              }}
              disabled={
                +user.userRolesPagesPermissions?.[
                  userRolesPages.NOTIFICATIONS
                ] < +userRolesPagesPermissionsValues.CAN_EDIT
              }
            >
              Send Global Notification
            </Button>
          </Tooltip>
        </span>
      </div>
      {+user.userRolesPagesPermissions?.[userRolesPages.USERS] >=
        +userRolesPagesPermissionsValues.CAN_READ && (
        <SearchableTable
          rowKey="userID"
          type="Notifications"
          modalWidth={720}
          pagination={{
            pageSize: limit,
            current,
            total,
            showSizeChanger: true,
            pageSizeOptions: seachableTablePageSizeOptions,
            onShowSizeChange: (_, size) => {
              setLimit(size);
            },
            onChange(newPage) {
              setPage(newPage);
            },
          }}
          rowSelection={{
            selectedRowKeys: selectedUsers,
            preserveSelectedRowKeys: true,
            onChange: onSelectChange,
          }}
          dataSource={users}
          loading={isPending}
          columns={columns}
          advancedSearchColumns={notificationSearchColumns}
          onSearch={value => {
            setSearch(value);
            setPage(1);
          }}
          searchPlaceholder={
            'Search By Email, Name, Comapny Or `Field Name`=Some Value.'
          }
          filterMapping={filterMapping}
          fieldsNamesMap={fieldsNamesMap}
        />
      )}

      {modalProps && (
        <NotificationsModal
          {...modalProps}
          selectedUsers={selectedUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default Notifications;
