import React, { useState, useContext } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import DynamicConfigFormItem, {
  arrayLogic,
} from './DynamicConfigFormItem/DynamicConfigFormItem';
import { useCreateConfiguration, useUpdateConfiguration } from 'hooks/queries';

function ConfigModal({ isNew, configuration, close }) {
  const { id, configKey, configValue, displayName, isRequired, dataType } =
    configuration ?? {};

  const {
    mutateAsync: mutateCreateConfiguration,
    isLoading: isCreateConfigurationLoading,
  } = useCreateConfiguration();
  const {
    mutateAsync: mutateUpdateConfiguration,
    isLoading: isUpdateConfigurationLoading,
  } = useUpdateConfiguration();

  async function handleFinish(configuration) {
    configuration = {
      configKey: configuration.configKey,
      displayName: configuration.displayName,
      configValue:
        dataType === 'array'
          ? configuration.value.join(', ')
          : configuration.value.toString(),
      dataType,
      isRequired,
    };
    isNew
      ? await mutateCreateConfiguration({ configuration })
      : await mutateUpdateConfiguration({ id, configuration });
    close();
  }

  const [currentConfigValue] = useState(
    dataType === 'array'
      ? arrayLogic(configuration.configValue)
      : configuration.configValue,
  );

  return (
    <Modal
      open
      title={isNew ? 'New Configuration' : 'Edit Configuration'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          configKey,
          configValue,
          displayName,
          dataType,
        }}
        layout="vertical"
        name="User"
        onFinish={handleFinish}
      >
        <Form.Item
          name="configKey"
          label="Configuration Key"
          rules={[{ required: true }]}
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item name="displayName" label={'Display Name'}>
          <Input />
        </Form.Item>

        <DynamicConfigFormItem
          isRequired={isRequired}
          dataType={dataType}
          configValue={currentConfigValue}
        />
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={
              isCreateConfigurationLoading || isUpdateConfigurationLoading
            }
          >
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ConfigModal;
