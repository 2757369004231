import React, { useCallback } from 'react';
import { Divider, Spin, Table } from 'antd';
import { getStatsForADate } from 'services/user';
import useRequest from 'hooks/useRequest';

function StatHistoryDetails({ stat, userObj }) {
  let { result, isPending, isRejected } = useRequest(
    useCallback(
      () => getStatsForADate({ userId: userObj.id, date: stat.date }),
      [stat.date, userObj],
    ),
    { resolve: true },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin />
      </div>
    );
  }

  if (isRejected) {
    return <div className="flex-center"></div>;
  }

  let instances = result?.data.instances;

  return (
    <div className="StatHistoryDetails">
      {instances?.map(instance => {
        return (
          <div key={instance.id}>
            <h2>{instance.name}</h2>
            <Table
              size="small"
              dataSource={instance.stats}
              pagination={false}
              rowKey="id"
              className="antd-table-customized-scroll"
              columns={[
                {
                  title: 'Date',
                  key: 'dateFormatted',
                  dataIndex: 'dateFormatted',
                },
                {
                  title: 'Units',
                  key: 'units',
                  dataIndex: 'units',
                  align: 'right',
                },
                {
                  title: 'Units cost',
                  key: 'unitsCost',
                  dataIndex: 'unitsCost',
                  align: 'right',
                  render: cost => `$${cost}`,
                },
                {
                  title: 'Users',
                  key: 'users',
                  dataIndex: 'users',
                  align: 'right',
                },
                {
                  title: 'Users cost',
                  key: 'usersCost',
                  dataIndex: 'usersCost',
                  align: 'right',
                  render: cost => `$${cost}`,
                },
                {
                  title: 'Usage',
                  key: 'usage',
                  dataIndex: 'usage',
                  align: 'right',
                  render: mb => `${mb} GB`,
                },
                {
                  title: 'Usage cost',
                  key: 'usageCost',
                  dataIndex: 'usageCost',
                  align: 'right',
                  render: cost => `$${cost}`,
                },
                {
                  title: 'Total',
                  key: 'totalCost',
                  dataIndex: 'totalCost',
                  align: 'right',
                  render: cost => `$${cost}`,
                },
              ]}
            />
            <Divider />
          </div>
        );
      })}
    </div>
  );
}

export default StatHistoryDetails;
