import axios from 'axios';
import {
  AddClusterToExternalBucketResponse,
  InstanceExternalBucketConfigResponse,
  ToggleDataExtractionResponse,
} from 'types/externalBucket.types';
import { API_URL } from 'utils/http';

export function instanceExternalBucketConfig({
  instanceID,
}: {
  instanceID: string;
}) {
  return axios.get<InstanceExternalBucketConfigResponse>(
    `${API_URL}/instances/${instanceID}/instanceexternalbucketconfig`,
  );
}

export function toggleDataExtraction({
  instanceID,
  enableExternalBucket,
}: {
  instanceID: string;
  enableExternalBucket: boolean;
}) {
  return axios.post<ToggleDataExtractionResponse>(
    `${API_URL}/instances/${instanceID}/externalbucket`,
    {
      enableExternalBucket,
    },
  );
}

export function addClusterToExternalBucket({
  instanceID,
  clusters,
}: {
  instanceID: string;
  clusters: string[]; // clusters ids
}) {
  return axios.post<AddClusterToExternalBucketResponse>(
    `${API_URL}/instances/${instanceID}/adddistuser`,
    {
      clusters,
    },
  );
}

export function removeClusterFromExternalBucket({
  instanceID,
  clusters,
}: {
  instanceID: string;
  clusters: string[]; // clusters ids
}) {
  return axios.delete<AddClusterToExternalBucketResponse>(
    `${API_URL}/instances/${instanceID}/removedistuser`,
    {
      data: {
        clusters,
      },
    },
  );
}

export function toggleExternalBucketDestinationCluster({
  instanceID,
  destinationClusterID,
  enableExternalBucketCluster,
}: {
  instanceID: string;
  destinationClusterID: string;
  enableExternalBucketCluster: boolean;
}) {
  return axios.put<AddClusterToExternalBucketResponse>(
    `${API_URL}/instances/${instanceID}/updatedistuser`,
    {
      enableExternalBucketCluster,
      clusterID: destinationClusterID,
    },
  );
}
