import './CostField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getCostType } from 'services/configuration';

let { Option } = Select;

function CostField({ name, label }) {
  let { result, isPending } = useRequest(getCostType, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let costTypes = result?.data?.costTypes;

  return (
    <Form.Item className="CostField" name={name} label={label}>
      <FieldSelect loading={isPending && !costTypes} costTypes={costTypes} />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, costTypes }: any) {
  return (
    <Select
      value={costTypes ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="CostField__select-dropdown"
      allowClear
    >
      {costTypes?.map(costType => (
        <Option key={costType.id} value={costType.id}>
          {costType.name}
        </Option>
      ))}
    </Select>
  );
}

export default CostField;
