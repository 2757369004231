import React, { useCallback, useContext, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Checkbox,
  Select,
  Spin,
} from 'antd';
import { createSparkImage, getSparkTags } from 'services/sparkimages';
import useRequest from 'hooks/useRequest';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
const { Option } = Select;

function SparkImageModal({ refetchSparkImages, close }) {
  const [tags, setTags] = useState({});
  const [currentTags, setCurrentTags] = useState([]);
  const [allowDefaultSelection, setDefaultSelection] = useState(true);
  const { user } = useContext(SessionContext);

  const languages = {
    Python: 'spark-py',
    'R Language': 'spark-r',
    Scala: 'spark',
  };

  const { isPending: loading } = useRequest(
    useCallback(async () => {
      const result = await getSparkTags();
      setTags(result.data.tags);
      // eslint-disable-next-line
    }, [refetchSparkImages]),
    {
      handleError: true,
      resolve: true,
    },
  );

  const { makeRequest, isPending } = useRequest(
    useCallback(
      async image => {
        await createSparkImage(image);
        await refetchSparkImages();
      },
      [refetchSparkImages],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(image) {
    image.driver = `${image.tag}-driver`;
    try {
      await makeRequest(image);
    } finally {
      refetchSparkImages();
      close();
    }
  }

  function handleLanguageChange(value) {
    setCurrentTags(tags[languages[value]]);
    setDefaultSelection(value === 'Python');
  }

  return (
    <Modal visible title={'New Spark Image'} footer={null} onCancel={close}>
      <Spin spinning={loading}>
        <Form layout="vertical" name="SparkImage" onFinish={handleFinish}>
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="language"
            label="Language"
            rules={[{ required: true }]}
          >
            <Select onChange={handleLanguageChange}>
              {Object.keys(languages)?.map(_language => (
                <Option key={_language} value={_language}>
                  {_language}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="languageVersion"
            label="Language Version"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="tag" label="Tag" rules={[{ required: true }]}>
            <Select>
              {currentTags?.map(_currentTag => (
                <Option key={_currentTag} value={_currentTag}>
                  {_currentTag}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="version"
            label="Spark Version"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          {allowDefaultSelection && (
            <Form.Item
              name="isDefault"
              label="Default Image"
              valuePropName="checked"
              rules={[{ required: false }]}
            >
              <Checkbox />
            </Form.Item>
          )}
          <Form.Item name="minVersion" label="Minimum Incorta Version">
            <Input />
          </Form.Item>
          <Form.Item name="maxVersion" label="Maximum Incorta Version">
            <Input />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              disabled={
                +user.userRolesPagesPermissions?.[userRolesPages.IMAGES] <
                +userRolesPagesPermissionsValues.CAN_EDIT
              }
            >
              {'Create'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form>
      </Spin>
    </Modal>
  );
}

export default SparkImageModal;
