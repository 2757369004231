import React, { useContext, useEffect, useRef, useState } from 'react';
import './UserRoleModal.less';
import _ from 'lodash';
import {
  AlertFilled,
  AlertOutlined,
  DeleteOutlined,
  InfoCircleFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import {
  useCreateOrUpdateUserRole,
  useGetUserRolesOperations,
  useGetUserRoleById,
} from 'hooks/queries';
import {
  userRolesPages,
  userRolesPagesPermissionsValues,
  userRoleModalForm,
  userRoleCreateOrEditEndpoint,
} from 'types/user';
import { displayErrorMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

const { Option } = Select;

const fieldNames = {
  name: 'name',
  actions: 'actions',
  pages: 'pages',
} as const;

const pagesTableFieldNames = {
  name: 'name',
  permission: 'permission',
} as const;

type tempPageType = {
  id: number;
  name: string;
  permission: string;
  isSelected: boolean;
};

type RoleModalProps = {
  close: () => void;
  isNew: boolean;
  roleId?: number;
  canBeDeleted?: boolean;
};
function UserRoleModal({ isNew, roleId, close, canBeDeleted }: RoleModalProps) {
  const [form] = Form.useForm();
  const intl = useIntl();

  const { user } = useContext(SessionContext);

  const userHasEditRolesPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.ROLES] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const {
    data: userRolesOperationsResult,
    isLoading: isUserRolesOperationsLoading,
    isError: hasUserRolesOperationsError,
  } = useGetUserRolesOperations();
  const allUserActions = userRolesOperationsResult?.actions;
  const allUserPages = userRolesOperationsResult?.pages;

  const isFirstPagesDataFetchingDone = useRef(false);

  const {
    data: userRoleData,
    isLoading: isUserRoleDataLoading,
    isError: hasUserRoleDataError,
    refetch: refetchUserRoleData,
  } = useGetUserRoleById({ roleId });

  const {
    mutateAsync: mutateCreateOrUpdateUserRole,
    isLoading: isCreateOrUpdateUserRoleLoading,
    isError: hasCreateOrUpdateUserRoleError,
  } = useCreateOrUpdateUserRole();

  const userRolePages = userRoleData?.pages;

  //#region name
  const [, setRoleName] = useState(userRoleData?.name);
  function handleChangeRoleName(e) {
    setRoleName(e.target.value);
  }
  //#endregion

  //#region action
  const [roleActions, setRoleActions] = useState(userRoleData?.actions);
  function handleChangeRoleActions(values) {
    setRoleActions(values);
  }
  //#endregion

  //#region pages
  function getPageNameById(id: number): string {
    return allUserPages?.find(userPage => userPage.id === id)?.name;
  }

  const [pagesDataSource, setPagesDataSource] = useState(
    userRolePages?.map(page => {
      return {
        key: page.page,
        permission: page.permission,
        id: page.page,
        name: getPageNameById(page.page),
        isSelected: true,
      };
    }),
  );

  useEffect(() => {
    if (!isFirstPagesDataFetchingDone.current && userRolePages) {
      setPagesDataSource(
        userRolePages?.map(page => {
          return {
            key: page.page,
            permission: page.permission,
            id: page.page,
            name: getPageNameById(page.page),
            isSelected: true,
          };
        }),
      );
      isFirstPagesDataFetchingDone.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRolePages]);

  const isAddPageDisabled =
    allUserPages?.length <= pagesDataSource?.length ||
    isCreateOrUpdateUserRoleLoading ||
    !userHasEditRolesPagePermission;

  function handleAddNewPage() {
    const newData = {
      key: -pagesDataSource?.length || 0,
      id: null,
      name: '',
      permission: '1',
      isSelected: true,
    };
    setPagesDataSource([...(pagesDataSource ?? []), newData]);
  }

  function handleRemovePage(rowIndex: number) {
    const newDataSource = pagesDataSource?.filter((_, i) => i !== rowIndex);
    setPagesDataSource(newDataSource);
    const pagesFieldValue = form.getFieldValue(fieldNames.pages) || [];
    const updatedPageFieldValue = pagesFieldValue?.filter(
      (_, i) => i !== rowIndex,
    );
    form.setFieldValue(fieldNames.pages, updatedPageFieldValue);
  }

  function handleChangePermission({
    radioChangeEvent,
    pageId,
  }: {
    radioChangeEvent: RadioChangeEvent;
    pageId: number;
  }) {
    const newDataSource = [];
    const newPagesFieldValue = [];

    pagesDataSource.forEach(dS => {
      newDataSource.push({
        ...dS,
        permission:
          dS.id === pageId ? radioChangeEvent.target.value : dS.permission,
      });
      newPagesFieldValue.push({
        page: dS.id,
        permission:
          dS.id === pageId ? radioChangeEvent.target.value : dS.permission,
      });
    });

    setPagesDataSource(newDataSource);
    form.setFieldValue(fieldNames.pages, newPagesFieldValue);
  }

  const pagesTableColumns = [
    {
      title: (
        <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.fields.page" />
      ),
      dataIndex: pagesTableFieldNames.name,
      render(_, page: tempPageType, index: number) {
        function handleChangePageSelect(value, option) {
          const isPageNew = page.id === null;
          const updatedPagesDataSource = pagesDataSource.map((page, i) => {
            if (i === index) {
              return {
                key: option.value,
                id: option.value,
                name: option.children,
                permission: '1',
                isSelected: true,
              };
            }
            return page;
          });

          const pagesFieldValue = form.getFieldValue(fieldNames.pages) || [];
          const currentPageDataSource = {
            page: option.value,
            permission: page.permission,
            name: option.children[0],
          };
          const isThisFirstPageToAdd = !pagesFieldValue.length;

          let updatedPageDataSource: any[];

          if (isThisFirstPageToAdd) {
            updatedPageDataSource = [currentPageDataSource];
          } else if (isPageNew) {
            updatedPageDataSource = [...pagesFieldValue, currentPageDataSource];
          } else {
            updatedPageDataSource = pagesFieldValue.map((page, i) => {
              if (i === index) {
                page = currentPageDataSource;
              }
              return page;
            });
          }

          form.setFieldValue(fieldNames.pages, updatedPageDataSource);
          setPagesDataSource([...updatedPagesDataSource]);
        }

        return (
          <Select
            key={page.name}
            value={page.id}
            onChange={handleChangePageSelect}
            showSearch
            optionFilterProp="children"
            disabled={!userHasEditRolesPagePermission}
          >
            {allUserPages?.map(allUserPage => {
              const pageHasDependencies = !!allUserPage.dependencies?.length;
              return (
                <Option
                  key={allUserPage.id}
                  value={allUserPage.id}
                  disabled={pagesDataSource.find(
                    pDS => pDS.id === allUserPage.id,
                  )}
                >
                  {allUserPage.name}{' '}
                  {pageHasDependencies && (
                    <Tooltip
                      title={
                        <article>
                          <FormattedMessage id="userRolesPage.userRoleModal.recommendationTooltip" />
                          <ul>
                            {allUserPage.dependencies.map(dependencyPage => (
                              <li key={dependencyPage}>{dependencyPage}</li>
                            ))}
                          </ul>
                        </article>
                      }
                    >
                      <AlertFilled />
                    </Tooltip>
                  )}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: (
        <div className="role-modal__pages-table-column-title">
          <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.fields.permission" />
          <Popover
            content={
              <div>
                <FormattedMessage
                  id="userRolesPage.userRoleModal.fields.pagesHint.title"
                  tagName={'h4'}
                />
                <FormattedMessage
                  id="userRolesPage.userRoleModal.fields.pagesHint.view"
                  tagName={'p'}
                />
                <FormattedMessage
                  id="userRolesPage.userRoleModal.fields.pagesHint.edit"
                  tagName={'p'}
                />
                <FormattedMessage
                  id="userRolesPage.userRoleModal.fields.pagesHint.delete"
                  tagName={'p'}
                />
              </div>
            }
          >
            <InfoCircleFilled className="role-modal__pages-table-column-title-hint" />
          </Popover>
        </div>
      ),
      dataIndex: pagesTableFieldNames.permission,
      render(_, page: tempPageType, index) {
        return (
          <Radio.Group
            key={page.name}
            defaultValue={page.permission}
            buttonStyle="solid"
            onChange={(e: RadioChangeEvent) =>
              handleChangePermission({
                radioChangeEvent: e,
                pageId: page.id,
              })
            }
            disabled={
              !userHasEditRolesPagePermission || isCreateOrUpdateUserRoleLoading
            }
          >
            <Radio.Button
              value={'1'}
              className="page-permission__1"
              disabled={isCreateOrUpdateUserRoleLoading}
            >
              <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.buttons.permission.view" />
            </Radio.Button>
            <Radio.Button
              value={'2'}
              className="page-permission__2"
              disabled={isCreateOrUpdateUserRoleLoading}
            >
              <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.buttons.permission.edit" />
            </Radio.Button>
            <Radio.Button
              value={'3'}
              className="page-permission__3"
              disabled={isCreateOrUpdateUserRoleLoading}
            >
              <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.buttons.permission.delete" />
            </Radio.Button>
          </Radio.Group>
        );
      },
      width: 250,
    },
    {
      key: 'action',
      title: '',
      render(_, page: any, index) {
        return (
          <Space>
            <Tooltip
              title={
                <FormattedMessage id="userRolesPage.userRoleModal.pagesTable.buttons.remove" />
              }
            >
              <Button
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleRemovePage(index);
                }}
                disabled={!userHasEditRolesPagePermission}
              ></Button>
            </Tooltip>
          </Space>
        );
      },
      width: 70,
    },
  ];
  //#endregion

  function isRequiredPagesForEachActionAdded(values: userRoleModalForm) {
    for (let i = 0; i < values?.actions?.length; i++) {
      const currentActionId = values.actions[i];
      const currentActionDepsNames = allUserActions.find(
        action => action.id === currentActionId,
      )?.dependencies;

      const currentActionDepsIds = currentActionDepsNames.map(actionName => {
        return allUserPages.find(page => page.name === actionName)?.id;
      });

      for (const requiredPageID of currentActionDepsIds) {
        if (!values.pages.some(page => page.page === requiredPageID)) {
          return false;
        }
      }
    }
    return true;
  }

  function formatActionsValues(actionsIDs: userRoleModalForm['actions']) {
    return actionsIDs.map(id => {
      const currentAction = allUserActions.find(action => action.id === id);
      return {
        id: currentAction.id,
        dependencies: currentAction.dependencies,
      };
    });
  }

  function formatPagesValues(
    pages:
      | {
          page: number;
          permission: string;
        }[]
      | { page: number; permission: string; name: string }[],
  ) {
    for (const currentPage of pages) {
      if (!('name' in currentPage)) {
        const currentPageName = allUserPages.find(
          page => page.id === currentPage.page,
        )?.name;
        currentPage['name'] = currentPageName;
      }
    }
  }

  async function handleFinish(values: userRoleModalForm) {
    if (!values.pages?.length) {
      displayErrorMessage(
        intl.formatMessage({
          id: 'userRolesPage.userRoleModal.shouldSelectActionOrPage',
        }),
      );
      return;
    }

    if (!isRequiredPagesForEachActionAdded(values)) {
      displayErrorMessage(
        intl.formatMessage({
          id: 'userRolesPage.userRoleModal.reviseRequiredPagesToAdd',
        }),
      );
      return;
    }

    const actionsObjects = formatActionsValues(values.actions);
    formatPagesValues(values.pages);

    const userRole: userRoleCreateOrEditEndpoint = {
      name: values.name,
      actions: actionsObjects,
      pages: values.pages,
    };

    let res: AxiosResponse;
    if (isNew) {
      res = await mutateCreateOrUpdateUserRole({ userRole });
    } else {
      res = await mutateCreateOrUpdateUserRole({
        userRole,
        roleId,
      });
    }
    if (res?.status === 200) {
      refetchUserRoleData();
    }
    close();
  }

  return (
    <Modal
      open
      title={
        isNew ? (
          <FormattedMessage id="userRolesPage.userRoleModal.title.newRole" />
        ) : (
          <FormattedMessage id="userRolesPage.userRoleModal.title.editRole" />
        )
      }
      footer={null}
      onCancel={close}
      width={720}
      className="role-modal__wrapper"
    >
      {hasUserRoleDataError && (
        <FormattedMessage id="userRolesPage.userRoleModal.somethingWentWrong" />
      )}
      {isUserRoleDataLoading && !userRolePages ? (
        <div className="spinner-container">
          <Spin
            tip={
              <FormattedMessage id="userRolesPage.userRoleModal.gettingUserData" />
            }
          />
        </div>
      ) : (
        <Form
          initialValues={{
            [fieldNames.name]: userRoleData ? userRoleData.name : '',
            [fieldNames.actions]: userRoleData ? userRoleData.actions : [],
            [fieldNames.pages]: userRoleData ? userRoleData.pages : [],
          }}
          form={form}
          layout="vertical"
          name="role"
          onFinish={handleFinish}
          disabled={isCreateOrUpdateUserRoleLoading}
        >
          <Form.Item
            name={fieldNames.name}
            label={
              <FormattedMessage id="userRolesPage.userRoleModal.fields.name" />
            }
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              onChange={handleChangeRoleName}
              disabled={!isNew}
            />
          </Form.Item>
          <article className="role-modal__must-add-action-or-page">
            <InfoCircleFilled />
            <FormattedMessage id="userRolesPage.userRoleModal.shouldSelectActionOrPage" />
          </article>
          <Form.Item
            name={fieldNames.actions}
            label={
              <div className="role-modal__field-label">
                <FormattedMessage
                  id="userRolesPage.userRoleModal.fields.actions"
                  tagName={'p'}
                />
              </div>
            }
          >
            <Select
              mode="multiple"
              maxTagCount={'responsive'}
              value={roleActions}
              onChange={handleChangeRoleActions}
              popupClassName="PermissionField__select-dropdown"
              loading={isUserRolesOperationsLoading}
              disabled={
                !userHasEditRolesPagePermission || hasUserRolesOperationsError
              }
              showSearch
              optionFilterProp="children"
            >
              {allUserActions?.map(action => {
                return (
                  <Option key={action.id} value={action.id}>
                    {action.name}{' '}
                    <Tooltip
                      title={
                        <article>
                          <FormattedMessage id="userRolesPage.userRoleModal.requiredPagesTooltip" />
                          <ul>
                            {action.dependencies.map(dependencyAction => (
                              <li key={dependencyAction}>{dependencyAction}</li>
                            ))}
                          </ul>
                        </article>
                      }
                    >
                      <AlertFilled />
                    </Tooltip>
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name={fieldNames.pages}
            label={
              <FormattedMessage id="userRolesPage.userRoleModal.fields.pages" />
            }
            className="pages-form-item__container"
          >
            <Table
              rowKey="key"
              className="antd-table-customized-scroll"
              bordered
              pagination={false}
              dataSource={pagesDataSource}
              loading={isUserRoleDataLoading}
              columns={pagesTableColumns}
              showHeader={true}
            />
          </Form.Item>
          <Button
            onClick={handleAddNewPage}
            type="primary"
            style={{ marginBottom: 16 }}
            disabled={isAddPageDisabled}
          >
            <PlusCircleOutlined />{' '}
            <FormattedMessage id="userRolesPage.userRoleModal.addPage" />
          </Button>
          <Divider />

          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreateOrUpdateUserRoleLoading}
              disabled={!userHasEditRolesPagePermission}
            >
              {isNew ? (
                <FormattedMessage id="userRolesPage.userRoleModal.buttons.create" />
              ) : (
                <FormattedMessage id="userRolesPage.userRoleModal.buttons.update" />
              )}
            </Button>
            <Button onClick={close}>
              <FormattedMessage id="userRolesPage.userRoleModal.buttons.cancel" />
            </Button>
          </Space>
        </Form>
      )}
    </Modal>
  );
}

export default UserRoleModal;
