import axios from 'axios';
import { API_URL } from 'utils/http';

export function getExportedBlueprints({ page = 1, search }: any) {
  return axios.get(`${API_URL}/blueprints/exports`, {
    params: { page, search },
  });
}

export function downloadBlueprint(exportId, token) {
  return axios.get(`${API_URL}/blueprints/exports/download`);
}

export function deleteExportedBlueprint(blueprintId, filename) {
  return axios.delete(
    `${API_URL}/blueprints/exports/${blueprintId}/${filename}/delete`,
  );
}
