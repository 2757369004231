import React, { useCallback } from 'react';
import { Spin, Table } from 'antd';
import useRequest from 'hooks/useRequest';
import { getNodes } from 'services/node';

function ClusterServiceStatus({ instance }) {
  const serviceStatus = true;
  let { result, isPending } = useRequest(
    useCallback(
      () => getNodes({ instance, serviceStatus }),
      [instance, serviceStatus],
    ),
    { handleError: true, resolve: true },
  );
  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting ClusterServiceStatus" />
      </div>
    );
  }

  const clusterNodes = result?.data?.nodes;
  const clusterServices = result?.data?.services;

  const dataSource: any[] = [];
  const columns = [
    { title: 'Service', dataIndex: 'service', key: 'objects' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  ];

  if (clusterNodes) {
    Object.entries(clusterNodes).forEach(([k, v]: any) =>
      dataSource.push({ service: v.type, status: v.status }),
    );
  }
  if (clusterServices && clusterServices[0]) {
    dataSource.push({
      service: 'zookeeper',
      status:
        clusterServices[0].zookeeper_status === 'running'
          ? 'connected'
          : 'disconnected',
    });
    dataSource.push({
      service: 'spark',
      status:
        clusterServices[0].spark_status === 'running'
          ? 'connected'
          : 'disconnected',
    });
  }
  return (
    <div>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
}

export default ClusterServiceStatus;
