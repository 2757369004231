import React, { useState, useCallback, useMemo } from 'react';
import { Tooltip, Typography, Input, Select, Spin, Form, Button } from 'antd';
import ClusterInsights from './ClusterInsightsModal';
import { getInfo } from 'services/clustersstatus';
import { getSlaType } from 'services/configuration';
import useRequest from 'hooks/useRequest';
import '../clustersstatus.less';

let { Title } = Typography;
let { Search } = Input;
let { Option } = Select;

function IncortaClusters() {
  let [searchQuery, setSearchQuery] = useState<any>('');
  let [searchDummy, setSearchDummy] = useState<any>('');
  let [operatorFilter, setOperatorFilter] = useState<any>('All');
  let [slaFilter, setSlaFilter] = useState<any>('All');
  let [clusterInsights, setClusterInsights] = useState<any>(null);

  const searchParams = useMemo(
    () => ({
      clusterName: searchQuery,
      clusterCode: operatorFilter,
      SlaTypeFilter: slaFilter,
    }),
    [operatorFilter, searchQuery, slaFilter],
  );

  let { result: generalInfoResult, isPending: generalInfoLoading } = useRequest(
    useCallback(() => getInfo(searchParams), [searchParams]),
    { resolve: true },
  );
  let generalInfo = generalInfoResult?.data;

  const onFilter = value => {
    setSearchQuery(value);
  };
  let { result, isPending } = useRequest(getSlaType, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let slaTypes = result?.data?.slaTypes;
  const searchPlaceholder = 'Search by cluster name';

  //setting the square color
  function getClusterStatus(status, name, instanceServices) {
    if (instanceServices[0] === undefined) {
      return (
        <Tooltip placement="top" title={name}>
          <div className="creating-cluster"></div>
        </Tooltip>
      );
    }
    let analytics_status = instanceServices[0].analytics_status;
    let loader_status = instanceServices[0].loader_status;
    let cmc_status = instanceServices[0].cmc_status;
    let zookeeper_status = instanceServices[0].zookeeper_status;
    if (status === 'loading' || status === 'starting') {
      return (
        <Tooltip placement="top" title={name}>
          <Button
            className={status}
            type="primary"
            size="large"
            onClick={() => {
              setClusterInsights({
                isNew: false,
                name: name,
              });
            }}
          >
            <div></div>
          </Button>
        </Tooltip>
      );
    } else if (status === 'running') {
      const svc = {
        Analytics: analytics_status,
        Loader: loader_status,
        CMC: cmc_status,
        Zookeeper: zookeeper_status,
      };
      let tooltipText = name;
      for (let svcName in svc) {
        if (svc[svcName] === 'disconnected') {
          tooltipText = tooltipText.concat(` (${svcName} Down)`);
        }
      }
      if (cmc_status === 'disconnected') {
        return (
          <Tooltip placement="top" title={tooltipText}>
            <div className="service-down"></div>
          </Tooltip>
        );
      } else {
        if (tooltipText !== name) {
          return (
            <Tooltip placement="top" title={tooltipText}>
              <Button
                className="service-down"
                type="primary"
                size="large"
                onClick={() => {
                  setClusterInsights({
                    isNew: false,
                    name: name,
                  });
                }}
              >
                <div></div>
              </Button>
            </Tooltip>
          );
        }
      }
      if (tooltipText === name) {
        return (
          <Tooltip placement="top" title={name}>
            <Button
              className={status}
              type="primary"
              size="large"
              onClick={() => {
                setClusterInsights({
                  isNew: false,
                  name: name,
                });
              }}
            >
              <div></div>
            </Button>
          </Tooltip>
        );
      }
    }
  }

  return (
    <div>
      {generalInfo ? (
        <div>
          <Form layout="inline" name="filterStuff">
            <Form.Item name="op">
              <FieldSelect
                operators={generalInfo.clusterCodes}
                onChange={e => {
                  setOperatorFilter(e);
                }}
              />
            </Form.Item>
            <Form.Item name="sla">
              <SlaFieldSelect
                loading={isPending && !slaTypes}
                slaTypes={slaTypes}
                onChange={e => {
                  setSlaFilter(e);
                }}
              />
            </Form.Item>
            <Form.Item>
              <Search
                placeholder={searchPlaceholder}
                onSearch={searchDummy => onFilter(searchDummy)}
                onChange={e => {
                  setSearchDummy(e.target.value);
                }}
                value={searchDummy}
                style={{
                  width: 304,
                  paddingBottom: 20,
                }}
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                htmlType="reset"
                onClick={() => {
                  setSearchQuery('');
                  setSearchDummy('');
                  setOperatorFilter(null);
                  setSlaFilter(null);
                }}
              >
                Clear search
              </Button>
            </Form.Item>
          </Form>
          <div className="count-info-running">
            <div className="running-clusters-square"></div>
            <span className="running-cluster-text">Running</span>
          </div>

          <div className="count-info-starting">
            <div className="starting-clusters-square"></div>
            <span className="starting-cluster-text">Starting</span>
          </div>

          <div className="count-info-upgrading">
            <div className="upgrading-clusters-square"></div>
            <span className="upgrading-clusters-text">Upgrading</span>
          </div>

          <div className="count-info-service-down">
            <div className="service-down-clusters-square"></div>
            <span className="service-down-clusters-text">Service Down</span>
          </div>

          <div className="count-info-being-created">
            <div className="clusters-being-created-square"></div>
            <span className="clusters-being-created-text">
              Cluster is being created
            </span>
          </div>

          {/* Cards */}

          {generalInfoLoading ? (
            <Spin />
          ) : (
            <div>
              <div>
                {healthScore(generalInfo.totalHealthScore, operatorFilter)}
              </div>
              <div className="card">
                {/* {search(generalInfo).map(instance => ( */}
                {generalInfo.servicesStatus.map(instance => (
                  <div>
                    <div>
                      {getClusterStatus(
                        instance.status,
                        instance.name,
                        instance.instanceServices,
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spin />
      )}
      {clusterInsights && (
        <ClusterInsights
          {...clusterInsights}
          close={() => setClusterInsights(null)}
        />
      )}
    </div>
  );
}

function FieldSelect({ onChange, loading, operators }: any) {
  return (
    <Select
      allowClear
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
      style={{
        width: 304,
        height: 20,
      }}
      placeholder="Filter by operator"
    >
      {operators?.map(operator => (
        <Option key={operator.cluster_code} value={`${operator.cluster_code}`}>
          {`${operator.cluster_code}`}
        </Option>
      ))}
      <Option key="All" value="All">
        All Operators
      </Option>
    </Select>
  );
}

function SlaFieldSelect({ onChange, loading, slaTypes }) {
  return (
    <Select
      allowClear
      onChange={onChange}
      loading={loading}
      dropdownClassName="SlaField__select-dropdown"
      style={{
        width: 304,
      }}
      placeholder="Filter by SLA Type"
    >
      {slaTypes?.map(slaType => (
        <Option key={slaType.id} value={slaType.id}>
          {slaType.name}
        </Option>
      ))}
      <Option key="null" value="noSla">
        Not bound to SLA
      </Option>
      <Option key="All" value="All">
        All Clusters
      </Option>
    </Select>
  );
}

function healthScore(totalHealthScore, operatorFilter) {
  if (totalHealthScore || totalHealthScore === 0) {
    return operatorFilter ? (
      <Title level={4}>{`${operatorFilter}: ${totalHealthScore}%`}</Title>
    ) : (
      <Title level={4}>{`All: ${totalHealthScore}%`}</Title>
    );
  } else {
    return (
      <Title
        level={4}
      >{`All clusters in this/these operator/s are disconnected`}</Title>
    );
  }
}

export default IncortaClusters;
