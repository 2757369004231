import axios from 'axios';
import { API_URL } from 'utils/http';

export function getToggleFeatures({ page = 1, search }) {
  return axios.get(`${API_URL}/togglefeatures`, { params: { page, search } });
}

export function updateToggleFeature(uid, toggleFeature) {
  return axios.put(`${API_URL}/togglefeatures/${uid}/update`, toggleFeature);
}

export function getToggleFeature({ page = 1, search }) {
  return axios.get(`${API_URL}/togglefeatures`, { params: { page, search } });
}

export function getAzurePlans({
  userId,
  userRole,
}: {
  userId?: string;
  userRole?: string;
}) {
  return axios.post(`${API_URL}/configurations/azure_plans`, {
    userId: userId,
    userRole: userRole,
  });
}
