import { useQuery, useMutation } from 'react-query';
import { AxiosResponse, AxiosError } from 'axios';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { createNewPartner, getPartners } from 'services/partner';
import { getPaginatedData } from 'hooks/queries';
import { NewPartner, PartnersResponse } from 'types/partner';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { queryClient } from 'index';

export function usePartners({
  page = 1,
  search = '',
  limit,
}: {
  page?: number;
  search?: string;
  limit?: number;
}) {
  const { queryKey, queryFn, queryOptions } = getPaginatedData({
    key: QueryServerKeys.PARTNERS.GET_PARTNERS,
    page,
    search,
    limit,
    service: getPartners,
  });
  return useQuery<PartnersResponse>(queryKey, queryFn, queryOptions);
}

export function useCreateNewPartner() {
  const queryOptions = useMutation(
    async ({
      name,
      partnerEmail,
      clustersCount,
      platforms,
      supportedRegions,
      clustersDefaultSize,
      maxCPU,
      maxMemory,
      allowSqlX,
      allowMLflow,
    }: NewPartner) => {
      const result: AxiosResponse = await createNewPartner({
        name,
        partnerEmail,
        clustersCount,
        platforms,
        supportedRegions: supportedRegions.join(','),
        clustersDefaultSize,
        maxCPU,
        maxMemory,
        allowSqlX,
        allowMLflow,
      });

      return result;
    },
    {
      mutationKey: [QueryServerKeys.USER_ROLES.CREATE_USER_ROLE],
      onSuccess(
        result: AxiosResponse<{
          data: {
            message: string;
          };
        }>,
      ) {
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USERS);
        queryClient.invalidateQueries(
          QueryServerKeys.USER_ROLES.GET_USER_ROLES,
        );
        queryClient.invalidateQueries(QueryServerKeys.PARTNERS.GET_PARTNERS);
        displaySuccessMessage(result.data?.data?.message);
      },
      onError(error: AxiosError) {
        queryClient.invalidateQueries(
          QueryServerKeys.USER_ROLES.GET_USER_ROLE_BY_ID,
        );
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}
