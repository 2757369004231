import { useContext } from 'react';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { SessionContext } from 'auth/SessionProvider';

function Login({}: any) {
  const { isLoading: loading, loginWithRedirect } = useContext(SessionContext);

  if (loading) {
    return <FullPageLoader />;
  }

  loginWithRedirect({
    redirectUri: `${process.env.REACT_APP_URL}`,
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
  });

  return null;
}

export default Login;
