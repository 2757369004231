import React, { useState, useCallback, useContext } from 'react';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { format } from 'date-fns';

import {
  SaveOutlined,
  DeleteOutlined,
  PlusOutlined,
  EyeOutlined,
  LoadingOutlined,
  StopOutlined,
  EditOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import BackupDetails from './BackupDetails/BackupDetails';
import BackupDetailsModal from './BackupDetailsModal/BackupDetailsModal';
import BackupEditModal from './BackupEdit/BackupEditModal';

import {
  getBackups,
  deleteScheduledBackup,
  restoreBackup,
  stopScheduledBackup,
} from 'services/backup';
import useRequest from 'hooks/useRequest';
import { showError } from 'utils/errors';
import { getBackupFunctionStatusTag, getWeekNumberAndDay } from 'utils/backup';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

let { Title } = Typography;

function Backups() {
  const { user } = useContext(SessionContext);

  const userHasEditBackupsPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.BACKUPS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const userHasDeleteBackupsPagePermission =
    user.userRolesPagesPermissions?.[userRolesPages.BACKUPS] ===
    userRolesPagesPermissionsValues.CAN_DELETE;

  let [page, setPage] = useState(1);
  let [modalProps, setModalProps] = useState<any>(null);
  let [viewDetailsModalProps, setViewDetailsModalProps] = useState<any>(null);
  let [editBackupModalProps, seteditBackupModalProps] = useState<any>(null);
  let [restoredBackups, setRestoredBackups] = useState<any>([]);

  let [search, setSearch] = useState('');

  let searchColumns = [
    'Instance Name',
    'Backup Date',
    'Cut-off Date',
    'Retention',
    'Frequency',
    'Status',
  ];

  let {
    result,
    isPending,
    makeRequest: refetchBackups,
  } = useRequest(
    useCallback(() => getBackups({ page, search }), [page, search]),
    { resolve: true },
  );

  const updateRestoreBackupIds = (currentRestoredBackups, backupID) => {
    var backupIndex = currentRestoredBackups.indexOf(backupID);
    currentRestoredBackups.splice(backupIndex, 1);
    setRestoredBackups([...currentRestoredBackups]);
  };

  const isRestoreDisabled = backup => {
    return (
      !userHasEditBackupsPagePermission ||
      backup.backupStatus !== 'succeeded' ||
      moment(backup.date)
        .add(backup.retention, 'days')
        .diff(moment(), 'seconds') < 0
    );
  };

  let backups = result?.data?.backups;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;
  let columns = [
    {
      title: 'Instance Name',
      render(_, data) {
        return data.instance.name;
      },
    },
    {
      title: 'Backup Date',
      dataIndex: 'date',
      render(_, data) {
        return format(new Date(data.date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Cut-off Date',
      dataIndex: 'endDate',
      render(_, data) {
        return data.endDate === null
          ? 'No End Date Specified'
          : format(new Date(data.endDate), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Retention',
      dataIndex: 'retention',
      width: '8%',
      render(_, data) {
        return data.retention;
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      width: '8%',
      render(_, data) {
        return data.frequency == 0
          ? `Doesn't repeat`
          : data.frequency == 1
          ? `Daily`
          : data.frequency == 7
          ? `Weekly`
          : data.frequency == 31
          ? `Monthly on ${getWeekNumberAndDay(data.date)}`
          : `Every ${data.frequency} days`;
      },
    },
    {
      title: 'Backup Status',
      dataIndex: 'backupStatus',
      render(_, data) {
        return getBackupFunctionStatusTag(data.backupStatus);
      },
    },
    {
      title: 'Actions',
      render(_, backup) {
        return (
          <Space>
            <Tooltip
              title={
                !userHasDeleteBackupsPagePermission
                  ? 'You don❜t have sufficient permission'
                  : 'Delete Scheduled Backup'
              }
            >
              <Button
                size="small"
                disabled={
                  !userHasDeleteBackupsPagePermission ||
                  backup.backupStatus !== 'scheduled'
                }
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Are you sure you want to {'delete'}{' '}
                        <strong>{backup.name}</strong>?
                      </>
                    ),
                    async onOk() {
                      await deleteScheduledBackup(backup.id);
                      await refetchBackups();
                    },
                  });
                }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>

            <Tooltip
              title={
                !userHasEditBackupsPagePermission
                  ? 'You don❜t have sufficient permission'
                  : 'Restore Succeeded Backup'
              }
            >
              <Button
                size="small"
                disabled={isRestoreDisabled(backup)}
                onClick={() => {
                  Modal.confirm({
                    title: (
                      <>
                        Restoring backup will result in restarting your cluster{' '}
                        <strong>{backup.name}</strong>
                        Are you sure you want to continue?
                      </>
                    ),
                    async onOk() {
                      if (!restoredBackups.includes(backup.id)) {
                        let currentRestoredBackups = [...restoredBackups];
                        currentRestoredBackups.push(backup.id);
                        setRestoredBackups(currentRestoredBackups);

                        restoreBackup(backup.instance.id, backup.id)
                          .then(() => {
                            updateRestoreBackupIds(
                              currentRestoredBackups,
                              backup.id,
                            );
                          })
                          .catch(error => {
                            updateRestoreBackupIds(
                              currentRestoredBackups,
                              backup.id,
                            );
                            showError(error);
                          });
                      }
                    },
                  });
                }}
              >
                {restoredBackups.includes(backup.id) ? (
                  <LoadingOutlined />
                ) : (
                  <SaveOutlined />
                )}
              </Button>
            </Tooltip>

            <Tooltip title={'View Details'}>
              <Button
                size="small"
                onClick={() => {
                  setViewDetailsModalProps({ backup: backup });
                }}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>

            {backup.backupStatus === 'scheduled' && (
              <Tooltip
                title={
                  !userHasEditBackupsPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Edit Backup'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    seteditBackupModalProps({ backup: backup });
                  }}
                  disabled={!userHasEditBackupsPagePermission}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}

            {backup.backupStatus === 'scheduled' && (
              <Tooltip
                title={
                  !userHasEditBackupsPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Stop Backup'
                }
              >
                <Button
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to {'stop'}{' '}
                          <strong>{backup.name}</strong>?
                        </>
                      ),
                      async onOk() {
                        await stopScheduledBackup(
                          backup.id,
                          backup.instance.name,
                        );
                        await refetchBackups();
                      },
                    });
                  }}
                  disabled={!userHasEditBackupsPagePermission}
                >
                  <StopOutlined />
                </Button>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return backups ? (
    <div className="Backups">
      <div className="tableTitle">
        <Title>Backups</Title>

        <Tooltip
          title={
            !userHasEditBackupsPagePermission
              ? 'You don❜t have sufficient permission'
              : 'Create'
          }
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              setModalProps({ isNew: true });
            }}
            disabled={!userHasEditBackupsPagePermission}
          >
            Add Backup
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={backups}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        searchPlaceholder={'Search Backup with Instance Name.'}
      />

      {modalProps && (
        <BackupDetailsModal
          {...modalProps}
          close={() => setModalProps(null)}
          refetchBackups={refetchBackups}
        />
      )}
      {viewDetailsModalProps && (
        <BackupDetails
          {...viewDetailsModalProps}
          close={() => setViewDetailsModalProps(null)}
          refetchBackups={refetchBackups}
        />
      )}
      {editBackupModalProps && (
        <BackupEditModal
          {...editBackupModalProps}
          close={() => seteditBackupModalProps(null)}
          refetchBackups={refetchBackups}
        />
      )}
    </div>
  ) : (
    <div />
  );
}

export default Backups;
