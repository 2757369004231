import './PermissionField.less';

import React from 'react';
import { Select, Form } from 'antd';
import { useGetUserPermissions } from 'hooks/queries';
import { showErrorMessage } from 'utils/errors';

let { Option } = Select;

function PermissionField({ name, label }) {
  const { data: result, isLoading } = useGetUserPermissions({
    handleError: err => {
      showErrorMessage(err.message);
    },
  });

  let permissions = result?.data?.permissions;

  return (
    <Form.Item className="PermissionField" name={name} label={label}>
      <FieldSelect
        loading={isLoading && !permissions}
        permissions={permissions}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, permissions }: any) {
  return (
    <Select
      value={permissions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="PermissionField__select-dropdown"
    >
      {permissions?.map(permission => (
        <Option key={permission} value={permission}>
          {permission}
        </Option>
      ))}
    </Select>
  );
}

export default PermissionField;
