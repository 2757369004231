import React from 'react';
import './UserRolesField.less';
import { Select, Form } from 'antd';
import { useGetUserRoles } from 'hooks/queries';
import { userRoleType } from 'types/user';

let { Option } = Select;

function UserRolesField({ name, label, currentUserRoles, setUserRolesField }) {
  const previouslySelectedUserRoles = currentUserRoles
    ? currentUserRoles.split?.(',')
    : [];

  const { data: userRolesResult, isLoading: isPending } = useGetUserRoles({
    limit: 1000000,
  });
  const allUserRoles = userRolesResult?.userRoles;

  function handleChangeRoles(value: string[]) {
    setUserRolesField(value);
  }

  return (
    <Form.Item
      className="user-roles-field"
      name={name}
      label={label}
      initialValue={previouslySelectedUserRoles || []}
      rules={[{ required: true, message: 'User Roles is Required' }]}
    >
      <Select
        mode="multiple"
        maxTagCount={'responsive'}
        onChange={handleChangeRoles}
        loading={isPending && !allUserRoles}
        popupClassName="user-roles-field__select-dropdown"
      >
        {allUserRoles?.map((userRole: userRoleType) => (
          <Option key={userRole.id} value={userRole.name}>
            {userRole.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default UserRolesField;
