import './MaintenanceTimeoutsField.less';

import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getMaintenanceTimeout } from 'services/configuration';

let { Option } = Select;

function MaintenanceTimeoutsField({ name, label }) {
  let { result, isPending } = useRequest(getMaintenanceTimeout, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let maintenanceTimeouts = result?.data?.maintenanceTimeouts;

  return (
    <Form.Item className="MaintenanceTimeoutsField" name={name} label={label}>
      <FieldSelect
        loading={isPending && !maintenanceTimeouts}
        maintenanceTimeouts={maintenanceTimeouts}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, maintenanceTimeouts }: any) {
  return (
    <Select
      value={maintenanceTimeouts ? value : undefined}
      onChange={onChange}
      loading={loading}
      popupClassName="MaintenanceTimeoutsField__select-dropdown"
      allowClear
    >
      {maintenanceTimeouts?.map(maintenanceTimeout => (
        <Option key={maintenanceTimeout.name} value={maintenanceTimeout.value}>
          {maintenanceTimeout.name}
        </Option>
      ))}
    </Select>
  );
}

export default MaintenanceTimeoutsField;
