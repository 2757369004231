import React from 'react';
import { Form, Input } from 'antd';

function CommunicateProvisionPanel({ form, provisionOptions }) {
  return (
    <div
      className="NewProvisionCustomer__panel-wrapper"
      style={{ maxWidth: '500px' }}
    >
      <Form.Item
        name={['email', 'subject']}
        label="Subject"
        initialValue={'Welcome to Incorta!'}
        rules={[{ required: true, message: 'Subject is required' }]}
      >
        <Input disabled />
      </Form.Item>
      <Form.Item
        name={['email', 'to']}
        label="To"
        rules={[{ required: true, message: 'To is required' }]}
      >
        <Input disabled placeholder="Instance owner email" />
      </Form.Item>
      <Form.Item
        name={['email', 'cc']}
        label="CC"
        rules={[{ required: true, message: 'CC is required' }]}
      >
        <Input placeholder="Default" />
      </Form.Item>
      <Form.Item
        name={['email', 'bcc']}
        label="BCC"
        rules={[{ required: true, message: 'BCC is required' }]}
      >
        <Input
          disabled
          value={form.setFieldsValue({
            email: {
              bcc: 'cloud-provisioning@incorta.com',
            },
          })}
        />
      </Form.Item>
    </div>
  );
}

export default CommunicateProvisionPanel;
