import './JavaVersionsField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getJavaVersions } from 'services/images';

let { Option } = Select;

function JavaVersionsField({ name, label }) {
  let { result, isPending } = useRequest(getJavaVersions, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let javaVersions = result?.data?.javaVersions;

  return (
    <Form.Item
      className="JavaVersionsField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !javaVersions}
        javaVersions={javaVersions}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, javaVersions }: any) {
  return (
    <Select
      value={javaVersions ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="JavaVersionsField__select-dropdown"
    >
      {javaVersions?.map(javaVersion => (
        <Option key={javaVersion} value={javaVersion}>
          {javaVersion}
        </Option>
      ))}
    </Select>
  );
}

export default JavaVersionsField;
