import { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, Tooltip, Select, Input } from 'antd';
import { mapRoleToName } from './helpers';
import { updateUserRole, removeUserRole } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function EditInvitedUsersModal({
  instance,
  record,
  close,
  refetchUserInstances,
}) {
  let { email, role } = record ?? {};
  const [roleSet, setRole] = useState(role);
  let authorizedRoles = ['accountManager', 'accountAdmin', 'developer'];

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async invitation => {
        await updateUserRole(instance.name, invitation.role, invitation.email);
        await refetchUserInstances();
      },
      [instance.name, refetchUserInstances],
    ),
    {
      handleError: true,
    },
  );

  let { makeRequest: deleteUserRequest } = useRequest(
    useCallback(async () => {
      await removeUserRole(instance.name, record.email);
      await refetchUserInstances();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record.name, refetchUserInstances]),

    {
      handleError: true,
    },
  );

  async function handleFinish(invitation) {
    await makeRequest(invitation);
    close();
  }

  async function handleDeleteUser() {
    await deleteUserRequest();
    close();
  }

  return (
    <Modal
      visible
      title={'Cluster Access & Security'}
      footer={null}
      onCancel={close}
    >
      <Form
        layout="vertical"
        name="schedulerRecord"
        onFinish={handleFinish}
        initialValues={{
          email,
          role,
        }}
      >
        <Form.Item name="email" label="User Email">
          <Input disabled={true}></Input>
        </Form.Item>

        <Form.Item name="role">
          <Select
            placeholder="Select Role"
            value={role}
            onChange={role => {
              setRole(role);
            }}
            disabled={record.status == 'pending'}
          >
            {authorizedRoles.map(rule => {
              return (
                <Select.Option key={rule} value={rule}>
                  {mapRoleToName(rule)}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item>
          <Space>
            <Tooltip
              placement="left"
              title={
                record.status == 'pending' ? 'Invitation is still pending' : ''
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={record.status == 'pending'}
              >
                Update
              </Button>
            </Tooltip>
            <Button
              type="default"
              onClick={() => {
                Modal.confirm({
                  title: (
                    <>
                      Are you sure you want to delete{' '}
                      <strong>{record.email}</strong> invitation ?
                    </>
                  ),
                  async onOk() {
                    await handleDeleteUser();
                  },
                });
              }}
            >
              Delete
            </Button>{' '}
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditInvitedUsersModal;
