import './SparkMaster.less';

import { useState, useEffect } from 'react';
import {
  Input,
  Select,
  Form,
  Switch,
  Button,
  Divider,
  Card,
  Upload,
  message,
  UploadFile,
  Spin,
  Tooltip,
} from 'antd';
import {
  CopyOutlined,
  UploadOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { chidoriSizeItem, submitChidori } from 'services/sparkmaster';
import IncortaIcon from 'images/incorta-blue-logo.svg';
import DatabricksIcon from 'images/databricks.svg';
import GCPCloudDataprocIcon from 'images/gcp-cloud-dataproc.svg';
import AzureHDInsightIcon from 'images/azure-hd-insight.svg';
import {
  useGetChidori,
  useGetChidoriSizes,
  useExposeChidoriCredentials,
  useDisableChidoriPublicApi,
} from 'hooks/queries';
import { Instance } from 'types/cluster';
import { copyText } from 'helpers/clipboard';

const { Option } = Select;
const requiredKeys = [
  'type',
  'project_id',
  'private_key_id',
  'private_key',
  'client_email',
  'client_id',
  'auth_uri',
  'token_uri',
  'auth_provider_x509_cert_url',
  'client_x509_cert_url',
  'universe_domain',
];

function SparkMaster({
  instance,
  close,
}: {
  instance: Instance;
  close: () => void;
}) {
  let instanceid = instance.id;
  const { data: chidoriResult, isLoading: chidoriIsPending } = useGetChidori({
    instanceid,
  });
  const {
    data: chidoriSizes,
    isLoading: isChidoriSizesLoading,
    isError: hasChidoriSizesError,
  } = useGetChidoriSizes({});
  const { mutateAsync: mutateChidoriCredentials } = useExposeChidoriCredentials(
    {
      instanceName: instance.name,
    },
  );

  const { mutateAsync: mutateDisableChidoriPublicApi } =
    useDisableChidoriPublicApi({
      instanceName: instance.name,
    });

  const isChidoriCredentialsEnabled = !!(
    chidoriResult?.publicApiEnabled &&
    chidoriResult?.publicApiEndpoint &&
    chidoriResult?.publicApiToken
  );

  const [form] = Form.useForm();
  let [master, setMaster] = useState('incortaspark');
  let [isEnabled, setIsEnabled] = useState(false);
  let [serviceAccountJson, setServiceAccountJson] = useState(false);
  let [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const handleMasterChange = value => {
    setMaster(value ?? '');
  };

  function handleSubmit(values) {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files[]', file as any);
    });
    values.serviceAccountJson = serviceAccountJson;
    submitChidori(values, instance);
    message.info(`Chidori is Processing for ${instance.name}`);
    close();
  }

  async function onValuesChange(changedValues: any, allValues: any) {
    if (Object.keys(allValues).length === 1 && allValues.install) {
      form.setFieldValue('master', 'incortaspark');
    }
    setTimeout(() => {
      const fieldsValues = form.getFieldsValue();
      const shouldDisable = Object.values(fieldsValues).some(
        value => !value && value !== false,
      );

      setIsSubmitButtonDisabled(shouldDisable);
    }, 500);
  }

  const handleToggle = () => {
    setIsEnabled(!isEnabled);
  };

  useEffect(() => {
    if (chidoriResult?.master) {
      setMaster(chidoriResult.master);
    }
    if (chidoriResult?.install) {
      form.setFieldsValue({ install: true });
      setIsEnabled(true);
    }
    // eslint-disable-next-line
  }, [chidoriResult?.master, chidoriResult?.install]);

  return (
    <div>
      {chidoriResult && !chidoriIsPending ? (
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{ ...chidoriResult }}
          onValuesChange={onValuesChange}
          className="chidori__wrapper"
        >
          <Form.Item name="install">
            <Switch
              defaultChecked={isEnabled}
              onChange={handleToggle}
              style={{ marginRight: '10px' }}
              checkedChildren="On"
              unCheckedChildren="Off"
              checked={isEnabled}
            />
          </Form.Item>
          {isEnabled && (
            <>
              <Form.Item name="master" label="Vendor">
                <Select
                  onChange={handleMasterChange}
                  defaultValue={'incortaspark'}
                >
                  <Option value="incortaspark">
                    <div className="option__wrapper">
                      <img src={IncortaIcon} />
                      Incorta Spark
                    </div>
                  </Option>
                  <Option value="databricks">
                    <div className="option__wrapper">
                      <img src={DatabricksIcon} />
                      Databricks
                    </div>
                  </Option>
                  <Option value="dataproc">
                    <div className="option__wrapper">
                      <img src={GCPCloudDataprocIcon} />
                      Google Cloud Dataproc
                    </div>
                  </Option>
                  <Option value="hdinsight">
                    <div className="option__wrapper">
                      <img src={AzureHDInsightIcon} />
                      Azure HDInsight
                    </div>
                  </Option>
                </Select>
              </Form.Item>
              <Card
                title="Configurations"
                className="vendor-configurations__wrapper"
              >
                {master === 'incortaspark' && (
                  <div>
                    <Form.Item
                      name="size"
                      label="Size"
                      className="form-item__wrapper"
                      rules={[{ required: true }]}
                    >
                      <Select
                        loading={isChidoriSizesLoading}
                        disabled={hasChidoriSizesError}
                      >
                        {chidoriSizes?.map((chidoriSize: chidoriSizeItem) => (
                          <Option
                            key={chidoriSize.size}
                            value={chidoriSize.size}
                          >
                            {chidoriSize.size}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                )}

                {master === 'databricks' && (
                  <div>
                    <Form.Item
                      name="apiUrl"
                      label="API URL"
                      className="form-item__wrapper"
                      required
                    >
                      <Input
                        type="url"
                        placeholder="https://<databricks-instance>.cloud.databricks.com"
                      />
                    </Form.Item>
                    <Form.Item
                      name="authToken"
                      label="Auth Token"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="xxxxxxxxxxxxxxxxxxxxxxx" />
                    </Form.Item>
                    <Form.Item
                      name="clusterID"
                      label="Cluster ID"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="0123-456789-abcd1234" />
                    </Form.Item>
                  </div>
                )}
                {master === 'dataproc' && (
                  <div>
                    <Form.Item
                      name="region"
                      label="Region"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="us-central1" />
                    </Form.Item>
                    <Form.Item
                      name="project"
                      label="Project"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-gcp-project" />
                    </Form.Item>
                    <Form.Item
                      name="cluster"
                      label="Cluster"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-dataproc-cluster" />
                    </Form.Item>
                    <Form.Item
                      name="serviceAccountEmail"
                      label="Service Account Email"
                      className="form-item__wrapper"
                      required
                    >
                      <Input type="email" placeholder="example@domain.com" />
                    </Form.Item>
                    <Form.Item name="serviceAccountFile" required>
                      <Upload
                        onRemove={(file: any) => {
                          const index = fileList.indexOf(file);
                          const newFileList = fileList.slice();
                          newFileList.splice(index, 1);
                          setFileList(newFileList);
                        }}
                        beforeUpload={(file: any) => {
                          const reader = new FileReader();
                          reader.readAsText(file);
                          return new Promise((resolve, reject) => {
                            reader.onload = () => {
                              const fileString = reader.result as string;
                              try {
                                const json = JSON.parse(fileString);
                                if (
                                  requiredKeys.every(key =>
                                    json.hasOwnProperty(key),
                                  )
                                ) {
                                  resolve(file);
                                  setFileList([file]);
                                  setServiceAccountJson(json);
                                  message.success(
                                    'File uploaded successfully.',
                                  );
                                } else {
                                  message.error(
                                    'The JSON file does not have all the required keys.',
                                  );
                                  reject(
                                    new Error(
                                      'The JSON file does not have all the required keys.',
                                    ),
                                  );
                                }
                              } catch (error) {
                                message.error('Invalid JSON file.');
                                reject(new Error('Invalid JSON file.'));
                              }
                              return false;
                            };
                          });
                        }}
                        fileList={fileList as any}
                        accept=".json"
                        multiple={false}
                        maxCount={1}
                      >
                        <Button>
                          <UploadOutlined /> Upload Service Account File
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>
                )}
                {master === 'hdinsight' && (
                  <div>
                    <Form.Item
                      name="clusterName"
                      label="Cluster Name"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-hdinsight-cluster" />
                    </Form.Item>
                    <Form.Item
                      name="clusterUsername"
                      label="Cluster Username"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-cluster-username" />
                    </Form.Item>
                    <Form.Item
                      name="clusterPassword"
                      label="Cluster Password"
                      className="form-item__wrapper"
                      required
                    >
                      <Input.Password placeholder="my-cluster-password" />
                    </Form.Item>
                    <Form.Item
                      name="subscriptionID"
                      label="Subscription ID"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="01234567-89ab-cdef-0123-456789abcdef" />
                    </Form.Item>
                    <Form.Item
                      name="resourceGroup"
                      label="Resource Group"
                      className="form-item__wrapper"
                      required
                    >
                      <Input placeholder="my-resource-group" />
                    </Form.Item>
                  </div>
                )}
              </Card>

              {chidoriResult?.install && (
                <Card
                  className="credentials-configurations__wrapper"
                  title="Public API Credentials"
                >
                  <Button
                    type="primary"
                    icon={
                      isChidoriCredentialsEnabled ? <ReloadOutlined /> : null
                    }
                    onClick={async () => {
                      await mutateChidoriCredentials();
                    }}
                  >
                    {isChidoriCredentialsEnabled
                      ? 'Regenerate API Key'
                      : 'Generate API Key'}
                  </Button>
                  {isChidoriCredentialsEnabled && (
                    <Button
                      danger
                      type="primary"
                      onClick={async () => {
                        await mutateDisableChidoriPublicApi();
                      }}
                    >
                      Disable Chidori API
                    </Button>
                  )}
                  <article className="credentials-configurations__details">
                    {isChidoriCredentialsEnabled && (
                      <label className="credentials-configurations__row-1">
                        Endpoint
                        <label>{`http://${chidoriResult.publicApiEndpoint}`}</label>
                        <Tooltip
                          title={
                            chidoriResult.publicApiEndpoint
                              ? 'Copy Endpoint'
                              : ''
                          }
                        >
                          <CopyOutlined
                            className={`copy-icon ${
                              !chidoriResult.publicApiEndpoint ? 'disabled' : ''
                            }`}
                            onClick={() => {
                              if (chidoriResult.publicApiEndpoint) {
                                copyText(
                                  `http://${chidoriResult.publicApiEndpoint}/`,
                                );
                              }
                            }}
                            disabled={!chidoriResult.publicApiEndpoint}
                          />
                        </Tooltip>
                      </label>
                    )}
                    <section>
                      <label className="credentials-configurations__row-2">
                        API Key
                        <Input disabled value={chidoriResult.publicApiToken} />
                        <Tooltip
                          title={chidoriResult.publicApiToken ? 'Copy Key' : ''}
                        >
                          <CopyOutlined
                            className={`copy-icon ${
                              !chidoriResult.publicApiToken ? 'disabled' : ''
                            }`}
                            onClick={() => {
                              if (chidoriResult.publicApiToken) {
                                copyText(chidoriResult.publicApiToken);
                              }
                            }}
                            disabled={!chidoriResult.publicApiToken}
                          />
                        </Tooltip>
                      </label>
                    </section>
                  </article>
                </Card>
              )}
              <Divider />
            </>
          )}
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              disabled={isSubmitButtonDisabled}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}{' '}
    </div>
  );
}

export default SparkMaster;
