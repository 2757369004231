import { useContext } from 'react';
import './PlatformAction.less';
import { Typography, Tag, Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import {
  PlatformActions,
  userRolesPages,
  userRolesPagesPermissionsValues,
} from 'types/user';
import { useUpdatePlatformAction } from 'hooks/queries';
import { useGetPlatformActions } from 'hooks/platformActionsQueries';
import { PlatformActionItem, PlatformItem } from 'types/platformAction';

const { Title } = Typography;

function PlatformAction() {
  const { user } = useContext(SessionContext);

  const { data: platformActionsData, isLoading: isPlatformActionsLoading } =
    useGetPlatformActions();
  const platforms = platformActionsData?.platforms;
  const actions = platformActionsData?.actions;

  const { mutateAsync: mutateUpdatePlatformAction } = useUpdatePlatformAction();

  const handleTagClick = async function (
    action: PlatformActionItem,
    platform: PlatformItem,
    status: boolean,
  ) {
    const payload = {
      action: action.name,
      platform: platform.name,
      status,
      actionDisplayName: action.displayName,
    };

    Modal.confirm({
      title: (
        <>
          Are you sure you want to {status ? 'Enable' : 'Disable'}{' '}
          <strong>{action.displayName}</strong> for{' '}
          <strong>{platform.displayName}</strong> clusters?
        </>
      ),
      async onOk() {
        await mutateUpdatePlatformAction(payload);
      },
    });
  };

  const platformColumns = platforms
    ? platforms.map(platform => {
        return {
          title: platform.displayName,
          key: platform.name,
          render(_, action: PlatformActionItem) {
            const isPlatformEnabled = action.platforms
              .split(',')
              .find((value: any) => value === platform.name);

            return (
              <div
                className="platform-action-tag__wrapper"
                onClick={() =>
                  +user.userRolesPagesPermissions?.[
                    userRolesPages.PLATFORM_ACTIONS
                  ] >= +userRolesPagesPermissionsValues.CAN_EDIT &&
                  handleTagClick(
                    action,
                    platform,
                    isPlatformEnabled ? false : true,
                  )
                }
              >
                <Tag color={isPlatformEnabled ? 'green' : 'red'}>Enabled</Tag>
              </div>
            );
          },
        };
      })
    : [];
  const columns: ColumnsType<PlatformActions> = [
    {
      title: 'Action',
      dataIndex: 'displayName',
      key: 'displayName',
      width: '10%',
    },
    ...platformColumns,
  ];

  return (
    <div className="PlatformAction">
      <div className="tableTitle">
        <Title>Platform Actions</Title>
      </div>

      <SearchableTable
        pagination={false}
        dataSource={actions}
        loading={isPlatformActionsLoading}
        columns={columns}
      />
    </div>
  );
}

export default PlatformAction;
