import { useState, useCallback, useRef } from 'react';
import {
  Modal,
  Form,
  Space,
  Button,
  Radio,
  DatePicker,
  Input,
  Select,
} from 'antd';
import moment from 'moment';
import FeatureBitsFormField from './FeatureFlagsFormField/FeatureFlagsFormField';
import VersionsFormFields from './VersionsFormFields/VersionsFormFields';
import CustomerFormField from './CustomersFormField/CustomersFormField';
import useRequest from 'hooks/useRequest';
import { exportLogsForAnalayzer } from 'services/auditHistory';
import './LogAnalyzerModal.less';

const { RangePicker } = DatePicker;
const EXCLUDED_DIRECTORIES = ['incorta', 'incorta_system', 'incorta_sqli'];

function LogsAnalyzerModal({ close }) {
  const [form] = Form.useForm();
  const switchRefs = useRef({});
  const [featureBits, setFeatureBits] = useState<
    { value: string; enabled: boolean }[]
  >([]);

  let { makeRequest: exportLogsForAnalayzerPost, isPending } = useRequest(
    useCallback(async payload => await exportLogsForAnalayzer(payload), []),
    {
      handleError: true,
    },
  );

  const handleSubmit = async payload => {
    payload.features = featureBits.reduce(
      (acc, curr) => ({ ...acc, [curr.value]: curr.enabled }),
      {},
    );

    if (payload.period?.length) {
      payload.startDate = moment(payload.period[0]).format('yyyy-MM-DD');
      payload.endDate = moment(payload.period[1]).format('yyyy-MM-DD');
    }

    delete payload.period;
    const { data } = await exportLogsForAnalayzerPost(payload);

    Modal.info({
      title: 'Logs Exported Successfully!',
      content: <>Result path: {data?.dest?.resultFilePath}</>,
    });

    close();
  };

  function validateRegex(value: string) {
    try {
      new RegExp(value);
      return;
    } catch (e) {
      throw new Error('Regex is not valid');
    }
  }

  return (
    <Modal
      className="log-analyzer-modal"
      maskClosable={false}
      open
      title={'Export Logs'}
      footer={null}
      onCancel={close}
    >
      <Form
        onFinish={handleSubmit}
        initialValues={{
          featureBit: [],
          tenant: '',
          blueprintId: '',
          overwrite: false,
          service: 'analytics',
          minVersion: null,
          maxVersion: null,
          excludeDirs: [],
          matchingRegex: '',
        }}
        layout="vertical"
        name="LogsAnalayzerForm"
        form={form}
      >
        <FeatureBitsFormField
          switchRefs={switchRefs}
          name="features"
          label="Features"
          onChange={featureBits => {
            setFeatureBits(featureBits);
          }}
        />
        <CustomerFormField name="customers" label="Customers" />
        <VersionsFormFields form={form} />

        <Form.Item
          label="Date Range"
          className="log-analyzer-modal__dates"
          name={'period'}
        >
          <RangePicker />
        </Form.Item>

        <div>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Matching Regex is Required',
              },
              {
                async validator(_, value) {
                  return validateRegex(value);
                },
              },
            ]}
            label={'Matching Regex'}
            name={'matchingRegex'}
          >
            <Input type="text" placeholder="enter matching regex" />
          </Form.Item>
        </div>
        <div className="log-analyzer-modal__services">
          <Form.Item label={'Exclude Directories'} name={'excludeDirs'}>
            <Select
              showArrow
              placeholder="select excluded directories"
              mode="multiple"
              options={EXCLUDED_DIRECTORIES.map(directoryName => ({
                label: directoryName,
                value: directoryName,
              }))}
            ></Select>
          </Form.Item>
          <div>
            <Space size={1}>
              <label>Service</label>
            </Space>
            <br />
            <Form.Item name={'service'}>
              <Radio.Group>
                <Radio.Button value="analytics">Analytics</Radio.Button>
                <Radio.Button value="loader">Loader</Radio.Button>
                <Radio.Button value="both">Both</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </div>
        </div>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Export
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default LogsAnalyzerModal;
