import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Button,
  Space,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Radio,
  Select,
  Input,
} from 'antd';
import moment from 'moment';
import { getWeekNumberAndDay } from '../../../utils/backup';
import { editScheduledBackup } from 'services/backup';
import useRequest from 'hooks/useRequest';

function BackupEditModal({ backup, close, refetchBackups }) {
  let backupRecord = {
    date: moment(backup.date),
    endDate: backup.endDate && moment(backup.endDate),
    retention: backup.retention,
    frequency: `${backup.frequency}`,
    fullBackup: backup.fullBackup,
    instanceName: backup.instance.name,
  };

  const [form] = Form.useForm();

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async backupRecordData => {
        await editScheduledBackup(backup.id, backupRecordData);
        await refetchBackups();
      },
      // eslint-disable-next-line
      [refetchBackups],
    ),
    {
      handleError: true,
    },
  );

  const [selectedDay, setSelectedDay] = useState('');
  const [selectedWeekNumberAndDay, setSelectedWeekNumberAndDay] = useState('');
  const [frequency, setFrequency] = useState(backup.frequency.toString());
  const [isFullBackup, setIsFullBackup] = useState(backup.fullBackup);

  useEffect(() => {
    const date = new Date();
    const weekDayName = moment(date).format('dddd');
    setSelectedDay(weekDayName);
    const backupDate = moment(form.getFieldValue('date'));
    setSelectedWeekNumberAndDay(getWeekNumberAndDay(backupDate));
    // eslint-disable-next-line
  }, []);

  const toggleBackupDetails = () => {
    setIsFullBackup(!isFullBackup);
  };

  async function handleFinish(backupRecordData) {
    await makeRequest(backupRecordData);
    close();
  }

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  const setDate = date => {
    let weekDayName = moment(date).format('dddd');
    setSelectedDay(weekDayName);
    setSelectedWeekNumberAndDay(getWeekNumberAndDay(moment(date)));
  };

  return (
    <Modal
      open
      title={'Cluster Backup Configurations'}
      footer={null}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={backupRecord}
        layout="vertical"
        name="backupRecord"
        onFinish={handleFinish}
      >
        <Form.Item name="instanceName" label="Cluster Name">
          <Input name="instanceName" readOnly disabled />
        </Form.Item>

        <Form.Item
          name="fullBackup"
          label={
            isFullBackup
              ? 'Backup metadata, tenant directory and installation directory'
              : 'Backup metadata Only'
          }
        >
          <Radio.Group name="fullBackup" onChange={toggleBackupDetails}>
            <Radio value={false}>Metadata Only</Radio>
            <Radio value={true}>Metadata And Data Directory</Radio>
          </Radio.Group>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="informBackupStatus"
              label="Notify Cluster Owners After Backup Completion"
            >
              <Radio.Group defaultValue="none">
                <Radio value="none">Never</Radio>
                <Radio value="success">Success</Radio>
                <Radio value="failure">Failure</Radio>
                <Radio value="both">Success or Failure</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="retention" label="Retention in Days">
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={30}
                defaultValue={7}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="frequency"
              rules={[{ required: true }]}
              label="Backup Frequency"
            >
              <Select
                onChange={value => {
                  setFrequency(value);
                }}
              >
                <Select.Option value="0"> Does not repeat </Select.Option>
                <Select.Option value="1"> Daily </Select.Option>
                <Select.Option value="7">Weekly on {selectedDay}</Select.Option>
                <Select.Option value="31">
                  Monthly on {selectedWeekNumberAndDay}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="date"
              label="Start Date"
              rules={[{ required: true }]}
            >
              <DatePicker
                suffixIcon={<span>GMT</span>}
                style={{ width: '100%' }}
                format="YYYY-MM-DD HH:mm:ss"
                disabledDate={disabledDate}
                showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                onChange={(value, date) => {
                  setDate(date);
                }}
              />
            </Form.Item>
          </Col>
          {frequency !== '0' && (
            <Col span={12}>
              <Form.Item
                name="endDate"
                label="End Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  suffixIcon={<span>GMT</span>}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  disabledDate={disabledDate}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  onChange={(value, date) => {
                    setDate(date);
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Update Scheduled Backup
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default BackupEditModal;
