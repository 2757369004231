import { useCallback, useState } from 'react';
import { Modal, Form, Button, Space, InputNumber, Select } from 'antd';
let { Option } = Select;
import { increaseDiskSize } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function DiskUpsizeModal({ instance, refetchClusters, close }) {
  const [selectedService, setSelectedService] = useState('analytics');
  const services = ['analytics', 'loader'];
  let diskUpsize = {
    instanceName: instance?.name,
    dsize: instance?.dsize,
    dsizeLoader: instance?.dsizeLoader,
    service: selectedService
  };

  const handleServiceChange = (e) => {
    setSelectedService(e);
  };

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async diskUpsizeRecord => {
        await increaseDiskSize(diskUpsizeRecord, instance.id);
        await refetchClusters();
      },
      [refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(diskUpsizeRecord) {
    await makeRequest(diskUpsizeRecord);
    close();
  }

  const validateAnalyticsDiskSize = (_, value) => {
    if (value <= diskUpsize.dsize) {
      return Promise.reject('Disk size must be bigger than the initial value');
    }
    else if (value < 0 || value > 1000) return Promise.reject('Disk size must be number');
    return Promise.resolve();
  };
  const validateLoaderDiskSize = (_, value) => {
    if (value <= diskUpsize.dsizeLoader) {
      return Promise.reject('Disk size must be bigger than the initial value');
    }
    return Promise.resolve();
  };

  return (
    <Modal visible title={'Disk Upsize'} footer={null} onCancel={close}>
      <Form
        initialValues={diskUpsize}
        layout="vertical"
        name="diskUpsize"
        onFinish={handleFinish}
      >
        <Form.Item label="Disk Type" name={'service'}>
          <Select
            value={selectedService}
            onChange={handleServiceChange}
            dropdownClassName="CostField__select-dropdown"
            allowClear
          >
            {services?.map(service => (
              <Option key={service} value={service}>
                {service}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {selectedService === 'analytics' ? (
          <Form.Item name="dsize" label="Analytics Disk Size"
            rules={[
              { validator: validateAnalyticsDiskSize }, { required: true }
            ]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>) :
          (<Form.Item name="dsizeLoader" label="Loader Disk Size" rules={[{ validator: validateLoaderDiskSize }, { required: true }]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>)}
        <Form.Item name="replica" label="Specify replica number or leave it empty to scale all replicas">
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Scale
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default DiskUpsizeModal;
