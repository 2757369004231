import axios from 'axios';
import { NotificationInstanceUsersData } from 'types/notification';
import { API_URL } from 'utils/http';

export function getGlobalNotifications({ page, search }) {
  return axios.get(`${API_URL}/notification/getGlobalNotifications`, {
    params: { page, search },
  });
}

export function getNotificationsInstanceUsers({ page, search, limit = 10 }) {
  return axios.get<NotificationInstanceUsersData>(
    `${API_URL}/notification/instanceusers`,
    {
      params: { page, search, limit },
    },
  );
}

export function addGlobalNotification(event, message, usersIDs, method) {
  return axios.post(`${API_URL}/notification/addGlobalNotification`, {
    params: { event, message, usersIDs, method },
  });
}
