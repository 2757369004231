import React from 'react';
import './ClusterHeaderActions.less';
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';

function ClusterHeaderActions({
  activeKey,
  panelKey,
  isDeletable = false,
  remove,
}) {
  return (
    <button
      type="button"
      className="cluster-header-actions reset-Button simple-padding"
    >
      {activeKey.includes(panelKey) ? <MinusOutlined /> : <PlusOutlined />}
      {isDeletable && <CloseOutlined title="Remove Cluster" onClick={remove} />}
    </button>
  );
}

export default ClusterHeaderActions;
