import { useCallback } from 'react';
import { Modal, Form, Button, Space, Row, Col, Radio } from 'antd';
import PriorityField from '../PriorityField/PriorityField';
import { updateOperator } from 'services/contextManager';
import useRequest from 'hooks/useRequest';

function ContextManagerModal({ operator, close, refetchOperators }) {
  const {
    platform,
    region,
    cluster_code,
    filteringCriteria,
    priority,
    incortaOne,
  } = operator ?? {};

  console.log(operator);
  const { makeRequest } = useRequest(
    useCallback(
      async operator => {
        await updateOperator(
          platform,
          region,
          cluster_code,
          operator.priority,
          filteringCriteria,
          operator.incortaOne,
        );
        await refetchOperators();
      },
      // eslint-disable-next-line
      [refetchOperators],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(operator) {
    await makeRequest(operator);
    close();
  }

  return (
    <Modal open title={'Edit Priority'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          region,
          cluster_code,
          priority,
          incortaOne,
        }}
        layout="vertical"
        name="Operator"
        onFinish={handleFinish}
      >
        <Row align="stretch" gutter={24} justify="space-around" key="guides">
          <Col span={12} key="priority">
            <PriorityField label="Priority" name="priority" />
          </Col>

          <Col span={12} key="incortaOne">
            <Form.Item name="incortaOne" label="Operator Availability">
              <Radio.Group>
                <Radio value="allUsers">Publicly Available</Radio>
                <Radio value="incortaOneExclusive">
                  Exclusive For Incorta One
                </Radio>
                <Radio value="incortaOneExclude">Exclude Incorta One</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Space>
          <Button type="primary" htmlType="submit">
            {'Update'}
          </Button>

          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default ContextManagerModal;
