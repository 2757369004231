import './RolesTag.less';
import { Tag, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { userRoleType, getUserResponseUserItem } from 'types/user';
import { ReactComponent as RoleIcon } from 'images/role.svg';
import { ReactComponent as CustomRoleIcon } from 'images/role-custom.svg';
import { ReactComponent as MultiRolesIcon } from 'images/role-multi.svg';
import { useGetUserRoles } from 'hooks/queries';
type RolesTagProps = {
  user: getUserResponseUserItem;
  roles: string;
};
function RolesTag({ user, roles }: RolesTagProps) {
  const { data: userRolesResult } = useGetUserRoles({});
  const userRoles = userRolesResult?.userRoles;

  const currentUserRoles = roles?.split?.(',');
  const predefinedRoles = [];
  const customRoles = [];

  currentUserRoles?.forEach((role: string) => {
    const canBeDeleted = userRoles?.find(
      (r: userRoleType) => r.name === role,
    )?.canBeDeleted;
    if (canBeDeleted) {
      customRoles.push(role);
    } else {
      predefinedRoles.push(role);
    }
  });

  const cliTag = userPermission => {
    if (userPermission === 'super admin') userPermission = 'admin';
    if (!['guest', 'support', 'admin'].includes(userPermission))
      userPermission = 'support';
    return (
      'Cli ' +
      userPermission.replace(/_/g, ' ').charAt(0).toUpperCase() +
      userPermission.slice(1)
    );
  };

  if (currentUserRoles?.length === 1) {
    return (
      <>
        {user.fullName}
        &emsp;
        <Tag color="geekblue" className="roles-tag">
          <RoleIcon className="roles-tag-icon" />
          {roles.replace(/_/g, ' ').charAt(0).toUpperCase() + roles.slice(1)}
        </Tag>
        {user.role ? <Tag color="green">{user.role.displayName}</Tag> : null}
        {user.invitedAccount ? <Tag color="yellow">Invited</Tag> : null}
        {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
        {user.isPartner ? <Tag color="volcano">Partner</Tag> : null}
        {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
        {user.blocked ? <Tag color="red">Blocked</Tag> : null}
        {user.isProvision ? <Tag color="volcano">Provision</Tag> : null}
        {user.permission ? (
          <Tag color="geekblue" className="roles-tag">
            <RoleIcon className="roles-tag-icon" />
            {cliTag(user.permission)}
          </Tag>
        ) : null}
      </>
    );
  } else if (!predefinedRoles.length && customRoles.length) {
    return (
      <>
        {user.fullName}
        &emsp;
        <Tooltip
          title={
            <section className="roles-tag-tooltip">
              {customRoles?.map((role: string) => (
                <div className="roles-tag-tooltip-content-wrapper">
                  <CustomRoleIcon className="roles-tag-icon" />
                  <label>{role}</label>
                </div>
              ))}
            </section>
          }
        >
          <Tag color="red" className="roles-tag">
            <CustomRoleIcon className="roles-tag-icon" />
            <FormattedMessage id="rolesTag.customRoles" />
          </Tag>
        </Tooltip>
        {user.role ? <Tag color="green">{user.role.displayName}</Tag> : null}
        {user.invitedAccount ? <Tag color="yellow">Invited</Tag> : null}
        {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
        {user.isPartner ? <Tag color="volcano">Partner</Tag> : null}
        {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
        {user.blocked ? <Tag color="red">Blocked</Tag> : null}
        {user.isProvision ? <Tag color="volcano">Provision</Tag> : null}
      </>
    );
  } else {
    return (
      <>
        {user.fullName}
        &emsp;
        {user.role ? <Tag color="green">{user.role.displayName}</Tag> : null}
        {user.invitedAccount ? <Tag color="yellow">Invited</Tag> : null}
        {user.confirmed ? <Tag color="purple">Confirmed</Tag> : null}
        {user.isPartner ? <Tag color="volcano">Partner</Tag> : null}
        {user.trialExpired ? <Tag color="red">Expired</Tag> : null}
        {user.blocked ? <Tag color="red">Blocked</Tag> : null}
        {user.isProvision ? <Tag color="volcano">Provision</Tag> : null}
        <Tooltip
          title={
            <section className="roles-tag-tooltip">
              {currentUserRoles?.map((role: string) => (
                <div className="roles-tag-tooltip-content-wrapper">
                  {userRoles?.find(r => r.name === role)?.canBeDeleted ===
                  false ? (
                    <RoleIcon className="roles-tag-icon" />
                  ) : (
                    <CustomRoleIcon className="roles-tag-icon" />
                  )}

                  <label>
                    {role.replace(/_/g, ' ').charAt(0).toUpperCase() +
                      role.slice(1)}
                  </label>
                </div>
              ))}
            </section>
          }
        >
          <Tag color="gold" className="roles-tag">
            <MultiRolesIcon className="roles-tag-icon" />
            <FormattedMessage id="rolesTag.multiRoles" />
          </Tag>
        </Tooltip>
      </>
    );
  }
}

export default RolesTag;
