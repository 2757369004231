import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import PermissionField from '../PermissionField/PermissionField';
import useRequest from 'hooks/useRequest';
import { changeCliCommandPermission } from 'services/clicommands';

function CliModal({ isNew, clicommand, refetchCliCommands, close }) {
  let { category, command, permission } = clicommand ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async clicommand => {
        await changeCliCommandPermission(clicommand);
        await refetchCliCommands();
      },
      [refetchCliCommands],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(clicommand) {
    await makeRequest(clicommand);
    close();
  }

  return (
    <Modal
      visible
      title={'Edit Command Permission'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          category,
          command,
          permission,
        }}
        layout="vertical"
        name="CliCommand"
        onFinish={handleFinish}
      >
        <Form.Item
          name="category"
          label="Category"
          rules={[{ required: true }]}
        >
          <Input disabled={true} />
        </Form.Item>

        <Form.Item name="command" label="Command" rules={[{ required: true }]}>
          <Input disabled={true} />
        </Form.Item>

        <PermissionField label="Least Allowed Permission" name="permission" />

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default CliModal;
