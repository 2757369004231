import { useMemo, useState } from 'react';
import _ from 'lodash';
import { Form, Button, Select, Modal, Table, Space, Tooltip } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import './DynamicAdvancedSearchModal.less';
import { FormattedMessage } from 'react-intl';

const { Option } = Select;

function isCriteriaPairsFromSearchValueStateValid(pair: string): boolean {
  const keyRegex = "'[^']*'";
  const equalSign = '=';
  const valueRegex = '[^,]+';
  const commaSeparatedValues = `(?:,${valueRegex})*`;
  const valueCanEndWithComma = `,?`;
  // pattern ^'[^']*'=[^,]+(?:,[^,]+)*,?$
  const pattern = new RegExp(
    `^${keyRegex}${equalSign}${valueRegex}${commaSeparatedValues}${valueCanEndWithComma}$`,
  );
  return pattern.test(pair);
}

type DynamicAdvancedSearchModalProps = {
  close: () => void;
  modalWidth?: number;
  columnsList: string[];
  type: string;
  searchValueState?: string;
  setSearchValue: (value: any) => void;
  filterMapping?: Map<
    string,
    (
      onChange: (value: any) => void,
      id: string,
      defaultValue?: any,
    ) => JSX.Element
  >;
  fieldsNamesMap: Map<string, string>;
};
const DynamicAdvancedSearchModal = ({
  close,
  modalWidth,
  columnsList,
  type,
  searchValueState,
  setSearchValue,
  filterMapping,
  fieldsNamesMap,
}: DynamicAdvancedSearchModalProps) => {
  const [form] = Form.useForm();

  const parsedValues = useMemo(() => {
    const criteriaPairsFromSearchValueState = searchValueState?.length
      ? searchValueState.split('&&')
      : [];
    const validCriteriaPairs = criteriaPairsFromSearchValueState?.length
      ? criteriaPairsFromSearchValueState.filter(
          isCriteriaPairsFromSearchValueStateValid,
        )
      : [];

    return validCriteriaPairs.length
      ? validCriteriaPairs.map(item => {
          const [key, values] = item.split('=');

          if (values) {
            const valuesItems = values.split(',');
            const searchItemValue =
              valuesItems.length === 1
                ? valuesItems[0]?.toLocaleLowerCase?.() === 'false'
                  ? false
                  : valuesItems[0]?.toLocaleLowerCase?.() === 'true'
                  ? true
                  : valuesItems[0]
                : valuesItems.filter(valueItem => valueItem !== '');

            return {
              searchKey:
                key.startsWith("'") && key.endsWith("'")
                  ? key.slice(1, -1)
                  : key.trim(), // Remove quotes from key
              searchItemValue,
            };
          }
        })
      : [];
  }, [searchValueState]);
  const [searchesDataSource, setSearchesDataSource] = useState<
    { key: number; searchKey: string | null; searchItemValue: any }[]
  >(
    parsedValues?.length
      ? parsedValues.map((v, index) => ({ key: index, ...v }))
      : [],
  );

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  function checkSubmitButtonEnablement({
    dataSource,
  }: {
    dataSource: {
      key: number;
      searchKey: string | null;
      searchItemValue: any;
    }[];
  }) {
    const submitButtonShouldBeEnabled =
      dataSource?.length > 0 &&
      dataSource.every(
        item => item.searchItemValue || item.searchItemValue === false,
      );

    if (submitButtonShouldBeEnabled && isSubmitButtonDisabled) {
      setIsSubmitButtonDisabled(false);
    } else if (!submitButtonShouldBeEnabled && !isSubmitButtonDisabled) {
      setIsSubmitButtonDisabled(true);
    }
  }

  async function handleFinish({
    searchValues,
  }: {
    searchValues: { searchItemValue: string | string[]; searchKey: string }[];
  }) {
    let searchObj: Record<string, string[]> = {};
    for (let i = 0; i < searchValues?.length; i++) {
      let searchItem = searchValues[i];
      const currentValue = searchItem.searchItemValue;

      if (!searchObj[searchItem.searchKey]) {
        searchObj[searchItem.searchKey] = Array.isArray(currentValue)
          ? currentValue
          : [currentValue as string];
      } else {
        const newValue = Array.isArray(currentValue)
          ? currentValue
          : [currentValue as string];
        const updateUniqueValue = new Set([
          ...searchObj[searchItem.searchKey],
          ...newValue,
        ]);
        searchObj[searchItem.searchKey] = Array.from(updateUniqueValue);
      }
    }

    const searchString = Object.entries(searchObj)
      .map(
        ([key, values]) =>
          `'${key}'=` + values.map(value => `${value}`).join(','),
      )
      .join('&&');

    setSearchValue(searchString);
    close();
  }

  function handleSearchItemValueChange({ field, id, value }) {
    const newDataSource: {
      key: number;
      searchKey: string | null;
      searchItemValue: any;
    }[] = [];
    const newPagesFieldValue = [];

    searchesDataSource.forEach(dS => {
      newDataSource.push({
        ...dS,
        searchItemValue:
          dS.key === id && dS.searchKey === field ? value : dS.searchItemValue,
      });
      newPagesFieldValue.push({
        searchKey: dS.searchKey,
        searchItemValue:
          dS.key === id && dS.searchKey === field ? value : dS.searchItemValue,
      });
    });

    checkSubmitButtonEnablement({ dataSource: newDataSource });

    setSearchesDataSource(newDataSource);
    form.setFieldValue(fieldNames.searchValues, newPagesFieldValue);
  }

  function handleAddNewSearch() {
    const newData = {
      key: -searchesDataSource?.length || 0,
      searchKey: null,
      searchItemValue: null,
    };
    setSearchesDataSource([...(searchesDataSource ?? []), newData]);
    setIsSubmitButtonDisabled(true);
  }
  function handleRemoveSearch(rowIndex: number) {
    const newDataSource = searchesDataSource?.filter((_, i) => i !== rowIndex);
    setSearchesDataSource(newDataSource);
    const searchesFieldValue =
      form.getFieldValue(fieldNames.searchValues) || [];
    const updatedSearchFieldValue = searchesFieldValue?.filter(
      (_, i) => i !== rowIndex,
    );

    checkSubmitButtonEnablement({ dataSource: newDataSource });

    form.setFieldValue(fieldNames.searchValues, updatedSearchFieldValue);
  }

  const fieldNames = {
    name: 'name',
    actions: 'actions',
    searchValues: 'searchValues',
  } as const;

  const searchesTableFieldNames = {
    searchKey: 'searchKey',
    searchValue: 'searchValue',
  } as const;
  type tempsearchItemType = {
    key?: any;
    searchKey: string;
    searchItemValue?: string | string[] | null;
  };

  const searchesTableColumns = [
    {
      title: <FormattedMessage id="dynamicAdvancedSearch.searchFieldKey" />,
      dataIndex: searchesTableFieldNames.searchKey,
      render(_, searchItem: tempsearchItemType, index: number) {
        function handleChangeSearchSelect(value, option) {
          const isSearchRowNew = searchItem.key <= 0;
          const updatedSearchesDataSource = searchesDataSource.map((dS, i) => {
            if (i === index) {
              return {
                key: index,
                searchKey: option.value,
                searchItemValue: null,
              };
            }
            return dS;
          });

          const searchesFieldValue =
            form.getFieldValue(fieldNames.searchValues) || [];
          const currentSearchesDataSource = {
            searchKey: option.value,
            searchItemValue: null,
          };
          const isThisFirstSearchRowToAdd = !searchesFieldValue.length;

          let updatedSearchDataSource: any[];

          if (isThisFirstSearchRowToAdd) {
            updatedSearchDataSource = [currentSearchesDataSource];
          } else if (isSearchRowNew) {
            updatedSearchDataSource = [
              ...searchesFieldValue,
              currentSearchesDataSource,
            ];
          } else {
            updatedSearchDataSource = searchesFieldValue.map((srch, i) => {
              if (i === index) {
                srch = currentSearchesDataSource;
              }
              return srch;
            });
          }

          checkSubmitButtonEnablement({ dataSource: updatedSearchDataSource });

          form.setFieldValue(fieldNames.searchValues, updatedSearchDataSource);
          setSearchesDataSource([...updatedSearchesDataSource]);
        }

        return (
          <Select
            key={searchItem.key}
            value={searchItem.searchKey}
            className="dynamic-advanced-search-modal__search-key-select"
            onChange={handleChangeSearchSelect}
            showSearch
            optionFilterProp="children"
          >
            {columnsList?.map(searchKey => {
              return (
                <Option
                  key={searchKey}
                  value={searchKey}
                  disabled={searchesDataSource.find(
                    pDS => pDS.searchKey === searchKey,
                  )}
                >
                  {fieldsNamesMap?.get?.(searchKey)}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
    {
      title: (
        <div className="role-modal__pages-table-column-title">
          <FormattedMessage id="dynamicAdvancedSearch.searchFieldValue" />
        </div>
      ),
      dataIndex: searchesTableFieldNames.searchValue,
      render(_, searchItem: tempsearchItemType, index) {
        return (
          <section className="dynamic-advanced-search-modal__search-value-select-wrapper">
            {searchItem.searchKey
              ? filterMapping.has(searchItem.searchKey)
                ? filterMapping.get(searchItem.searchKey)(
                    handleSearchItemValueChange,
                    index,
                    searchItem.searchItemValue,
                  )
                : null
              : null}
          </section>
        );
      },
      width: 400,
    },
    {
      key: 'action',
      title: '',
      render(_, searchItem: any, index) {
        return (
          <Space>
            <Tooltip
              title={
                <FormattedMessage id="dynamicAdvancedSearch.removeSearch" />
              }
            >
              <Button
                type="primary"
                size="small"
                icon={<DeleteOutlined />}
                onClick={() => {
                  handleRemoveSearch(index);
                }}
              ></Button>
            </Tooltip>
          </Space>
        );
      },
      width: 70,
    },
  ];

  return (
    <Modal
      open
      bodyStyle={{ maxHeight: '450px', overflow: 'auto' }}
      title={`Search For ${type}`}
      footer={null}
      onCancel={close}
      className="dynamic-advanced-search-modal__wrapper"
      width={modalWidth || 520}
    >
      <Form
        form={form}
        layout="vertical"
        name="role"
        onFinish={handleFinish}
        {...(parsedValues?.length
          ? {
              initialValues: {
                searchValues: parsedValues,
              },
            }
          : {})}
      >
        <Form.Item
          name={fieldNames.searchValues}
          className="pages-form-item__container"
        >
          <Table
            rowKey="key"
            className="antd-table-customized-scroll"
            bordered
            pagination={false}
            dataSource={searchesDataSource}
            columns={searchesTableColumns}
            showHeader={true}
          />
          <Button
            onClick={handleAddNewSearch}
            type="primary"
            style={{ marginBottom: 16 }}
          >
            <PlusCircleOutlined />{' '}
            <FormattedMessage id="dynamicAdvancedSearch.addSearch" />
          </Button>
        </Form.Item>
        <Form.Item>
          <Tooltip
            title={
              isSubmitButtonDisabled ? (
                <FormattedMessage id="dynamicAdvancedSearch.disabledBtnTooltip" />
              ) : (
                ''
              )
            }
          >
            <Button
              type="primary"
              block
              htmlType="submit"
              disabled={isSubmitButtonDisabled}
            >
              Search
            </Button>
          </Tooltip>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DynamicAdvancedSearchModal;
