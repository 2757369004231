import { Modal } from 'antd';
import LockActionsModal from './LockActionsModal';

function LockInterfaceModal({
  cluster = null,
  clusterIds = null,
  refetchClusters,
  handleShowErrorModal,
  close,
}) {
  return (
    <Modal
      title="Blocked Actions"
      visible={true}
      footer={null}
      width={'40%'}
      onCancel={close}
    >
      <LockActionsModal
        close={close}
        clusterIds={clusterIds}
        cluster={cluster}
        refetchClusters={refetchClusters}
        handleShowErrorModal={handleShowErrorModal}
      />
    </Modal>
  );
}
export default LockInterfaceModal;
