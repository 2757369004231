import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Button, Tooltip, Space, Modal, message, AutoComplete } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';
import { getImages, deleteImageByName } from 'services/images';
import { showError } from 'utils/errors';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

function ImagesTable() {
  const [filteredResult, setResult] = useState([]);
  const { user } = useContext(SessionContext);

  const {
    result,
    isPending,
    makeRequest: refetchImages,
  } = useRequest(
    useCallback(() => getImages(), []),
    { resolve: true },
  );

  const images = result?.data?.images;
  useEffect(() => {
    setResult(images);
  }, [images]);

  const columns = [
    {
      title: 'Images',
      width: '75%',
    },
    {
      title: 'Action',
      render(_, image) {
        if (
          user.userRolesPagesPermissions?.[userRolesPages.IMAGES] >=
          userRolesPagesPermissionsValues.CAN_DELETE
        ) {
          return (
            <Space>
              <Tooltip title="Delete">
                <Button
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to delete this image
                          <strong> {image} </strong>?
                        </>
                      ),
                      async onOk() {
                        await deleteImageByName(image)
                          .then(response => message.info(response.data.message))
                          .catch(error => {
                            showError(error);
                          });
                        await refetchImages();
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </Space>
          );
        }
        return null;
      },
    },
  ];

  const handleSearch = value => {
    setResult(images.filter(searchResult => searchResult.includes(value)));
  };

  return (
    <div className="images">
      <AutoComplete
        style={{ width: '100%' }}
        onSearch={handleSearch}
        placeholder="Search For Image here"
      ></AutoComplete>
      <SearchableTable
        pagination={false}
        dataSource={filteredResult}
        loading={isPending}
        columns={columns}
      />
    </div>
  );
}

export default ImagesTable;
