import React, { useCallback, useContext } from 'react';
import { Modal, Form, Input, Button, Space, message } from 'antd';
import SupportedVersionField from '../SupportedVersionField/SupportedVersionField';
import useRequest from 'hooks/useRequest';
import { createDataAgent, updateDataAgent } from 'services/dataagent';
import Upload from 'components/ui/Upload/Upload';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';

function DataAgentModal({ isNew, dataagent, refetchDataAgents, close }) {
  const { user } = useContext(SessionContext);

  const { id, name, version, incortaVersion, incortaVersionFormatted } =
    dataagent ?? {};

  const { makeRequest, isPending } = useRequest(
    useCallback(
      async dataagent => {
        isNew
          ? await createDataAgent(dataagent)
          : await updateDataAgent(id, dataagent);
        await refetchDataAgents();
      },
      [id, isNew, refetchDataAgents],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(dataagent) {
    await makeRequest(dataagent);
    close();
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Data Agent' : 'Edit Data Agent'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          id,
          name,
          version,
          incortaVersion,
          incortaVersionFormatted,
        }}
        layout="vertical"
        name="DataAgent"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>

        <Form.Item name="version" label="Version" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>

        <SupportedVersionField
          label="Incorta Version"
          name="incortaVersionFormatted"
          agent={dataagent}
        />

        <Form.Item
          name="exportedFile"
          label="Data Agent zip file"
          rules={[{ required: isNew }]}
        >
          <Upload
            draggable
            beforeUpload={file => {
              const isValue = [
                'application/x-zip-compressed',
                'application/zip',
              ].includes(file.type);
              if (!isValue) {
                message.error('You can only upload Zip file');
                return false;
              }

              const isLt30M = file.size / 1024 / 1024 < 400;
              if (!isLt30M) {
                message.error('Zip file must smaller than 400MB!');
                return false;
              }

              return isValue && isLt30M;
            }}
            notImage
          />
        </Form.Item>

        <Space>
          {+user.userRolesPagesPermissions?.[userRolesPages.DATA_AGENT] >=
            +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Button type="primary" htmlType="submit" loading={isPending}>
              {isNew ? 'Create' : 'Update'}
            </Button>
          )}
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default DataAgentModal;
