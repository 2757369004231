import { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, Tooltip, Space, Button, Modal } from 'antd';
import { format } from 'date-fns';
import {
  CloudDownloadOutlined,
  CopyFilled,
  DeleteOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import ExportSummaryModal from './ExportSummaryModal/ExportSummaryModal';
import CopyParquetFilesModal from './CopyParquetFilesModal/CopyParquetFilesModal';
import useRequest from 'hooks/useRequest';
import { API_URL } from 'utils/http';
import {
  deleteExportedBlueprint,
  getExportedBlueprints,
} from 'services/exportedBlueprints';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { showError, showSuccessMessage } from 'utils/errors';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';

let { Title } = Typography;

function ExportedBlueprints() {
  const { user } = useContext(SessionContext);

  let [page, setPage] = useState(1);
  let [modalProps, setModalProps] = useState<any>(null);
  let [copyParquetModalProps, setCopyParquetModalProps] = useState<any>(null);
  let [search, setSearch] = useState('');

  const downloadRef = useRef(null);
  const [authToken, setAuthToken] = useState(null);

  const userHasEditExportBlueprintPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.BLUEPRINTS_EXPORTS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const userHasDeleteExportBlueprintPagePermission =
    user.userRolesPagesPermissions?.[userRolesPages.BLUEPRINTS_EXPORTS] ===
    userRolesPagesPermissionsValues.CAN_DELETE;

  useEffect(() => {
    async function setToken() {
      const token = await getAccessTokenSilently();
      setAuthToken(token);
    }
    setToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let {
    result,
    isPending,
    makeRequest: refetchExportedBlueprints,
  } = useRequest(
    useCallback(() => getExportedBlueprints({ page, search }), [page, search]),
    { resolve: true },
  );
  const { getAccessTokenSilently } = useAuth0();

  let exportedBlueprints = result?.data?.exports;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Content Name',
      dataIndex: 'contentName',
    },
    {
      title: 'Exporter Email',
      dataIndex: 'email',
      render(_, exportedBlueprint) {
        return <span>{exportedBlueprint.user.email}</span>;
      },
    },
    {
      title: 'Tenant Name',
      dataIndex: 'tenant',
    },
    {
      title: 'Cluster',
      dataIndex: 'instanceName',
      render(_, exportedBlueprint) {
        return exportedBlueprint.instance.name;
      },
    },
    {
      title: 'Cluster Version',
      dataIndex: 'image',
      width: '7%',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Actions',
      render(_, exportedBlueprint) {
        return (
          <Space>
            <Tooltip title="Details">
              <Button
                type="text"
                size="small"
                onClick={() => {
                  setModalProps({ isNew: false, exportedBlueprint });
                }}
              >
                <ProfileOutlined />
              </Button>
            </Tooltip>
            {userHasEditExportBlueprintPagePermission && (
              <Tooltip title={'download'}>
                <a
                  rel="noopener noreferrer"
                  href={`${API_URL}/blueprints/exports/download?exportId=${exportedBlueprint.id}&token=${authToken}`}
                  download={exportedBlueprint.zipFileName}
                  ref={downloadRef}
                >
                  <CloudDownloadOutlined />
                </a>
              </Tooltip>
            )}
            {!exportedBlueprint.deletedAt && (
              <Tooltip
                placement="left"
                title={
                  !userHasDeleteExportBlueprintPagePermission
                    ? 'You don❜t have sufficient permission'
                    : 'Delete'
                }
              >
                <Button
                  size="small"
                  disabled={!userHasDeleteExportBlueprintPagePermission}
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to delete this exported
                          blueprint <strong>{exportedBlueprint.name}</strong>?
                        </>
                      ),
                      async onOk() {
                        await deleteExportedBlueprint(
                          exportedBlueprint.id,
                          exportedBlueprint.zipFileName,
                        )
                          .then(() => {
                            showSuccessMessage(
                              'Exported blueprint deleted successfully.',
                            );
                          })
                          .catch(error => {
                            showError(error);
                          });
                        await refetchExportedBlueprints();
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ExportedBlueprints">
      <div className="tableTitle">
        <Title>Blueprints Exports</Title>

        <Tooltip
          title={
            !userHasEditExportBlueprintPagePermission
              ? 'You don❜t have sufficient permission'
              : 'Create'
          }
        >
          <Button
            type="primary"
            icon={<CopyFilled />}
            onClick={() => {
              setCopyParquetModalProps({ isNew: true });
            }}
            disabled={!userHasEditExportBlueprintPagePermission}
          >
            Copy Parquet Files
          </Button>
        </Tooltip>
      </div>

      <SearchableTable
        searchPlaceholder="Search Blueprint or 'Field Name'=Some Value."
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={exportedBlueprints}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <ExportSummaryModal
          {...modalProps}
          refetchAudit={refetchExportedBlueprints}
          close={() => setModalProps(null)}
        />
      )}
      {copyParquetModalProps && (
        <CopyParquetFilesModal
          {...copyParquetModalProps}
          close={() => setCopyParquetModalProps(null)}
        />
      )}
    </div>
  );
}

export default ExportedBlueprints;
