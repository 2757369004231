import axios from 'axios';
import { API_URL } from 'utils/http';

export function getCliCommands({ page, search }: any = {}) {
  return axios.get(`${API_URL}/cli`, {
    params: { page, search },
  });
}

export function changeCliCommandPermission(cliCommand) {
  return axios.post(`${API_URL}/cli/changeCommandPermission`, { cliCommand });
}

export function getCliConfig() {
  return axios.get(`${API_URL}/configurations/clipermissions`);
}
