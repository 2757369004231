import { useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { displaySuccessMessage, displayErrorMessage } from 'utils';
import { getErrorMessage } from 'utils/errors';
import { queryClient } from 'index';
import { InstancePlatform } from 'types/cluster';
import { fetchPlatformActions, updatePlatformAction } from 'services/platform';

export function useGetPlatformActions() {
  return useQuery(
    [QueryServerKeys.SYSTEM_MANAGEMENT.PLATFORM_ACTIONS],
    async () => {
      const result = await fetchPlatformActions();
      return result?.data;
    },
  );
}

export function useUpdatePlatformAction() {
  const queryOptions = useMutation(
    async ({
      action,
      platform,
      status,
      actionDisplayName,
    }: {
      action: string;
      platform: InstancePlatform;
      status: boolean;
      actionDisplayName: string;
    }) => {
      return await updatePlatformAction({ action, platform, status });
    },
    {
      mutationKey: [QueryServerKeys.SYSTEM_MANAGEMENT.UPDATE_PLATFORM_ACTION],
      onSuccess(result, { actionDisplayName }) {
        queryClient.invalidateQueries(
          QueryServerKeys.SYSTEM_MANAGEMENT.PLATFORM_ACTIONS,
        );
        queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
        displaySuccessMessage(
          result.status.toString().startsWith('2')
            ? `${actionDisplayName} is successfully updated`
            : result.data?.message,
        );
      },
      onError(error: AxiosError) {
        const errorMessage = error && getErrorMessage(error);
        if (errorMessage) {
          displayErrorMessage(errorMessage);
        }
      },
    },
  );

  return queryOptions;
}
