import React, { useCallback, useContext } from 'react';
import {
  Button,
  Space,
  Modal,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  message,
} from 'antd';

import { Option } from 'antd/lib/mentions';
import useRequest from 'hooks/useRequest';
import { cleanupResources } from 'services/cluster';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';

function CleanupModal({ cancel, refresh }) {
  const { user } = useContext(SessionContext);

  const TYPES = [
    { key: 'all', value: 'All' },
    { key: 'db', value: 'Databases' },
    { key: 'disk', value: 'Disks' },
    { key: 'image', value: 'Images' },
    { key: 'bucket', value: 'Buckets' },
    { key: 'infrastructure', value: 'Infrastructure' },
  ];

  const { makeRequest, isPending } = useRequest(
    // eslint-disable-next-line
    useCallback(async filters => {
      await cleanupResources(filters);
    }, []),
    {
      handleError: true,
    },
  );

  async function handleFinish(filters) {
    try {
      const payload = {
        type: filters.type,
        range: { start: filters.start, end: filters.end },
      };
      await makeRequest(payload);
      message.success('Clean up request is being processed now!');
    } finally {
      refresh();
      cancel();
    }
  }

  return (
    <Modal
      title="Cleanup Resources"
      visible={true}
      footer={null}
      width={'50%'}
      onCancel={cancel}
    >
      <Form
        initialValues={{ type: TYPES[0].key }}
        className="FeatureBits"
        layout="vertical"
        name="Customize Instance"
        onFinish={handleFinish}
      >
        <Form.Item
          name="type"
          label="Cleanup Type"
          rules={[{ required: true }]}
        >
          <Select dropdownClassName="SizeField__select-dropdown">
            {TYPES.map(type => (
              <Option key={type.key} value={type.key}>
                {type.value}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="range" label="Date Range">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="start"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="end"
                label="End Date"
                rules={[{ required: true }]}
              >
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isPending}
              disabled={
                user.userRolesPagesPermissions?.[
                  userRolesPages.INFRASTRUCTURE
                ] !== userRolesPagesPermissionsValues.CAN_DELETE
              }
            >
              Cleanup
            </Button>
            <Button onClick={cancel}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
}
export default CleanupModal;
