import React, { useCallback } from 'react';
import moment from 'moment';

import {
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
  Button,
  Space,
  DatePicker,
} from 'antd';

import useRequest from 'hooks/useRequest';
import { createPackage, updatePackage } from 'services/package';
const { Option } = Select;

function PackageModal({
  isNew,
  userPackageID,
  userPackage,
  refetchPackages,
  close,
}) {
  let { id, name, type, userID, availableUnits, start, end } =
    userPackage ?? {};

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async userPackage => {
        isNew
          ? await createPackage(userPackage)
          : await updatePackage(id, userPackage);
        await refetchPackages();
      },
      [id, isNew, refetchPackages],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(userPackage) {
    await makeRequest(userPackage);
    close();
  }

  function disabledDate(current) {
    return current && current < moment().startOf('day');
  }

  return (
    <Modal
      open
      title={isNew ? 'New Package' : 'Edit Package'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
          type,
          userID,
          availableUnits,
          start,
          end,
          userPackageID,
        }}
        layout="vertical"
        name="Package"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>

        <Form.Item
          name="type"
          label="Package Type"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Select Package Type"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="once">Once</Option>
            <Option value="monthly">Monthly</Option>
            <Option value="annual">Annually</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="userPackageID"
          label="userID"
          style={{ display: 'none' }}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          name="availableUnits"
          label="Units"
          rules={[{ required: true }]}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name="start" label="Start Date" rules={[{ required: true }]}>
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
          />
        </Form.Item>

        <Form.Item name="end" label="End Date" rules={[{ required: true }]}>
          <DatePicker
            disabledDate={disabledDate}
            style={{ width: '100%' }}
            format="YYYY-MM-DD"
          />
        </Form.Item>

        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            if (getFieldValue('type') === 'once') {
              return (
                <Form.Item
                  name="end"
                  label="End Date"
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    disabledDate={disabledDate}
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD"
                  />
                </Form.Item>
              );
            }
          }}
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default PackageModal;
