import axios, { AxiosResponse } from 'axios';
import { API_URL } from 'utils/http';

export function getFeatureBit({ page = 1, search }) {
  return axios.get(`${API_URL}/featureBit`, { params: { page, search } });
}

export function getFeatureBits({
  page,
  search,
}: {
  page?: number;
  search: string;
}): Promise<AxiosResponse<any>> {
  const params: any = {};
  if (page !== undefined) {
    params.page = page;
  }

  params.search = search;
  return axios.get(`${API_URL}/featurebits`, { params });
}

export function getClusterFeatureBits(uid) {
  return axios.get(`${API_URL}/featurebits/${uid}/featureBits`);
}

export function createFeatureBit(featureBit) {
  return axios.post(`${API_URL}/featurebits/create`, featureBit);
}

export function updateFeatureBit(uid, featureBit) {
  return axios.put(`${API_URL}/featurebits/${uid}/update`, featureBit);
}

export function deleteFeatureBit(uid, featureBit) {
  return axios.delete(`${API_URL}/featurebits/${uid}/delete`, featureBit);
}
