import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getTimezones } from 'services/cluster';
import './TimezoneField.less';

let { Option } = Select;

function TimezoneField({ name, label }) {
  let { result, isPending } = useRequest(getTimezones, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let timezones = result?.data?.timezones;

  return (
    <Form.Item
      className="TimezoneField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect loading={isPending && !timezones} timezones={timezones} />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, timezones }: any) {
  return (
    <Select
      showSearch
      value={timezones ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="TimezoneField__select-dropdown"
    >
      {timezones?.map(timezone => (
        <Option key={timezone} value={timezone}>
          {timezone}
        </Option>
      ))}
    </Select>
  );
}

export default TimezoneField;
