import React, { useState, useCallback, useMemo } from 'react';
import { Tooltip, Input, Select, Spin, Form, Button } from 'antd';
import { getSparkMvsStatus } from 'services/clustersstatus';
import useRequest from 'hooks/useRequest';
import '../clustersstatus.less';

let { Search } = Input;
let { Option } = Select;

function SparkMVs() {
  let [schemaQuery, setSchemaQuery] = useState<any>('');
  let [schemaDummy, setSchemaDummy] = useState<any>('');
  let [mvQuery, setMvQuery] = useState<any>('');
  let [mvDummy, setMvDummy] = useState<any>('');
  let [clusterQuery, setClusterQuery] = useState<any>('');
  let [clusterDummy, setClusterDummy] = useState<any>('');
  let [operatorFilter, setOperatorFilter] = useState<any>('all');

  const searchParams = useMemo(
    () => ({
      clusterName: clusterQuery,
      clusterCode: operatorFilter,
      mv: mvQuery,
      schema: schemaQuery,
    }),
    [operatorFilter, clusterQuery, mvQuery, schemaQuery],
  );

  let { result: sparkMVsResult, isPending: sparkMVsLoading } = useRequest(
    useCallback(() => getSparkMvsStatus(searchParams), [searchParams]),
    { resolve: true, handleError: true },
  );
  let sparkMVs = sparkMVsResult?.data;

  const onSchemaFilter = value => {
    setSchemaQuery(value);
  };

  const onMvFilter = value => {
    setMvQuery(value);
  };

  const onClusterFilter = value => {
    setClusterQuery(value);
  };

  function getClusterStatus(message, mv, cluster, schema) {
    let tooltipText = `${cluster}/${mv} (${message})`;
    let mappedResult = {
      interrupted: 'aborted',
      success: 'running',
      aborted: 'aborted',
      failed: 'service-down',
      'finished with errors': 'service-down',
    };

    return (
      <Tooltip placement="top" title={tooltipText}>
        <div className={mappedResult[message]}></div>
      </Tooltip>
    );
  }
  return (
    <div>
      {sparkMVs ? (
        <div>
          <div>
            <Form layout="inline" name="filterStuff">
              <Form.Item name="op">
                <FieldSelect
                  operators={sparkMVs.clusterCodes}
                  onChange={e => {
                    setOperatorFilter(e);
                  }}
                />
              </Form.Item>
              <Form.Item>
                <Search
                  placeholder={'Filter by cluster'}
                  onSearch={clusterDummy => onClusterFilter(clusterDummy)}
                  onChange={e => {
                    setClusterDummy(e.target.value);
                  }}
                  value={clusterDummy}
                  style={{
                    width: 304,
                    paddingBottom: 20,
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item>
                <Search
                  placeholder={'Filter by schema'}
                  onSearch={schemaDummy => onSchemaFilter(schemaDummy)}
                  onChange={e => {
                    setSchemaDummy(e.target.value);
                  }}
                  value={schemaDummy}
                  style={{
                    width: 304,
                    paddingBottom: 20,
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item>
                <Search
                  placeholder={'Filter by MV'}
                  onSearch={schemaDummy => onMvFilter(mvDummy)}
                  onChange={e => {
                    setMvDummy(e.target.value);
                  }}
                  value={mvDummy}
                  style={{
                    width: 304,
                    paddingBottom: 20,
                  }}
                  allowClear
                />
              </Form.Item>
            </Form>
          </div>
          <div className="count-info-running">
            <div className="running-clusters-square"></div>
            <span className="running-cluster-text">Succeeded</span>
          </div>
          <div className="count-info-service-down">
            <div className="service-down-clusters-square"></div>
            <span className="service-down-clusters-text">
              Failed or finished with errors
            </span>
          </div>
          <div className="count-info-aborted">
            <div className="aborted-clusters-square"></div>
            <span className="aborted-clusters-text">
              Aborted or interrupted
            </span>
          </div>
          {sparkMVsLoading ? (
            <Spin />
          ) : (
            <div>
              <div className="card">
                {sparkMVs.sparkMvsData.map(mv => (
                  <div>
                    <div>
                      {getClusterStatus(
                        mv.message,
                        mv.mv,
                        mv.cluster,
                        mv.schema,
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Spin />
      )}
    </div>
  );
}
function FieldSelect({ onChange, loading, operators }: any) {
  return (
    <Select
      allowClear
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
      style={{
        width: 304,
        height: 20,
      }}
      placeholder="Filter by operator"
    >
      {operators?.map(operator => (
        <Option key={operator.cluster_code} value={`${operator.cluster_code}`}>
          {`${operator.cluster_code}`}
        </Option>
      ))}
      <Option key="All" value="all">
        All Operators
      </Option>
    </Select>
  );
}

export default SparkMVs;
