import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, Select } from 'antd';
import { migrateCluster } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { getOperators } from 'services/contextManager';

let { Option } = Select;

function MigrateModal({ isNew, cluster, refetchClusters, close }) {
  let { name, platform } = cluster ?? {};
  let newMigrationName = `${name}-1`;

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async selectedValues => {
        const splittedOperator = selectedValues.operator.split('$');
        await migrateCluster(name, {
          name: selectedValues.newMigrationName,
          newMigrationClusterCode: splittedOperator[0],
          newMigrationRegion: splittedOperator[1],
          newMigrationZone: splittedOperator[2],
        });
        await refetchClusters();
      },
      [name, refetchClusters],
    ),
    {
      handleError: true,
    },
  );
  let { result } = useRequest(getOperators, {
    resolve: true,
    handleError: true,
    useCache: true,
  });
  let operators = [];
  if (result) {
    operators = result.data.operators.filter(
      operator =>
        operator.platform == platform &&
        operator.priority !== -1 &&
        operator.cluster_code !== cluster.k8sClusterCode,
    );
  }
  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal visible title={'Migrate Cluster'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          name,
          newMigrationName,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item
          name="newMigrationName"
          label="Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="New Cluster Name" />
        </Form.Item>
        <Form.Item name="operator" label="Cluster" rules={[{ required: true }]}>
          <FieldSelect
            loading={isPending && !operators}
            operators={operators}
          />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Migrate'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

function FieldSelect({ value, onChange, loading, operators }: any) {
  return (
    <Select
      value={operators ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
    >
      {operators?.map(operator => (
        <Option
          key={operator.cluster_code}
          value={`${operator.cluster_code}$${operator.region}$${operator.zone}`}
        >
          {`${operator.alias} - ${operator.cluster_code}`}
        </Option>
      ))}
    </Select>
  );
}

export default MigrateModal;
