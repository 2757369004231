import axios from 'axios';
import { SLATypeData } from 'types/slatype';
import { API_URL } from 'utils/http';

export function getConfigurations({ page = 1, search }) {
  return axios.get(`${API_URL}/configurations`, { params: { page, search } });
}

export function updateConfiguration(id, configuration) {
  if (
    [
      'latestReleaseInfo',
      'supportedVersions',
      'defaultIncortaImage',
      'parkedClusters',
    ].includes(configuration.configKey)
  )
    return axios.put(
      `${API_URL}/configurations/${id}/update/releaseconfigurations`,
      configuration,
    );
  else
    return axios.put(`${API_URL}/configurations/${id}/update`, configuration);
}

export function createConfiguration(configuration) {
  return axios.post(`${API_URL}/configurations/create`, configuration);
}

export function deleteConfiguration(id) {
  return axios.delete(`${API_URL}/configurations/${id}/delete`);
}

export function getCostType() {
  return axios.get(`${API_URL}/costtypes`);
}

export function getSlaType() {
  return axios.get<SLATypeData>(`${API_URL}/slaTypes`);
}

export function getMaintenanceTimeout() {
  return axios.get(`${API_URL}/maintenanceTimeouts`);
}

export function getMaintenanceStatus() {
  return axios.get(`${API_URL}/maintenanceStatus`);
}
