import './NavigationLayout.less';

import { useContext, useState } from 'react';
import {
  UserOutlined,
  SettingOutlined,
  ClusterOutlined,
  DeploymentUnitOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HomeOutlined,
  FileDoneOutlined,
  BookOutlined,
  DatabaseOutlined,
  AuditOutlined,
  MailOutlined,
  EyeOutlined,
  BuildOutlined,
  DollarCircleOutlined,
  LaptopOutlined,
  RadarChartOutlined,
  CloudServerOutlined,
  StrikethroughOutlined,
  SnippetsOutlined,
  BorderOuterOutlined,
  CloudDownloadOutlined,
  UserSwitchOutlined,
  PieChartOutlined,
  CloudSyncOutlined,
  InfoCircleOutlined,
  ContactsOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Menu, Layout, Button, Typography } from 'antd';
import { useHistory, useLocation, Link } from 'react-router-dom';
import cookie from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as ChidoriBlackIcon } from 'images/chidori-black-logo.svg';
import { ReactComponent as NotebookIcon } from 'images/notebook-logo.svg';
import { ReactComponent as IncortaOneLogo } from 'images/incorta-one-icon.svg';
import IncortaLogo from 'images/incorta-logo.svg';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages } from 'types/user';

const { Header, Sider, Content } = Layout;
const { Text } = Typography;

function NavigationLayout({ children }) {
  const location = useLocation();
  const history = useHistory();
  const { user, logout } = useContext(SessionContext);

  const [collapsed, setCollapsed] = useState(true);

  function handleToggle() {
    setCollapsed(!collapsed);
  }

  return (
    <Layout className="NavigationLayout">
      <Sider width={240} trigger={null} collapsible collapsed={collapsed}>
        <div className="NavigationLayout__logo">
          <img src={IncortaLogo} alt="Incorta Logo" />
        </div>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[location.pathname]}
          className="NavigationLayout__menu"
        >
          <Menu.Item
            key="/"
            icon={<HomeOutlined />}
            onClick={() => {
              history.push('/');
            }}
          >
            Home
          </Menu.Item>
          {
            <Menu.SubMenu
              key="users-management"
              title="Users Management"
              icon={<UserOutlined />}
            >
              {userRolesPages.ROLES in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/roles"
                  icon={<ContactsOutlined />}
                  onClick={() => {
                    history.push('/userroles');
                  }}
                >
                  <FormattedMessage id="userRolesPage.pageTitle" />
                </Menu.Item>
              )}
              {userRolesPages.USERS in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/users"
                  onClick={() => {
                    history.push('/users');
                  }}
                  icon={<UserOutlined />}
                >
                  Users
                </Menu.Item>
              )}
              {userRolesPages.PROVISION_USERS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/provision-user"
                  icon={<UserSwitchOutlined />}
                  onClick={() => {
                    history.push('/provision-user');
                  }}
                >
                  Provision Users
                </Menu.Item>
              )}
            </Menu.SubMenu>
          }
          {
            <Menu.SubMenu
              key="blueprints-management"
              icon={<FileDoneOutlined />}
              title="Blueprints Management"
            >
              {userRolesPages.BLUEPRINTS_EXPORTS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/exportedblueprints"
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    history.push('/exportedblueprints');
                  }}
                >
                  Blueprints Exports
                </Menu.Item>
              )}
            </Menu.SubMenu>
          }
          {
            <Menu.SubMenu
              key="clusters-management"
              title="Clusters Management"
              icon={<ClusterOutlined />}
            >
              {userRolesPages.CLUSTERS in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/clusters"
                  icon={<ClusterOutlined />}
                  onClick={() => {
                    history.push('/clusters');
                  }}
                >
                  Clusters
                </Menu.Item>
              )}
              {userRolesPages.FEATURE_BITS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/featurebits"
                  icon={<LaptopOutlined />}
                  onClick={() => {
                    history.push('/featurebits');
                  }}
                >
                  Feature Bits
                </Menu.Item>
              )}

              {userRolesPages.BACKUPS in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/backups"
                  icon={<CloudServerOutlined />}
                  onClick={() => {
                    history.push('/backups');
                  }}
                >
                  Backups
                </Menu.Item>
              )}
              {userRolesPages.RESTORED_CLUSTERS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/restore"
                  icon={<RetweetOutlined />}
                  onClick={() => {
                    history.push('/restore');
                  }}
                >
                  Restored Clusters
                </Menu.Item>
              )}
            </Menu.SubMenu>
          }

          <Menu.SubMenu
            key="system-management"
            title="System Management"
            icon={<SettingOutlined />}
          >
            {userRolesPages.CONFIGURATIONS in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/configurations"
                icon={<SettingOutlined />}
                onClick={() => {
                  history.push('/configurations');
                }}
              >
                Configurations
              </Menu.Item>
            )}

            {userRolesPages.DATA_AGENT in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/dataagents"
                disabled={true}
                icon={<DatabaseOutlined />}
                onClick={() => {
                  history.push('/dataagents');
                }}
              >
                Data Agents (Deprecated)
              </Menu.Item>
            )}
            {userRolesPages.PLATFORM_ACTIONS in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/platform"
                icon={<BorderOuterOutlined />}
                onClick={() => {
                  history.push('/platform');
                }}
              >
                Platform Actions
              </Menu.Item>
            )}
            {userRolesPages.TOGGLE_FEATURES in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/togglefeatures"
                icon={<DeploymentUnitOutlined />}
                onClick={() => {
                  history.push('/togglefeatures');
                }}
              >
                Toggle Features
              </Menu.Item>
            )}

            {userRolesPages.CLI_COMMAND in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/clicommands"
                icon={<BookOutlined />}
                onClick={() => {
                  history.push('/clicommands');
                }}
              >
                CLI Commands
              </Menu.Item>
            )}
            {userRolesPages.OPERATORS in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/contextmanager"
                icon={<RadarChartOutlined />}
                onClick={() => {
                  history.push('/contextmanager');
                }}
              >
                Operators
              </Menu.Item>
            )}

            {userRolesPages.COST_AWARENESS in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/costawareness"
                icon={<DollarCircleOutlined />}
                onClick={() => {
                  history.push('/costawareness');
                }}
              >
                Cost Awareness
              </Menu.Item>
            )}
            {userRolesPages.INFRASTRUCTURE in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/infrastructure"
                icon={<SnippetsOutlined />}
                onClick={() => {
                  history.push('/infrastructure');
                }}
              >
                Infrastructure
              </Menu.Item>
            )}
          </Menu.SubMenu>
          {
            <Menu.SubMenu
              key="monitoring"
              title="Monitoring"
              icon={<EyeOutlined />}
            >
              {userRolesPages.CLOUD_INSIGHTS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/clustersstatus"
                  icon={<EyeOutlined />}
                  onClick={() => {
                    history.push('/clustersstatus');
                  }}
                >
                  Cloud Insights
                </Menu.Item>
              )}
              {userRolesPages.CLOUD_ACTION_CENTER in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/clustersactions"
                  icon={<CloudSyncOutlined />}
                  onClick={() => {
                    history.push('/clustersactions');
                  }}
                >
                  Cloud Actions
                </Menu.Item>
              )}
              {userRolesPages.AUDIT_HISTORY in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/audit"
                  icon={<AuditOutlined />}
                  onClick={() => {
                    history.push('/audit');
                  }}
                >
                  Audit History
                </Menu.Item>
              )}
              {userRolesPages.NOTIFICATIONS in
                user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/notifications"
                  icon={<MailOutlined />}
                  onClick={() => {
                    history.push('/notifications');
                  }}
                >
                  Notifications
                </Menu.Item>
              )}
            </Menu.SubMenu>
          }
          {
            <Menu.SubMenu
              key="images-management"
              title="Images Management"
              icon={<BuildOutlined />}
            >
              {userRolesPages.IMAGES in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/images"
                  icon={<BuildOutlined />}
                  onClick={() => {
                    history.push('/images');
                  }}
                >
                  Images
                </Menu.Item>
              )}
              {userRolesPages.IMAGES in user.userRolesPagesPermissions && (
                <Menu.Item
                  key="/sparkimages"
                  icon={<StrikethroughOutlined />}
                  onClick={() => {
                    history.push('/sparkimages');
                  }}
                >
                  Spark Images
                </Menu.Item>
              )}
            </Menu.SubMenu>
          }
          <Menu.SubMenu
            key="guidance"
            title="Guidance"
            icon={<InfoCircleOutlined />}
          >
            {userRolesPages.CLUSTER_SIZES in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/cluster-sizes"
                icon={<ClusterOutlined />}
                onClick={() => {
                  history.push('/cluster-sizes');
                }}
              >
                Cluster Sizes
              </Menu.Item>
            )}
            {userRolesPages.CHIDORI in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/chidori-sizes"
                className="chidori-sizes-nav"
                icon={
                  <ChidoriBlackIcon
                    style={{ width: '12px', height: '12px', fill: 'white' }}
                  />
                }
                onClick={() => {
                  history.push('/chidori-sizes');
                }}
              >
                Chidori Sizes
              </Menu.Item>
            )}
            {userRolesPages.CLUSTERS in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/notebook-sizes"
                className="notebook-sizes-nav"
                icon={
                  <NotebookIcon
                    style={{ width: '12px', height: '12px', fill: 'white' }}
                  />
                }
                onClick={() => {
                  history.push('/notebook-sizes');
                }}
              >
                Notebook Users Count
              </Menu.Item>
            )}
            {userRolesPages.CHIDORI in user.userRolesPagesPermissions && (
              <Menu.Item
                key="/chidori"
                className="chidori-sizes-nav"
                icon={
                  <ChidoriBlackIcon
                    style={{ width: '12px', height: '12px', fill: 'white' }}
                  />
                }
                onClick={() => {
                  history.push('/chidori');
                }}
              >
                Chidori Upgrade
              </Menu.Item>
            )}
            {userRolesPages.OVER_COMMITMENT in
              user.userRolesPagesPermissions && (
              <Menu.Item
                key="/oclabels"
                icon={<PieChartOutlined />}
                onClick={() => {
                  history.push('/oclabels');
                }}
              >
                Over Commitment
              </Menu.Item>
            )}
          </Menu.SubMenu>
          {userRolesPages.INCORTA_ONE in user.userRolesPagesPermissions && (
            <Menu.Item
              key="/incorta-one"
              icon={
                <IncortaOneLogo
                  style={{
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              onClick={() => {
                history.push('/incorta-one');
              }}
            >
              IncortaOne
            </Menu.Item>
          )}
        </Menu>
      </Sider>
      <Layout className="NavigationLayout__site-layout">
        <Header className="NavigationLayout__header">
          <div>
            {collapsed ? (
              <MenuUnfoldOutlined
                className="NavigationLayout__trigger"
                onClick={handleToggle}
              />
            ) : (
              <MenuFoldOutlined
                className="NavigationLayout__trigger"
                onClick={handleToggle}
              />
            )}
          </div>
          <div className="NavigationLayout__header-nav">
            <Text strong>{user.fullName}</Text>
            <Link to={`/support-dashboard`}>
              <Button type="dashed">Support Dashboard</Button>
            </Link>
            <Button
              onClick={() => {
                logout({ returnTo: window.location.origin });
                cookie.remove('CloudAdminSSO', {
                  domain: process.env.REACT_APP_DOMAIN,
                });
                document.dispatchEvent(new CustomEvent('SESSION_LOGGED_OUT'));
              }}
            >
              Logout
            </Button>
          </div>
        </Header>
        <Content className="NavigationLayout__content">{children}</Content>
      </Layout>
    </Layout>
  );
}

export default NavigationLayout;
