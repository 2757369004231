import React, { useState, useCallback } from 'react';
import { Spin, Table } from 'antd';
import useRequest from 'hooks/useRequest';
import { sensitiveData } from 'services/cluster';

function Secrets({ instance }) {
  let [instanceName] = useState(instance?.name);
  let { result, isPending } = useRequest(
    useCallback(() => sensitiveData(instanceName), [instanceName]),
    {
      handleError: true,
      resolve: true,
    },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting secrets" />
      </div>
    );
  }
  const secrets = result?.data?.body?.secrets;
  const dataSource: any = [];
  const columns = [
    { title: 'Key', dataIndex: 'key', key: 'key' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  if (secrets) {
    Object.entries(secrets).forEach(([k, v]) =>
      dataSource.push({ key: k, value: v }),
    );
  }

  return (
    <div>
      <Table dataSource={dataSource} columns={columns} />;
    </div>
  );
}

export default Secrets;
