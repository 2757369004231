import React, { useContext } from 'react';
import { Typography, Button, Tooltip, Tabs } from 'antd';
import { BackwardOutlined } from '@ant-design/icons';
import { useParams, useHistory } from 'react-router-dom';
import UserDetails from 'components/Users/UserDetails/UserDetails';
import InstanceDetails from 'components/Users/InstanceDetails/InstanceDetails';
import UserConsumption from 'components/Users/UserConsumption/UserConsumption';
import UserPackages from 'components/Users/UserPackages/UserPackages';
import AuditHistory from 'components/AuditHistory/AuditHistory';
import { userRolesActions, userRolesPages } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';

const { TabPane } = Tabs;

const { Title } = Typography;

function UserInfo({
  backToBtnText,
  pushToURL,
}: {
  backToBtnText: string;
  pushToURL: string;
}) {
  const { userid }: any = useParams();
  const { user } = useContext(SessionContext);

  const history = useHistory();

  return (
    <div className="UserInfo">
      <div className="tableTitle">
        <Title>Account Details</Title>
        <Tooltip title="Users">
          <Button
            type="primary"
            icon={<BackwardOutlined />}
            onClick={() => {
              history.push(`${pushToURL}`);
            }}
          >
            {backToBtnText}
          </Button>
        </Tooltip>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Account & Security" key="1">
          <UserDetails userID={userid} />
        </TabPane>
        <TabPane tab="Clusters" key="2">
          <InstanceDetails userID={userid} />
        </TabPane>
        {userRolesActions.VIEW_USER_PACKAGES in user.userRolesActions && (
          <TabPane tab="Packages" key="3">
            <UserPackages userID={userid} />
          </TabPane>
        )}
        {userRolesActions.USER_CONSUMPTION in user.userRolesActions && (
          <TabPane tab="Consumption" key="4">
            <UserConsumption userID={userid} />
          </TabPane>
        )}
        {/* {userRolesPages.AUDIT_HISTORY in user.userRolesPagesPermissions && ( */}
        <TabPane tab="Audit" key="5">
          <AuditHistory userID={userid} instance={null} />
        </TabPane>
        {/* )} */}
      </Tabs>
    </div>
  );
}

export default UserInfo;
