import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, Radio } from 'antd';

import { scheduler } from 'services/cluster';

import useRequest from 'hooks/useRequest';

function SchedulerModal({ isNew, cluster, refetchClusters, close }) {
  let instanceName = cluster.name;
  let schedulerRecord = {
    instanceName: instanceName,
    CronJob: '',
    TimeZone: '',
    SchedulerType: '',
  };

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async schedulerRecord => {
        await scheduler(schedulerRecord);
        await refetchClusters();
      },
      [refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(schedulerRecord) {
    await makeRequest(schedulerRecord);
    close();
  }

  return (
    <Modal visible title={'Scheduler'} footer={null} onCancel={close}>
      <Form
        initialValues={schedulerRecord}
        layout="vertical"
        name="schedulerRecord"
        onFinish={handleFinish}
      >
        <Form.Item name="instanceName" label="Cluster Name">
          <Input disabled={!isNew} />
        </Form.Item>

        <Form.Item
          name="SchedulerType"
          label="Scheduler Type"
          rules={[{ required: true }]}
        >
          <Radio.Group>
            <Radio value="connect">Wakeup</Radio>
            <Radio value="disconnect">Disconnect</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="CronJob" label="Cron Job" rules={[{ required: true }]}>
          <Input placeholder="Example: * * * * *" />
        </Form.Item>

        <Form.Item
          name="TimeZone"
          label="Time Zone"
          rules={[{ required: true }]}
        >
          <Input placeholder="Example: Africa/Cairo " />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Schedule' : 'Schedule'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default SchedulerModal;
