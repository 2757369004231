import axios from 'axios';
import { API_URL } from 'utils/http';

export function createImage({
  platform,
  build,
  upgradeMode,
  image,
  overwrite,
  javaversions,
  spark,
}) {
  return axios.post(`${API_URL}/configurations/image/createimage`, {
    platform,
    build,
    release: upgradeMode,
    image,
    overwrite,
    java: javaversions,
    spark,
  });
}

export function sanityCheck({
  cloudVersion,
  incortaCloudLatestOfficialBuildID,
  Type,
}) {
  return axios.post(`${API_URL}/configurations/image/sanityCheck`, {
    cloudVersion,
    incortaCloudLatestOfficialBuildID,
    Type,
  });
}

export function promoteImage({ incortaRelease, Promotion }) {
  return axios.post(`${API_URL}/configurations/image/promoteimage`, {
    incortaRelease,
    Promotion,
  });
}

export function deleteImage(deleteOptions) {
  return axios.post(`${API_URL}/configurations/image/deleteimage`, {
    deleteOptions: {
      release: deleteOptions.upgradeMode,
      build: deleteOptions.build,
    },
  });
}

export function deleteImageByName(imageName) {
  return axios.post(`${API_URL}/configurations/image/deleteimage`, {
    deleteOptions: { imageName },
  });
}

export function getJavaVersions() {
  return axios.get(`${API_URL}/configurations/image/getjavaversions`);
}

export function getImages() {
  return axios.get(`${API_URL}/configurations/image/getimages`);
}
