import { useCallback, useMemo } from 'react';
import { Form, Select, Switch, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import useRequest from 'hooks/useRequest';
import { getFeatureBits } from 'services/featureBit';
import './FeatureFlagsFormField.less';
const { Option } = Select;

function FeatureFlagsFormField({ label, name, onChange, switchRefs }) {
  const form = Form.useFormInstance();
  const { result } = useRequest(
    useCallback(() => getFeatureBits({ page: 1, search: '' }), []),
    { resolve: true },
  );

  const featureBits = useMemo(() => {
    return result?.data?.featureBits;
  }, [result]);

  const tagRender = props => {
    const { label, value, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleOnChangeEnablingFeature = (checked: boolean) => {
      switchRefs.current[value] = checked ? 'enabled' : 'disabled';
      onChange(
        form.getFieldValue(name || 'featureBit')?.map(value => ({
          value,
          enabled: switchRefs.current[value] === 'enabled',
        })),
      );
    };

    return (
      <Tag onMouseDown={onPreventMouseDown} style={{ marginRight: 3 }}>
        <span className="feature-bit-option">
          <span>{label}</span>
          <Switch
            ref={el => (switchRefs.current[value] = el?.innerText)}
            onChange={handleOnChangeEnablingFeature}
            size="small"
            defaultChecked
            checkedChildren="enabled"
            unCheckedChildren="disabled"
          ></Switch>
          <CloseOutlined color="grey" onClick={onClose} />
        </span>
      </Tag>
    );
  };

  return (
    <Form.Item
      label={label || 'Features'}
      rules={[{ required: true, message: 'FeatureBit Name is Required' }]}
      name={name || 'featureBit'}
    >
      <Select
        showArrow
        mode="multiple"
        showSearch
        placeholder="select feature bit"
        optionFilterProp="children"
        notFoundContent={null}
        tagRender={tagRender}
        onChange={selectedValues => {
          return onChange(
            selectedValues?.map(value => ({
              value,
              enabled: true,
            })),
          );
        }}
      >
        {featureBits?.map(featureBit => (
          <Option key={featureBit.id} value={featureBit.id}>
            {featureBit.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default FeatureFlagsFormField;
