import './SizeField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getSizes } from 'services/cluster';

let { Option } = Select;

function SizeField({ name, label }) {
  let { result, isPending } = useRequest(getSizes, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let sizes = result?.data?.sizes;

  return (
    <Form.Item
      className="SizeField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect loading={isPending && !sizes} sizes={sizes} />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, sizes }: any) {
  return (
    <Select
      value={sizes ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="SizeField__select-dropdown"
    >
      {sizes?.map(size => (
        <Option key={size.type} value={size.type}>
          {size.size}
        </Option>
      ))}
    </Select>
  );
}

export default SizeField;
