import axios from 'axios';
import { API_URL } from 'utils/http';

interface IBlueprintParams {
  page?: number;
  search?: string;
  fields?: string[];
}
export function getBlueprints({ page = 1, search, fields }: IBlueprintParams) {
  return axios.get(`${API_URL}/blueprints`, {
    params: { page, search, fields: fields?.join(',') },
  });
}

export function getCMSBlueprints({ fields }: IBlueprintParams) {
  return axios.get(`${API_URL}/blueprints/cms`, {
    params: {
      fields: fields?.join(','),
    },
  });
}

export function updateBlueprint(id, blueprint) {
  let formData = new FormData();
  Object.entries(blueprint).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.put(`${API_URL}/blueprints/${id}/update`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function createBlueprint(blueprint) {
  let formData = new FormData();
  Object.entries(blueprint).forEach(([key, value]: any) => {
    formData.append(key, value);
  });
  return axios.post(`${API_URL}/blueprints/create`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
}

export function deleteBlueprint(id) {
  return axios.delete(`${API_URL}/blueprints/${id}/delete`);
}

export function getAvailableVersions(blueprint) {
  return axios.get(
    `${API_URL}/blueprints/${blueprint?.blueprint?.id}/supportedVersions`,
  );
}

export function copyParquetFiles({
  tenant,
  blueprintId,
  instanceId,
  overwrite,
}) {
  return axios.post(`${API_URL}/blueprints/copy_parquet`, {
    tenant,
    blueprintId,
    instanceId,
    overwrite,
  });
}
