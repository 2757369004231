import { Modal, Form, Space, Spin, Tag } from 'antd';
import '../clustersstatus.less';

const SparkPodDetails = ({ close, name, podStatus, podState }) => {
  let status = podStatus.phase;
  let messageBody = '';

  let messageColor =
    podState === 'loading'
      ? 'yellow'
      : podState === 'running'
      ? 'green'
      : podState === 'starting'
      ? 'blue'
      : 'red';

  if (status === 'Running' || status === 'Succeeded') {
    messageBody = 'Pod is running successfully';
  } else if (status === 'Pending') {
    if (!podStatus.containerStatuses) {
      messageBody = 'Pod is being scheduled in a node';
    } else {
      messageBody = podStatus.containerStatuses[0].state.waiting.reason;
    }
  } else {
    messageBody = `${podStatus.containerStatuses[0].state['terminated'].reason}! Terminated`;
  }

  return (
    <Modal
      className="SparkPodDetails"
      title={name}
      visible
      onCancel={close}
      footer={null}
    >
      <Space>
        {!podStatus ? (
          <Spin />
        ) : (
          <Form name="sparkPodsReason" layout="horizontal">
            <Tag color={messageColor}>{messageBody}</Tag>
          </Form>
        )}
      </Space>
    </Modal>
  );
};

export default SparkPodDetails;
