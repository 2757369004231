import React from 'react';
import './ClusterForm.less';
import { Form, Input, Select } from 'antd';
import AzureLogo from 'images/azure-a-logo.svg';
import AzureSaaSLogo from 'images/azure-saas-logo.svg';
import GoogleCloudLogo from 'images/google-cloud-logo.svg';
import { InstancePlatform, Region } from 'types/cluster';

const { Option, OptGroup } = Select;

function ClusterForm({
  index,
  provisionOptionsIsPending,
  provisionOptions,
  form,
  clusterSizes,
  clusters,
  mapClusterSize,
}) {
  const gcRegions = provisionOptions?.clusterRegions?.filter(
    (region: Region) => region.platform === InstancePlatform.GC,
  );
  const azRegions = provisionOptions?.clusterRegions?.filter(
    (region: Region) => region.platform === InstancePlatform.AZURE,
  );
  const azSaaSRegions = provisionOptions?.clusterRegions?.filter(
    (region: Region) => region.platform === InstancePlatform.AZURE_SAAS,
  );

  return (
    <>
      <Form.Item
        name={['clusters', index, 'name']}
        label="Cluster Name"
        rules={[
          {
            required: true,
            message: 'Cluster Name is required',
          },
          {
            message: 'Cluster Name already used',
            validator: (_, value) => {
              if (
                form
                  .getFieldsValue()
                  ?.clusters?.filter(
                    cluster => value !== '' && cluster.name === value,
                  ).length > 1
              ) {
                return Promise.reject('Cluster Name already used');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Input
          placeholder="IC-AccountName-Environment"
          onChange={e => (clusters[index].name = e.target.value)}
        />
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'userRole']}
        label="User Role"
        rules={[{ required: true, message: 'User Role is required' }]}
      >
        <Select disabled loading={provisionOptionsIsPending}>
          {provisionOptions?.userRoles?.map(role => {
            return (
              <Select.Option key={role} value={role}>
                {role}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'csize']}
        label="Cluster Size"
        rules={[
          { required: true, message: 'Cluster Size is required' },
          {
            message: 'Cluster Size is required',
            validator: (_, value) => {
              if (value === 'Select') {
                return Promise.reject('Cluster Size is required');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => {
            mapClusterSize(form.getFieldValue().clusters);
            clusters[index].csize = value;
          }}
        >
          {clusterSizes?.map(size => {
            return (
              <Select.Option key={size.key} value={size.key}>
                {size.display}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'cluster_code']}
        label="Cluster Region"
        rules={[{ required: true, message: 'Cluster Region is required' }]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => {
            clusters[index].region = value;
          }}
          className="cluster-form__region-select-wrapper"
        >
          <OptGroup
            label={
              <div className="cluster-form__region-select-group-wrapper">
                <img
                  className="cluster-form__region-select-group-img"
                  src={GoogleCloudLogo}
                  alt="Google Cloud"
                />
                <label>Google Cloud</label>
              </div>
            }
          >
            {gcRegions?.map((region: Region) => (
              <Option
                key={`${region.region}--${region.cluster_code}`}
                className="cluster-form__region-select-item"
                value={region.cluster_code}
              >
                <label>{region.regionAlias}</label>
                <label>{region.cluster_code}</label>
              </Option>
            ))}
          </OptGroup>
          <OptGroup
            label={
              <div className="cluster-form__region-select-group-wrapper">
                <img
                  className="cluster-form__region-select-group-img"
                  src={AzureSaaSLogo}
                  alt="Azure-SaaS"
                />
                <label>Azure (SaaS)</label>
              </div>
            }
          >
            {azSaaSRegions?.map((region: Region) => (
              <Option
                key={`${region.region}--${region.cluster_code}`}
                className="cluster-form__region-select-item"
                value={region.cluster_code}
              >
                <label>{region.regionAlias}</label>
                <label>{region.cluster_code}</label>
              </Option>
            ))}
          </OptGroup>
          <OptGroup
            label={
              <div className="cluster-form__region-select-group-wrapper">
                <img
                  className="cluster-form__region-select-group-img"
                  src={AzureLogo}
                  alt="Azure"
                />
                <label>Azure (Private)</label>
              </div>
            }
          >
            {azRegions?.map((region: Region) => (
              <Option
                disabled={true}
                key={`${region.region}--${region.cluster_code}`}
                className="cluster-form__region-select-item"
                value={region.cluster_code}
              >
                <label>{region.regionAlias}</label>
                <label>{region.cluster_code}</label>
              </Option>
            ))}
          </OptGroup>
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'environmentType']}
        label="Environment Type"
        rules={[
          { required: true, message: 'Environment Type is required' },
          {
            message: 'Environment Type is required',
            validator: (_, value) => {
              if (value === 'Select') {
                return Promise.reject('Environment Type is required');
              } else {
                return Promise.resolve(value);
              }
            },
          },
        ]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => {
            let type = form.getFieldValue([
              'clusters',
              index,
              'environmentType',
            ]);
            form.setFieldsValue({
              clusters: form.getFieldsValue()?.clusters?.map((_, i) => ({
                userRole: form.getFieldValue(['clusters', i, 'userRole']),
                csize: form.getFieldValue(['clusters', i, 'csize']),
                region: form.getFieldValue(['clusters', i, 'region']),
                cluster_code: form.getFieldValue([
                  'clusters',
                  i,
                  'cluster_code',
                ]),
                name: form.getFieldValue(['clusters', i, 'name']),
                slaTypeID: 1,
                costTypeID: 1,
                environmentType: form.getFieldValue([
                  'clusters',
                  i,
                  'environmentType',
                ]),
                autoSuspend:
                  index === i
                    ? type === 'Prod'
                      ? 'Never'
                      : '1 Hour'
                    : form.getFieldValue(['clusters', i, 'autoSuspend']),
              })),
            });

            clusters[index].environmentType = value;
            if (type !== 'Prod') {
              clusters[index].autoSuspend = '1 Hour';
            }
          }}
        >
          {provisionOptions?.environmentTypes?.map(type => {
            return (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={['clusters', index, 'autoSuspend']}
        label="Auto Suspend"
        rules={[{ required: true, message: 'Auto Suspend is required' }]}
      >
        <Select
          loading={provisionOptionsIsPending}
          onChange={value => (clusters[index].autoSuspend = value)}
        >
          {provisionOptions?.availableIdleTime?.map(time => {
            return (
              <Select.Option key={time.key} value={time.display}>
                {time.display}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
    </>
  );
}

export default ClusterForm;
