import { useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import useRequest from 'hooks/useRequest';
import { getCMSBlueprints } from 'services/blueprint';

function DataAppFormField({ label, name }) {
  const { result } = useRequest(
    useCallback(() => getCMSBlueprints({ fields: ['name', 'id'] }), []),
    {
      resolve: true,
    },
  );

  const dataApps = useMemo(() => {
    return result?.data?.blueprints?.map(e => ({
      label: e?.name,
      value: e?.id,
    }));
  }, [result]);

  return (
    <Form.Item label={label || 'Data App'} name={name || 'dataApp'}>
      <Select
        showSearch
        placeholder={'select data application'}
        defaultActiveFirstOption={true}
        notFoundContent={null}
      >
        {dataApps &&
          dataApps.map(app => (
            <Select.Option key={app.value} value={app.value}>
              {app.label}
            </Select.Option>
          ))}
      </Select>
    </Form.Item>
  );
}

export default DataAppFormField;
