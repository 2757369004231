import React, { useState } from 'react';
import './NewPartnerModal.less';
import _ from 'lodash';
import {
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
} from 'antd';
import { FormattedMessage } from 'react-intl';
import { AxiosResponse } from 'axios';
import { useGetUserRoleById, useOperators } from 'hooks/queries';
import GoogleCloudLogo from 'images/google-cloud-logo.svg';
import SparkLogo from 'images/spark.svg';
import MLFlowLogo from 'images/ml-flow-logo.svg';
import IncortaOneLogo from 'images/incorta-one-icon.svg';
import { useCreateNewPartner } from 'hooks/partner';
import { NewPartner } from 'types/partner';
import { InstancePlatform } from 'types/cluster';
import { ClusterSizeEnum } from 'hooks/useClusterSize';

const { Option, OptGroup } = Select;

const fieldNames = {
  name: 'name',
  partnerEmail: 'partnerEmail',
  clustersCount: 'clustersCount',
  platforms: 'platforms',
  supportedRegions: 'supportedRegions',
  clustersDefaultSize: 'clustersDefaultSize',
  maxCPU: 'maxCPU',
  maxMemory: 'maxMemory',
  allowSqlX: 'allowSqlX',
  allowMLflow: 'allowMLflow',
  actions: 'actions',
  pages: 'pages',
} as const;

type NewPartnerModalProps = {
  close: () => void;
  isNew: boolean;
  roleId?: number;
};
function NewPartnerModal({ isNew, roleId, close }: NewPartnerModalProps) {
  const [form] = Form.useForm();

  const {
    data: userRoleData,
    isLoading: isUserRoleDataLoading,
    isError: hasUserRoleDataError,
  } = useGetUserRoleById({ roleId });
  const userRolePages = userRoleData?.pages;

  const {
    data: operatorsData,
    isLoading: isOperatorsLoading,
    isError: hasOperatorsErrors,
  } = useOperators();
  const gcEnabledRegions = operatorsData?.filter(
    operator =>
      operator.platform === InstancePlatform.GC &&
      operator.priority.toString() !== '-1',
  );

  const optionsClusterSizes = Object.keys(ClusterSizeEnum).map(
    (key: string) => {
      const label = ClusterSizeEnum[key as keyof typeof ClusterSizeEnum];
      return { label, value: label };
    },
  );

  const {
    mutateAsync: mutateCreateNewPartner,
    isLoading: isCreateNewPartner,
    isError: hasCreateNewPartnerError,
  } = useCreateNewPartner();

  //#region name
  const [, setRoleName] = useState(userRoleData?.name);
  function handleChangeRoleName(e) {
    setRoleName(e.target.value);
  }
  //#endregion

  async function handleFinish() {
    try {
      const newPartner: NewPartner = await form.validateFields();

      const res: AxiosResponse = await mutateCreateNewPartner(newPartner);

      form.resetFields();
      close();
    } catch (error) {
      console.log('Validate Failed:', error);
    }
  }

  return (
    <Modal
      open
      title={
        isNew ? (
          <article className="new-partners-modal__title">
            <img
              // className="NavigationLayout__incorta-one-logo"
              src={IncortaOneLogo}
              alt="Incorta One"
            />
            <FormattedMessage id="partnersPage.partnersModal.title.newPartner" />
          </article>
        ) : (
          <FormattedMessage id="partnersPage.partnersModal.title.editPartner" /> // TODO:
        )
      }
      okText={
        isNew ? (
          <FormattedMessage id="partnersPage.partnersModal.buttons.create" />
        ) : (
          <FormattedMessage id="partnersPage.partnersModal.buttons.update" />
        )
      }
      okButtonProps={{
        loading: isCreateNewPartner,
      }}
      onOk={handleFinish}
      onCancel={close}
      width={720}
      className="new-partners-modal__wrapper"
    >
      {hasUserRoleDataError && (
        <FormattedMessage id="partnersPage.partnersModal.somethingWentWrong" />
      )}
      {isUserRoleDataLoading && !userRolePages ? (
        <div className="spinner-container">
          <Spin
            tip={
              <FormattedMessage id="partnersPage.partnersModal.gettingPartnersData" />
            }
          />
        </div>
      ) : (
        <Form
          initialValues={{
            [fieldNames.name]: '',
            [fieldNames.partnerEmail]: '',
            [fieldNames.clustersCount]: 1,
            [fieldNames.platforms]: 'gc',
            [fieldNames.supportedRegions]: [],
            [fieldNames.maxMemory]: '',
            [fieldNames.maxCPU]: '',
            [fieldNames.allowSqlX]: false,
            [fieldNames.allowMLflow]: false,
          }}
          form={form}
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          name="role"
          onFinish={handleFinish}
          disabled={isCreateNewPartner}
          validateMessages={{
            required: '${label} is required!',
            types: {
              email: 'Please enter a valid email!',
              number: 'Please enter a valid number!',
            },
          }}
        >
          <Form.Item
            name={fieldNames.name}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.name" />
            }
            rules={[{ required: true }]}
          >
            <Input
              type="text"
              onChange={handleChangeRoleName}
              disabled={!isNew}
            />
          </Form.Item>

          <Form.Item
            name={fieldNames.partnerEmail}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.partnerEmail" />
            }
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Valid Email is Required',
              },
            ]}
          >
            <Input
              type="text"
              // onChange={handleChangeRoleName}
              disabled={!isNew}
            />
          </Form.Item>

          <Form.Item
            name={fieldNames.clustersCount}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.clustersCount" />
            }
            rules={[
              {
                required: true,
                min: 1,
                type: 'number',
                message: 'Clusters Count is Required',
              },
            ]}
          >
            <InputNumber
              min={1}
              // onChange={handleChangeRoleName}
            />
          </Form.Item>

          <Form.Item
            name={fieldNames.platforms}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.platforms" />
            }
            rules={[{ required: true, message: 'Platform is Required' }]}
          >
            <Select
              disabled
              options={[
                {
                  value: 'gc',
                  label: (
                    <section className="new-partners-modal__platform-wrapper">
                      <img
                        className="new-partners-modal__platform-select-group-img"
                        src={GoogleCloudLogo}
                        alt="Google Cloud"
                      />
                      <label>Google Cloud</label>
                    </section>
                  ),
                },
                { value: 'az', label: 'Azure' },
              ]}
            />
          </Form.Item>

          <Form.Item
            name={fieldNames.supportedRegions}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.supportedRegions" />
            }
            rules={[{ required: true, message: 'Region is Required' }]}
          >
            <Select
              placeholder="Please select"
              mode="multiple"
              loading={isOperatorsLoading}
              disabled={hasOperatorsErrors}
            >
              <OptGroup
                label={
                  <div className="new-partners-modal__supported-regions-select-label">
                    <img
                      className="new-partners-modal__region-select-img"
                      src={GoogleCloudLogo}
                      alt="Google Cloud"
                    />
                    <label>Google Cloud</label>
                  </div>
                }
              >
                {gcEnabledRegions?.map(region => (
                  <Option
                    key={`${region.region}--${region.cluster_code}`}
                    className="new-partners-modal__region-select-item"
                    value={region.cluster_code}
                  >
                    <label>{region.alias}</label>
                    <label>{region.cluster_code}</label>
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>

          <Form.Item
            name={fieldNames.clustersDefaultSize}
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.clustersDefaultSize" />
            }
            rules={[{ required: true, message: 'Cluster Size is Required' }]}
          >
            <Select placeholder="Please select" options={optionsClusterSizes} />
          </Form.Item>

          <Divider />

          <section className="new-partners-modal__section">
            <h3 className="new-partners-modal__section-title">
              <img src={SparkLogo} alt="Spark" />
              Spark Quotas
            </h3>
            <Form.Item
              name={fieldNames.maxCPU}
              label={
                <FormattedMessage id="partnersPage.partnersModal.fields.maxCPU" />
              }
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: 'Max CPU is Required',
                },
              ]}
            >
              <InputNumber
                min={1}
                // onChange={handleChangeRoleName}
              />
            </Form.Item>
            <Form.Item
              name={fieldNames.maxMemory}
              label={
                <FormattedMessage id="partnersPage.partnersModal.fields.maxMemory" />
              }
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: 'Max Memory is Required',
                },
              ]}
            >
              <InputNumber
                min={1}
                // onChange={handleChangeRoleName}
              />
            </Form.Item>
          </section>

          <Divider />

          <Form.Item
            name={fieldNames.allowSqlX}
            valuePropName="checked"
            label={
              <FormattedMessage id="partnersPage.partnersModal.fields.allowSqlX" />
            }
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            className="new-partners-modal__ml-flow-form-item"
            name={fieldNames.allowMLflow}
            valuePropName="checked"
            label={
              <>
                <img src={MLFlowLogo} alt="MLflow" />
                <FormattedMessage id="partnersPage.partnersModal.fields.allowMLflow" />
              </>
            }
          >
            <Checkbox />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

export default NewPartnerModal;
