import React, { useEffect } from 'react';
import './DebouncedSelectSearch.less';
import { SelectProps, Select } from 'antd';

// A debounced select search react component
function DebouncedSelectSearch<T>({
  value: initialValue,
  currentSearchInput,
  setCurrentSearchInput,
  selectedSearchResult,
  setSelectedSearchResult,
  options,
  onSearch,
  debounce = 500,
  placeholder,
  loading,
  prefix,
  ...props
}: SelectProps & {
  value: string;
  currentSearchInput: string;
  setCurrentSearchInput: React.Dispatch<React.SetStateAction<string>>;
  selectedSearchResult: string;
  setSelectedSearchResult: React.Dispatch<React.SetStateAction<string>>;
  options: T[];
  onSearch: (value: string) => void;
  debounce?: number;
  placeholder?: string;
  loading: boolean;
  prefix?: any;
}) {
  function handleSearchCluster(value: string): void {
    if (value) {
      setCurrentSearchInput(value);
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSearch(currentSearchInput);
    }, debounce);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearchInput]);

  function handleChangeSelectedCluster(value: string): void {
    setSelectedSearchResult(value);
  }

  return (
    <Select
      className="debounced-select-search"
      showSearch
      value={selectedSearchResult}
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearchCluster}
      onChange={handleChangeSelectedCluster}
      notFoundContent={null}
      options={options}
      loading={loading}
      {...(props.disabled ? { disabled: props.disabled } : {})}
    />
  );
}

export default DebouncedSelectSearch;
