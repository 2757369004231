import axios from 'axios';
import { API_URL } from 'utils/http';

export function getAuditHistory({
  page = 1,
  search,
  userid = null,
  instanceid = null,
}) {
  return axios.get(`${API_URL}/audit`, {
    params: { page, search, userid, instanceid },
  });
}

export function exportLogsForAnalayzer(payload) {
  return axios.post(`${API_URL}/analyze_logs`, payload);
}
