import './LogsModal.less';

import React, { useState } from 'react';
import { Input, Modal, Form, Radio, Button, Space, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { collectLogs, sendLogsToCloudProvider } from 'services/cluster';
import { showError } from 'utils/errors';

function LogsModal({ cluster, refetchClusters, close }) {
  let { name } = cluster ?? {};
  let [isLoading, setIsLoading] = useState(false);
  let [url, setUrl] = useState('');
  const [form] = Form.useForm();

  async function handleFinish(cluster) {
    setIsLoading(true);
    const getClusterLogs =
      form.getFieldValue('function') === 'true'
        ? collectLogs(name, form.getFieldValue('service'))
        : sendLogsToCloudProvider(name, form.getFieldValue('service'));
    getClusterLogs
      .then(response => {
        if (response.status > 500) {
          showError('Please ask support for the logs URL');
          close();
        } else if (response.status >= 400) {
          showError(response.data.message);
        }
        setIsLoading(false);
        // setUrl(response.data.url);
        if (form.getFieldValue('function') === 'true') {
          // collect logs case
          message.info(response.data.message);
          close();
        } else {
          setUrl(response.data.url);
        }
      })
      .catch(error => {
        if (error.status > 500)
          showError('Please ask support for the logs URL');
        else showError(error);
        setIsLoading(false);
        close();
      });
    refetchClusters();
  }

  function copy() {
    /* Get the text field */
    var copyText: any = document.getElementById('myInput');

    /* Select the text field */
    copyText.select();

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    close();
  }

  return (
    <Modal visible title={'Cluster Logs'} footer={null} onCancel={close}>
      <Form
        form={form}
        initialValues={{
          name,
          function: 'true',
          service: 'Analytics',
        }}
        layout="vertical"
        name="cluster"
      >
        {!url ? (
          <div>
            <Form.Item name="function" label="Action on Logs">
              <Radio.Group>
                <Radio value="true">Download Logs</Radio>
                <Radio value="false">Send Logs To GCP</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item name="service" label="Service Type">
              <Radio.Group>
                <Radio value="Analytics">Analytics</Radio>
                <Radio value="Loader">Loader</Radio>
              </Radio.Group>
            </Form.Item>

            <Space>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={handleFinish}
              >
                {'Execute'}
              </Button>
              <Button onClick={close}>Cancel</Button>
            </Space>
          </div>
        ) : (
          <div>
            <p className="copiedText__custom-warning">
              <ExclamationCircleOutlined />
              {`You should restart the cluster to be able to see the logs in GCP`}
            </p>
            <Input
              type="text"
              value={url}
              id="myInput"
              className="copiedText"
            ></Input>
            <br></br>
            <Space>
              <Button type="primary" onClick={copy}>
                Copy to clipboard and close
              </Button>
            </Space>
          </div>
        )}
      </Form>
    </Modal>
  );
}

export default LogsModal;
