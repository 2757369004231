import { useEffect, useCallback } from 'react';
import usePromiseRunner from './usePromiseRunner';
import { getErrorMessage, showErrorMessage } from 'utils/errors';

let cache = {};

function useRequest(
  service,
  {
    useCache = false,
    resolve = false,
    handleError = false,
    initialResult = useCache ? cache[service.name] : null,
  } = {},
) {
  const {
    isRest,
    isPending,
    isRejected,
    status,
    setResult,
    result,
    error,
    run,
  } = usePromiseRunner(initialResult);

  const errorMessage = error ? getErrorMessage(error) : null;

  const makeRequest = useCallback(
    (...args) => run(service(...args)),
    [run, service],
  );

  useEffect(() => {
    if (useCache && result) {
      cache[service.name] = result;
    }
  }, [result, service.name, useCache]);

  useEffect(() => {
    if (resolve) {
      makeRequest();
    }
  }, [makeRequest, resolve]);

  useEffect(() => {
    if (handleError && isRejected && errorMessage) {
      showErrorMessage(errorMessage);
    }
  }, [handleError, isRejected, errorMessage]);

  return {
    isRest,
    isPending,
    isRejected,
    status,
    setResult,
    result,
    error,
    errorMessage,
    run,
    makeRequest,
  };
}

export default useRequest;
