import { useCallback } from 'react';
import { Modal, Timeline } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import ClustersActions from '../Constants/milestoneActions.json';
import useRequest from 'hooks/useRequest';
function ClusterActionsModal({
  entity,
  refetchClusterActions,
  close,
  service,
}) {
  let { id, action } = entity ?? {};
  let milestone = [];

  let { makeRequest } = useRequest(
    useCallback(
      async _configuration => {
        await refetchClusterActions();
      },
      // eslint-disable-next-line
      [id, refetchClusterActions],
    ),
    {
      handleError: true,
    },
  );
  if (service === 'analytics') {
    let { analyticsStatus } = entity ?? {};
    milestone = analyticsStatus;
  }
  if (service === 'loader') {
    let { loaderStatus } = entity ?? {};
    milestone = loaderStatus;
  }
  if (service === 'cmc') {
    let { cmcStatus } = entity ?? {};
    milestone = cmcStatus;
  }
  if (milestone) {
    let len = milestone.length;
    if (Object.keys(ClustersActions[action]).length != len) {
      let diff = Object.keys(ClustersActions[action]).length - len;
      for (let i = 0; i < diff; i += 1) {
        len = milestone.length;
        let c = len == 0 ? 0 : milestone[len - 1].name;
        milestone.push({ name: `${Number(c) + 1}` });
      }
    }
  }

  const timeFormat = duration => {
    let formattedDuration = duration;
    let unit = 'sec';
    if (duration > 60) {
      formattedDuration = Math.floor(duration / 60);
      unit = 'min';
    }
    return `${formattedDuration} ${unit}`;
  };
  return (
    <Modal visible title={'Milestone Timeline'} footer={null} onCancel={close}>
      <Timeline mode="left" key="name" style={{ width: '85%' }}>
        {milestone?.map(item => {
          let displayName = item.name;
          let milestoneAction = ClustersActions[action];
          let name = milestoneAction[displayName];
          let color = item.status
            ? item.status == 'SUCCESS'
              ? '#008b02'
              : item.status == 'FAILED'
              ? '#b80000'
              : '#FFD500'
            : 'gray';
          let dot =
            item.status == 'SUCCESS' ? (
              <CheckCircleOutlined />
            ) : item.status == 'IN PROGRESS' ? (
              <ClockCircleOutlined />
            ) : (
              <CloseCircleOutlined />
            );

          return (
            <Timeline.Item
              color={color}
              children={name}
              label={
                item.duration
                  ? `${timeFormat(item.duration)}`
                  : color == 'blue'
                  ? 'In progress'
                  : ''
              }
              position="left"
              dot={dot}
            />
          );
        })}
      </Timeline>
    </Modal>
  );
}
export default ClusterActionsModal;
