export interface Heap {
  track: (event: string, properties?: Object) => void;
  identify: (identity: string) => void;
  resetIdentity: () => void;
  addUserProperties: (properties: Object) => void;
  addEventProperties: (properties: Object) => void;
  removeEventProperty: (property: string) => void;
  clearEventProperties: () => void;
  appid: string;
  userId: string;
  identity: string | null;
  config: any;
}

export enum TrackingEventsEnum {
  SUBMIT_SIGN_IN_EMAIL = 'Successful SignIn Email Submission',
  SUBMIT_SIGN_IN_WITH_PASSWORD = 'Successful SignIn Password Submission',
  SUBMIT_SIGN_UP_WITH_EMAIL = 'Successful SignUp Email Submission',
  SUBMIT_SIGN_UP_WITH_PASSWORD = 'Successful SignUp Password Submission',
  SUBMIT_PASSWORD_RECOVERY_EMAIL = 'Successful PW Recovery Email Submission',
  SUBMIT_PASSWORD_RECOVERY_NEW_PASSWORD = 'Successful PW Recovery Password Submission',
}
