import { useCallback, useMemo } from 'react';
import { Form, Select } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import useRequest from 'hooks/useRequest';
import { getClusters } from 'services/cluster';

const { Option } = Select;

function ClusterFormField({ label, name, onSelect }) {
  const { result, makeRequest: fetchClusters } = useRequest(
    useCallback(({ page, search }) => getClusters({ page, search }), []),
  );

  const clusters = useMemo(() => {
    return result?.data?.instances;
  }, [result]);

  const handleSearch = useDebouncedCallback(search => {
    fetchClusters({ search });
  }, 200).callback;

  return (
    <Form.Item
      label={label || 'Cluster Name'}
      rules={[{ required: true, message: 'Cluster Name is Required' }]}
      name={name || 'cluster'}
    >
      <Select
        showSearch
        placeholder="search by cluster name"
        optionFilterProp="children"
        onSearch={handleSearch}
        showArrow={false}
        notFoundContent={null}
        onSelect={(val, option) => {
          onSelect(clusters.find(e => e.id === option.value));
        }}
      >
        {clusters?.map(cluster => (
          <Option key={cluster.id} value={cluster.id}>
            {cluster.name}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default ClusterFormField;
