import { useEffect, useState } from 'react';
import { Button, Modal, Tooltip, Typography, notification } from 'antd';
import moment from 'moment';

import {
  DatabaseOutlined,
  UserOutlined,
  FieldTimeOutlined,
  HomeOutlined,
  MailOutlined,
  CloudServerOutlined,
  ClusterOutlined,
  FundViewOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import './Home.less';
import { useGetBlueprints } from 'utils/home';

let { Title } = Typography;

function Home() {
  let [generalInfo, setGeneralInfo] = useState<any>(null);

  const { data, isLoading, isError } = useGetBlueprints();
  useEffect(() => {
    if (!isLoading && !isError && data) {
      setGeneralInfo(data?.data);
    }
  }, [data, isError, isLoading]);

  const [isGmonitorCredentialsModalOpen, setIsGmonitorCredentialsModalOpen] =
    useState(false);

  function copyCredential(text: string) {
    navigator.clipboard.writeText(text);
    notification.success({
      message: 'copied',
    });
  }

  return (
    <div className="Home">
      <div className="gmonitor-container">
        <InfoCircleOutlined />
        <div>
          <div>
            <Title level={4}>
              For more details about cloud clusters you can access{' '}
              <a href="https://gmonitor.cloud2.incorta.com/">Gmonitor Cloud </a>
            </Title>
          </div>
          <div className="gmonitor-info">
            <Title level={4}>Tenant:</Title>
            <Title level={4}>demo</Title>
          </div>
          <div className="gmonitor-info">
            <Title level={4}>Username:</Title>
            <Title level={4}>Admin</Title>
          </div>
          <div className="gmonitor-info">
            <Title level={4}>Password:</Title>
            <Title level={4}>inc0rCl0ud$ro8By</Title>
          </div>
          <Button
            className="reveal-credentials-button"
            type="primary"
            icon={<EyeOutlined color="red" />}
            onClick={() => setIsGmonitorCredentialsModalOpen(true)}
          >
            Reveal Credentials
          </Button>
          <Modal
            open={isGmonitorCredentialsModalOpen}
            title={'Gmonitor Credentials'}
            footer={null}
            className="gmonitor-credentials-modal__wrapper"
            onCancel={() => setIsGmonitorCredentialsModalOpen(false)}
          >
            <div className="gmonitor-info">
              <Title level={4}>Tenant:</Title>
              <Title level={4}>demo</Title>
              <Tooltip title="copy tenant">
                <CopyOutlined
                  className="copy-icon"
                  onClick={() => copyCredential('demo')}
                />
              </Tooltip>
            </div>
            <div className="gmonitor-info">
              <Title level={4}>Username:</Title>
              <Title level={4}>Admin</Title>
              <Tooltip title="copy username">
                <CopyOutlined
                  className="copy-icon"
                  onClick={() => copyCredential('Admin')}
                />
              </Tooltip>
            </div>
            <div className="gmonitor-info">
              <Title level={4}>Password:</Title>
              <Title level={4}>inc0rCl0ud$ro8By</Title>
              <Tooltip title="copy password">
                <CopyOutlined
                  className="copy-icon"
                  onClick={() => copyCredential('inc0rCl0ud$ro8By')}
                />
              </Tooltip>
            </div>
          </Modal>
        </div>
      </div>
      {generalInfo && (
        <div className="home-container">
          <div className="count-section">
            <div className="count-info running-cluster">
              <ClusterOutlined />
              <div>
                <span>Running Clusters</span>{' '}
                <span className="count">
                  {generalInfo.currentRunningClusters || 0}
                </span>
              </div>
            </div>
            <div className="count-info created-cluster">
              <DatabaseOutlined />
              <div>
                <span>Created Clusters </span>{' '}
                <span className="count">
                  {generalInfo.createdClusters || 0}
                </span>
              </div>
            </div>
            <div className="count-info verified-user">
              <UserOutlined />
              <div>
                <span>Verified Users</span>{' '}
                <span className="count">
                  {generalInfo.signedUsers.verified || 0}
                </span>
              </div>
            </div>

            <div className="count-info unverified-user">
              <UserOutlined />
              <div>
                <span>Unverified Users</span>{' '}
                <span className="count">
                  {generalInfo.signedUsers.unverified || 0}
                </span>
              </div>
            </div>
          </div>
          {generalInfo.recentLoggedInUsers.length > 0 && (
            <div className="users-card">
              <Title level={3}>Recently Logged In Users</Title>
              <div className="card">
                {generalInfo.recentLoggedInUsers.map(user => (
                  <div className="card-container" key={user.id}>
                    <Tooltip placement="top" title={user.fullName}>
                      <span className="name">{user.fullName}</span>
                    </Tooltip>
                    <div>
                      <MailOutlined /> <span>{user.email}</span>
                    </div>
                    <div>
                      <HomeOutlined />
                      <span>
                        Working at <strong>{user.organization}</strong>
                      </span>
                    </div>
                    <div>
                      {' '}
                      <FieldTimeOutlined />
                      <span>
                        Logged in at{' '}
                        <strong>
                          {user.lastLoginAt
                            ? moment(user.lastLoginAt)
                                .utcOffset(user.lastLoginAt)
                                .format('DD/MM/YYYY HH:mm')
                            : ''}
                        </strong>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {generalInfo.recentInstances.length > 0 && (
            <div className="clusters-card">
              <Title level={3}>Recently Created Clusters</Title>
              <div className="card">
                {generalInfo.recentInstances.map(instance => (
                  <div className="card-container" key={instance.id}>
                    <Tooltip placement="top" title={instance.name}>
                      <span className="name">{instance.name}</span>
                    </Tooltip>
                    <div>
                      <HomeOutlined />
                      <span>
                        At <strong>{instance.organization}</strong>
                      </span>
                    </div>
                    <div>
                      <UserOutlined />
                      <span>
                        Used by <strong>{instance.user.fullName}</strong>
                      </span>
                    </div>
                    <div>
                      <FundViewOutlined />
                      <span>
                        Status is <strong>{instance.status}</strong>
                      </span>
                    </div>
                    <div>
                      <CloudServerOutlined />
                      <span>
                        Version is{' '}
                        <strong>
                          {instance.customBuildName ||
                            instance.customBuild ||
                            instance.image}
                        </strong>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
