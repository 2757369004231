import React, { useState, useContext } from 'react';
import { Typography, Tag, Button, Tooltip, Space } from 'antd';
import { useHistory } from 'react-router-dom';

import { formatISO } from 'date-fns';
import { EditOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import ToggleFeatureModal from './ToggleFeatureModal/ToggleFeatureModal';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { useGetToggleFeatures } from 'hooks/queries';
import { SessionContext } from 'auth/SessionProvider';
import {
  userRolesPages,
  userRolesPagesPermissionsValues,
  userRolesActions,
} from 'types/user';

const { Title } = Typography;

function ToggleFeatures() {
  const [page, setPage] = useState(1);
  const [search] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const history = useHistory();
  const { user } = useContext(SessionContext);

  const isEditDisabled =
    +user.userRolesPagesPermissions?.[userRolesPages.TOGGLE_FEATURES] <
    +userRolesPagesPermissionsValues.CAN_EDIT;
  const hasAssignUserActionPermission =
    userRolesActions.ASSIGN_UNASSIGN_USER_TOGGLE_FEATURE in
    user.userRolesActions;

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchToggleFeatures,
  } = useGetToggleFeatures({ page });

  const toggleFeatures = result?.data?.toggleFeatures;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Released for Selected Users',
      render(_, toggleFeature) {
        return (
          <span>
            {toggleFeature.toggle ? (
              <Tag color="green">Enabled</Tag>
            ) : (
              <Tag color="red">Disabled</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Released for Everyone',
      render(_, toggleFeature) {
        return (
          <span>
            {toggleFeature.published ? (
              <Tag color="green">Enabled</Tag>
            ) : (
              <Tag color="red">Disabled</Tag>
            )}
          </span>
        );
      },
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },

    {
      title: 'Action',
      render(_, toggleFeature) {
        return (
          <Space>
            <Tooltip
              title={
                isEditDisabled
                  ? 'You don❜t have sufficient permission to edit user'
                  : 'Edit'
              }
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ toggleFeature });
                }}
                disabled={isEditDisabled}
              >
                <EditOutlined />
              </Button>
            </Tooltip>

            <Tooltip
              title={
                !hasAssignUserActionPermission
                  ? 'You don❜t have sufficient permission to edit user'
                  : toggleFeature?.published
                  ? `${toggleFeature.name} is already released for everyone`
                  : `Users`
              }
            >
              <Button
                disabled={
                  toggleFeature?.published || !hasAssignUserActionPermission
                }
                size="small"
                onClick={() => {
                  history.push(
                    `/togglefeatures/${toggleFeature.key}?value=${toggleFeature.name}`,
                  );
                }}
              >
                <UsergroupAddOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ToggleFeatures">
      <Title>Toggle Features</Title>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={toggleFeatures}
        loading={isPending}
        columns={columns}
      />
      {modalProps && (
        <ToggleFeatureModal
          {...modalProps}
          refetchToggleFeatures={refetchToggleFeatures}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default ToggleFeatures;
