import { Table, Typography } from 'antd';

const dataSource = [
  { name: 'small', label: 'XSmall', ipu: 0.5 },
  { name: 'medium', label: 'Small', ipu: 1 },
  { name: 'larges1', label: 'Medium', ipu: 2 },
  { name: 'larges2', label: 'Large', ipu: 4 },
  { name: 'larges3', label: 'XLarge', ipu: 8 },
  { name: 'larges4', label: '2XLarge', ipu: 16 },
  { name: 'large3x', label: '3XLarge', ipu: 24 },
  { name: 'larges5', label: '4XLarge', ipu: 28 },
  { name: 'larges6', label: '6XLarge', ipu: 32 },
  { name: 'larges7', label: '10XLarge', ipu: 64 },
];

const columns = [
  {
    title: 'Size Name',
    dataIndex: 'name',
  },
  {
    title: 'Size Label',
    dataIndex: 'label',
  },
  {
    title: 'Incorta Power Units (GB)',
    dataIndex: 'ipu',
  },
];

function ClusterSizes() {
  const { Title } = Typography;

  return (
    <div className="cluster-sizes__wrapper">
      <div className="tableTitle">
        <Title>Cluster Sizes</Title>
      </div>
      <Table
        rowKey="name"
        className="antd-table-customized-scroll"
        bordered
        // pagination={pagination}
        dataSource={dataSource}
        // loading={loading}
        columns={columns}
      />
    </div>
  );
}

export default ClusterSizes;
