import React, { useState, useCallback } from 'react';
import { Modal, Form, Switch, Space, Button } from 'antd';
import { copyParquetFiles } from '../../../services/blueprint';
import ClusterNameFormField from './ClusterNameFormField/ClusterNameFormField';
import DataAppFormField from './DataAppFormField/DataAppFormField';
import TenantFormField from './TenantFormField/TenantFormField';
import useRequest from 'hooks/useRequest';
import { showSuccessMessage } from 'utils/errors';

function CopyParquetFilesModal({ close }) {
  const [form] = Form.useForm();
  const [cluster, setCluster] = useState('');

  let { makeRequest: copyParquetFilesPOST, isPending } = useRequest(
    useCallback(async payload => {
      await copyParquetFiles(payload);
    }, []),
    {
      handleError: true,
    },
  );

  const handleSubmit = async payload => {
    await copyParquetFilesPOST(payload);
    close();
    showSuccessMessage('Parquet Files Copied Successfully!');
  };

  return (
    <Modal visible title={'Copy Parquet Files'} footer={null} onCancel={close}>
      <Form
        onFinish={handleSubmit}
        initialValues={{
          instanceId: '',
          tenant: '',
          blueprintId: '',
          overwrite: false,
        }}
        layout="vertical"
        name="CopyParquetForm"
        form={form}
      >
        <ClusterNameFormField
          name="instanceId"
          label="Cluster Name"
          onSelect={cluster => {
            setCluster(cluster);
          }}
        />
        <TenantFormField name="tenant" label="Tenant Name" cluster={cluster} />
        <DataAppFormField label={'Data Application'} name={'blueprintId'} />
        <Form.Item label="Overwrite files" name="overwrite">
          <Switch />
        </Form.Item>

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            Copy Parquet Files
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default CopyParquetFilesModal;
