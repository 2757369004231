import React, { useContext, useEffect, useState } from 'react';
import './NotebookUsersCount.less';
import { Alert, Button, Slider, Tooltip, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import {
  useGetNotebookUsersConfigs,
  useUpdateNotebookUsersConfigs,
} from 'hooks/queries';
import { Spinner } from 'components/Spinner/Spinner';
import { userRolesActions } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import { NotebookUsersConfig } from 'types/notebook';

function NotebookUsersCount() {
  const { user } = useContext(SessionContext);
  const canUserEdit =
    userRolesActions.UPDATE_CHIDORI_SIZES_TABLE in user.userRolesActions;

  let { Title } = Typography;

  const [dataSource, setDataSource] = useState<NotebookUsersConfig[]>([]);
  const {
    data: notebookUsersConfigsData,
    isLoading,
    isError,
  } = useGetNotebookUsersConfigs({});
  useEffect(() => {
    setDataSource(notebookUsersConfigsData);
  }, [notebookUsersConfigsData]);

  const { mutateAsync: mutateUpdateNotebookUsersConfigs } =
    useUpdateNotebookUsersConfigs();

  const marks = notebookUsersConfigsData?.reduce((acc, item) => {
    acc[item.sizeValue] = (
      <div className="mark__wrapper">
        <label className="mark__mark-name">{item.size}</label>
        <label className="mark__mark-original">Original</label>
      </div>
    );
    return acc;
  }, {});

  function handleSliderChange(value, index) {
    if (index === 0) {
      if (value < dataSource[1].sizeValue) {
        let newDataSource = structuredClone(dataSource);
        newDataSource.shift();
        newDataSource = [
          { ...dataSource[0], sizeValue: value },
          ...newDataSource,
        ];
        setDataSource(newDataSource);
      }
    } else if (index === dataSource.length - 1) {
      if (value > dataSource[index - 1].sizeValue) {
        let newDataSource = structuredClone(dataSource);
        newDataSource.pop();
        newDataSource = [
          ...newDataSource,
          { ...dataSource[index], sizeValue: value },
        ];
        setDataSource(newDataSource);
      }
    } else {
      if (
        value > dataSource[index - 1].sizeValue &&
        value < dataSource[index + 1].sizeValue
      ) {
        const modifiedItem = { ...dataSource[index], sizeValue: value };
        const firstPart = dataSource.slice(0, index);
        const secondPart = dataSource.slice(index + 1);
        const newDataSource = [...firstPart, modifiedItem, ...secondPart];
        setDataSource(newDataSource);
      }
    }
  }

  async function handleModifyNotebookUsersSizes() {
    const modifiedNotebookUsersSizes = [];

    notebookUsersConfigsData.forEach(
      (originalItem: NotebookUsersConfig, i: number) => {
        if (originalItem.sizeValue !== dataSource[i].sizeValue) {
          modifiedNotebookUsersSizes.push({
            name: originalItem.size,
            memory: dataSource[i].sizeValue,
          });
        }
      },
    );
    if (modifiedNotebookUsersSizes.length) {
      try {
        const res = await mutateUpdateNotebookUsersConfigs({
          notebookUsersSizes: modifiedNotebookUsersSizes,
        });
      } catch (error) {
        //
      }
    }
  }

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="notebook-sizes__wrapper">
      <article className="tableTitle">
        <Title>Business Notebook Users Count</Title>
      </article>
      <section className="notebook-sizes__items">
        {dataSource?.map((d, index) => {
          return (
            <section key={d.size} className="notebook-sizes__item">
              <section className="notebook-sizes__item-info">
                <h3>{d.size}</h3>
                <label>{d.sizeValue} Users</label>
              </section>
              <Tooltip
                title={
                  !canUserEdit ? 'User does not have required role to edit' : ''
                }
              >
                <Slider
                  value={d.sizeValue}
                  min={1}
                  marks={marks}
                  max={100}
                  onChange={value => handleSliderChange(value, index)}
                  disabled={!canUserEdit}
                />
              </Tooltip>
              <br />
            </section>
          );
        })}
        {dataSource && (
          <Button
            type="primary"
            icon={<SaveOutlined />}
            onClick={handleModifyNotebookUsersSizes}
          >
            {'Save Sizes'}
          </Button>
        )}
      </section>
    </div>
  );
}

export default NotebookUsersCount;
