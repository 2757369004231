import React, { useState, useCallback, useContext } from 'react';
import { Typography, Button, Tooltip, Space } from 'antd';
import { format } from 'date-fns';
import { UserOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import UserModal from '../Users/UserModal/UserModal';
import useRequest from 'hooks/useRequest';
import { getProvisionedUsers } from 'services/user';
import { SessionContext } from 'auth/SessionProvider';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import RolesTag from 'components/RolesTag/RolesTag';

const { Title } = Typography;

function ProvisionUsers() {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const {
    result,
    isPending,
    makeRequest: refetchUsers,
  } = useRequest(
    useCallback(() => getProvisionedUsers({ page, search }), [page, search]),
    { resolve: true },
  );

  const users = result?.data?.users;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;
  const { user } = useContext(SessionContext);

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      render(_, userItem) {
        return (
          <RolesTag key={userItem.id} user={userItem} roles={userItem.roles} />
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'Last Login At',
      dataIndex: 'lastLoginAt',
      render(date) {
        return format(new Date(date), 'dd-MM-yyyy hh:mm a');
      },
    },
    {
      title: 'User Info',
      render(_, userItem) {
        return (
          <Space>
            <Tooltip title="User Info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  history.push(
                    `/accounting/provisionuserdetails/${userItem.id}`,
                  );
                }}
              >
                <UserOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const searchColumns = [
    'Full Name',
    'Email',
    'Company',
    'Cost Center',
    'Organization',
    'Created At',
    'Role',
    'User Role',
  ];

  return (
    <div className="Users">
      <div className="ProvisionCustomer">
        <div className="tableTitle">
          <Title>Provision Users</Title>

          {+user.userRolesPagesPermissions?.[userRolesPages.PROVISION_USERS] >=
            +userRolesPagesPermissionsValues.CAN_EDIT && (
            <Tooltip title="New">
              <Link to={`/provision-customer/new`}>
                <Button type="primary" icon={<PlusOutlined />}>
                  Provision New User
                </Button>
              </Link>
            </Tooltip>
          )}
        </div>
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        dataSource={users}
        loading={isPending}
        columns={columns}
        advancedSearchColumns={searchColumns}
        type="Clusters"
        searchPlaceholder={
          "Search By Email, Name, Comapny Or 'Field Name'=Some Value."
        }
      />
      {modalProps && (
        <UserModal
          {...modalProps}
          refetchUsers={refetchUsers}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default ProvisionUsers;
