import React, { useCallback, useContext } from 'react';
import { Modal, Form, Input, Button, Space } from 'antd';
import { updateSparkImage } from 'services/sparkimages';
import useRequest from 'hooks/useRequest';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

function SparkEditImageModal({ image, refetchSparkImages, close }) {
  const { user } = useContext(SessionContext);

  const { id, minVersion, maxVersion } = image;
  const { makeRequest, isPending } = useRequest(
    useCallback(
      async image => {
        await updateSparkImage(id, image);
        await refetchSparkImages();
      },

      [refetchSparkImages, id],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(image) {
    try {
      await makeRequest(image);
    } finally {
      refetchSparkImages();
      close();
    }
  }

  return (
    <Modal visible title={'Edit Spark Image'} footer={null} onCancel={close}>
      <Form
        initialValues={{
          minVersion,
          maxVersion,
        }}
        layout="vertical"
        name="Image"
        onFinish={handleFinish}
      >
        <Form.Item name="minVersion" label="Minimum Incorta Version">
          <Input />
        </Form.Item>
        <Form.Item name="maxVersion" label="Maximum Incorta Version">
          <Input />
        </Form.Item>
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={isPending}
            disabled={
              +user.userRolesPagesPermissions?.[userRolesPages.IMAGES] <
              +userRolesPagesPermissionsValues.CAN_EDIT
            }
          >
            {'Edit'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default SparkEditImageModal;
