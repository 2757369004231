import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { format } from 'date-fns';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { SessionContext } from 'auth/SessionProvider';
import { useGetAllChidoris, useUpgradeChidori } from 'hooks/queries';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
const { Title } = Typography;
import { seachableTablePageSizeOptions } from 'utils/constants';

import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { ChidoriItem } from 'types/chidori';

function Chidoris() {
  const { user } = useContext(SessionContext);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);

  const {
    data: result,
    isLoading: isPending,
    refetch: refetchChidoris,
  } = useGetAllChidoris({ page, limit, search });
  const chidoris = result?.data?.chidoris;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const {
    mutateAsync: mutateUpgradeChidori,
    isLoading: isUpgradeChidoriLoading,
    isError: hasUpgradeChidoriError,
  } = useUpgradeChidori(user.uuid);

  const columns: ColumnsType<ChidoriItem> = [
    {
      title: 'Name',
      dataIndex: 'instanceName',
      render(_, chidori) {
        return chidori.instanceName;
      },
    },
    {
      title: 'Upgrade Status',
      dataIndex: ' upgradeStatus',
      render(_, chidori) {
        return chidori.upgradeStatus;
      },
    },
    {
      title: 'Last Successfully Upgraded At',
      dataIndex: 'upgradedAt',
      render(date) {
        return date ? format(new Date(date), 'dd-MM-yyyy hh:mm a') : 'Never';
      },
    },
    {
      title: 'Actions',
      render(_, chidori) {
        return (
          <Space>
            {' '}
            {+user?.userRolesPagesPermissions?.[userRolesPages.CHIDORI] >=
              +userRolesPagesPermissionsValues.CAN_EDIT && (
              <Tooltip
                title={
                  chidori.upgradeStatus === 'in-progress'
                    ? 'Upgrade in progress'
                    : chidori.instanceStatus === 'running'
                    ? 'Upgrade Chidori'
                    : 'Instance is not running'
                }
              >
                <Button
                  size="small"
                  type="primary"
                  disabled={chidori.instanceStatus !== 'running'}
                  loading={chidori.upgradeStatus === 'in-progress'}
                  icon={<CloudDownloadOutlined />}
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to upgrade chidori for
                          <strong> {chidori.instanceName} </strong>?
                        </>
                      ),
                      async onOk() {
                        await mutateUpgradeChidori(chidori);
                        await refetchChidoris();
                      },
                    });
                  }}
                ></Button>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="ChidoriUpgrade">
      <Title>Chidori Upgrade</Title>
      <SearchableTable
        pagination={{
          pageSize: limit,
          current,
          total,
          showSizeChanger: true,
          pageSizeOptions: seachableTablePageSizeOptions,
          onShowSizeChange: (_, size) => {
            setLimit(size);
          },
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        onSearch={value => {
          setSearch(value);
          setPage(1);
        }}
        dataSource={chidoris}
        loading={isPending}
        columns={columns}
      />
    </div>
  );
}

export default Chidoris;
