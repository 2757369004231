import React, { useState, useCallback, useContext } from 'react';
import { Typography, Button, Tooltip, Space, Modal } from 'antd';
import { formatISO } from 'date-fns';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DataAgentModal from './DataAgentModal/DataAgentModal';
import useRequest from 'hooks/useRequest';
import { getDataAgents, deleteDataAgent } from 'services/dataagent';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';

const { Title } = Typography;

function DataAgents() {
  const { user } = useContext(SessionContext);

  const [page, setPage] = useState(1);
  const [search] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);

  const {
    result,
    isPending,
    makeRequest: refetchDataAgents,
  } = useRequest(
    useCallback(() => getDataAgents({ page, search }), [page, search]),
    { resolve: true },
  );

  const dataagents = result?.data?.dataagents;
  const pageSize = result?.data?.limit;
  const current = result?.data?.page;
  const total = result?.data?.total;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Version',
      dataIndex: 'version',
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      render(_, dataagent) {
        return `${dataagent.user.fullName} (${dataagent.user.email})`;
      },
    },
    {
      title: 'Last Modifier',
      dataIndex: 'modifier',
      render(_, dataagent) {
        return `${dataagent.lastModified.fullName} (${dataagent.lastModified.email})`;
      },
    },
    {
      title: 'Incorta Versions',
      dataIndex: 'incortaVersion',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      render(date) {
        return formatISO(new Date(date), { representation: 'date' });
      },
    },
    {
      title: 'Action',
      render(_, dataagent) {
        return (
          <Space>
            {+user.userRolesPagesPermissions?.[userRolesPages.DATA_AGENT] >=
              +userRolesPagesPermissionsValues.CAN_EDIT && (
              <Tooltip title="Edit">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    setModalProps({ isNew: false, dataagent });
                  }}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}

            {user.userRolesPagesPermissions?.[userRolesPages.DATA_AGENT] ===
              userRolesPagesPermissionsValues.CAN_DELETE && (
              <Tooltip title={'Delete'}>
                <Button
                  size="small"
                  onClick={() => {
                    Modal.confirm({
                      title: (
                        <>
                          Are you sure you want to {'delete'}{' '}
                          <strong>{dataagent.name}</strong>?
                        </>
                      ),
                      async onOk() {
                        await deleteDataAgent(dataagent.id);
                        await refetchDataAgents();
                      },
                    });
                  }}
                >
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="DataAgents">
      <div className="tableTitle">
        <Title>Data Agent</Title>
        {+user.userRolesPagesPermissions?.[userRolesPages.DATA_AGENT] >=
          +userRolesPagesPermissionsValues.CAN_EDIT && (
          <Tooltip title="Create">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setModalProps({ isNew: true });
              }}
            >
              Add Data Agent
            </Button>
          </Tooltip>
        )}
      </div>
      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={dataagents}
        loading={isPending}
        columns={columns}
      />

      {modalProps && (
        <DataAgentModal
          {...modalProps}
          refetchDataAgents={refetchDataAgents}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default DataAgents;
