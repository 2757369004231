import React from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

function ProvisionHeaderActions({ activeKey, provisionKey }) {
  return (
    <span className="Collapse__panel-header-actions">
      <button type="button" className="reset-Button simple-padding">
        {activeKey.includes(provisionKey) ? <UpOutlined /> : <DownOutlined />}
      </button>
    </span>
  );
}

export default ProvisionHeaderActions;
