import axios, { AxiosResponse } from 'axios';
import { NewPartner, PartnersResponse } from 'types/partner';
import { API_URL } from 'utils/http';

export async function getPartners({ page = 1, search, limit = 10 }) {
  try {
    const result = await axios.get<PartnersResponse>(`${API_URL}/partner`, {
      params: { page, search, limit },
    });
    return result.data;
  } catch (error) {
    throw error;
  }
}

export async function createNewPartner(
  props: Omit<NewPartner, 'supportedRegions'> & { supportedRegions: string },
): Promise<AxiosResponse> {
  try {
    const result = await axios.post(`${API_URL}/partner`, props);
    return result;
  } catch (error) {
    throw error;
  }
}
