import React, { useCallback } from 'react';
import { Modal, Form, Input, Button, Space, Checkbox } from 'antd';
import SlaField from '../SlaField/SlaField';
import CostField from '../CostField/CostField';
import { updateClusterSlaCost } from 'services/cluster';
import useRequest from 'hooks/useRequest';

function SlaCostModal({ isNew, cluster, refetchClusters, close }) {
  let {
    id,
    name,
    cost_center,
    organization,
    slaTypeID,
    costTypeID,
    isExcludedOc,
    deletedAt,
  } = cluster ?? {};

  const [form] = Form.useForm();

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        await updateClusterSlaCost(id, cluster);
        await refetchClusters();
      },
      [id, refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal
      visible
      title="SLA and Cost Configurations"
      footer={null}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={{
          name,
          cost_center,
          organization,
          slaTypeID,
          costTypeID,
          isExcludedOc,
        }}
        layout="vertical"
        name="cluster"
        onFinish={handleFinish}
      >
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input disabled={!isNew} />
        </Form.Item>
        <SlaField label="SLA Type" name="slaTypeID" form={form} />
        <CostField label="Cost Type" name="costTypeID" />
        <Form.Item name="cost_center" label="Cost Center">
          <Input />
        </Form.Item>
        <Form.Item name="organization" label="Organization">
          <Input />
        </Form.Item>
        {!deletedAt && (
          <Form.Item name="isExcludedOc" valuePropName="checked">
            <Checkbox>Exclude from over commitment.</Checkbox>
          </Form.Item>
        )}
        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default SlaCostModal;
