import React from 'react';

function CollapseHeader(props) {
  return (
    <div className="Collapse__panel-header">
      <span>{props.text}</span>
      {props.children}
    </div>
  );
}

export default CollapseHeader;
