import React from 'react';
import { useIntl } from 'react-intl';
import { Tag } from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { pythonInstallationStateEnum } from 'types/cluster';

type PythonPackageInstallationStatusTagProps = {
  pythonPackageStatus: pythonInstallationStateEnum;
};
function PythonPackageInstallationStatusTag({
  pythonPackageStatus,
}: PythonPackageInstallationStatusTagProps) {
  const intl = useIntl();
  const pythonPackageInstallationStatusLabelMap = new Map<
    pythonInstallationStateEnum,
    { label: string; icon: JSX.Element; color: string }
  >([
    [
      pythonInstallationStateEnum.installing,
      {
        label: intl.formatMessage({
          id: 'pythonPackagesTag.installing',
        }),
        icon: <SyncOutlined />,
        color: 'blue',
      },
    ],
    [
      pythonInstallationStateEnum.failed,
      {
        label: intl.formatMessage({
          id: 'pythonPackagesTag.failed',
        }),
        icon: <CloseCircleOutlined />,
        color: 'red',
      },
    ],
    [
      pythonInstallationStateEnum.succeeded,
      {
        label: intl.formatMessage({
          id: 'pythonPackagesTag.succeeded',
        }),
        icon: <CheckCircleOutlined />,
        color: 'green',
      },
    ],
  ]);

  const statusTagGreyInfo = {
    color: 'default', // grey
    icon: <ExclamationCircleOutlined />,
  };

  const currentTagMapItem =
    pythonPackageInstallationStatusLabelMap.get(pythonPackageStatus);

  return (
    <Tag
      className="status-tag"
      icon={currentTagMapItem?.icon ?? statusTagGreyInfo.icon}
      color={currentTagMapItem?.color ?? statusTagGreyInfo.color}
      key={pythonPackageStatus}
    >
      {currentTagMapItem?.label}
    </Tag>
  );
}

export default PythonPackageInstallationStatusTag;
