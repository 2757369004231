import axios from 'axios';
import { InstancePlatform } from 'types/cluster';
import { PlatFormActionsResponse } from 'types/platformAction';
import { API_URL } from 'utils/http';

export function fetchPlatformActions() {
  return axios.get<PlatFormActionsResponse>(`${API_URL}/platform`);
}

export function updatePlatformAction(payload: {
  action: string;
  platform: InstancePlatform;
  status: boolean;
}) {
  return axios.post(`${API_URL}/platform`, payload);
}
