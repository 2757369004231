import React from 'react';
import { Switch, Table, Tag } from 'antd';

function FeatureBitsTable({
  clusterFeatureBits,
  featureBits,
  pageSize,
  current,
  total,
  setStatus,
  setPage,
  loading,
}) {
  function handleSwitchChange(record, switchValue) {
    setStatus(prev => {
      prev.forEach(element => {
        if (element.id === record.id) element.enabled = switchValue;
      });
      if (!prev.some(element => element.id === record.id))
        prev.push({ id: record.id, enabled: switchValue });
      return prev;
    });
  }

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Key',
      dataIndex: 'key',
    },
    {
      title: 'Service To Be Restarted',
      dataIndex: 'serviceToBeRestarted',
      render: (e, record) =>
        record.serviceToBeRestarted === 'all' ? (
          <Tag color="purple">{record.serviceToBeRestarted}</Tag>
        ) : record.serviceToBeRestarted === 'analytics' ? (
          <Tag color="orange">{record.serviceToBeRestarted}</Tag>
        ) : record.serviceToBeRestarted === 'loader' ? (
          <Tag color="green">{record.serviceToBeRestarted}</Tag>
        ) : (
          <Tag> {record.serviceToBeRestarted} </Tag>
        ),
    },
    {
      title: 'Enable',
      dataIndex: 'isEnabled',
      render: (e, record) => (
        <Switch
          onChange={switchValue => handleSwitchChange(record, switchValue)}
          defaultChecked={clusterFeatureBits.includes(record.id)}
          disabled={!record.enabled && !clusterFeatureBits.includes(record.id)}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey="id"
        className="antd-table-customized-scroll"
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={featureBits}
        loading={loading}
        columns={columns}
      />
    </div>
  );
}
export default FeatureBitsTable;
