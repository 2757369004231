import { Modal, Form, Space, Table, Tag, Spin, Typography } from 'antd';
import React, { useCallback } from 'react';
import useRequest from 'hooks/useRequest';
import { insightsInfo } from 'services/clustersstatus';
import '../clustersstatus.less';

let { Title } = Typography;

const ClusterInsights = ({ close, name }) => {
  let { result: cmcInfoResult, isPending: cmcInfoLoading } = useRequest(
    useCallback(() => insightsInfo(name), [name]),
    { handleError: true, resolve: true },
  );

  if (!cmcInfoResult) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting Status from CMC" />
      </div>
    );
  }

  const cmcInfo = cmcInfoResult?.data;

  let cmcInsights = {};
  const svcColumnsData: any = [];
  if (cmcInfo.cmcInfo === 'Error') {
    return (
      <Modal title={name} visible onCancel={close} footer={null}>
        <Title level={4}>Something Went Wrong!</Title>
      </Modal>
    );
  } else if (cmcInfo.cmcInfo !== 'None') {
    cmcInsights = JSON.parse(cmcInfo.cmcInfo);
  }

  for (let insight in cmcInsights) {
    if (
      insight.includes('status') &&
      !insight.includes('LastUpdated') &&
      !insight.includes('Heap')
    ) {
      if (cmcInsights[insight] && cmcInsights[insight] !== 'null') {
        svcColumnsData.push({
          service: insight.replace('-status', ''),
          status: cmcInsights[insight],
          lastUpdated: cmcInsights[`${insight}-LastUpdated`],
          onHeap: cmcInsights[`${insight}-OnHeap`],
          offHeap: cmcInsights[`${insight}-OffHeap`],
        });
      }
    }
  }

  let color = '';
  let columns = [
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
    },
    {
      title: 'Status (from CMC)',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        if (['Starting', 'Starting Tenants'].includes(status)) {
          color = 'yellow';
        } else if (status.includes('Started')) {
          color = 'green';
        } else if (status.includes('Processing')) {
          color = 'orange';
        } else if (['Stopping', 'N/A', 'Stopping Tenants'].includes(status)) {
          color = 'grey';
        } else if (status.includes('Stopped')) {
          color = 'black';
        } else if (
          ['Error', 'Not Responding', 'Terminated Unexpectedly'].includes(
            status,
          )
        ) {
          color = 'red';
        }
        return (
          <Tag color={color} key={status}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
    },
    {
      title: 'On Heap Memory(GB)',
      dataIndex: 'onHeap',
      key: 'onHeap',
    },
    { title: 'Off Heap Memory(GB)', dataIndex: 'offHeap', key: 'offHeap' },
  ];

  return (
    <Modal
      className="ClusterInsightsModal"
      title={name}
      visible
      onCancel={close}
      footer={null}
    >
      <Space>
        {cmcInfoLoading ? (
          <Spin />
        ) : (
          <Form name="cmcStuff" layout="horizontal">
            <Table columns={columns} dataSource={svcColumnsData} />
          </Form>
        )}
      </Space>
    </Modal>
  );
};

export default ClusterInsights;
