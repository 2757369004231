import React, { ComponentType, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Login from '../Login/Login';
import FullPageLoader from '../FullPageLoader/FullPageLoader';
import { SessionContext } from '../../auth/SessionProvider';
import UserRoles from 'components/UserRoles/UserRoles';
import Home from 'components/Home/Home';
import ProvisionUsers from 'components/ProvisionUsers/ProvisionUsers';
import Users from 'components/Users/Users';
import Configurations from 'components/Configurations/Configurations';
import AuditHistory from 'components/AuditHistory/AuditHistory';
import Notification from 'components/Notification/Notification';
import Backups from 'components/Backups/Backups';
import Cli from 'components/Cli/Cli';
import Clusters from 'components/Clusters/Clusters';
import ClustersStatus from 'components/ClustersStatus/clustersstatus';
import ContextManager from 'components/ContextManager/ContextManager';
import CostAwareness from 'components/CostAwareness/costAwareness';
import DataAgents from 'components/DataAgents/DataAgents';
import ExportedBlueprints from 'components/ExportedBlueprints/ExportedBlueprints';
import FeatureBits from 'components/FeatureBits/FeatureBits';
import Images from 'components/Images/images';
import Infrastructure from 'components/Infrastructure/Infrastructure';
import NewProvisionCustomer from 'components/NewProvisionCustomer/NewProvisionCustomer/NewProvisionCustomer';
import PlatformAction from 'components/PlatformAction/PlatformAction';
import SparkImages from 'components/SparkImages/SparkImages';
import SupportDashboard from 'components/SupportDashboard/SupportDashboard';
import ToggleFeature from 'components/ToggleFeatures/ToggleFeature';
import ToggleFeatures from 'components/ToggleFeatures/ToggleFeatures';
import UserInfo from 'components/Users/UserInfo';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import NavigationLayout from 'components/ui/NavigationLayout/NavigationLayout';
import { ErrorHandler } from 'auth/ErrorHandler';
import { Spinner } from 'components/Spinner/Spinner';
import OverCommitment from 'components/guidance/OverCommitment/OverCommitment';
import ClustersActions from 'components/clusterActions/ClusterActions';
import ClusterSizes from 'components/guidance/ClusterSizes/ClusterSizes';
import ChidoriSizes from 'components/guidance/ChidoriSizes/ChidoriSizes';
import ClusterProvider from 'components/Clusters/contexts/ClusterContext';
import NotebookUsersCount from 'components/Clusters/NotebookUsersCount/NotebookUsersCount';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { userPermissionTypes } from 'utils/user';
import Restore from 'components/Restore/Restore';
import Chidoris from 'components/Chidori/InstanceChidoris';
import { isJsonString } from 'utils/helpers';
import Partners from 'components/Partners/Partners';

interface LocationState {
  redirectTo?: any;
}

function AppRoutes() {
  const { user, isAuthenticated, isLoading, error } =
    useContext(SessionContext);
  const { pathname } = useLocation<LocationState>();
  useEffect(() => {
    let script = document.createElement('script');
    if (pathname === '/signup' || pathname === '/signup/') {
      script = document.createElement('script');

      script.src = '//js.hs-scripts.com/7507810.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'block'));
    } else {
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'none'));
    }
  });

  function getCookie(cname: string) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }
  const isLoggedIn = !!getCookie('CloudAdminSSO');
  const currentUserRoles = user.roles;

  if (error) {
    if (isJsonString(error.message)) {
      const { message, userEmail } = JSON.parse(error.message);
      return <ErrorHandler message={message as string} userEmail={userEmail} />;
    } else {
      return <ErrorHandler message={error.message} />;
    }
  }

  if (!isAuthenticated && !isLoggedIn) {
    return <Login />;
  }

  if (user?.uuid && !user?.userDataIsSet) {
    return <Spinner />;
  }

  if (user?.email && isAuthenticated) {
    if (!user?.confirmed) {
      return <ErrorHandler message={'Verify Your Email'} />;
    } else if (currentUserRoles === 'guest' || !currentUserRoles) {
      return <ErrorHandler message={"You don't have permission"} />;
    } else if (user?.roles === userPermissionTypes.guest) {
      return <ErrorHandler message={"You don't have permission"} />;
    }
  }

  return (
    <NavigationLayout>
      <Switch>
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute
          exact
          path="/userroles"
          component={UserRoles}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.ROLES in user.userRolesPagesPermissions}
        />
        <ProtectedRoute
          exact
          path="/provision-user"
          component={ProvisionUsers}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.PROVISION_USERS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/users"
          component={Users}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.USERS in user.userRolesPagesPermissions}
        />
        <ProtectedRoute
          exact
          path="/configurations"
          component={Configurations}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CONFIGURATIONS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/exportedblueprints"
          component={ExportedBlueprints}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.BLUEPRINTS_EXPORTS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/clusters"
          component={() => (
            <ClusterProvider>
              <Clusters />
            </ClusterProvider>
          )}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLUSTERS in user.userRolesPagesPermissions
          }
        />

        <Route exact path="/support-dashboard" component={SupportDashboard} />
        <ProtectedRoute
          exact
          path="/dataagents"
          component={DataAgents}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.DATA_AGENT in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/togglefeatures"
          component={ToggleFeatures}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.TOGGLE_FEATURES in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          path="/togglefeatures/:feature"
          component={ToggleFeature}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.TOGGLE_FEATURES in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          path="/accounting/userdetails/:userid"
          component={() => (
            <UserInfo backToBtnText="Users" pushToURL="/users" />
          )}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.USERS in user.userRolesPagesPermissions}
        />

        <ProtectedRoute
          path="/accounting/provisionuserdetails/:userid"
          component={() => (
            <UserInfo
              backToBtnText="Provision Users"
              pushToURL="/provision-user"
            />
          )}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.PROVISION_USERS in user.userRolesPagesPermissions
          }
        />
        {/* Account Managers: Hidden for now as it conflicts with Provision Users */}
        {/*<ProtectedRoute path="/accountmanagers" component={AccountManager}  />
         <ProtectedRoute
          path="/accountmanagers/users"
          component={AccountManagerUsers}
        /> */}
        <ProtectedRoute
          path="/clicommands"
          component={Cli}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLI_COMMAND in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          path="/notifications"
          component={Notification}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.NOTIFICATIONS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/audit"
          component={AuditHistory}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.AUDIT_HISTORY in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/images"
          component={Images}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.IMAGES in user.userRolesPagesPermissions}
        />
        <ProtectedRoute
          exact
          path="/contextmanager"
          component={ContextManager}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.OPERATORS in user.userRolesPagesPermissions
          }
        />
        <ProtectedRoute
          exact
          path="/costawareness"
          component={CostAwareness}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.COST_AWARENESS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/clustersstatus"
          component={ClustersStatus}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLOUD_INSIGHTS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/featurebits"
          component={FeatureBits}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.FEATURE_BITS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/backups"
          component={Backups}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.BACKUPS in user.userRolesPagesPermissions}
        />

        <ProtectedRoute
          exact
          path="/restore"
          component={Restore}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.RESTORED_CLUSTERS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/sparkimages"
          component={SparkImages}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.IMAGES in user.userRolesPagesPermissions}
        />
        <ProtectedRoute
          exact
          path="/infrastructure"
          component={Infrastructure}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.INFRASTRUCTURE in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/platform"
          component={PlatformAction}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.PLATFORM_ACTIONS in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/provision-customer/new"
          component={NewProvisionCustomer}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.PROVISION_USERS in user.userRolesPagesPermissions &&
            +user.userRolesPagesPermissions?.[userRolesPages.PROVISION_USERS] >=
              +userRolesPagesPermissionsValues.CAN_EDIT
          }
        />

        <ProtectedRoute
          exact
          path="/oclabels"
          component={OverCommitment}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.OVER_COMMITMENT in user.userRolesPagesPermissions
          }
        />

        <ProtectedRoute
          exact
          path="/clustersactions"
          component={ClustersActions}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLOUD_ACTION_CENTER in user.userRolesPagesPermissions
          }
        />
        <Route exact path="/verify">
          <EmailSentPage />
        </Route>

        <ProtectedRoute
          exact
          path="/cluster-sizes"
          component={ClusterSizes}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLUSTER_SIZES in user.userRolesPagesPermissions
          }
        />
        <ProtectedRoute
          exact
          path="/chidori-sizes"
          component={ChidoriSizes}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.CHIDORI in user.userRolesPagesPermissions}
        />
        <ProtectedRoute
          exact
          path="/notebook-sizes"
          component={NotebookUsersCount}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.CLUSTERS in user.userRolesPagesPermissions
          }
        />
        <ProtectedRoute
          exact
          path="/chidori"
          component={Chidoris}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={userRolesPages.CHIDORI in user.userRolesPagesPermissions}
        />

        <ProtectedRoute
          exact
          path="/incorta-one"
          component={Partners}
          isLoading={isLoading || !user.userRolesPagesPermissions}
          isPermitted={
            userRolesPages.INCORTA_ONE in user.userRolesPagesPermissions
          }
        />
        <Redirect to="/" />
      </Switch>
    </NavigationLayout>
  );
}

interface ProtectedRouteProps {
  path?: string | string[];
  exact?: boolean;
  component: ComponentType<any>;
  isLoading?: boolean;
  isPermitted?: boolean;
  redirectURL?: string;
}

function ProtectedRoute({
  path,
  exact,
  component,
  isLoading,
  isPermitted = true,
  redirectURL = '/',
}: React.PropsWithChildren<ProtectedRouteProps>) {
  function Redirection() {
    return <Redirect to={redirectURL} />;
  }
  const location = useLocation();

  return (
    <Route
      exact={exact}
      path={path}
      component={
        isPermitted
          ? withAuthenticationRequired(component, {
              onRedirecting: () => <FullPageLoader />,
              returnTo: location.pathname || '/',
              loginOptions: {
                redirectUri: window.location.origin,
                appState: {
                  returnTo: location.pathname,
                },
              },
            })
          : isLoading
          ? Spinner
          : Redirection
      }
    />
  );
}

export default AppRoutes;
