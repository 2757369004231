import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Button,
  Space,
  DatePicker,
  InputNumber,
  Row,
  Col,
  Radio,
  Select,
} from 'antd';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';
import { getWeekNumberAndDay } from '../../../utils/backup';
import { getInstancesNames, backupInstance } from 'services/backup';
import useRequest from 'hooks/useRequest';

function BackupDetailsModal({ close, refetchBackups }) {
  let backupRecord = {
    backupNow: true,
    date: moment().add(1, 'hours'),
    endDate: null,
    retention: 3,
    frequency: '1',
    backupType: 'metadata',
  };
  const [form] = Form.useForm();

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async backupRecord => {
        await backupInstance(backupRecord);
        await refetchBackups();
      },
      [refetchBackups],
    ),
    {
      handleError: true,
    },
  );

  const [disabled, setDisabled] = useState(false);
  const [instancesNames, setInstancesNames] = useState([]);
  const [selectedDay, setSelectedDay] = useState('');
  const [selectedInstance, setSelectedInstance] = useState('');
  const [selectedWeekNumberAndDay, setSelectedWeekNumberAndDay] = useState('');

  const [frequency, setFrequency] = useState('1');

  useEffect(() => {
    const date = new Date();
    const weekDayName = moment(date).format('dddd');
    setSelectedDay(weekDayName);
    const backupDate = moment(form.getFieldValue('date'));
    setSelectedWeekNumberAndDay(getWeekNumberAndDay(backupDate));

    // eslint-disable-next-line
  }, []);

  const toggle = () => {
    setDisabled(!disabled);
  };

  async function handleFinish(backupRecord) {
    await makeRequest(backupRecord);
    close();
  }

  const debounced = useDebouncedCallback(async name => {
    let res = [];
    if (name) {
      let instancesNames = await getInstancesNames(name);
      res = instancesNames.data.instancesNames;
    }
    setInstancesNames(res);
  }, 500);

  async function handleSearch(name) {
    debounced.callback(name);
  }

  function disabledDate(current) {
    // Can not select days before today
    return current < moment().startOf('day');
  }

  const handleChange = value => {
    setSelectedInstance(value);
  };

  return (
    <Modal
      open
      title={'Cluster Backup Configurations'}
      footer={null}
      onCancel={close}
    >
      <Form
        form={form}
        initialValues={backupRecord}
        layout="vertical"
        name="backupRecord"
        onFinish={handleFinish}
      >
        <Form.Item
          name="instanceName"
          label="Cluster Name"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            value={selectedInstance}
            placeholder={'Cluster Name'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
          >
            {instancesNames &&
              instancesNames.map(name => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="backupNow" label="Backup Now">
          <Radio.Group onChange={toggle}>
            <Radio value={true}>Backup Now</Radio>
            <Radio value={false}>Scheduled Backup</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="Backup metadata, tenant directory and installation directory"
          name="backupType"
        >
          <Radio.Group defaultValue="metadata">
            <Radio value="metadata">Metadata</Radio>
            <Radio value="disks">Disks</Radio>
            <Radio value="metadisks">Metadata and Disks</Radio>
            <Radio value="full">Full Backup</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="informBackupStatus"
          label="Notify Cluster Owners After Backup Completion"
        >
          <Radio.Group defaultValue="none">
            <Radio value="none">Never</Radio>
            <Radio value="success">Success</Radio>
            <Radio value="failure">Failure</Radio>
            <Radio value="both">Success or Failure</Radio>
          </Radio.Group>
        </Form.Item>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="retention" label="Retention in Days">
              <InputNumber
                style={{ width: '100%' }}
                min={1}
                max={90}
                defaultValue={7}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {disabled && (
              <Form.Item
                name="frequency"
                rules={[{ required: true }]}
                label="Backup Frequency"
              >
                <Select
                  onChange={value => {
                    setFrequency(value);
                  }}
                >
                  <Select.Option value="0"> Does not repeat </Select.Option>
                  <Select.Option value="1"> Daily </Select.Option>
                  <Select.Option value="7">
                    Weekly on {selectedDay}
                  </Select.Option>
                  <Select.Option value="31">
                    Monthly on {selectedWeekNumberAndDay}
                  </Select.Option>
                </Select>
              </Form.Item>
            )}
          </Col>
        </Row>

        {disabled && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="date"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  suffixIcon={<span>GMT</span>}
                  style={{ width: '100%' }}
                  format="YYYY-MM-DD HH:mm:ss"
                  disabledDate={disabledDate}
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  onChange={(value, date) => {
                    var weekDayName = moment(date).format('dddd');
                    setSelectedDay(weekDayName);
                    setSelectedWeekNumberAndDay(
                      getWeekNumberAndDay(moment(date)),
                    );
                  }}
                />
              </Form.Item>
            </Col>
            {frequency !== '0' && (
              <Col span={12}>
                <Form.Item name="endDate" label="End Date">
                  <DatePicker
                    suffixIcon={<span>GMT</span>}
                    style={{ width: '100%' }}
                    format="YYYY-MM-DD HH:mm:ss"
                    disabledDate={disabledDate}
                    showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {disabled ? 'Schedule Backup' : 'Backup'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default BackupDetailsModal;
