import { useState } from 'react';
import './ClusterModal.less';
import {
  Modal,
  Form,
  Input,
  Button,
  Space,
  Switch,
  Tooltip,
  Row,
  Col,
  Typography,
} from 'antd';
const { Title } = Typography;
import { SyncOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import IdleField from '../IdleField/IdleField';
import { useSyncPythonPackages, useUpdateCluster } from 'hooks/queries';
import {
  Instance,
  InstalledPythonPackageVersionSeparated,
} from 'types/cluster';
import { showSuccessMessage } from 'utils/errors';
import PythonPackagesEditableTable from 'components/Clusters/ClusterModal/PythonPackagesEditableTable/PythonPackagesEditableTable';

type PythonPackagesBody = {
  packagesToBeInstalled: '' | string;
  packagesToBeUnInstalled: '' | string;
};
type EditedInstanceBody = Instance & PythonPackagesBody;

type ClusterModalProps = {
  isNew: boolean;
  initialCluster: Instance;
  close: () => void;
  refetchClusters: Function;
};

function ClusterModal({
  isNew,
  initialCluster,
  close,
  refetchClusters,
}: ClusterModalProps) {
  const [form] = Form.useForm();

  const {
    name,
    idleTime,
    sleeppable,
    pyPackages,
    pythonPackages,
    enableWelcome,
    enableChat,
    enableOnboarding,
    allowBackup,
    concurrentSessions,
    filestoreMountPath,
    cname,
    sparkCpu,
    sparkMem,
    hostName,
  } = initialCluster ?? {};
  const isClusterRunning = initialCluster.status === 'running';
  const enableOnDemandLoader = initialCluster.onDemandLoader
    ? initialCluster.onDemandLoader.enabled
    : false;

  const isChidori = initialCluster.isChidori;

  const isAnyPythonPackageHasNoVersion =
    pythonPackages?.findIndex(
      currentPackage => !currentPackage.name.includes('=='),
    ) !== -1
      ? true
      : false;

  const {
    mutateAsync: mutateRefreshPythonPackages,
    isLoading: isRefreshPythonPackagesLoading,
  } = useSyncPythonPackages({ instanceID: initialCluster.id });

  async function refreshPythonPackages() {
    try {
      const res = await mutateRefreshPythonPackages();
      refetchClusters();
      showSuccessMessage(res?.data?.body);
    } catch (error) {}
  }

  const {
    mutateAsync: mutateUpdateCluster,
    isLoading: isUpdateClusterLoading,
  } = useUpdateCluster();

  async function continueSubmitEditCluster(editedCluster: EditedInstanceBody) {
    mutateUpdateCluster({ name, cluster: editedCluster });
    close();
  }

  function preparePythonPackagesChanges(editedCluster: Instance) {
    function updatePyPackages() {
      editedCluster.pyPackages = reduceToStringCommaSeparated(
        pythonPackagesTableDataSource,
      );
    }
    updatePyPackages();

    delete editedCluster.pythonPackages;

    function reduceToStringCommaSeparated(
      arr: InstalledPythonPackageVersionSeparated[],
    ) {
      return arr.reduce((acc, pkg, index) => {
        if (index === 0) {
          return pkg.name;
        } else {
          return acc + (pkg.name ? ',' + pkg.name : '');
        }
      }, '');
    }
    function getPythonPackagesGroupedByInstallation(): PythonPackagesBody {
      const differenceInPackages: InstalledPythonPackageVersionSeparated[] =
        _.differenceWith(
          pythonPackages,
          pythonPackagesTableDataSource.map(pkg => ({
            analyticsState: pkg.analyticsState,
            chidoriState: pkg.chidoriState,
            createdAt: pkg.createdAt,
            id: pkg.id,
            instanceID: pkg.instanceID,
            loaderState: pkg.loaderState,
            name: pkg.name,
            updatedAt: pkg.updatedAt,
          })),
          _.isEqual,
        );

      let packagesToBeUnInstalledWholeObject = [];
      let packagesChangingTheirVersion = [];

      for (let pkg of differenceInPackages) {
        const pkgIndex = pythonPackagesTableDataSource.findIndex(p => {
          return p.name.split('==')[0] === pkg.name.split('==')[0];
        });
        if (pkgIndex !== -1) {
          packagesChangingTheirVersion.push(
            pythonPackagesTableDataSource[pkgIndex],
          );
        } else {
          packagesToBeUnInstalledWholeObject.push(pkg);
        }
      }
      const packagesToBeInstalledWholeObject = [
        ...packagesChangingTheirVersion,
        ...pythonPackagesTableDataSource.filter(pkg =>
          pkg.id.toString().startsWith('-'),
        ),
      ];
      const packagesToBeInstalled = packagesToBeInstalledWholeObject?.length
        ? reduceToStringCommaSeparated(packagesToBeInstalledWholeObject)
        : '';
      const packagesToBeUnInstalled = packagesToBeUnInstalledWholeObject?.length
        ? reduceToStringCommaSeparated(packagesToBeUnInstalledWholeObject)
        : '';

      return { packagesToBeInstalled, packagesToBeUnInstalled };
    }
    const { packagesToBeInstalled, packagesToBeUnInstalled } =
      getPythonPackagesGroupedByInstallation();

    return { packagesToBeInstalled, packagesToBeUnInstalled };
  }

  function handleFinish(editedCluster: Instance) {
    let shouldContinue = true;

    if (shouldContinue) {
      const { packagesToBeInstalled, packagesToBeUnInstalled } =
        preparePythonPackagesChanges(editedCluster);

      continueSubmitEditCluster({
        ...editedCluster,
        packagesToBeInstalled,
        packagesToBeUnInstalled,
      });
    }
  }

  const pythonPackagesVersionSeparated = pythonPackages.map(pkg => ({
    ...pkg,
    packageName: pkg.name.split('==')[0],
    packageVersion: pkg.name.split('==')[1],
  }));
  const [pythonPackagesTableDataSource, setPythonPackagesTableDataSource] =
    useState<InstalledPythonPackageVersionSeparated[]>(
      pythonPackagesVersionSeparated,
    );

  return (
    <>
      <Modal
        open
        title={
          <div className="cluster-modal-title">
            <label>
              {isNew ? 'New Cluster' : 'Edit Cluster'}{' '}
              <b>{initialCluster.name}</b>
            </label>
          </div>
        }
        footer={null}
        onCancel={close}
        destroyOnClose={false}
        width={800}
      >
        <Form
          form={form}
          initialValues={{
            name,
            sleeppable,
            idleTime,
            pyPackages,
            enableWelcome,
            enableChat,
            enableOnboarding,
            allowBackup,
            concurrentSessions,
            filestoreMountPath,
            cname,
            sparkCpu,
            sparkMem,
            hostName,
            enableOnDemandLoader,
          }}
          layout="vertical"
          name="cluster"
          onFinish={handleFinish}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input disabled={!isNew} />
          </Form.Item>

          <Row
            align="stretch"
            gutter={24}
            justify="space-around"
            key="guides-config"
          >
            <Col span={18} key="idle-time">
              <IdleField label="Idle Time" name="idleTime" />
            </Col>
            <Col span={6} key="concurrentSessions">
              <Form.Item name="concurrentSessions" label="Concurrent Sessions">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <div className="cluster-modal-toggles__container">
            <div className="cluster-modal-toggles__item welcome">
              <Form.Item
                name="enableWelcome"
                label="Welcome Message"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </div>
            <div className="cluster-modal-toggles__item chat">
              <Form.Item name="enableChat" label="Chat" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
            <div className="cluster-modal-toggles__item homepage">
              <Form.Item
                name="enableOnboarding"
                label="Home Page"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </div>
            <div className="cluster-modal-toggles__item ondemand">
              <Form.Item
                name="enableOnDemandLoader"
                label="On-Demand Loader"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </div>
          </div>
          <Form.Item name="cname" label="Cname">
            <Input />
          </Form.Item>
          <Form.Item name="hostName" label="Alias (Host Name)">
            <Input />
          </Form.Item>
          <Form.Item name="filestoreMountPath" label="File Store Mount Path">
            <Input />
          </Form.Item>

          <Title level={4}>Spark Resources Quota Configurations</Title>
          <Row align="stretch" gutter={24} justify="space-around" key="guides">
            <Col span={12} key="sparkCpu">
              <Form.Item name="sparkCpu" label="CPU">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} key="sparkMem">
              <Form.Item name="sparkMem" label="Memory">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="pythonPackages"
            label={
              <section>
                {isAnyPythonPackageHasNoVersion && (
                  <Tooltip
                    title={
                      !isClusterRunning && (
                        <FormattedMessage id="general.clusterMustBeConnectedToChange" />
                      )
                    }
                  >
                    <Button
                      type="link"
                      onClick={refreshPythonPackages}
                      disabled={
                        isRefreshPythonPackagesLoading || !isClusterRunning
                      }
                    >
                      <SyncOutlined
                        style={{ marginRight: 8 }}
                        spin={isRefreshPythonPackagesLoading}
                      />
                      <FormattedMessage id="clusterPage.displayVersions" />
                    </Button>
                  </Tooltip>
                )}
              </section>
            }
          >
            <PythonPackagesEditableTable
              pythonPackages={pythonPackages}
              instanceID={initialCluster.id}
              isClusterRunning={isClusterRunning}
              isChidori={isChidori}
              dataSource={pythonPackagesTableDataSource}
              setDataSource={setPythonPackagesTableDataSource}
            />
          </Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isUpdateClusterLoading}
            >
              {isNew ? 'Create' : 'Update'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
}

export default ClusterModal;
