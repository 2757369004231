import React, { useState } from 'react';
import './ExternalBucketModal.less';
import {
  Alert,
  Button,
  Divider,
  Input,
  Modal,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import {
  CopyOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { FormattedMessage, useIntl } from 'react-intl';
import { copyText } from 'helpers/clipboard';
import ExternalBucketIcon from 'images/external-bucket.svg';
import DataExtractionIcon from 'images/data-extraction.svg';
import {
  useAddClusterToExternalBucket,
  useGetInstanceExternalBucketConfig,
  useRemoveClusterFromExternalBucket,
  useSimpleClusterSearchForExternalBucket,
  useToggleDataExtraction,
  useToggleExternalBucketDestinationCluster,
} from 'hooks/externalBucketQueries';
import DebouncedSelectSearch from 'components/reusables/DebouncedSelectSearch/DebouncedSelectSearch';
import { ExternalBucketDestination } from 'types/externalBucket.types';

type ExternalBucketModalProps = {
  close: () => void;
  instanceID: string;
  clusterName: string;
};
function ExternalBucketModal({
  close,
  instanceID,
  clusterName,
}: ExternalBucketModalProps) {
  const intl = useIntl();
  const {
    data: instanceExternalBucketConfig,
    isLoading: isInstanceExternalBucketConfigLoading,
  } = useGetInstanceExternalBucketConfig({ instanceID });
  const isClusterADestination = instanceExternalBucketConfig?.configuredAsDist;
  const externalBucket = instanceExternalBucketConfig?.instance?.externalBucket;

  const destinations =
    instanceExternalBucketConfig?.instance?.externalBucketDestinations;

  const {
    mutateAsync: mutateToggleDataExtraction,
    isLoading: isToggleDataExtractionLoading,
  } = useToggleDataExtraction({
    instanceID,
    clusterName,
  });

  const {
    data: simpleClusterSearchData,
    mutateAsync: mutateSimpleClusterSearch,
    isLoading: isSimpleClusterSearchLoading,
  } = useSimpleClusterSearchForExternalBucket();
  // const [selectedClusterSearch, setSelectedClusterSearch] = useState<string>();

  const {
    mutateAsync: mutateAddClusterToExternalBucket,
    isLoading: isAddClusterToExternalBucketLoading,
  } = useAddClusterToExternalBucket({ instanceID });

  const {
    mutateAsync: mutateRemoveClusterFromExternalBucket,
    isLoading: isRemoveClusterFromExternalBucketLoading,
  } = useRemoveClusterFromExternalBucket({ instanceID });

  const {
    mutateAsync: mutateToggleExternalBucketDestinationCluster,
    isLoading: isToggleExternalBucketDestinationClusterLoading,
  } = useToggleExternalBucketDestinationCluster({ instanceID });

  async function handleToggleDataExtraction(checked: boolean) {
    await mutateToggleDataExtraction({ enableExternalBucket: checked });
  }

  async function handleSearchCluster(value: string) {
    await mutateSimpleClusterSearch({ search: value });
  }

  async function handleAddClusterToExternalBucket() {
    await mutateAddClusterToExternalBucket({ clusters: [selectedCluster] });
    setSelectedCluster('');
  }

  async function handleRemoveClusterFromExternalBucket({
    clusterID,
  }: {
    clusterID: string;
  }) {
    await mutateRemoveClusterFromExternalBucket({ clusters: [clusterID] });
  }

  async function handleToggleExternalBucketDestinationCluster({
    destinationClusterID,
    checked,
  }: {
    destinationClusterID: string;
    checked: boolean;
  }) {
    await mutateToggleExternalBucketDestinationCluster({
      destinationClusterID,
      enableExternalBucketCluster: checked,
    });
  }

  const [currentSearchInput, setCurrentSearchInput] = useState<string>();
  const [selectedCluster, setSelectedCluster] = useState<string>();

  const isExternalBucketSwitchLoading =
    isInstanceExternalBucketConfigLoading || isToggleDataExtractionLoading;

  const isCopyExternalBucketPathButtonDisabled =
    !instanceExternalBucketConfig?.instance?.absoluteExternalBucketName ||
    isToggleDataExtractionLoading;

  const isAddClusterButtonDisabled =
    !!isClusterADestination || !selectedCluster;

  const isSearchClusterInputDisabled =
    !!isClusterADestination || isAddClusterToExternalBucketLoading;
  const isToggleExternalBucketDestinationClusterButtonDisabled =
    !!isClusterADestination;
  const isRemoveClusterFromExternalBucketButtonDisabled =
    !!isClusterADestination;

  const columns: ColumnsType<ExternalBucketDestination> = [
    {
      title: (
        <FormattedMessage id="clusterActions.externalBucket.clustersTable.clusterColumn" />
      ),
      key: 'instanceName',
      render(value, { destination: { name } }) {
        return name;
      },
    },
    {
      title: (
        <FormattedMessage id="clusterActions.externalBucket.clustersTable.isDestinationClusterEnabledColumn" />
      ),
      key: 'isDestinationClusterEnabled',
      render(
        value,
        {
          destinationID: destinationClusterID,
          status: isDestinationClusterEnabled,
        },
      ) {
        return (
          <Switch
            checked={isDestinationClusterEnabled}
            loading={isToggleExternalBucketDestinationClusterLoading}
            disabled={isToggleExternalBucketDestinationClusterButtonDisabled}
            onChange={checked =>
              handleToggleExternalBucketDestinationCluster({
                checked,
                destinationClusterID,
              })
            }
          />
        );
      },
    },
    {
      title: '',
      width: 150,
      render(value, { destinationID: clusterID }, index) {
        return (
          <section>
            <Tooltip
              title={
                <FormattedMessage id="clusterActions.externalBucket.clustersTable.removeClusterFromExternalBucketTooltip" />
              }
            >
              <Button
                icon={<MinusCircleOutlined />}
                onClick={() =>
                  handleRemoveClusterFromExternalBucket({ clusterID })
                }
                disabled={isRemoveClusterFromExternalBucketButtonDisabled}
              >
                {intl.formatMessage({
                  id: 'clusterActions.externalBucket.clustersTable.removeClusterFromExternalBucketButton',
                })}
              </Button>
            </Tooltip>
          </section>
        );
      },
    },
  ];

  return (
    <Modal
      key={instanceID}
      className="external-bucket-modal__wrapper"
      title={
        <div className="external-bucket-modal__title">
          <img src={ExternalBucketIcon} alt="external bucket" />
          <FormattedMessage
            id="clusterActions.externalBucket.title"
            values={{
              clusterName,
              strong: chunks => <strong>{chunks}</strong>,
            }}
          />
        </div>
      }
      open={true}
      onCancel={close}
      footer={null}
      maskClosable={false}
    >
      {isClusterADestination && (
        <section>
          <Alert
            message={
              <FormattedMessage
                id="clusterActions.externalBucket.destinationClusterAlert"
                values={{
                  clusterName,
                  isClusterADestination,
                  strong: chunks => <strong>{chunks}</strong>,
                  i: chunks => <i>{chunks}</i>,
                }}
              />
            }
            description={
              <FormattedMessage
                id="clusterActions.externalBucket.destinationClusterAlertDescription"
                values={{
                  isClusterADestination,
                  strong: chunks => <strong>{chunks}</strong>,
                }}
              />
            }
            type="info"
            showIcon
          />
        </section>
      )}
      <section className="external-bucket-modal__switch-section">
        <img src={DataExtractionIcon} alt="Data Extraction" />
        <FormattedMessage
          tagName={'h3'}
          id="clusterActions.externalBucket.dataExtraction"
        />
        <Switch
          checked={externalBucket}
          disabled={!!isClusterADestination}
          loading={isExternalBucketSwitchLoading}
          onChange={handleToggleDataExtraction}
        />
      </section>
      <section className="external-bucket-modal__bucket-path-section">
        <FormattedMessage
          tagName={'h3'}
          id="clusterActions.externalBucket.bucketPath"
        />
        <Input.Group
          compact
          className="external-bucket-modal__bucket-path-input-group"
        >
          <Input
            disabled
            value={
              instanceExternalBucketConfig?.instance?.absoluteExternalBucketName
            }
          />
          <Tooltip
            title={
              <FormattedMessage id="clusterActions.externalBucket.copyBucketPath" />
            }
          >
            <Button
              icon={<CopyOutlined />}
              disabled={isCopyExternalBucketPathButtonDisabled}
              onClick={e =>
                copyText(
                  instanceExternalBucketConfig?.instance
                    ?.absoluteExternalBucketName,
                )
              }
            />
          </Tooltip>
        </Input.Group>
      </section>
      <Divider />
      <FormattedMessage
        tagName={'h3'}
        id="clusterActions.externalBucket.clustersInSameBucket"
      />
      <section className="external-bucket-modal__clusters-section">
        <DebouncedSelectSearch
          onSearch={handleSearchCluster}
          value={''}
          placeholder={intl.formatMessage({
            id: 'clusterActions.externalBucket.searchClusters',
          })}
          currentSearchInput={currentSearchInput}
          setCurrentSearchInput={setCurrentSearchInput}
          selectedSearchResult={selectedCluster}
          setSelectedSearchResult={setSelectedCluster}
          options={
            simpleClusterSearchData?.map(item => ({
              value: item.id,
              label: item.name,
            })) || []
          }
          loading={isSimpleClusterSearchLoading}
          disabled={isSearchClusterInputDisabled}
        />
        <Tooltip
          title={
            <FormattedMessage id="clusterActions.externalBucket.addClusterToExternalBucket" />
          }
        >
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            disabled={isAddClusterButtonDisabled}
            loading={isAddClusterToExternalBucketLoading}
            onClick={handleAddClusterToExternalBucket}
          >
            {intl.formatMessage({
              id: 'clusterActions.externalBucket.addClusterToExternalBucketButton',
            })}
          </Button>
        </Tooltip>
      </section>
      <Table dataSource={destinations || []} columns={columns} />
    </Modal>
  );
}

export default ExternalBucketModal;
