import { useState, useCallback, useEffect } from 'react';
import { Typography, notification, Button, Tooltip, Space, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { getClusterAction } from '../../services/clusterActions';
import ClusterActionsModal from './ClusterActionModal/ClusterActionModal';

import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';

let { Title } = Typography;

function ClustersActions({}) {
  let [modalProps, setModalProps] = useState<any>(null);
  let [page, setPage] = useState(1);
  let [action, setAction] = useState('UPGRADE');
  let [filterBy, setFilterBy] = useState('');
  let [filterName, setFilterName] = useState('');

  let {
    result,
    isPending,
    makeRequest: refetchClusterActions,
  } = useRequest(
    useCallback(
      () => getClusterAction({ page, action, filterBy, filterName }),
      [page, action, filterBy, filterName],
    ),
    { resolve: true },
  );

  useEffect(() => () => notification.destroy(), []);

  let entities = result?.data?.entities;
  let pageSize = result?.data?.limit;
  let current = result?.data?.page;
  let total = result?.data?.total;

  let columns = [
    {
      title: 'Last Updated',
      dataIndex: 'lastupdated',
      width: '20%',
    },
    {
      title: 'Instance',
      dataIndex: 'cluster',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: '20%',
    },
    {
      title: 'Build Version',
      dataIndex: 'buildNo',
      width: '15%',
    },
    {
      title: 'Analytics Status',
      render(_, entity) {
        return (
          <Space>
            <Tooltip title="Analytics info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, entity, service: 'analytics' });
                }}
              >
                <InfoCircleOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: 'Loader Status',
      render(_, entity) {
        return (
          <Space>
            <Tooltip title="Loader info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, entity, service: 'loader' });
                }}
              >
                <InfoCircleOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      title: ' CMC Status',
      render(_, entity) {
        return (
          <Space>
            <Tooltip title="CMC info">
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ isNew: false, entity, service: 'cmc' });
                }}
              >
                <InfoCircleOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="Cloud Actions">
      <div className="tableTitle">
        <Title>Clusters Actions</Title>

        <Select
          placeholder="Filter By Action"
          allowClear
          style={{ width: 150 }}
          onChange={value => {
            setFilterName(value);
            setFilterBy('action');
          }}
          options={[
            { value: 'UPGRADE', label: 'Upgrade' },
            { value: 'CONNECT', label: 'Connect' },
            { value: 'DISCONNECT', label: 'Disconnect' },
          ]}
        />
      </div>

      <SearchableTable
        pagination={{
          pageSize,
          current,
          total,
          showSizeChanger: false,
          onChange(newPage) {
            setPage(newPage);
          },
        }}
        dataSource={entities}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setFilterBy('cluster');
          setFilterName(value);
          setPage(1);
        }}
        searchPlaceholder={'Filter By Cluster'}
      />

      {modalProps && (
        <ClusterActionsModal
          {...modalProps}
          refetchClusterActions={refetchClusterActions}
          close={() => setModalProps(null)}
        />
      )}
    </div>
  );
}

export default ClustersActions;
