import './IdleField.less';

import React from 'react';
import { Select, Form } from 'antd';
import useRequest from 'hooks/useRequest';
import { getAvailableIdleTime } from 'services/blueprintCategory';

let { Option } = Select;

function IdleField({ name, label }) {
  let { result, isPending } = useRequest(getAvailableIdleTime, {
    resolve: true,
    handleError: true,
    useCache: true,
  });

  let availableIdleTime = result?.data?.availableIdleTime;

  return (
    <Form.Item
      className="IdleField"
      name={name}
      label={label}
      rules={[{ required: true }]}
    >
      <FieldSelect
        loading={isPending && !availableIdleTime}
        availableIdleTime={availableIdleTime}
      />
    </Form.Item>
  );
}

function FieldSelect({ value, onChange, loading, availableIdleTime }: any) {
  return (
    <Select
      value={availableIdleTime ? value : undefined}
      onChange={onChange}
      loading={loading}
      dropdownClassName="IdleField__select-dropdown"
    >
      {availableIdleTime?.map(idleTime => (
        <Option key={idleTime.key} value={idleTime.key}>
          {idleTime.display}
        </Option>
      ))}
    </Select>
  );
}

export default IdleField;
