import React, { useState } from 'react';
import { Input, Switch, Select, Form } from 'antd';

function booleanLogic(configValue: string) {
  return configValue === 'false' ? false : true;
}

function getUniqueArray(arr: string[]) {
  return arr.filter((value, index, array) => {
    return array.indexOf(value) === index;
  });
}

function prepareSelectData(arr: string[]) {
  return arr.map(val => ({
    label: val,
    value: val,
  }));
}
export function arrayLogic(configValue: string) {
  if (configValue === '') {
    return [];
  }
  const splittedData = configValue.split(',').map(val => val.trim());
  const uniqueData = getUniqueArray(splittedData);
  return prepareSelectData(uniqueData);
}

function DynamicConfigFormItem({ isRequired, dataType, configValue }) {
  const [value, setValue] = useState(
    dataType === 'boolean'
      ? booleanLogic(configValue)
      : dataType === 'array'
      ? configValue
      : configValue,
  );

  function handleChangeInMultiSelect(vals) {
    const newVal = prepareSelectData(vals);
    setValue(newVal);
  }
  let content;
  switch (dataType) {
    case 'array':
      content = (
        <Select
          mode="tags"
          allowClear
          placeholder="Please Select"
          value={value}
          options={configValue}
          onChange={handleChangeInMultiSelect}
          maxTagCount="responsive"
        />
      );
      break;
    case 'number':
    case 'integer':
    case 'nonNegativeInteger':
    case 'float':
      content = (
        <Input
          value={value}
          type="number"
          min={dataType === 'nonNegativeInteger' ? '0' : null}
        />
      );
      break;
    case 'boolean':
      content = (
        <Switch checked={value} onChange={newValue => setValue(newValue)} />
      );
      break;
    default:
      content = (
        <Input.TextArea
          value={configValue}
          onChange={e => setValue(e.currentTarget.value)}
        />
      );
  }

  return (
    <Form.Item
      name="value"
      label={'Configuration Value: ' + dataType}
      rules={[
        {
          required: isRequired,
          message: 'Configuration Value cannot be empty',
        },
      ]}
      initialValue={dataType === 'array' ? value.map(v => v.value) : value}
    >
      {content}
    </Form.Item>
  );
}
export default DynamicConfigFormItem;
