import { useCallback, useState } from 'react';
import { Modal, Form, Switch, Button, Radio, Input } from 'antd';
import useRequest from 'hooks/useRequest';
import { createFeatureBit, updateFeatureBit } from 'services/featureBit';
import { showErrorMessage } from 'utils/errors';

function FeatureBitModal({ isNew, featureBit, refetchFeatureBits, close }) {
  let {
    name,
    key,
    id,
    enabled,
    enabledByDefault,
    updateThrough,
    serviceToBeRestarted,
    dependencies,
    minIncortaVersion,
  } = featureBit ?? {};
  const [form] = Form.useForm();
  const [showIncortaVersion, setShowIncortaVersion] =
    useState(enabledByDefault);

  const dependenciesPlaceHolder =
    '[{"configKey": "key_1", "configEnableValue": "enableValue_1", "configDisableValue": "disableValue_1"},' +
    '{"configKey": "key_2", "configEnableValue": "enableValue_2", "configDisableValue": "disableValue_2"}]';
  let { makeRequest, isPending } = useRequest(
    useCallback(
      async featureBit => {
        isNew
          ? await createFeatureBit(featureBit)
          : await updateFeatureBit(id, featureBit);
        await refetchFeatureBits();
      },
      [id, isNew, refetchFeatureBits],
    ),
    {
      handleError: false,
    },
  );

  async function handleFinish(featureBit) {
    try {
      if (
        featureBit.dependencies !== undefined &&
        featureBit.dependencies !== ''
      ) {
        JSON.parse(featureBit.dependencies); // test if dependencies is json formatted
      } else {
        featureBit.dependencies = '';
      }
      await makeRequest(featureBit);
      close();
    } catch (e) {
      showErrorMessage(
        'Dependencies field is not in correct JSON Array format or missing some values (configKey, configEnableValue, configDisableValue)',
      );
    }
  }

  return (
    <Modal
      visible
      title={isNew ? 'New Feature Bit' : 'Edit Feature Bit'}
      footer={null}
      onCancel={close}
    >
      <Form
        initialValues={{
          name,
          key,
          enabled,
          enabledByDefault,
          updateThrough,
          serviceToBeRestarted,
          minIncortaVersion,
          dependencies,
        }}
        layout="vertical"
        name="Feature Bit"
        onFinish={handleFinish}
        form={form}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Feature Bit Name Cannot Be Empty',
            },
          ]}
        >
          <Input disabled={!isNew} />
        </Form.Item>

        <Form.Item
          name="key"
          label="Key"
          rules={[
            {
              required: true,
              message: 'Feature Bit Key Cannot Be Empty',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="enabled"
          label="Enabled for the whole system"
          valuePropName="checked"
        >
          <Switch defaultChecked={isNew || enabled} />
        </Form.Item>

        <Form.Item
          name="enabledByDefault"
          label="Enabled By Default"
          valuePropName="checked"
        >
          <Switch
            defaultChecked={!isNew || enabledByDefault}
            onChange={e => {
              if (!e) form.setFieldValue('minIncortaVersion', '');
              setShowIncortaVersion(e);
            }}
          />
        </Form.Item>
        <Form.Item name="minIncortaVersion">
          <Input
            disabled={!showIncortaVersion}
            placeholder="Minimum Incorta Version"
          />
        </Form.Item>

        <Form.Item name="updateThrough" label="Update Through">
          <Radio.Group defaultValue="cmc">
            <Radio value="cmc">CMC</Radio>
            <Radio value="node">Node Properties</Radio>
            <Radio value="engine">Engine Properties</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          name="serviceToBeRestarted"
          label="Service to restart when enabled"
        >
          <Radio.Group defaultValue="none">
            <Radio value="all">All</Radio>
            <Radio value="analytics">Analytics</Radio>
            <Radio value="loader">Loader</Radio>
            <Radio value="none">None</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="dependencies" label="Dependencies">
          <Input.TextArea
            placeholder={dependenciesPlaceHolder}
            allowClear
            autoSize={true}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {isNew ? 'Create' : 'Update'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FeatureBitModal;
