import { useCallback, useState, useContext } from 'react';
import { Modal, Form, Input, Button, Space, Select } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { RedoOutlined } from '@ant-design/icons';
import { restoreClusterFromBackup, cloneCluster } from 'services/cluster';
import { getValidBackups, getInstancesNamesRunning } from 'services/backup';
import useRequest from 'hooks/useRequest';
import { multiclusterAction } from 'utils/helpers';
import {
  SetActiveModalContext,
  SetModalErrorContext,
} from 'components/Clusters/contexts/ClusterContext';
import { modalKeys } from 'components/Clusters/helper';

function RestoreClusterModal({ refetchClusters, close }) {
  const [instancesNames, setInstancesNames] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState('');
  const [validBackups, setValidBackups] = useState([]);
  const [showValidBackups, setShowValidBackups] = useState(false);
  const [showFetchValidBackups, setFetchValidBackups] = useState(false);
  const setActiveModal = useContext(SetActiveModalContext);
  const setError = useContext(SetModalErrorContext);

  function handleShowErrorModal({ message, responseMessages }) {
    setError({ message, responseMessages });
    setActiveModal(modalKeys.ERROR);
  }

  let { makeRequest, isPending } = useRequest(
    useCallback(
      async cluster => {
        let { name, clone } = cluster ?? {};
        if (cluster.backupVersion !== 'Latest' && cluster.backupVersion != -1) {
          await multiclusterAction(
            restoreClusterFromBackup,
            [cluster.backupVersion, name],
            refetchClusters,
            handleShowErrorModal,
          );
        } else {
          await cloneCluster(clone, cluster);
        }
      },
      [refetchClusters],
    ),
    {
      handleError: true,
    },
  );

  let {
    result,
    makeRequest: fetchValidBackups,
    isPending: isLoading,
  } = useRequest(
    useCallback(async selectedInstance => {
      let result = await getValidBackups(selectedInstance);
      return result;
    }, []),
  );

  const debounced = useDebouncedCallback(async name => {
    let res = [];
    if (name) {
      let instancesNames = await getInstancesNamesRunning(name);
      res = instancesNames.data.instancesNames;
    }
    setInstancesNames(res);
  }, 500);

  async function handleSearch(name) {
    setShowValidBackups(false);
    setFetchValidBackups(false);
    debounced.callback(name);
  }

  const handleChange = async value => {
    setFetchValidBackups(true);
    setSelectedInstance(value);
  };

  const handleFetchValidBackups = async () => {
    await new Promise(resolve => {
      handleSearch(selectedInstance);
      resolve(selectedInstance);
    });

    let result = await fetchValidBackups(selectedInstance);
    if (result?.data && result.data.validBackups)
      setValidBackups(result.data.validBackups);

    setShowValidBackups(true);
  };

  async function handleFinish(cluster) {
    await makeRequest(cluster);
    close();
  }

  return (
    <Modal visible title={'Restore Cluster'} footer={null} onCancel={close}>
      <Form layout="vertical" name="cluster" onFinish={handleFinish}>
        <Form.Item
          name="name"
          label="New Cluster Name"
          rules={[{ required: true }]}
        >
          <Input placeholder="New Cluster Name" />
        </Form.Item>
        <Form.Item
          name="clone"
          label="Choose Running Cluster To Be Restored"
          rules={[{ required: true }]}
        >
          <Select
            showSearch
            value={selectedInstance}
            placeholder={'Choose Cluster To Be Restored'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
          >
            {instancesNames &&
              instancesNames.map(name => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item name="refetch">
          <Button
            disabled={!showFetchValidBackups}
            type="primary"
            size="small"
            icon={<RedoOutlined />}
            onClick={handleFetchValidBackups}
          >
            Fetch {selectedInstance} Backups
          </Button>
        </Form.Item>
        {showValidBackups && (
          <Form.Item
            name="backupVersion"
            label={'Backup version'}
            initialValue={validBackups ? validBackups[0]?.backup : 'Latest'}
            rules={[
              {
                required: true,
                message: 'Backup Version is required.',
              },
            ]}
          >
            <Select loading={isLoading}>
              {validBackups &&
                validBackups.map(({ id, backup, backupType }) => {
                  return (
                    <Select.Option
                      key={id}
                      value={id}
                      className="image-options_ddl"
                    >
                      {' '}
                      {id !== -1 ? new Date(backup).toLocaleString() : backup}
                      {backupType === 'full'
                        ? ' (Full Backup)'
                        : backupType === 'metadata'
                        ? ' (Metadata Backup)'
                        : backupType === 'metadisks'
                        ? '(Metadata and Disks Backup)'
                        : backupType === 'disks'
                        ? '(Disks Backup)'
                        : ''}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        )}

        <Space>
          <Button type="primary" htmlType="submit" loading={isPending}>
            {'Restore'}
          </Button>
          <Button onClick={close}>Cancel</Button>
        </Space>
      </Form>
    </Modal>
  );
}

export default RestoreClusterModal;
