import axios from 'axios';
import {
  ChidoriStateResponse,
  ExposeChidoriCredentialsResponse,
} from 'types/spark';
import { ChidorisResponse } from 'types/chidori';
import { API_URL } from 'utils/http';

export function submitChidori(values, instance) {
  return axios.post(`${API_URL}/spark/chidori`, {
    values,
    instanceName: instance.name,
    userID: instance.userID,
  });
}

export async function fetchInstanceChidoriStatus(instanceId) {
  try {
    const res = await axios.get<ChidoriStateResponse>(
      `${API_URL}/spark/fetchChidori`,
      {
        params: { instanceId },
      },
    );
    return res.data.chidoriState;
  } catch (e) {
    throw new Error(e);
  }
}

export async function exposeChidoriCredentials(instanceName: string) {
  try {
    const res = await axios.post<ExposeChidoriCredentialsResponse>(
      `${API_URL}/spark/expose/chidori`,
      {
        instanceName,
      },
    );
    return res.data;
  } catch (e) {
    throw new Error(e);
  }
}

export async function disableChidoriPublicApi(instanceName: string) {
  try {
    const res = await axios.delete(
      `${API_URL}/spark/apis/chidori/${instanceName}`,
      {},
    );
    return res.data;
  } catch (e) {
    throw new Error(e);
  }
}

export type chidoriSizeItem = {
  id: number;
  size: string;
  sizeValue: number;
  createdAt: Date;
  updatedAt: Date;
};
type chidoriSizesResult = {
  allChidoriSizesInTable: {
    count: number;
    rows: chidoriSizeItem[];
  };
  total: number;
  limit: number;
  page: number;
  pagesCount: number;
};
export async function getChidoriSizes({ page = 1, search, userid = null }) {
  try {
    const res = await axios.get<chidoriSizesResult>(
      `${API_URL}/spark/getChidoriSizesTable`,
      {
        params: { page, search, userid },
      },
    );
    return res.data.allChidoriSizesInTable.rows;
  } catch (e) {
    throw new Error(e);
  }
}

export type modifiedChidoriSize = { name: string; memory: string };
export async function updateChidoriSizes({
  chidoriSizes,
}: {
  chidoriSizes: modifiedChidoriSize[];
}) {
  try {
    const res = await axios.post<any>(
      `${API_URL}/spark/updateChidoriSizesTable`,
      {
        chidoriSizes,
      },
    );
    return res;
  } catch (e) {
    throw new Error(e);
  }
}

export function upgradeChidori(instanceName: string, userID: string) {
  return axios.post(`${API_URL}/spark/upgrade/chidori`, {
    instanceName,
    userID,
  });
}

export async function fetchAllChidoris({ page = 1, limit = 10, search = '' }) {
  try {
    return await axios.get<ChidorisResponse>(`${API_URL}/spark/chidoris`, {
      params: { page, limit, search },
    });
  } catch (e) {
    throw new Error(e);
  }
}
