import './slis.less';
import { Select, Form, Typography, Spin } from 'antd';
import { useState, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { getInstancesNames, getSlis } from 'services/clustersstatus';
import useRequest from 'hooks/useRequest';

let { Title } = Typography;
let { Option } = Select;

function SLIs() {
  const [instancesNames, setInstancesNames] = useState<any>([]);
  const [operatorFilter, setOperatorFilter] = useState<any>('all');
  const [selectedInstance, setSelectedInstance] = useState('');
  const [timeFilter, setTimeFilter] = useState('daily');

  const filterParams = useMemo(
    () => ({
      timeFilter: timeFilter,
      clusterCode: operatorFilter,
      clusterName: selectedInstance,
    }),
    [operatorFilter, timeFilter, selectedInstance],
  );
  let { result: clusterInfoResult, isPending: clusterInfoLoading } = useRequest(
    useCallback(() => getSlis(filterParams), [filterParams]),
    { resolve: true, handleError: true },
  );

  let clusterInfo = clusterInfoResult?.data;
  const handleChange = value => {
    setSelectedInstance(value);
  };
  const handleDurationChange = value => {
    setTimeFilter(value);
  };
  const debounced = useDebouncedCallback(async name => {
    let res = [];
    if (name) {
      let instancesNames = await getInstancesNames(name);
      res = instancesNames.data.instancesNames;
    }
    setInstancesNames(res);
  }, 500);
  async function handleSearch(name) {
    debounced.callback(name);
  }

  return (
    <div>
      <Form layout="inline" name="filterStuff">
        <Form.Item name="Duration">
          <Select
            defaultValue="Daily"
            style={{ width: 120 }}
            onChange={value => handleDurationChange(value)}
            options={[
              { value: 'daily', label: 'Daily' },
              { value: 'weekly', label: 'Weekly' },
              { value: 'monthly', label: 'Monthly' },
            ]}
          />
        </Form.Item>
        {/* <Form.Item name="operatorFilter">
          <FieldSelect
            operators={clusterInfo.clusterCodes}
            onChange={e => {
              setOperatorFilter(e);
            }}
          />
        </Form.Item> */}
        <Form.Item name="instanceName">
          <Select
            showSearch
            value={selectedInstance}
            placeholder={'Cluster Name'}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={null}
            allowClear
            style={{ width: 250 }}
          >
            {instancesNames &&
              instancesNames.map(name => (
                <Select.Option key={name} value={name}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
      <div className="clusterSpecifier">
        {clusterSpecifier(selectedInstance)}
      </div>
      {!clusterInfo ? (
        <Spin />
      ) : (
        <div>
          <div className="clusterStats">
            <div
              className="count-info analytics"
              style={{
                color: arrowState(clusterInfo.sli.stats.analytics).color,
              }}
            >
              {arrowState(clusterInfo.sli.stats.analytics).direction}
              <div>
                <span>
                  {' '}
                  <strong> Analytics</strong>
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.stats.analytics}%` || 0}
                </span>
              </div>
            </div>
            <div
              className="count-info loadJobs"
              style={{
                color: arrowState(clusterInfo.sli.stats.loadJobs).color,
              }}
            >
              {arrowState(clusterInfo.sli.stats.loadJobs).direction}
              <div>
                <span>
                  <strong>Load Jobs </strong>{' '}
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.stats.loadJobs}%` || 0}
                </span>
              </div>
            </div>
            <div
              className="count-info mvs"
              style={{
                color: arrowState(clusterInfo.sli.stats.mvs).color,
              }}
            >
              {arrowState(clusterInfo.sli.stats.mvs).direction}
              <div>
                <span>
                  <strong>MVs</strong>
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.stats.mvs}%` || 0}
                </span>
              </div>
            </div>
          </div>
          <div className="errorBudget">
            <div
              className="count-info analytics"
              style={{
                color: arrowStateErrorBudget(clusterInfo.sli.errorBudget.analytics)
                  .color,
              }}
            >
              {
                arrowStateErrorBudget(clusterInfo.sli.errorBudget.analytics)
                  .direction
              }
              <div>
                <span>
                  <strong> Error Budget</strong>
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.errorBudget.analytics}%` || 0}
                </span>
              </div>
            </div>
            <div
              className="count-info loadJobs"
              style={{
                color: arrowStateErrorBudget(clusterInfo.sli.errorBudget.loadJobs)
                  .color,
              }}
            >
              {
                arrowStateErrorBudget(clusterInfo.sli.errorBudget.loadJobs)
                  .direction
              }
              <div>
                <span>
                  <strong> Error Budget </strong>
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.errorBudget.loadJobs}%` || 0}
                </span>
              </div>
            </div>
            <div
              className="count-info mvs"
              style={{
                color: arrowStateErrorBudget(clusterInfo.sli.errorBudget.mvs).color,
              }}
            >
              {arrowStateErrorBudget(clusterInfo.sli.errorBudget.mvs).direction}
              <div>
                <span>
                  <strong>Error Budget </strong>
                </span>{' '}
                <span className="count">
                  {`${clusterInfo.sli.errorBudget.mvs}%` || 0}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="count-section">
        <div className="count-info running-cluster">
          <ArrowUpOutlined />
          <div>
            <span>SLA</span> <span className="count">{'bate5a' || 0}</span>
          </div>
        </div>
      </div> */}
    </div>
  );
}

function FieldSelect({ onChange, loading, operators }: any) {
  return (
    <Select
      allowClear
      onChange={onChange}
      loading={loading}
      dropdownClassName="Priority__select-dropdown"
      style={{
        width: 304,
        height: 20,
      }}
      placeholder="Filter by operator"
    >
      {operators?.map(operator => (
        <Option key={operator.cluster_code} value={`${operator.cluster_code}`}>
          {`${operator.cluster_code}`}
        </Option>
      ))}
      <Option key="All" value="All">
        All Operators
      </Option>
    </Select>
  );
}

function clusterSpecifier(name) {
  if (name) {
    return <Title level={4}>{`${name}:`}</Title>;
  } else {
    return <Title level={4}>{`All clusters: `}</Title>;
  }
}
function arrowState(value) {
  if (value > 90) {
    return {
      color: 'green',
      direction: <ArrowUpOutlined style={{ fontWeight: 'bold' }} />,
    };
  } else {
    return {
      color: 'red',
      direction: <ArrowDownOutlined style={{ fontWeight: 'bolder' }} />,
    };
  }
}
function arrowStateErrorBudget(value) {
  if (value < 5) {
    return {
      color: 'red',
      direction: <ArrowDownOutlined />,
    };
  } else {
    return {
      color: 'green',
      direction: <ArrowUpOutlined />,
    };
  }
}
export default SLIs;
