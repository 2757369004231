import React, { useContext, useState } from 'react';
import { Modal, Form, Input, Button, Space, Checkbox, message } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SessionContext } from 'auth/SessionProvider';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { useAddGlobalNotification } from 'hooks/queries';

function NotificationModal({ close, selectedUsers }) {
  const { user } = useContext(SessionContext);
  const { TextArea } = Input;
  const [email, setEmail] = useState(false);
  const [alert, setAlert] = useState(false);

  const { mutateAsync: mutateAddGlobalNotification, isLoading } =
    useAddGlobalNotification({
      selectedUsersIDs: selectedUsers,
    });

  async function handleFinish(notification) {
    const method = { email: email, alert: alert };
    try {
      await mutateAddGlobalNotification({
        method,
        message: notification.message,
        event: notification.event,
      });
    } catch (error) {
      console.log(error);
    }
    close();
  }

  return (
    <Modal
      visible
      title={'Send New Global Notification'}
      footer={null}
      onCancel={close}
    >
      <div>
        {selectedUsers.length === 0 && (
          <div className="notificationInfo">
            <p>
              <InfoCircleOutlined />
              Looks like you haven't selected any users, this will send a
              notification to all active users!
            </p>
          </div>
        )}
        <Form layout="vertical" name="Notification" onFinish={handleFinish}>
          <Form.Item name="event" label="Event" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="message"
            label="Message"
            rules={[{ required: true }]}
          >
            <TextArea />
          </Form.Item>
          <Form.Item
            name="method"
            label="Send via"
            rules={[
              {
                message: 'At least one method is required!',
                validator: (_, value) => {
                  if (!email && !alert) {
                    return Promise.reject('At least one method is required!');
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Checkbox
              onChange={e => {
                setEmail(e.target.checked);
              }}
            >
              Email
            </Checkbox>
            <Checkbox
              onChange={e => {
                setAlert(e.target.checked);
              }}
            >
              Alert
            </Checkbox>
          </Form.Item>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={
                +user.userRolesPagesPermissions?.[
                  userRolesPages.NOTIFICATIONS
                ] < +userRolesPagesPermissionsValues.CAN_EDIT
              }
            >
              {'Send'}
            </Button>
            <Button onClick={close}>Cancel</Button>
          </Space>
        </Form>
      </div>
    </Modal>
  );
}

export default NotificationModal;
