import {
  FilterOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import { useState, useCallback, useContext } from 'react';
import { Typography, Button, Tooltip, Space, Tag } from 'antd';

import ContextManagerModal from './ContextManagerModal/ContextManagerModal';
import ContextManagerCriteriaModal from './ContextManagerModal/ContextManagerCriteriaModal';
import { getOperators } from 'services/contextManager';
import useRequest from 'hooks/useRequest';
import SearchableTable from 'components/ui/SearchableTable/SearchableTable';
import { userRolesPages, userRolesPagesPermissionsValues } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

function ContextManager() {
  const { user } = useContext(SessionContext);
  const [search, setSearch] = useState('');
  const [modalProps, setModalProps] = useState<any>(null);
  const [criteriaModalProps, setCriteriaModalProps] = useState<any>(null);

  const userHasAnyOperatorsPagePermission =
    userRolesPages.OPERATORS in user.userRolesPagesPermissions;
  const userHasEditOperatorsPagePermission =
    +user.userRolesPagesPermissions?.[userRolesPages.OPERATORS] >=
    +userRolesPagesPermissionsValues.CAN_EDIT;

  const {
    result,
    isPending,
    makeRequest: refetchOperators,
  } = useRequest(
    useCallback(() => getOperators(search), [search]),
    { resolve: true },
  );

  const operators = result?.data?.operators;

  const columns = [
    {
      title: 'Cluster Code',
      dataIndex: 'cluster_code',
      width: '20%',
      render(_, operator) {
        return (
          <>
            {operator.cluster_code}
            &emsp;
            {operator.incortaOne === 'allUsers' ? (
              <Tooltip title="Operator Publicly Available">
                <Tag color="green">{'Publicly Available'}</Tag>
              </Tooltip>
            ) : null}
            {operator.incortaOne === 'incortaOneExclude' ? (
              <Tooltip title="Operators Excluded for Incorta One">
                <Tag color="red">{'Incorta One Excluded'}</Tag>
              </Tooltip>
            ) : null}
            {operator.incortaOne === 'incortaOneExclusive' ? (
              <Tooltip title="Operators Exclusive For Incorta One">
                <Tag color="blue">{'Exclusive For Incorta One'}</Tag>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Region',
      dataIndex: 'region',
    },
    {
      title: 'Zone',
      dataIndex: 'zone',
    },
    {
      title: 'Url',
      dataIndex: 'url',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width: '10%',
      render(priority) {
        return priority > 0 ? priority : 'Disabled';
      },
    },
    {
      title: 'Action',
      render(_, operator) {
        return (
          <Space>
            <Tooltip
              title={
                !userHasEditOperatorsPagePermission
                  ? 'You don❜t have sufficient permission'
                  : 'Edit'
              }
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setModalProps({ operator });
                }}
                disabled={!userHasEditOperatorsPagePermission}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Tooltip
              title={
                !userHasAnyOperatorsPagePermission
                  ? 'You don❜t have sufficient permission'
                  : 'Set Filter Criteria'
              }
            >
              <Button
                size="small"
                type="primary"
                onClick={() => {
                  setCriteriaModalProps({ operator });
                }}
                disabled={!userHasAnyOperatorsPagePermission}
              >
                <FilterOutlined />
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  return (
    <div className="contextmanager">
      <Title>Operators</Title>

      <SearchableTable
        pagination={false}
        dataSource={operators}
        loading={isPending}
        columns={columns}
        onSearch={value => {
          setSearch(value);
        }}
        searchPlaceholder={'Search By Region or Url.'}
      />

      {modalProps && (
        <ContextManagerModal
          {...modalProps}
          refetchOperators={refetchOperators}
          close={() => setModalProps(null)}
        />
      )}

      {criteriaModalProps && (
        <ContextManagerCriteriaModal
          {...criteriaModalProps}
          refetchOperators={refetchOperators}
          close={() => setCriteriaModalProps(null)}
        />
      )}
    </div>
  );
}

export default ContextManager;
